import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../context/AppContext"
import { SideBar } from "../../layout/dashboardLayout/SideBar";
import { Header } from "../../layout/dashboardLayout/Header";
import { message } from "antd";
import { useMutation } from "react-query";
import { useState } from "react";
import { changePassword } from "../../api/userDetails";
import { Footer } from "../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../component/BackToTop";

export const ChangePassword = () => {
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [passError, setPassError] = useState('');
    const [lengthError, setLengthError] = useState('');


    const { sidebarOpen } = useAppContext();

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (newpassword !== e.target.value) {
            setPassError("Password do not match")
        }
        else {
            setPassError('');
        }
    }

    //change pass logic
    const changePass = useMutation(changePassword, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Successfully update Password!");
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    })

    const validatePassword = () => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        return strongRegex.test(newpassword);
    }

    const passwordStrength = () => {
        if (newpassword.length === 0) {
            return '';
        } else if (newpassword.length < 8) {
            return 'Weak';
        } else if (!validatePassword()) {
            return 'Medium';
        } else {
            return 'Strong';
        }
    }

    const passwordStrengthColor = () => {
        if (passwordStrength() === 'Weak') {
            return 'red';
        } else if (passwordStrength() === 'Medium') {
            return '#ffd11a';
        } else if (passwordStrength() === 'Strong') {
            return 'green';
        } else {
            return '';
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (oldpassword === "" ||
            newpassword === "" ||
            confirmpassword === "") {
            setError("Please fill above field");
            return;

        }

        if (newpassword !== confirmpassword) {
            message.error("does not match password")
        }

        if (newpassword.length < 5 || confirmpassword.length < 5) {
            setLengthError("Password must be between 5 and 15 characters.");
            return;
        }

        let data = {
            oldpassword,
            newpassword,
            confirmpassword
        }

        changePass.mutate(data);
    }

    return (
        <>
            <Helmet><title>Change Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Change Password</h1>
                    </div>
                </div>

                <div className="bg-dark rounded-3 px-4 py-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="current_password" className="form-label">Current Password <span className="required-mark">*</span></label>
                                    <input name="current_password" className="form-control form-control-lg"
                                        id="current_password" placeholder="Enter Current Password"
                                        value={oldpassword}
                                        type="password"
                                        onChange={(e) => setOldPassword(e.target.value)} />
                                    <p className={
                                        oldpassword.length === 0 && error
                                            ? "text-danger"
                                            : "d-none"
                                    }>{error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="new_password" className="form-label">New Password <span className="required-mark">*</span></label>
                                    <input name="new_password" className="form-control form-control-lg"
                                        id="new_password"
                                        placeholder="Enter New Password"
                                        type="password"
                                        value={newpassword}
                                        onChange={(e) => setNewPassword(e.target.value)} />
                                    <div>Password Strength: <span style={{ color: passwordStrengthColor(), fontWeight: 600, fontSize: "14px" }}>{passwordStrength()}</span></div>
                                    <div className={
                                        (newpassword.length < 5 || newpassword.length > 15) && lengthError
                                            ? "text-danger"
                                            : "d-none"}>{lengthError}</div>
                                    <p className={
                                        newpassword.length === 0 && error
                                            ? "text-danger"
                                            : "d-none"
                                    }>{error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="new_password_confirmation" className="form-label">Confirm Password <span className="required-mark">*</span></label>
                                    <input name="new_password_confirmation" className="form-control form-control-lg"
                                        id="new_password_confirmation"
                                        placeholder="Enter Confirm Password"
                                        value={confirmpassword}
                                        onChange={handleConfirmPasswordChange} />
                                    {passError && <div className="text-danger">{passError}</div>}
                                    <p className={
                                        confirmpassword.length === 0 && error
                                            ? "text-danger"
                                            : "d-none"
                                    }>{error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
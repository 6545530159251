import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { DeleteUser, getAllUser } from "../../../api/user";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Image, message } from "antd";
import { TablePagination } from "@mui/material";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

const StyleTablePagination = styled(TablePagination)`
.css-levciy-MuiTablePagination-displayedRows {
    font-family: Gilroy;
    padding-top: 10px;
  }
  .css-pdct74-MuiTablePagination-selectLabel {
    padding-top:9px;
    font-family: Gilroy;
  }
  
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    font-family: Gilroy;
  }
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    fill: #ffff;
  }
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #ffff;
}
`

export const RewardList = () => {
    const {sidebarOpen} = useAppContext();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const[searched, setSearched]= useState("")


     //get user 
     const { data: allUserDetails} = useGetdata(
        "allUserDetails",
        getAllUser
    )


    // pagination

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const filteredData = allUserDetails?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email.toLowerCase().includes(searched.toLowerCase())
    

  )

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const PaginatedData = filteredData?.slice(startIndex, endIndex);



    
    //Delete user
    const queryclient = useQueryClient();
    const deleteUser = useMutation(DeleteUser, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("User deleted Successfully!");
                queryclient.invalidateQueries("allUserDetails")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDelete = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to delete user?');
    if(userConfirmed){
        deleteUser.mutate({id});

    }
       
    }

    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Name',
      'Image',
      'Description',
      'NTC',
      'Min Traded Lots Per Client',
      'Min Deposit Per Client',
      'Total Deposit',
      'Total Traded Lots',
      'Status',
    ];
    
    const rows = data?.map((item,index) => [
      index + 1,
      item.name,
      item.image,
      item.description,
      item.ntc,
      item.min_trade_lots_per_client,
      item.min_deposit_per_client,
      item.total_deposit,
      item.traded_lots,
      item.status,
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('Reward List.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
    const dataForExport = data?.map((item,index) => ({
        'ID':index + 1,
        'Name': item.name,
        'Image':item.image,
        'Description': item.description,
        'NTC':item.ntc,
        'Min Traded Lots Per Client': item.min_trade_lots_per_client,
        'Min Deposit Per Client': item.min_deposit_per_client,
        'Total Deposit':item.total_deposit,
        'Total Traded Lots': item.traded_lots,
        'Status':item.status,

    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Reward List.xlsx';

    XLSX.writeFile(wb, filename);
  }


    return (
        <>
         <Helmet><title>Reward List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> Reward List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(allUserDetails?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(allUserDetails?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                        <table className="table table-dark table-striped mb-0 text-center table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Description</th>
                                                    <th>NTC</th>
                                                    <th>Min Traded Lots Per Client</th>
                                                    <th>Min Deposit Per Client</th>
                                                    <th>Total Deposit</th>
                                                    <th>Total Traded Lots</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {allUserDetails?.data?.length > 0 ?
                                            <tbody>
                                                {
                                                    PaginatedData?.map((data)=> (
                                                <tr key={data.id}>
                                                    <td>{data.id}</td>
                                                    <td>{data.name}</td>
                                                    <td><Image src={`https://api.unicorntechnology.co/images/rewardUploads/${data?.image}`} alt="reward" height={60} width={80} /></td>
                                                    <td>{data.description}</td>
                                                    <td>{data.ntc}</td>
                                                    <td>{data.min_trade_lots_per_client}</td>
                                                    <td>{data.min_deposit_per_client}</td>
                                                    <td>{data.total_deposit}</td>
                                                    <td>{data.traded_lots}</td>
                                                    <td>{data.status}</td>
                                                    <td> 
                                                        <button className="btn btn-xs btn-primary">Suspend</button>       
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                            </tbody>: 
                                            <tbody>
                                            <tr className="text-center">
                                               <td  className="dataTables_empty pt-3 pb-3" colSpan={11}>No data available</td>
                                            </tr>
                                        </tbody>}
                                        </table>
                                        <StyleTablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        className="text-white d-flex justify-content-end"
                        count={allUserDetails?.data?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//add bank details
export const CreateBankDetail = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/bankdetail/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //get all bakdetails
export const getAllBankDetails = async (data) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/admin/bankdetail/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //approved bank details
  export const ApprovedBankDetails = async (data) => {
    let token = localStorage.getItem("token");
     const { id}= data
    let response = await instance({
      url: `api/admin/bankdetail/approve/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

   //Reject bank details
   export const RejectBankDetails = async (data) => {
    let token = localStorage.getItem("token");
     const { id }=data
    let response = await instance({
      url: `api/admin/bankdetail/reject/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get single bakdetails
export const getSingleBankDetails = async (data) => {
  let token = localStorage.getItem("token");
const {id} = data;
  let response = await instance({
    url: `api/admin/bankdetail/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//edit bank details
export const EditBanks = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/bankdetail/update/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'multipart/form-data',
    },
    data,
  });

  return response.data;
};

//delete bank details
export const DeleteBanks = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/bankdetail/delete/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

  return response.data;
};
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation } from "react-query";
import { message } from "antd";
import { MoveClientToIb, getApprovedReqUser } from "../../../api/ib";
import { getAllUser } from "../../../api/user";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { getLiveAccountData } from "../../../api/account";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const MoveClientToIB = () => {
    const [ibUserId, setIBUserID] = useState("");
    const [accountno, setAccountNo] = useState("");
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    //get ib users
    const{data: ibApprovedUser} = useGetdata(
        "ibApprovedUser",
        getApprovedReqUser
    )

 //get user list
 const{data: allLiveAccount} = useGetdata(
    "allLiveAccount",
    getLiveAccountData
)

   //move client under ib
   const moveClient = useMutation(MoveClientToIb, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success(" Assign MT5 Under IB Successfully!");
               setDisable(false)
               setIBUserID("");
               setAccountNo("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }

       },
   });

   const handleMoveClient = (e) => {
        e.preventDefault();
        setDisable(true)

       if (
           ibUserId === "" ||
           accountno === "") {
           setLoginError("Please Fill the above field.");
           setDisable(false)
           return;
       }

       const data = {
           ibUserId,
           accountno: Number(accountno)
       }
       moveClient.mutate(data)
   }

    return (
        <>
            <Helmet><title>Assign Client Under IB | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Assign Client Under IB</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="mb-4">
                                    <label for="user" className="form-label">Select MT5 <span className="required-mark">*</span></label>
                                    <SelectDropdown id="mt5"
                                    value={accountno}
                                     onChange={(value) => setAccountNo(value)}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {allLiveAccount?.data?.map((data) => (
                                            <option key={data?.id} value={data?.account_no}>{data?.email} ({data?.account_no})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        accountno === "" && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>

                                <div className="mb-4">
                                    <label for="user" className="form-label">Select IB User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={ibUserId}
                                     onChange={(value) => setIBUserID(value)}>
                                        <option selected="">Please choose...</option>

                                        {ibApprovedUser?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        ibUserId === "" && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleMoveClient}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { useEffect, useState } from "react";
import { GetIBPlanData } from "../../../api/ib";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const IBPlan = () => {
    const {sidebarOpen} = useAppContext();
    const[searched, setSearched]= useState("")


    //get ib plan 
    const { data: allIBPlan } = useGetdata(
        "allIBPlan",
        GetIBPlanData
    );

    const headers = [
        {label: "ID", key:"id", sortable: true},
        {label: "Plan Name", key:"name", sortable: true},
        {label: "Date", key:"createdAt", sortable: true},
        {label: "Action"}
    ]

    const filteredData = allIBPlan?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase())

  )


    //set permission
const{data:checkPermission, refetch} = useGetdata(
    "checkPermission",
    GetPermissionCheck
  )
  
  let token = localStorage.getItem('token');

  useEffect(()=>{
  if(token) {
  refetch()
  }
  },[token, refetch])

    return (
        <>
         <Helmet><title>IB Plan List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>IB Plan List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                            {
                              checkPermission?.typeAdmin === 1 &&
                                <Link to="/admin/ib/addibplan" className="btn btn-xs btn-primary">Add IB Plan</Link>}
                                {
                                  checkPermission?.typeAdmin === 0 &&
                                      checkPermission?.data.includes("add_ibplan") ?
                                <Link to="/admin/ib/addibplan" className="btn btn-xs btn-primary">Add IB Plan</Link> : ""}
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                                <DataTable
                                                data={filteredData}
                                                headers={headers}
                                                renderRow={(rowData)=> (
                                                <>
                                                    <td>{rowData.name}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                    <td>
                                                    <Link to={`/admin/ib/editibplan/${rowData.id}`} className="btn btn-primary btn-xs mb-1"> Edit </Link>
                                                    </td>
                                                    
                                                </>
                                                )} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//get pending kyc 
export const getPendingKYcList = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/kyc/all?type=pending`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //get approved Kyc
  export const getApprovedKYC = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/kyc/all?type=approved`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };


  //single Kyc
  export const getSingleKyc = async (data) => {
    const {id} = data;
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/kyc/user/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };


  //kyc approved
  export const ApprovedKyc = async (kyc) => {
    let token = localStorage.getItem("token");
     const { id, data}= kyc
    let response = await instance({
      url: `api/admin/kyc/approve/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //kyc Reject
  export const RejectKyc = async (kyc) => {
    let token = localStorage.getItem("token");
     const { id, data}= kyc
    let response = await instance({
      url: `api/admin/kyc/reject/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get user kyc followup list
  
  export const getUserFollowUpList = async () => {

    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/user/kyc/followlist`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //add kyc
export const AddUserKyc = async (data) => {
  let token = localStorage.getItem("token");
  
  let response = await instance({
    url: "api/admin/kyc/add",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

  return response.data;
};
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useRef, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { getAllUser } from "../../../api/user";
import {  message } from "antd";
import { getAllCountry } from "../../../api/countries"
import { useNavigate, useParams } from "react-router-dom";
import { EditBanks, getSingleBankDetails } from "../../../api/bankDetails";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditBankDetail = () => {
    const [userId, setUserId] = useState("");
    const [accountname, setAccountName] = useState("")
    const [accountnumber, setAccountNumber] = useState("")
    const [bankname, setBankName] = useState("")
    const [bankcountry, setBankCountry] = useState("")
    const [iban, setIban] = useState("")
    const [ifsc, setIfsc] = useState("");
    const [bankaddress, setBankAddress] = useState("");
    // const [bankbook, setBankBook] = useState(null);
    const [selectedBank, setSelectedBank] = useState({})
    const[disable, setDisable]=useState(false)
    const navigate = useNavigate()


    const { id } = useParams();
    const { sidebarOpen } = useAppContext();

    //get All users
    const { data: allUsers, isLoading: userDLoad } = useGetdata(
        "allUsers",
        getAllUser
    )

    // get Country
    const { data: getCountries } = useGetdata(
        "getCountries",
        getAllCountry
    )

    // fetch single bank details
    useEffect(() => {
        const fetchSingleBank = async () => {
            try {
                const data = await getSingleBankDetails({ id });
                setSelectedBank(data);
            } catch (error) {
                message.error(error.message);
            }
        };

        fetchSingleBank();
    }, [id]);


    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setBankBook(file);
    // };

    //add bank details
    const quericlient = useQueryClient();
    const editbank = useMutation(EditBanks, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Bank Details Edited Successfully!");
                setDisable(false)
                navigate("/admin/user/bankdetailslist")
                quericlient.invalidateQueries("getBankDetails");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    useEffect(() => {
        if (!userDLoad) {
            setUserId(selectedBank?.data?.user_id)
            setAccountName(selectedBank?.data?.accountname)
            setAccountNumber(selectedBank?.data?.accountnumber)
            setBankName(selectedBank?.data?.bankname)
            setBankCountry(selectedBank?.data?.bankcountry)
            setIban(selectedBank?.data?.iban)
            setIfsc(selectedBank?.data?.ifsc)
            setBankAddress(selectedBank?.data?.bankaddress)
            // setBankBook(selectedBank?.data?.bankbook)
        }
    }, [userDLoad, selectedBank])

    const handleBankDetails = (e) => {
        e.preventDefault();
        setDisable(true)

        // const formData = new FormData();
        // formData.append('userId', userId);
        // formData.append('accountname', accountname);
        // formData.append('accountnumber', accountnumber);
        // formData.append('bankname', bankname);
        // formData.append('bankcountry', bankcountry);
        // formData.append('iban', iban)
        // formData.append('ifsc', ifsc);
        // formData.append('bankaddress', bankaddress);

        const data = {
            id,
            userId,
            accountname,
            accountnumber,
            bankname,
            bankcountry,
            iban,
            ifsc,bankaddress
        }
        editbank.mutate(data);

    }

    return (
        <>
            <Helmet><title>Edit Bank Details | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit User Bank Details</h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <form>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                        <SelectDropdown
                                          id="user"
                                             value={userId}
                                            onChange={(value) => setUserId(value)}>
                                            {/* <option selected="">Please choose...</option> */}
                                            {allUsers?.data?.map((data) => (
                                                <option key={data?.id} value={data.id}>{data?.name} ({data.email})</option>

                                            ))}
                                        </SelectDropdown>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="accountname" className="form-label">Account Name<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="accountname" name="accountname"
                                            type="text" placeholder="Enter account name"
                                            value={accountname} onChange={(e) => setAccountName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="accountno" className="form-label">Account Number<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="accountno" name="accountno"
                                            type="text" placeholder="Enter account number"
                                            value={accountnumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="bankname" className="form-label">Bank Name<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="bankname" name="bankname"
                                            type="text" placeholder="Enter bank name"
                                            value={bankname} onChange={(e) => setBankName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                        <SelectDropdown className="form-select form-select-lg " id="country"
                                        value={bankcountry}
                                        onChange={(value) => setBankCountry(value)}>
                                            {/* <option selected="">Please choose...</option> */}
                                            {getCountries?.countries?.map((data) => (
                                                <option key={data.code} value={data.country}>{data?.country}</option>

                                            ))}
                                        </SelectDropdown>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="iban" className="form-label">IBAN<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="iban" name="iban"
                                            type="text" placeholder="Enter iban"
                                            value={iban} onChange={(e) => setIban(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="ifsc" className="form-label">IFSC Code<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="ifsc" name="ifsc"
                                            type="text" placeholder="Enter ifsc/swift code"
                                            value={ifsc}
                                             onChange={(e) => setIfsc(e.target.value)} />
                                    </div>
                                </div>
                                {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="bankbook" className="form-label">Bank Book <span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg bg-secondary" id="bankbook" name="bankbook"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={handleFileChange} />
                                    </div> 
                                        </div>*/}
                                <div className="col-sm-6 col-md-4 col-lg-4 mb-4">
                                    <div className="mb-4">
                                        <label for="bankaddress" className="form-label">Bank Address<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="bankaddress" name="bankaddress"
                                            type="text" placeholder="Enter bank address"
                                            value={bankaddress}
                                            onChange={(e) => setBankAddress(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2">
                                    <button className="btn btn-primary btn-xs font-15" type="submit"
                                        onClick={handleBankDetails} 
                                        disabled={disable}>
                                            {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
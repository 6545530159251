import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { CreateGroup } from "../../../api/group";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddGroup = () => {
    const[name, setName]= useState("")
    const[mtgroup, setMtgroup]= useState("")
    const[status, setStatus]= useState("")
    const[loginError, setLoginError]=useState("");
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();
    const navigate = useNavigate()

   //add Group 
const queryclient = useQueryClient();
const  addGroup= useMutation(CreateGroup, {
  onSuccess: (data) => {
    if (data?.status === 200) {
      message.success("Group Created Successfully!");
      navigate("/admin/group/grouplist");
      queryclient.invalidateQueries("allGroupDetails");
      setDisable(false)

    setName("");
    setMtgroup("");
    setStatus("");

    }
    
  },
  onError: (data) => {
    setDisable(false)
    if (data?.response?.data.status === 404) {
        message.error(data?.response.data.message);
    }
    if (data?.response?.data.status === 400) {
        message.error(data?.response.data.message);
    }
    if (data?.response?.data.status === 403) {
        message.error(data?.response.data.message);
    }
    if (data?.response?.data.status === 500) {
        message.error(data?.response.data.message);
    }
  },
});


const handleAddGroup = (e) => {
    e.preventDefault()
    setDisable(true)

    if (name === "" ||
            mtgroup === "" ||
            status === "" ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

    let data = { 
        name,
        mtgroup,
        status: Number(status),

    }
    addGroup.mutate(data)
}


    return (
        <>
            <Helmet><title>Add Group | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add Group</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e)=> setName(e.target.value)}
                                        />
                                        <p className={
                                    name.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="mtgroup" className="form-label">MT5 Group Name <span className="required-mark">*</span></label>
                                    <input type="mtgroup" name="mtgroup" className="form-control form-control-lg " id="email"
                                        placeholder="Enter group Name" 
                                        value={mtgroup}
                                        onChange={(e)=> setMtgroup(e.target.value)}/>
                                         <p className={
                                    mtgroup.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="status" className="form-label">Status <span className="required-mark">*</span></label>
                                    <SelectDropdown id="groupstatus"
                                    value={status}
                                     onChange={(value) => setStatus(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value={0}>Inactive</option>
                                        <option value={1}>Active</option>
                                    </SelectDropdown>
                                    <p className={
                                        status.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddGroup}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";


//Get ib request
export const getIBRequest = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/ib/requested/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //Approve Ib request
  export const ApproveIBRequest = async (id) => {
    let token = localStorage.getItem("token");
    // const {id, data} = user
  let response = await instance({
    url: `api/admin/ib/approve/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // data
  });

  return response.data;
};

 //reject Ib request
 export const RejectIBRequest = async (id) => {
    let token = localStorage.getItem("token");
    // const {id, data} = user
  let response = await instance({
    url: `api/admin/ib/reject/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // data
  });

  return response.data;
};

//Get Approved users
export const getApprovedReqUser = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/ib/approved/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };


  //get ib user Level
  export const getIBLevel = async (ib_key) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/ib/levels/${ib_key}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //add user Ib Commission
  export const AddUserIBCommission = async (data) => {
    let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/setcommission`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
     data,
  });

  return response.data;
};

//get all ib plan list
export const getAllIBPlan = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/plan/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get all Ib Commission
export const GetAllIBCommssion = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/commission/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get single ib commission

export const GetSingleIBCommission = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/ib/commission/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};
//update ib commission
export const UpdateUserIBCommission = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
let response = await instance({
  url: `api/admin/ib/commission/update/${id}`,
  method: "PUT",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//get all Ib plan
export const GetIBPlanData = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/plan/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//add IB plan
export const AddPlanIB = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/ib/plan/create`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//edit ib plan 
export const EditPlanIB = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  console.log(data)
let response = await instance({
  url: `api/admin/ib/plan/update/${id}`,
  method: "PUT",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//get single commission group
export const GetSingleCommissionGroup = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/ib/commissiongroup/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get all commission group
export const GetCommissionGroup = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/commissiongroup/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//add commission group
export const AddGroupCommissions = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/ib/commissiongroup/create`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//update groupCommission

export const UpdateCommissionGroup = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
let response = await instance({
  url: `api/admin/ib/commissiongroup/update/${id}`,
  method: "PUT",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//ib to ib
export const AssignIBToIb = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/ib/moveibtoib`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//move to client
export const MoveClientToIb = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/ib/mt5moveclient`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};
//remove to client
export const RemoveIBClient = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/ib/removeclient`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//demote ib
export const DemoteIbClient = async (data) => {
  let token = localStorage.getItem("token");
let response = await instance({
  url: `api/admin/user/demoteib`,
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//get single ib commission list
export const getMyIBCommission = async (id, data) => {
  let token = localStorage.getItem("token");
  const {startDate, endDate} = data;
  let response = await instance({
    url: `api/admin/ib/mycommission/${id}?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//pending ib withdraw
export const getAllPendingIBWithdraw = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/pendingibwithdraw`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

 //approve ib withdraw
 export const ApproveIBWithdraw = async (ibwithdraw) => {
  let token = localStorage.getItem("token");
   const { id, data}= ibwithdraw
  let response = await instance({
    url: `api/admin/ib/withdraw/approve/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

 //Reject ib withdraw
 export const RejectIBWithdraw = async (ibwithdraw) => {
  let token = localStorage.getItem("token");
   const { id, data}= ibwithdraw
  let response = await instance({
    url: `api/admin/ib/withdraw/reject/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

 //get ib tree data
 export const GetIBTreeData = async (data) => {
  let token = localStorage.getItem("token");
   const { id}= data;
  let response = await instance({
    url: `api/admin/ib/treedata/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get single ib tree data
export const GetSingleIBTreeDetail = async (data) => {
  let token = localStorage.getItem("token");
  const{id} =data;
  let response = await instance({
    url: `api/admin/ib/treesingledetails/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get ib withdraw report
export const getIBWithdrawReport = async (data) => {
  let token = localStorage.getItem("token");
  const {fromDate, toDate, type, paymentmethod}=data;
let response = await instance({
  url: `api/admin/report/ibwithdraw?type=${type}&paymentmethod=${paymentmethod}&fromDate=${fromDate}&toDate=${toDate}`,
  method: "GET",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});

return response.data;
};

//add ib withdraw
export const IbWithdrawAdd = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/ib/addibwithdraw`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//team total ib
export const getTeamTotalIB = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/ib/teamtotal/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
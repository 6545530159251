import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { addMarketing } from "../../../api/marketing";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const AddMarketing = () => {
    const { sidebarOpen } = useAppContext();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('');
    const [incentive, setIncentive] = useState("");
    const [netDeposit, setNetDeposit] = useState("");
    const [lot, setLot] = useState("");
    const [loginError, setLoginError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const[disable, setDisable] = useState(false)
    const navigate = useNavigate();


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };


    //create marketing
    const queryclient = useQueryClient();
    const marketinguser = useMutation(addMarketing, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                 
                message.success("Add Marketing User Successfully!");
                queryclient.invalidateQueries("marketinglistdata");
                navigate("/admin/marketing/marketinglist");
                setDisable(false)
                setName("");
                setEmail("");
                setPassword("");
                setIncentive("");
                setNetDeposit("");
                setLot("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleAddMarketing = async (event) => {
        event.preventDefault();
        setDisable(true)
        if (name === "" ||
            email === "" ||
            password === "" ||
            incentive === "" ||
            netDeposit === "" ||
        lot === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        let data = {
            name,
            email,
            password,
            incentive,
            netDeposit,
            lot

        };
        marketinguser.mutate(data);


    }


    return (
        <>
            <Helmet><title>Add Marketing | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add Marketing</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <p className={
                                        name.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="email" name="email" className="form-control form-control-lg " id="email"
                                        placeholder="Enter Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p className={
                                        email.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="password" className="form-label">Password <span className="required-mark">*</span></label>
                                    <input type={showPassword ? 'text' : 'password'} name="password" className="form-control form-control-lg " id="password"
                                        placeholder="Enter Password" value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                     <span
                                            className="eye-icon toggle-password" onClick={togglePasswordVisibility}>
                                       <i className= {showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}>
                                        </i></span>
                                    <p className={
                                        password.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="incentive" className="form-label">Incentive(%) <span className="required-mark">*</span></label>
                                    <input type="text" name="incentive" className="form-control form-control-lg "
                                        id="incentive" placeholder="Incentive" value={incentive}
                                        onChange={(e) => setIncentive(e.target.value)}
                                    />
                                    <p className={
                                        incentive.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="netdeposit" className="form-label">Net Deposit (Monthly) <span className="required-mark">*</span></label>
                                    <input type="text" name="netdeposit" className="form-control form-control-lg "
                                        id="netdeposit" placeholder="Net Deposit" value={netDeposit}
                                        onChange={(e) => setNetDeposit(e.target.value)}
                                    />
                                    <p className={
                                        netDeposit.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="lot" className="form-label">Lot<span className="required-mark">*</span></label>
                                    <input type="text" name="lot" className="form-control form-control-lg "
                                        id="lot" placeholder="Enter lot" value={lot}
                                        onChange={(e) => setLot(e.target.value)}
                                    />
                                    <p className={
                                        lot.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>  
                            </div>
                             <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddMarketing}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
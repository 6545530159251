import { Helmet } from "react-helmet-async"
import logo from "../../img/logo.png"
import sign_up from "../../img/sign-up.png"
import { useState } from "react";
import "../../css/style.css"
import "../../css/bootstrap.min.css"
import "../../css/fontawesome.css"
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useMutation } from "react-query";
import { loginAuth } from "../../api/auth";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [disable, setDisable] = useState(false)
  const navigate = useNavigate()


  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Login Method
  const login = useMutation(loginAuth, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        message.success("Successfully Logged In!");
        localStorage.setItem("token", data.token);
        sessionStorage.setItem("loggedin", 1);
        navigate("/admin/dashboard");
        setDisable(false);
      }

    },
    onError: (data) => {
      setDisable(false);
      if (data?.response?.data.status === 400) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 403) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 404) {
        message.error(data?.response?.data.message);
      }
      if (data?.response?.data.status === 500) {
        message.error(data?.response?.data.message);
      }

    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);


    if (email === "" || password === "") {
      setLoginError("Please Fill the above field.");
      setDisable(false);
      return;
    }

    let data = {
      email,
      password,
    };

    login.mutate(data);

    return;
  };

  return (
    <>
      <Helmet><title>Admin Login | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <div className="container-fluid position-relative d-flex p-0">
        <div className="container-fluid">
          <div className="row h-100 align-items-center" style={{ minHeight: "100vh" }}>
            <div className="col-lg-5 col-xl-4 p-0">
              <div className="bg-secondary p-4 p-md-5 h-100">
                <Link to="" className="mb-5 d-block">
                  <img src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`}  width="100px" alt="logo"/> </Link>
                <h2 className="display-3 mb-3">Sign In</h2>
                <form>
                  <div className="mb-3">
                    <label for="email" className="form-label">Enter your Email Address <span className="required-mark">*</span></label>
                    <input type="text" name="email"
                      className="form-control form-control-lg bg-dark " id="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                    <p className={
                      email.length === 0 && loginError ? "text-danger" : "d-none"
                    }>{loginError}</p>
                  </div>
                  <div className="mb-3">
                    <label for="password" className="form-label">Enter your Password <span className="required-mark">*</span></label>
                    <div style={{ position: 'relative' }}>
                      <input type={showPassword ? 'text' : 'password'}
                        name="password" className="form-control form-control-lg bg-dark "
                        id="password-field"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                      <span
                        className="eye-icon toggle-password" onClick={togglePasswordVisibility}>
                        <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}>
                        </i></span>
                      <p className={
                        password.length === 0 && loginError
                          ? "text-danger"
                          : "d-none"
                      }>{loginError}</p>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary w-100 mt-4"
                    disabled={disable}
                    onClick={handleSubmit} >{disable ? "Sign In..." : "Sign In"}</button>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-xl-8 p-0 bg-img-login bg-dark h-100" style={{
              backgroundImage: `url(${sign_up})`,
              backgroundPosition: "center right", backgroundSize: "contain"
            }}>
              <div className="">
                <div className="titel p-4 p-md-5">
                  <div className="ms-lg-5 mt-4">
                    <h1>Get Started.</h1>
                    <p>Create an account to experience our<br />powerful CRM tool.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>       
      </>
  )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { Image, Modal, message } from "antd";
import { TablePagination } from "@mui/material";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useState } from "react";
import styled from "styled-components";
import { getAllPendingDeposit, getDepositReport } from "../../../api/deposit";
import { subMonths, format } from 'date-fns';
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


const StyleTablePagination = styled(TablePagination)`
.css-levciy-MuiTablePagination-displayedRows {
    font-family: Gilroy;
    padding-top: 10px;
  }
  .css-pdct74-MuiTablePagination-selectLabel {
    padding-top:9px;
    font-family: Gilroy;
  }
  
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    font-family: Gilroy;
  }
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    fill: #ffff;
  }
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #ffff;
}
`
const StyleModal = styled(Modal)`
.ant-modal-title {
    color: #ffff;
    font-weight: 600;
    font-size: 24px;
    background-color:#f37b4c;
    padding: 10px;
    font-family:'Gilroy';
    border-radius: 8px 8px 0px 0px;
}
 .ant-modal-footer {
    display: none !important;
}
 .ant-modal-content {
    position: relative;
    background-color:black;
    padding: 0px 0px;
    font-family:'Gilroy';
}
 .ant-modal-close {
    color:#ffff;
}
.ant-modal-close:hover{
  color:#ffff;
}
.text-black{
  --bs-text-opacity: 1;
  color: black;
  font-size:16px;
}
.value{
  font-size:16px;
}
`

export const IBExchangerWithdraw = () => {
    const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

    const {sidebarOpen} = useAppContext();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const[startDate, setStartDate]=useState(defaultFromDate);
    const[endDate, setEndDate]= useState(defaultToDate);
    const[type, setType]=useState("");
    const[paymentmethod, setPaymentMethod]=useState("")
    const [depositData, setDepositData] = useState([]);
    const[searched, setSearched] = useState("")


    const formattingDate = (date) => {
        return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
      };
    

    //search data
    const handleSearch = async () => {
        try {
            const data = { startDate : formattingDate(startDate), endDate: formattingDate(endDate), type, paymentmethod  };
            const result = await getDepositReport(data);
            setDepositData(result);
          } catch (error) {
            message.error(error)
          }
        };

    // pagination

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const filteredData = depositData?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    // item.userName.toLowerCase().includes(searched.toLowerCase()) ||
    item.userEmail.toLowerCase().includes(searched.toLowerCase()) ||
    item.paymentmethod.toLowerCase().includes(searched.toLowerCase()) ||
    item.amount.toString().includes(searched) 

    

  )

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const PaginatedData = filteredData?.slice(startIndex, endIndex);

     
   
    
    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
        'ID',
        'Name',
        'Email',
        'Amount',
        'Withdraw To',
        'Payment Method',
        'Note',
        'Comment',
        'Status',
        'Date',
        'Exchanger'
      ];
      
      const rows = data?.map((item,index) => [
        index + 1,
        item.userName,
        item.userEmail,
        item.amount,
        item.withdrawTo,
        item.paymentmethod,
        item.note,
        item.admincomment,
        item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
        formatDate(item.createdAt),
        item.exchanger
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('Exchanger IB Withdraw Report.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
    const dataForExport = data?.map((item,index) => ({
        'ID':  index + 1,
        'Name':item.userName,
        'Email':item.userEmail,
      'Amount':item.amount,
      'Withdraw To': item.withdrawTo,
      'Payment Method': item.paymentmethod,
      'Note':item.note,
      'Comment':item.admincomment,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Date':formatDate(item.createdAt),
      'Exchanger':item.exchanger
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Exchanger IB Withdraw Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

    return (
        <>
         <Helmet><title>Exchanger IB Withdraw Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> IB Withdraw Report</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                        <div className="row">
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>Status :</strong></label>
						<select id="status" className="form-control form-control-lg bg-dark"
                         onChange={(e)=> setType(e.target.value)}>
							<option value="all" selected>All</option>
							<option value="approved">Approved</option>
							<option value="rejected">Rejected</option>
						</select>
					</div>
				</div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>Exchanger :</strong></label>
						<select id="paymentmethod" className="form-control form-control-lg bg-dark"
                        onChange={(e)=> setPaymentMethod(e.target.value)}>
							<option value="all" selected>All</option>
						</select>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>Payment Method :</strong></label>
						<select id="paymentmethod" className="form-control form-control-lg bg-dark"
                        onChange={(e)=> setPaymentMethod(e.target.value)}>
							<option value="all" selected>All</option>
							<option value="cash">Cash</option>
							<option value="bank">Bank</option>
						</select>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>From :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark" name="fromdate" id="fromdate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)} />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>To :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark " name="todate" id="todate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}/>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3 pt-4">
						<button type="button" id="dateSearch"
                         className="btn btn-sm btn-primary"
                         onClick={handleSearch}>Search</button>
					</div>
				</div>
			</div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(PaginatedData)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(PaginatedData)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                        <table className="table table-dark table-striped mb-0 text-center table-hover dataTable">
                                            <thead>
                                                <tr>
                                        <th>ID</th>
                                       <th>Name</th>
                                        <th>Email</th>
                                        <th>Amount</th>
                                        <th>Withdraw To</th>
                                        <th>Payment Method</th>
                                        <th>Note</th>
                                        <th>Comment</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Exchanger</th>
                                                </tr>
                                            </thead>
                                            {depositData?.data?.length > 0 ?
                                            <tbody>
                                                {
                                                    PaginatedData?.map((data)=> (
                                                <tr key={data.id}>
                                                    <td>{data.id}</td>
                                                    <td>{data.userName}</td>
                                                    <td>{data.userEmail}</td>
                                                    <td>{data.amount}</td>
                                                    <td>{data.withdrawTo}</td>
                                                    <td>{data.paymentmethod}</td>
                                                    <td>{data.note}</td>
                                                    <td>{data.comment}</td>
                                                    <td>
                                                        {data.status === 0 ? <strong className="text-warning">Pending</strong> :"" ||
                                                        data.status === 1 ? <strong className="text-success">Approved</strong> :
                                                        <strong className="text-danger">Reject</strong> }
                                                    </td>
                                                    <td>{formatDate(data.createdAt)}</td>
                                                    <td>{data.exchanger}</td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>: 
                                            <tbody>
                                            <tr className="text-center">
                                               <td  className="dataTables_empty pt-3 pb-3" colSpan={11}>No data available</td>
                                            </tr>
                                        </tbody>}
                                        </table>
                                        <StyleTablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        className="text-white d-flex justify-content-end"
                        count={depositData?.data?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
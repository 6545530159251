import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../../context/AppContext"
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../../layout/dashboardLayout/Header";
import {  useState } from "react";
import useGetdata from "../../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import {  getLiveAccountData } from "../../../../api/account";
import { transferInternal } from "../../../../api/transfer";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";
import { SelectDropdown } from "../../../../component/DropDownComponent";

export const InternalTransfer = () => {
    // const[userId, setUserId]= useState("");
    const [amount, setAmount] = useState("")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)


    const { sidebarOpen } = useAppContext();


    //get live account
const {data:liveAccounts} = useGetdata(
    "liveAccounts",
    getLiveAccountData
    )
    
        //add client deposit
        const quericlient = useQueryClient();
        const transfer = useMutation(transferInternal, {
            onSuccess: (data) => {
                if (data?.status === 200) {
                    message.success("internal transfer Successfully!");
                    quericlient.invalidateQueries("");
                    setDisable(false)
                    // navigate("/admin/transaction/approvedeposit")
                    // setUserId("");
                    setFrom("")
                    setTo("");
                    setAmount("");
                }
    
            },
            onError: (data) => {
                 setDisable(false)
                if (data?.response?.data.status === 400) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 403) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 404) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 500) {
                    message.error(data?.response?.data.message);
                }
    
                if (data?.response?.data.status === 502) {
                    message.error(data?.response?.data.message);
                }
    
    
            },
        });
    
    
        const handleAddClientDeposit = (e) => {
                e.preventDefault();
                setDisable(true)
            if (
                amount === "" ||
                // userId === "" ||
                from === "" ||
                to === "") {
                setLoginError("Please Fill the above field.");
                setDisable(false)
                return;
            }
    
           let data = {
                // userId,
                from,
                to,
                amount: Number(amount),
           }
    
            transfer.mutate(data);
            }

    return (
        <>
            <Helmet><title>Internal Transfer | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Internal Transfer</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                {/* <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <select className="form-select form-select-lg " id="user"
                                    value={userId}
                                     onChange={(e) => setUserId(e.target.value)}>
                                        <option selected="">Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </select>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div> */}
                                <div className="mb-4">
                                <label for="fromAccount" className="form-label">From Account <span className="required-mark">*</span></label>
                                    <SelectDropdown id="fromAccount"
                                    value={from}
                                     onChange={(value) => setFrom(value)}>
                                        {/* <option selected="">Please choose...</option> */}
                                        <option value="wallet" disabled={to === "wallet"}>Wallet</option>
                                        {
                                                liveAccounts?.data?.map((data)=>(
                                                    <option key={data.id} value={data.account_no}>{data.contactname} ({data.email}) ({data.account_no})</option>
                                                ))
                                            }
                                    </SelectDropdown>
                                    <p className={
                                    from.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="toAccount" className="form-label">To Account <span className="required-mark">*</span></label>
                                    <SelectDropdown id="toAccount"
                                    value={to}
                                     onChange={(value) => setTo(value)}>
                                        {/* <option selected="">Please choose...</option> */}
                                        <option value="wallet" disabled={from === "wallet"}>Wallet</option>
                                        {
                                                liveAccounts?.data?.map((data)=>(
                                                    <option key={data.id} value={data.account_no}>{data.contactname} ({data.email}) ({data.account_no})</option>
                                                ))
                                            }
                                    </SelectDropdown>
                                    <p className={
                                    to.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="amount" className="form-label">Amount <span className="required-mark">*</span></label>
                                    <input type="text" name="amount" className="form-control form-control-lg "
                                        id="amount" placeholder="Enter amount" value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <p className={
                                    amount.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddClientDeposit}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//add marketing
export const addMarketing = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/marketing/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
}

//get marketing list data
export const GetMarketingList = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/marketing/list`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //fetch single marketing user
  export const getSingleMarketingUser = async (data) => {
    let token = localStorage.getItem("token");
    const {id} = data;
    let response = await instance({
      url: `api/admin/marketing/details/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

    //edit marketing user
    export const EditMarketingUser = async (marketing) => {
        let token = localStorage.getItem("token");
        const {id, data} = marketing;
        let response = await instance({
          url: `api/admin/marketing/edit/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
        });
      
        return response.data;
      };

      //delete marketing user
    export const DeleteMarketingUser = async (marketing) => {
        let token = localStorage.getItem("token");
        const {id, data} = marketing;
        let response = await instance({
          url: `api/admin/marketing/delete/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
        });
      
        return response.data;
      };

//get incentive list      
export const getIncentivelist = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/marketing/incentivelist`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
}

//get marketing withdraw list      
export const getMarketingWithdrawList = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/marketing/withdrawlistt`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
}

//get marketing clients data
export const GetMarketingClients = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/marketing/clientlist/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get marketing team deposit list
export const GetMarketingTeamDepsoit = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/marketing/teamdeposit/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get marketing team withdraw list
export const GetMarketingTeamWithdraw = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/marketing/teamwithdraw/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};
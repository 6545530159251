import instance from "../instance";

//get country
export const getAllCountry = async (data) => {
    let response = await instance({
      url: `api/countries`,
      method: "GET",
      data
    });
  
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { ChangePasswordMT } from "../../../api/account";
import { Link } from "react-router-dom";
import { getAllCountry } from "../../../api/countries";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const ExchangerTransfer = () => {
    const[accountno, setaccountNo]=useState("");
    const[amount, setAmount]=useState("");
    const[comment, setComment] = useState("")
    const[loginError, setLoginError]= useState("")
    const[country, setCountry]= useState("")


    const { sidebarOpen } = useAppContext();

     // get Countries
     const { data: getCountries } = useGetdata(
        "getCountries",
        getAllCountry
    )

   //change password mt-5
   const quericlient = useQueryClient();
   const chnagePassword = useMutation(ChangePasswordMT, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("MT5 Password Change Successfully!");
               quericlient.invalidateQueries("allLiveAccounts")
               setaccountNo("");
               setAmount("");
               setComment("");
           }

       },
       onError: (data) => {
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   const handlePasswordChange = (e) => {
        e.preventDefault();
       if (
           accountno === "" ||
           amount === "" ||
           comment === ""){
               setLoginError("Please Fill the above field.");
               return;
           }

           const data = {
               accountno: Number(accountno),
               amount,
               comment
           }
           chnagePassword.mutate(data)
       }

    return (
        <>
            <Helmet><title>Exchanger Transfer | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Exchanger Transfer </h1>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                        <Link to="/admin/exchanger/exchangerlist" className="btn btn-xs btn-primary">View Exchanger</Link>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="mb-4">
                                    <label for="user" className="form-label">From Exchanger<span className="required-mark">*</span></label>
                                    <select className="form-select form-select-lg " id="user"
                                     onChange={(e) => setaccountNo(e.target.value)}>
                                        <option>Please choose...</option>
                                    </select>
                                    <p className={
                                    accountno.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="user" className="form-label">To Exchanger<span className="required-mark">*</span></label>
                                    <select className="form-select form-select-lg " id="user"
                                     onChange={(e) => setaccountNo(e.target.value)}>
                                        <option>Please choose...</option>
                                    </select>
                                    <p className={
                                    accountno.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                    <select className="form-select form-select-lg " id="country"
                                     onChange={(e) => setCountry(e.target.value)}>
                                        <option selected="">Please choose...</option>

                                        {getCountries?.countries.map((data) => (
                                            <option key={data?.code} value={data?.country}>{data?.country}</option>

                                        ))}
                                    </select>
                                    <p className={
                                        country.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="text" name="email" id="email"
                                     className="form-control form-control-lg"
                                     placeholder="Enter email"
                                     value={comment}
                                     onChange={(e)=>setComment(e.target.value)} />
                                    <p className={
                                    comment.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="mobile" className="form-label">Mobile <span className="required-mark">*</span></label>
                                    <input type="text" name="mobile" id="mobile"
                                     className="form-control form-control-lg"
                                     placeholder="Enter mobile"
                                     value={comment}
                                     onChange={(e)=>setComment(e.target.value)} />
                                    <p className={
                                    comment.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 >Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { DatePicker, message } from "antd";
import {getMonthlyGraph, getWeeklyGraph, getYearlyGraph} from "../../../../api/graph"
import { useEffect, useState } from "react";
import {formatDate} from '../../../../utility/formatTime'
import useGetdata from "../../../../hooks/useGetData";
import moment from "moment/moment";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    TimeScale,
  BarElement,
    Legend,
  } from 'chart.js';
import { GetPermissionCheck } from "../../../../api/subAdmin";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  )

const ChartLayout = styled.div`
  .year-select {
    color: var(--primary);
    border-radius: 7px;
    background-color: inherit;
    border: 1px solid grey;
    height: 32px;
    width: 115px;
    outline: none;
      color: var(--color-black);
    text-transform:capitalize;
      font-size: 15px;
      padding-left: 8px;
}
  .year-select:-ms-input-placeholder{
    color: #ffff;
  }
  .year-select:focus {
    box-shadow: inherit;
    color: #ffff !important;
  }
    .btn-week{
    height: 32px;
    padding: 0;
    font-size: .813rem;
    border-radius: 0.375rem;
    font-weight: 500;
    width: 80px;
  }
`

const StyleDatePicker = styled(DatePicker)`
.ant-picker-input{
  font-family:'Gilroy';
}
 .ant-picker-input >input::placeholder {
  color:grey;
  
}
.ant-picker-input >input:focus {
    color: #ffff;
}
.ant-picker-suffix {
  color: grey;
}
  
`
const months = [
    'january', 'february', 'march', 'april',
    'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'
  ];

export const DepositWithdrawChart = () => {
    const currentMonth = new Date().getMonth();
    const currentMonthName = months[currentMonth];
    const [selectedFilter, setSelectedFilter] = useState('monthly');
    const [month, setMonth] = useState(currentMonthName)
    const[year, setYear]= useState('')
    const [monthlyGraphData, setMonthlyGraphData] = useState(null);
    const [yearlyGraphData, setYearlyGraphData] = useState(null);
  
    //set permission
const { data: checkPermission, refetch } = useGetdata(
  "checkPermission",
  GetPermissionCheck
)

let token = localStorage.getItem('token');
useEffect(() => {
  if (token) {
      refetch()
  }
}, [token, refetch])

    const handleFilterChange = (filter) => {
      setSelectedFilter(filter);
    };
  
    const onChange = (date, dateString) => {
      setYear(dateString)
    };
  
    const currentYear = new Date().getFullYear();
    
    const disabledFutureYears = (current) => {
      const currentYear = new Date().getFullYear();
      return current.year() > currentYear;
    };
  
      //get monthly deposit and withdraw
      useEffect(() => {
        const fetchMonthlygraphData = async () => {
          try {
            const data = await getMonthlyGraph({month})
            setMonthlyGraphData(data);
          } catch (error) {
            message.error(error.message)
          }
    
        };
    
        fetchMonthlygraphData();
      }, [month]);
  
  
      const aggregatedMonthChartData = monthlyGraphData?.data.reduce((acc, entry) => {
        const existingEntry = acc.find(item => formatDate(item.date) === formatDate(entry.date));
    
        if (existingEntry) {
          existingEntry.deposit += entry.total_deposit;
          existingEntry.withdraw += entry.total_withdraw;
        } else {
          acc.push({
            date: formatDate(entry.date),
            deposit: entry.total_deposit,
            withdraw: entry.total_withdraw,
          });
        }
    
        return acc;
      }, []);
    
      //sorting data
       const sortedChartData = aggregatedMonthChartData?.sort((a, b) => new Date(a.date) - new Date(b.date));

       const labels = sortedChartData?.map(entry => entry.date);
       const deposits = sortedChartData?.map(entry => entry.deposit);
       const withdrawals = sortedChartData?.map(entry => entry.withdraw);
     
  
       const Monthlyoptions = {
        responsive: true,
        scales: {
          x: {
              ticks: {
                color: '#ffff', // Change X-axis ticks color
                font: {
                  family:'Gilroy' // Adjust the font size
                },
              },
            },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#ffff', // Change X-axis ticks color
              font: {
                family:'Gilroy' // Adjust the font size
              },
            },
          },
        },
        plugins: {
          legend: {
              labels: {
                color: '#ffff', // Change legend font color
                font: {
                    family:'Gilroy' // Adjust the font size
                  },
              },
            },
        },
      }
    
      const monthlyData = {
        labels: labels,
        datasets: [
          {
            label: 'Deposits',
            data:  checkPermission?.typeAdmin === 1 ? 
                    deposits : (checkPermission?.data?.includes("deposit_report") && checkPermission?.typeAdmin === 0 ? deposits : []),
            fill: true,
            backgroundColor: '#f37b4c',
            borderColor: "#f37b4c",
            barThickness: 30,
          },
          {
            label: 'Withdrawals',
            data:  checkPermission?.typeAdmin === 1 ? withdrawals : (checkPermission?.data?.includes("withdraw_report") && checkPermission?.typeAdmin === 0 ? withdrawals : []),
            // fill: false,
            backgroundColor: '#A6C626',
            borderColor: "#A6C626", 
            barThickness: 30,
          },
        ],
      };

    // get yearly deposit and withdraw data
    useEffect(() => {
      const fetchYearlygraphData = async () => {
        try {
          const data = await getYearlyGraph({year})
          setYearlyGraphData(data);
        } catch (error) {
          message.error(error.message)
        }
  
      };
  
      fetchYearlygraphData();
    }, [year]);
  
  
    const uniqueMonths = Array.from(new Set(yearlyGraphData?.data.map(entry => entry.month)));
  
    const sortedMonths = uniqueMonths?.sort((a, b) => new Date('2020 ' + a) - new Date('2020 ' + b));
  
    // Map the sorted months to the corresponding data entries
    const sortedYearChartData = sortedMonths?.map(month => {
      const entry = yearlyGraphData?.data.find(dataEntry => dataEntry.month === month);
      return {
        month: month,
        deposit: entry ? entry.total_deposit : 0,
        withdraw: entry ? entry.total_withdraw : 0,
      };
    });
  
    const labelsYear = sortedYearChartData?.map(entry => entry.month);
    const depositsYear = sortedYearChartData?.map(entry => entry.deposit);
    const withdrawalsYear = sortedYearChartData?.map(entry => entry.withdraw);

    const yearlyOption = {
        responsive: true,
        scales: {
          x: {
              ticks: {
                color: '#ffff', // Change X-axis ticks color
                font: {
                  family:'Gilroy' // Adjust the font size
                },
              },
            },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#ffff', // Change X-axis ticks color
              font: {
                family:'Gilroy' // Adjust the font size
              },
            },
          },
        },
        plugins: {
          legend: {
              labels: {
                color: '#ffff', // Change legend font color
                font: {
                    family:'Gilroy' // Adjust the font size
                  },
              },
            },
        },
      }
    
      const yearlyData = {
        labels: labelsYear,
        datasets: [
          {
            label: 'Deposits',
            data: depositsYear,
            fill: true,
            backgroundColor: '#f37b4c',
            borderColor: "#f37b4c",
            barThickness: 30           
          },
          {
            label: 'Withdrawals',
            data: withdrawalsYear,
            // fill: false,
            backgroundColor: '#A6C626',
            borderColor: "#A6C626", 
            barThickness: 30
          },
        ],
      };
    
      
   // get weekly deposit and withdraw data
    const{data: weeklyGraphData}= useGetdata(
      "weeklyGraphData",
      getWeeklyGraph
    )
    const aggregatedWeekChartData = weeklyGraphData?.data?.reduce((acc, entry) => {
      const existingEntry = acc?.find(item => formatDate(item.date) === formatDate(entry.date));
  
      if (existingEntry) {
        existingEntry.deposit += entry.total_deposit;
        existingEntry.withdraw += entry.total_withdraw;
      } else {
        acc.push({
          date: formatDate(entry.date),
          deposit: entry.total_deposit,
          withdraw: entry.total_withdraw,
        });
      }
  
      return acc;
    }, []);
  
    //sorting data
    const sortedWeekChartData = aggregatedWeekChartData?.sort((a, b) => new Date(a.date) - new Date(b.date));
  
    const labelsWeek = sortedWeekChartData?.map(entry => entry.date);
    const depositsWeek = sortedWeekChartData?.map(entry => entry.deposit);
    const withdrawalsWeek = sortedWeekChartData?.map(entry => entry.withdraw);

    const weeklyOption = {
        responsive: true,
        scales: {
          x: {
              ticks: {
                color: '#ffff', // Change X-axis ticks color
                font: {
                  family:'Gilroy' // Adjust the font size
                },
              },
            },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#ffff', // Change X-axis ticks color
              font: {
                family:'Gilroy' // Adjust the font size
              },
            },
          },
        },
        plugins: {
          legend: {
              labels: {
                color: '#ffff', // Change legend font color
                font: {
                    family:'Gilroy' // Adjust the font size
                  },
              },
            },
        },
      }
    
      const weeklyData = {
        labels: labelsWeek,
        datasets: [
          {
            label: 'Deposits',
            data: depositsWeek,
            fill: true,
            backgroundColor: '#f37b4c',
            borderColor: "#f37b4c",
            barThickness: 30           
          },
          {
            label: 'Withdrawals',
            data: withdrawalsWeek,
            // fill: false,
            backgroundColor: '#A6C626',
            borderColor: "#A6C626", 
            barThickness: 30
          },
        ],
      };

    return (
        <ChartLayout>
         <div className="bg-dark rounded-3 p-3 borderbx">
                   <div className="chartbtn d-flex align-items-center justify-content-center mb-3 gap-2 flex-direction-column">
                   <select placeholder="select Month" 
                   className='year-select bg-dark text-light col-sm-2' 
                   value={month}
                   onClick={() => handleFilterChange('monthly')}
                    onChange={(e) => setMonth(e.target.value)}>
                    {months?.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
                </select>
          <StyleDatePicker onChange={onChange} picker="year" 
          defaultValue={moment(`${currentYear}`, 'YYYY')}
          onClick={() => handleFilterChange('yearly')} 
          disabledDate={disabledFutureYears}
          className='bg-dark text-light year-select'/>
          <button  className='btn  btn-xs btn-primary btn-week' onClick={() => handleFilterChange('weekly')}>Weekly</button>
        </div>
        {selectedFilter === 'monthly' && (
      <>
      <div className='earning mb-2'>Monthly Deposit / Withdrawal</div>
     <Bar data={monthlyData} options={Monthlyoptions}  style={{maxHeight:360}}/>
    </>)}

    {selectedFilter === 'yearly' && (
      <>
      <div className='earning mb-2'>Yearly Deposit / Withdrawal</div>
      <Bar data={yearlyData} options={yearlyOption}  style={{maxHeight:360}}/>
    </>)}

    {selectedFilter === 'weekly' && (
      <>
      <div className='earning mb-2'>Weekly Deposit / Withdrawal</div>
     <Bar data={weeklyData} options={weeklyOption} style={{maxHeight:360}}/>
    </>)} 
    </div>
        </ChartLayout>
    )
}
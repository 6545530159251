import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { GetSales, UpdateSales } from "../../../api/sales";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const EditSales = () => {
    const { sidebarOpen } = useAppContext();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const[disable, setDisable] = useState(false)
    const navigate = useNavigate();

    const {id} = useParams();
    const {isLoading: userDLoad} = useGetdata();

    const {data:saleslist} = useGetdata(
        "saleslist",
        GetSales
    )

    const singlesales = saleslist?.data?.find(sale => sale.id === parseInt(id));


    //edit sales
    const queryclient = useQueryClient();
    const editsales = useMutation(UpdateSales, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                 
                message.success("Sales Updated Successfully!");
                queryclient.invalidateQueries("saleslist");
                navigate("/admin/sales/saleslist");
                setDisable(false)
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    useEffect(() => {
        if (!userDLoad) {
            setName(singlesales?.name);
            setEmail(singlesales?.email);

        }
    },[singlesales, userDLoad])

    const handleEditSales = async (event) => {
        event.preventDefault();
        setDisable(true)

        let data = {
            name,
            email

        };
        editsales.mutate({id:id, data});


    }

    return (
        <>
            <Helmet><title>Edit Sales | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Sales</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="email" name="email" className="form-control form-control-lg " id="email"
                                        placeholder="Enter Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                             <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleEditSales}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../../context/AppContext"
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../../layout/dashboardLayout/Header";
import {  useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { AddBanners } from "../../../../api/banner";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";
import { SelectDropdown } from "../../../../component/DropDownComponent";

export const AddBanner = () => {
    const [type, setType] = useState("")
    const [status, setStatus] = useState("")
    const [imageFile, setImageFile] = useState(null);
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    const handleUploadBanner = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

  


    // add banner
    const queryclient = useQueryClient()
    const addBanner = useMutation(AddBanners, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Banner Added Successfully!");
                queryclient.invalidateQueries("getBanner")
                setDisable(false)
                setType("");
                setStatus("");
                setImageFile(null);
                
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 409) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
        },
    });

    const handleAddBanner = (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            type === "" ||
            status === "" ||
            imageFile === "") {

            setLoginError("Plese fill above the field")
            setDisable(false)
        }


        const formData = new FormData();
        formData.append('type', type);
        formData.append('status', status);
        formData.append('image', imageFile);

        addBanner.mutate(formData);


    }

    return (
        <>
            <Helmet><title>Add Banner | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add Banner</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                <label for="type" className="form-label">Type <span className="required-mark">*</span></label>
                                    <SelectDropdown id="type"
                                        value={type}
                                        onChange={(value) => setType(value)}>
                                        {/* <option selected="">Please choose...</option> */}
                                        <option value={0}>User</option>
                                        {/* <option value={1}>IB</option> */}
                                    </SelectDropdown>
                                    <p className={
                                    type === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="Status" className="form-label">Status <span className="required-mark">*</span></label>
                                    <SelectDropdown id="subadmin"
                                        value={status}
                                        onChange={(value) => setStatus(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value={0}>Inactive</option>
                                        <option value={1}>Active</option>
                                    </SelectDropdown>
                                    <p className={
                                    status === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="bannerimage" className="form-label">Upload Banner <span className="required-mark">*</span></label>
                                    <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleUploadBanner} />
                                    <p className={
                                    !imageFile && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddBanner}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
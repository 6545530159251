import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../api/user";
import { message } from "antd";
import {  SendCustomMail } from "../../../api/emailVerification";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const SendCustomEmail = () => {
    const [userId, setUserId] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)


    const { sidebarOpen } = useAppContext();


    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    //send mail
    const quericlient = useQueryClient();
    const sendmail = useMutation(SendCustomMail, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Send Custom Email Successfully!");
                quericlient.invalidateQueries("allUsers")
                setDisable(false)
                setUserId("");
                setSubject("");
                setDescription("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handleSendMail = (e) => {
        e.preventDefault();
        setDisable(true)
        
        if (
            userId === "" ||
            subject === "" ||
            description === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        const data = {
            userId,
            subject,
            description
        }
        sendmail.mutate(data)
    }


    return (
        <>
            <Helmet><title>Send Mail | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Send Email </h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userId}
                                     onChange={(value) => setUserId(value)}>
                                        <option>Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="subject" className="form-label">Subject <span className="required-mark">*</span></label>
                                <input type="text" className="form-control form-control-lg" name="subject" id="subject"
                                placeholder="Enter subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)} />
                                <p className={
                                    subject.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="description" className="form-label">Description <span className="required-mark">*</span></label>
                                <textarea  rows={5} type="text" className="form-control form-control-lg" name="description" id="description"
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)} />
                                <p className={
                                    description.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>

                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleSendMail}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../../context/AppContext"
import { Header } from "../../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../../hooks/useGetData";
import { formatDate } from "../../../../utility/formatTime";
import { message } from "antd";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ApproveWithdraw, RejectWithdraw, getAllPendingWithdraw } from "../../../../api/withdraw";
import { StyleModal } from "../../../../component/StyleModal";
import DataTable from "../../../../component/DataTable";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";


export const PendingWithdraw = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false)
    const [admincomment, setAdminComment] = useState("")
    const [amount, setAmount] = useState("")
    const [selectedWithdraw, setSelectedWithdraw] = useState({})
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    //get pending withdraw
    const { data: pendingWithdraw } = useGetdata(
        "pendingWithdrawList",
        getAllPendingWithdraw
    );

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Name", key: "userName", sortable: true },
        { label: "Email", key: "userEmail", sortable: true },
        { label: "Amount", key: "amount", sortable: true },
        { label: "MT5 ID", key: "mt5id", sortable: true },
        { label: "Payment Method", key: "paymentmethod", sortable: true },
        { label: "Withdraw From", key: "withdraw_from", sortable: true },
        { label: "Note", key: "note", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
        { label: "Action", sortable: false },
    ]

    const filteredData = pendingWithdraw?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.userName && item.userName.toLowerCase().includes(searched.toLowerCase()) ||
    item.userEmail && item.userEmail.toLowerCase().includes(searched.toLowerCase()) ||
    item.paymentmethod && item.paymentmethod.toLowerCase().includes(searched.toLowerCase())
    )

    //Approve deposit modal 
    const showApproveModal = (id) => {
        setOpenApprove(true);
        setSelectedWithdraw(id);
    }

    const hideModal = () => {
        setOpenApprove(false)
    }

    //reject depsoit modal
    const showRejectModal = (id) => {
        setOpenReject(true);
        setSelectedWithdraw(id);
    }

    const cancelModal = () => {
        setOpenReject(false)
    }

    //approve Withdraw 
    const queryclient = useQueryClient()
    const approveWithdraw = useMutation(ApproveWithdraw, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Withdraw Approved Successfully!");
                setOpenApprove(false)
                queryclient.invalidateQueries("pendingWithdraw")
                setDisable(false)
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleApproveWithdraw = (id) => {
        setDisable(true)
        if (amount === "" ||
            admincomment === "") {
            setLoginError("Please fill above field")
            setDisable(false)
            return;
        }

        let data = {
            admincomment,
            amount
        }

        approveWithdraw.mutate({ id: selectedWithdraw, data });
    }

    //reject withdraw 
    const rejectWithdraw = useMutation(RejectWithdraw, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Withdraw Rejected!");
                setOpenReject(false)
                queryclient.invalidateQueries("pendingWithdraw")
                setDisable(false)
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleRejectWithdraw = (id) => {
        setDisable(true)
        let data = {
            admincomment
        }

        rejectWithdraw.mutate({ id: selectedWithdraw, data });
    }

    //export to pdf 
    const exportToPDF = (data) => {
        const doc = new jsPDF();

        // Define columns and rows based on your data structure
        const columns = [
            'ID',
            'Name',
            'Email',
            'Amount',
            'MT5 ID',
            'Payment Method',
            'Withdraw From',
            'Note',
            'Date'
        ];

        const rows = data?.map((item,index) => [
            index + 1,
            item.userName,
            item.userEmail,
            item.amount,
            item.mt5id,
            item.paymentmethod,
            item.withdraw_from,
            item.note,
            formatDate(item.createdAt)
        ]);
        doc.autoTable({
            // columns, body: rows
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'grid',
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },

            },
            styles: {
                fontSize: 10,
                font: 'Gilroy'
            }
        });
        doc.save('Pending Withdraw List.pdf');
    };

    //   export excel format
    const exportToExcel = (data) => {
        if (!data || data?.length === 0) {
            const headers = [
                'ID',
                'Name',
                'Email',
                'Amount',
                'MT5 ID',
                'Payment Method',
                'withdraw From',
                'Note',
                'Date'
            ];

            const ws = XLSX.utils.aoa_to_sheet([headers]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const filename = "Pending Withdraw List.xlsx";

            XLSX.writeFile(wb, filename);
            return;
        }
        const dataForExport = data?.map((item,index) => ({
            'ID': index + 1,
            'Name': item.userName,
            'Email': item.userEmail,
            'Amount': item.amount,
            'MT5 ID': item.mt5id,
            'Pyament Method': item.paymentmethod,
            'Withdraw From': item.withdraw_from,
            'Note': item.note,
            'Date': formatDate(item.createdAt)
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const filename = 'Pending Withdraw List.xlsx';

        XLSX.writeFile(wb, filename);
    }

    return (
        <>
            <Helmet><title>Pending Withdraw List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Pending Withdraw List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(pendingWithdraw?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(pendingWithdraw?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>
                                                <td>{rowData.userName}</td>
                                                <td>{rowData.userEmail}</td>
                                                <td>{rowData.amount}</td>
                                                <td>{rowData.mt5id}</td>
                                                <td>{rowData.paymentmethod}</td>
                                                <td>{rowData.withdraw_from}</td>
                                                <td>{rowData.note}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    <button className="btn btn-success btn-xs mb-1" onClick={() => showApproveModal(rowData.id)}>
                                                        Approve</button> &nbsp;
                                                    <button className="btn btn-danger btn-xs mb-1" onClick={() => showRejectModal(rowData.id)}>
                                                        Reject</button>
                                                </td>

                                            </>
                                        )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* opent approve deposit modal */}
                <StyleModal
                    title="Approve Withdraw"
                    open={openApprove}
                    width={500}
                    centered={false}
                    onCancel={hideModal}

                >
                    <div className="p-3">
                        <div className="pb-2">
                            <label for="comment" className="form-label">Admin Comment</label>
                            <textarea type="text" className="form-control"
                                placeholder="Enter comment" rows={3}
                                value={admincomment}
                                onChange={(e) => setAdminComment(e.target.value)} />
                            <p className={
                                admincomment.length === 0 && loginError
                                    ? "text-danger"
                                    : "d-none"}>{loginError}</p>
                        </div>
                        <div className="pb-1">
                            <label for="amount" className="form-label">Amount</label>
                            <input type="number" className="form-control"
                                placeholder="Enter amount" rows={3}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)} />
                            <p className={
                                amount.length === 0 && loginError
                                    ? "text-danger"
                                    : "d-none"}>{loginError}</p>
                        </div>
                        <button className="btn btn-xs btn-primary mt-3" onClick={() => handleApproveWithdraw()}
                        disabled={disable}>
                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                    </div>
                </StyleModal>

                {/* open reject deposit modal */}
                <StyleModal
                    title="Reject Withdraw"
                    open={openReject}
                    width={500}
                    centered={false}
                    onCancel={cancelModal}

                >
                    <div className="p-3">
                        <label for="comment" className="form-label">Admin Comment</label>
                        <textarea type="text" className="form-control"
                            placeholder="Enter comment" rows={3}
                            value={admincomment}
                            onChange={(e) => setAdminComment(e.target.value)} />
                        <button className="btn btn-xs btn-primary mt-3" onClick={() => handleRejectWithdraw()}
                        disabled={disable}>
                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                    </div>
                </StyleModal>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { getLiveAccountData } from "../../../api/account";
import { BonusRemoved } from "../../../api/bonus";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { set } from "date-fns";

export const RemoveBonus = () => {
    const[accountid, setAccountId]=useState("");
    const[amount, setAmount]=useState("");
    const[comment, setComment] = useState("")
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable]= useState(false)


    const { sidebarOpen } = useAppContext();

    //get All liveaccount
    const { data: allLiveAccounts } = useGetdata(
        "allLiveAccounts",
        getLiveAccountData
    )

   //remove bonus
   const quericlient = useQueryClient();
   const removebonus = useMutation(BonusRemoved, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("Bonus Removed Successfully!");
               quericlient.invalidateQueries("allLiveAccounts")
               setDisable(false)
               setAccountId("");
               setAmount("");
               setComment("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   const handleRemoveBonus = (e) => {
        e.preventDefault();
        setDisable(true)
       if (
           accountid === "" ||
           amount === "" ||
           comment === ""){
               setLoginError("Please Fill the above field.");
               setDisable(false)
               return;
           }

           const data = {
               accountid: Number(accountid),
               amount,
               comment
           }
           removebonus.mutate(data)
       }

    return (
        <>
            <Helmet><title>Remove Bonus | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Remove Bonus </h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select MT5 ID<span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={accountid}
                                     onChange={(value) => setAccountId(value)}>
                                        <option>Please choose...</option>

                                        {allLiveAccounts?.data?.map((data) => (
                                            <option key={data?.id} value={data.account_no}>{data?.contactname} ({data.email})({data.account_no})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    accountid.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="amount" className="form-label">Amount <span className="required-mark">*</span></label>
                                    <input type="number" name="amount" id="amount"
                                     className="form-control form-control-lg"
                                     placeholder="Enter Amount"
                                     value={amount}
                                     onChange={(e)=>setAmount(e.target.value)} />
                                    <p className={
                                    amount.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>

                                <div className="mb-4">
                                    <label for="comment" className="form-label">Comment <span className="required-mark">*</span></label>
                                    <input type="text" name="comment" id="comment"
                                     className="form-control form-control-lg"
                                     placeholder="Enter comment"
                                     value={comment}
                                     onChange={(e)=>setComment(e.target.value)} />
                                    <p className={
                                    comment.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleRemoveBonus}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleLeadData } from "../../../../api/lead";
import { message } from "antd";

export const SingleLead = () => {
    const[leadData, setLeadData]= useState(null)


    const {id} = useParams()
      useEffect(() => {
          const fetchSingleLeadData = async () => {
            try {
              const data = await getSingleLeadData({id});
              setLeadData(data);
            } catch (error) {
              message.error(error.message);
            }
          };
      
          fetchSingleLeadData();
        }, [id]);
    return(
        <>
        <div className="row mb-3 d-flex justify-content-center">
                    <div className="col-lg-10">
                        <div className="bg-dark rounded-3 p-4 p-xl-5 p-xxl-5 mt-3">
                            <div className="row gx-4 gx-xl-5">
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Name:</p>
                                        <p className="fs-4">{leadData?.data?.firstname}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Source:</p>
                                        <p className="fs-4">{leadData?.data?.leadsourceName}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Status:</p>
                                        <p className="fs-4">{leadData?.data?.leadstatusName}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Phone:</p>
                                        <p className="fs-4">{leadData?.data?.phone}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Email:</p>
                                        <p className="fs-4">{leadData?.data?.email}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Country:</p>
                                        <p className="fs-4">{leadData?.data?.country}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <p className="fs-3 text-light">Description:</p>
                                        <p className="fs-4">{leadData?.data.remark === null && "Test Description"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { ApprovedKyc, RejectKyc, getSingleKyc } from "../../../api/kyc";
import { Button, Image, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import useGetdata from "../../../hooks/useGetData";
import { StyleModal } from "../../../component/StyleModal";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";



export const UserDocumentDetailList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    // const[singleUserKyc, setSingleUserKyc] = useState(null)
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("")
    const [openPoa, setOpenPoa] = useState(false)
    const[disable, setDisable]=useState(false)

    const { id } = useParams();
    const queryclient = useQueryClient();

    const headers = [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'File', key: 'filename', sortable: true },
        { label: 'Document Type', key: 'docType', sortable: true },
        { label: 'Expiry Date', key: 'expiry_date', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Date', key: 'createdAt', sortable: true },
        { label: 'Action', sortable: false },
    ]
    
    const { data: singleUserKyc } = useGetdata(
        ['userKyc'], // Define the query key including the user id
        async () => {
            const data = await getSingleKyc({ id }); // Fetch user KYC data
            return data;
        },
        {
            onSuccess: () => {
                // Invalidate the query on successful data fetch
                queryclient.invalidateQueries(['userKyc']);
            },
        }
    );

    //filtered kyc list
    const pendingkycdata = singleUserKyc?.data?.filter(data => data.status === 0)

    const filteredData = pendingkycdata?.filter((item) =>
        // index + 1.toString().includes(searched) ||
        (item.docType === "poi" ? "Proof of Identity" : "" || item.docType === "poa" ? "Proof of Address" : "").toLowerCase().includes(searched.toLowerCase())
    )

    // open poi modal
    const openRejectModal = (id) => {
        setOpen(true);
    }
    const hideModal = () => {
        setOpen(false);
    }

    //open poa modal
    const openPoaRejectModal = (id) => {
        setOpenPoa(true);
    }
    const hidePoaModal = () => {
        setOpenPoa(false);
    }


    //approve kyc poi
    const kycApprovePoi = useMutation(ApprovedKyc, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Kyc POI Approved Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("userKyc")
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleApprovePoi = (id) => {
        const confirmedmsg = window.confirm("Are you want to sure approve POI documents?")
        if(confirmedmsg) {

        const allData = {
            id,
            data: {
                poi: true
            }
        }

        kycApprovePoi.mutate(allData);
    }

    }

    //reject poi
    const rejectKycPoi = useMutation(RejectKyc, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Kyc POI Rejected.");
                setDisable(false)
                queryclient.invalidateQueries("userKyc")
                setOpen(false)
                setComment("")
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleRejectPoi = () => {
        const allData = {
            id: id,
            data: {
                poi: true,
                comment
            }
        }


        rejectKycPoi.mutate(allData);
    }

    //kyc approved poa 
    const kycApprovePoa = useMutation(ApprovedKyc, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Kyc POA Approved Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("userKyc")
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleApprovePoa = (id) => {
        const confirmMsg = window.confirm("Are you sure you want to approve POA documents?")
        if(confirmMsg) {
        const allData = {
            id,
            data: {
                poa: true
            }
        }
        kycApprovePoa.mutate(allData);
    }
    }

    //reject poa
    const rejectKycPoa = useMutation(RejectKyc, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Kyc POA Rejected.!");
                setDisable(false)
                queryclient.invalidateQueries("userKyc")
                setOpenPoa(false)
                setComment("")
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleRejectPoa = () => {
        const allData = {
            id: id,
            data: {
                poa: true,
                comment,
            }
        }

        rejectKycPoa.mutate(allData);
    }

    return (
           <>
            <Helmet><title>User Document List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> User Document List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">

                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            rowData?.docType === "poi" && (
                                                <>
                                                    <td>
                                                        <Image src={`${process.env.REACT_APP_API_URL}/images/kycUploads/${rowData?.filename}`} alt="docs" height={50} width={80} />
                                                    </td>
                                                    <td>{rowData.docType === "poi" ? "Proof of Identity" : "" ||
                                                        rowData.docType === "poa" ? "Proof of Address" : ""}</td>
                                                    <td>{formatDate(rowData.expiry_date)}</td>
                                                    <td>{rowData.status === 0 ? <b className="text-warning">Pending</b> : "" ||
                                                        rowData.status === 1 ? <b className="text-success">Approved</b> :
                                                        <b className="text-danger">Reject</b>}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                    <td>
                                                        {
                                                            rowData.status === 1 || rowData.status === 2 ? "" :
                                                                <>
                                                                    <button className="btn btn-primary btn-xs mb-1" onClick={() => handleApprovePoi(rowData?.user_id)}>
                                                                        Approve</button> &nbsp;
                                                                    <Link to="" className="btn btn-primary btn-xs mb-1" onClick={() => openRejectModal(rowData.id)}>
                                                                        Reject</Link>


                                                                </>
                                                        }
                                                    </td>

                                                </>) ||
                                            rowData?.docType === "poa" &&
                                            <>
                                                
                                                <td>
                                                    <Image src={`${process.env.REACT_APP_API_URL}/images/kycUploads/${rowData?.filename}`} alt="docs" height={50} width={80} />
                                                </td>
                                                <td>{rowData.docType === "poi" ? "Proof of Identity" : "" ||
                                                    rowData.docType === "poa" ? "Proof of Address" : ""}</td>
                                                <td>{formatDate(rowData.expiry_date)}</td>
                                                <td>{rowData.status === 0 ? <b className="text-warning">Pending</b> : "" ||
                                                    rowData.status === 1 ? <b className="text-success">Approved</b> :
                                                    <b className="text-danger">Reject</b>}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    {
                                                        rowData.status === 1 || rowData.status === 2 ? "" :
                                                            <>
                                                                <button className="btn btn-primary btn-xs mb-1" onClick={() => handleApprovePoa(rowData?.user_id)}
                                                                disabled={disable}>
                                                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                                                    "Approve" }</button> &nbsp;
                                                                <Link to="" className="btn btn-primary btn-xs mb-1" onClick={() => openPoaRejectModal(rowData.id)}>
                                                                    Reject</Link>


                                                            </>
                                                    }
                                                </td>

                                            </>

                                        )
                                        } />
                                </div>
                            </div>
                        </div>
                        {/* reject poi modal */}
                        <StyleModal
                            title="User POI Documents"
                            open={open}
                            width={500}
                            centered
                            onCancel={hideModal}
                            footer={[
                                <Button key="cancel" variant="contained" size="small" onClick={hideModal}>
                                    Cancel
                                </Button>,
                            ]}
                        >
                            <div className="p-3">
                                <label for="comment" className="form-label">Comment</label>
                                <textarea type="text" className="form-control" placeholder="Enter comment" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
                                <button className="btn btn-xs btn-primary mt-3"
                                 onClick={() => handleRejectPoi()}
                                 disabled={disable}>
                                {disable ?<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </StyleModal>

                        {/* open poa modal */}
                        <StyleModal
                            title="User POA Documents"
                            open={openPoa}
                            width={500}
                            centered
                            onCancel={hidePoaModal}
                            footer={[
                                <Button key="cancel" variant="contained" size="small" onClick={hidePoaModal}>
                                    Cancel
                                </Button>,
                            ]}
                        >
                            <div className="p-3">
                                <label for="comment" className="form-label">Comment</label>
                                <textarea type="text" className="form-control" placeholder="Enter comment" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
                                <button className="btn btn-xs btn-primary mt-3"
                                 onClick={() => handleRejectPoa()}
                                 disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </StyleModal>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
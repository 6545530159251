import useGetdata from "../../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {  DeleteLeadReminder, GetLeadReminder, UpdateLeadReminder, createLeadReminder } from "../../../../api/lead";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { formatDate } from "../../../../utility/formatTime";
import DataTable from "../../../../component/DataTable";
import {StyleModal} from "../../../../component/StyleModal"

export const LeadReminderList = () => {
    const [searched, setSearched] = useState("")
    const [open, setOpen] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedleadReminder, setSelectedLeadReminder] = useState({})
    const [reminder, setReminder] = useState(null);
    const [description, setDescription] = useState("")
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { id } = useParams()
    //get lead reminder data  
    const { data: leadReminderData, isLoading: userDLoad } = useGetdata(
        ['leadreminderData'], // Define the query key including the user id
        async () => {
            const data = await GetLeadReminder({id}); // Fetch  lead  notes data
            return data;
        },
        {
            onSuccess: () => {
                // Invalidate the query on successful data fetch
                queryclient.invalidateQueries(['leadreminderData']);
            },
        }
    );

    const headers = [
        { label: "Sr.No.", key: "id", sortable: true },
        { label: "Reminder Date", key: "reminder", sortable: true },
        { label: "Description", key: "description", sortable: true },
        { label: "Action", sortable: false },
    ]

    const filteredData = leadReminderData?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
        item.description.toLowerCase().includes(searched.toLowerCase())
    )

    //show lead status modal
    const showAddLeadReminderModal = async () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false)
    }


    //edit lead status modal
    const ShowEditLeadReminderModal = async (id) => {
        setOpenEditModal(true);
        setSelectedLeadReminder(id)
    }

    const cancelModal = () => {
        setOpenEditModal(false)
    }
    //Update lead reminder
    const queryclient = useQueryClient();
    const leadreminderUpdate = useMutation(UpdateLeadReminder, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Leas Reminder Updated Successfully!");
                queryclient.invalidateQueries("leadreminderData");
                setOpenEditModal(false);
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
        }
    })

    useEffect(() => {
        if (!userDLoad) {
            setReminder(formatDate(selectedleadReminder?.reminder));
            setDescription(selectedleadReminder?.description);

        }
    }, [userDLoad, selectedleadReminder]);


    const handleUpdateLeadReminder = async (e) => {
        e.preventDefault();
        setDisable(true)

        let data = {
            reminder,
            description
        }

        leadreminderUpdate.mutate({ leadid: id, noteid: selectedleadReminder?.id, data });
        return;
    }

    //delete lead reminder
    const deleteleadreminder = useMutation(DeleteLeadReminder, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead Reminder deleted Successfully!");
                queryclient.invalidateQueries("leadreminderData");

            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDelete = async (data) => {
        window.confirm("Are you sure you want to delete this record?")
        deleteleadreminder.mutate({ leadid: data.lead_id, noteid: data.id });
    }

    //create lead reminder
    const leadreminderadd = useMutation(createLeadReminder, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead Reminder Created Successfully!");
                queryclient.invalidateQueries("leadreminderData");
                setDisable(false)
                setReminder("");
                setDescription("");
                setOpen(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleCreateLeadReminder = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            reminder === "" ||
            description === "") {
            setLoginError("Plese fill above the field");
            setDisable(false)
            return;
        }

        leadreminderadd.mutate({ leadId: Number(id), reminder, description });
    }

    return (
        <>
            <div className="row p-3">
                <div className="col-lg-12">
                    <div className="d-flex justify-content-end mb-3">
                        <button className="btn btn-xs btn-primary" onClick={showAddLeadReminderModal}><i className="fa-light fa-plus"></i> Add</button>
                    </div>
                    <div className="">
                        <div className="table-responsive-lg p-3">
                            <div className="row mb-3 dataTables_filter">
                                <div className="col-sm-12 d-flex justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <label> Search:&nbsp;</label>
                                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                data={filteredData}
                                headers={headers}
                                renderRow={(rowData) => (
                                    <>
                                        <td>{formatDate(rowData?.reminder)}</td>
                                        <td>{rowData?.description}</td>
                                        <td>

                                            <button className="btn btn-xs btn-primary" onClick={() => ShowEditLeadReminderModal(rowData)}>Edit</button> &nbsp;
                                            <button className="btn btn-xs btn-primary" onClick={() => handleDelete(rowData)}>Delete</button>
                                        </td>
                                    </>
                                )} />
                        </div>
                    </div>
                </div>
                {/* add lead reminder */}
                <StyleModal
                    title="Add Lead Reminder"
                    open={open}
                    width={500}
                    onCancel={hideModal}
                    centered={false}>

                    <div className="row p-3">
                        <div className="col-sm-12">
                            <div className="mb-4">
                                <label for="date" className="form-label">Reminder<span className="required-mark">*</span></label>
                                <input type="date" name="name" className="form-control form-control-lg"
                                    id="date"
                                    value={reminder}
                                    onChange={(e) => setReminder(e.target.value)}
                                />
                                <p className={
                                    reminder === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="mb-4">
                                <label for="description" className="form-label">Description<span className="required-mark">*</span></label>
                                <textarea type="text" name="description" className="form-control form-control-lg "
                                    id="description"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}>
                                </textarea>
                                <p className={
                                    description === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-sm btn-primary" type="submit" onClick={handleCreateLeadReminder}
                            disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Create" }</button>
                        </div>
                    </div>
                </StyleModal>

                {/* edit lead reminder modal */}
                <StyleModal
                    title="Edit Lead Reminder"
                    open={openEditModal}
                    width={500}
                    onCancel={cancelModal}
                    centered={false}>

                    <div className="row p-3">
                        <div className="col-sm-12">
                            <div className="mb-4">
                                <label for="date" className="form-label">Reminder<span className="required-mark">*</span></label>
                                <input type="date" name="name" className="form-control form-control-lg"
                                    id="date"
                                    value={reminder}
                                    onChange={(e) => setReminder(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="mb-4">
                                <label for="description" className="form-label">Description<span className="required-mark">*</span></label>
                                <textarea type="text" name="description" className="form-control form-control-lg "
                                    id="description"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}>
                                </textarea>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-sm btn-primary" type="submit" onClick={handleUpdateLeadReminder}
                            disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Update" }</button>
                        </div>
                    </div>
                </StyleModal>
            </div>
        </>
    )
}
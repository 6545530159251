import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import {  useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { EditPlanIB, GetIBPlanData } from "../../../api/ib";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import useGetdata from "../../../hooks/useGetData";


export const EditIBPlan = () => {
    const[name, setName]=useState("");
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();
    const navigate = useNavigate();
    const {id} = useParams();

  //get ib plan 
  const { data: allIBPlan, isLoading:userDLoad } = useGetdata(
    "allIBPlan",
    GetIBPlanData
);

const singleibplan = allIBPlan?.data?.find(el=> el.id == id)

   //create ib plan
   const quericlient = useQueryClient();
   const editplanib = useMutation(EditPlanIB, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("IB Plan Edited Successfully!");
               quericlient.invalidateQueries("allIBPlan")
               navigate("/admin/ib/ibplanlist")
               setDisable(false)
               setName("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   useEffect(()=>{
        if(!userDLoad){
            setName(singleibplan?.name)
        }
   },[singleibplan, userDLoad])

   const handleEditIBPlan = (e) => {
        e.preventDefault();
        setDisable(true)

       if (name === "") {
           setLoginError("Please Fill the above field.");
           setDisable(false)
           return;
       }

      
       editplanib.mutate({id:singleibplan?.id, name})
   }


    return (
        <>
            <Helmet><title>Edit IB Plan | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit IB Plan </h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="planname" className="form-label">Plan Name <span className="required-mark">*</span></label>
                                    <input type="text" name="planname" id="planname"
                                    placeholder="Enter Plan"
                                     className="form-control form-control-lg"
                                     value={name}
                                     onChange={(e)=>setName(e.target.value)} />
                                    <p className={
                                    name.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleEditIBPlan} disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

export const getUserDetails = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const changePassword = async (data) => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/admin/changepassword",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

export const ProfileUpdate = async (data) => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: "api/admin/update",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fTime(date) {
  if (!date) return '';

  const currentDate = new Date();
  const timestamp = new Date(date);
  const timeDifference = currentDate.getTime() - timestamp.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);

  let interval = Math.floor(secondsDifference / 31536000);

  if (interval >= 1) {
    return interval + " years ago";
  }
  interval = Math.floor(secondsDifference / 2592000);
  if (interval >= 1) {
    return interval + " months ago";
  }
  interval = Math.floor(secondsDifference / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }
  interval = Math.floor(secondsDifference / 3600);
  if (interval >= 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(secondsDifference / 60);
  if (interval >= 1) {
    return interval + " minutes ago";
  }
  return Math.floor(secondsDifference) + " seconds ago";
}
export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
export function formatDate(date) {
  if (!date) {
    return '';
  }

  let d = new Date(date);
  if (isNaN(d.getTime())) {
    // Fallback for other date string formats
    d = new Date(date.replace(/-/g, '/'));
    if (isNaN(d.getTime())) {
      return '';
    }
  }

  let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../api/user";
import { message } from "antd";
import { ResendMail } from "../../../api/emailVerification";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const SendMailVerifications = () => {
    const[userId, setUserId]=useState("");
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();


    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    //change password mt-5
    const quericlient = useQueryClient();
    const chnagePassword = useMutation(ResendMail, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Send Verification Mail Successfully!");
                quericlient.invalidateQueries("allUsers")
                setDisable(false)
                setUserId("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handleResendMail = (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            userId === ""){
                setLoginError("Please Fill the above field.");
                setDisable(false)
                return;
            }

            const data = {
                userId,
            }
            chnagePassword.mutate(data)
        }

    return (
        <>
            <Helmet><title>Resend Mail Verification | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Resend Verification Mail </h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userId}
                                     onChange={(value) => setUserId(value)}>
                                        {/* <option>Please choose...</option> */}

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleResendMail}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
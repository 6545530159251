import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { CloseTicket, getTicketList } from "../../../api/ticket";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const TicketList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //Get  ticket list
    const { data: ticketList } = useGetdata(
        "ticketList",
        getTicketList
    );

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Title", key: "title", sortable: true },
        { label: "Description", key: "description", sortable: true },
        { label: "Priority", key: "priority", sortable: true },
        { label: "Ticket ID", key: "ticket_id", sortable: true },
        { label: "Status", key: "status", sortable: true }, 
        { label: "Action", sortable: false },
    ]

    const filteredData = ticketList?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.title && item.title.toLowerCase().includes(searched.toLowerCase())
    )

    //close ticket
const queryclient = useQueryClient();
const closeTicket = useMutation(CloseTicket, {
    onSuccess: (data) => {
        if (data?.status === 200) {
            message.success("Ticket Closed Successfully!");
            queryclient.invalidateQueries("ticketList");
        }
    },
    onError: (data) => {
        if (data?.response?.data.status === 404) {
            message.error(data?.response.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response.data.message);
        }
    },
});

const handleClosedTicket = (ticket_id) => {
    closeTicket.mutate(ticket_id);
}


    return (
        <>
            <Helmet><title>Ticekt List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Ticket List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                    data={filteredData}
                                    headers={headers}
                                    renderRow={(rowData) => (
                                                        <>
                                                            <td>{rowData.title}</td>
                                                            <td>{rowData.description}</td>
                                                            <td>{rowData.priority === 0 ? "Low" : "" ||
                                                                rowData.priority === 1 ? "Medium" : "" ||
                                                                    rowData.priority === 2 ? "High" : ""}</td>
                                                            <td>{rowData.ticket_id}</td>
                                                            <td>{rowData.status === 0 ? <strong className="text-success">Open</strong> :
                                                                <strong className="text-danger">Closed</strong>}</td>
                                                            <td>
                                                                <Link to={`/admin/ticket/${rowData.ticket_id}`} className="btn btn-xs btn-primary">View</Link> &nbsp;
                                                                {rowData.status === 0 ? (
                                                                    <button className="btn btn-xs btn-danger" onClick={()=>handleClosedTicket(rowData.ticket_id)}>Close</button>
                                                                ) : (
                                                                    <button className="btn btn-xs btn-danger" disabled={true}>Closed</button>
                                                                )}
                                                            </td>
                                                        </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
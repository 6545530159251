import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2/dist/js/select2.min.js';
import 'select2/dist/css/select2.min.css';

export const MultiSelectDropdown = ({children, allowClear, selectedValues, onChange }) => {
  const selectRef = useRef(null);

  useEffect(() => {
    // Initialize Select2 on the select element
    $(selectRef.current).select2({
      multiple: true,
      placeholder: "Please choose...",
      allowClear:true,
    });

    // Set initial selected values
    $(selectRef.current).val(selectedValues).trigger('change');

    // Handle change event
    $(selectRef.current).on('change', function (e) {
      const selected = $(selectRef.current).val();
      onChange(selected);
    });

    // Cleanup on unmount
    return () => {
      $(selectRef.current).select2('destroy');
    };
  }, []);

  return (
    <select ref={selectRef} className='form-control form-control-lg bg-dark'>
      {/* {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))} */}
       <option></option>
            {children}
    </select>
  );
};


import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { getAllGroup } from "../../../api/group";
import { SubAdminCreate } from "../../../api/subAdmin";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { MultiSelectDropdown } from "../../../component/MultiSelectDropdown";

export const CreateSubAdmin = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [adminType, setAdminType] = useState("")
    const [groupId, setGroupId] = useState([])
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    //get user
    const { data: getGroups } = useGetdata(
        "getGroups",
        getAllGroup
    )

    // const groupOption = getGroups?.data?.map((el) => (
    //     { value: el.id, label: el.name }
    // ))
    const onChangeGroupIDs = (selected) => {
        setGroupId(selected)
    }

    //create sub admin
    const queryclient = useQueryClient();
    const cratesubadmin = useMutation(SubAdminCreate, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Sub Admin Created Successfully!");
                navigate("/admin/subadmin/subadminlist")
                queryclient.invalidateQueries("subadminList");
                setDisable(false)
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleCreateAdmin = (e) => {
        e.preventDefault();
        setDisable(true)

        if (
            name === "" ||
            email === "" ||
            password === "" ||
            adminType === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        if (adminType === 'menu') {

            let data = {
                name,
                email,
                password,
                adminType
                // adminType: adminType?.value,
            }
            cratesubadmin.mutate(data);
            return;

        }

        if (adminType === 'group') {
            let data = {
                name,
                email,
                password,
                adminType,
                groupId
                // adminType: adminType?.value,
                // groupId:  groupId?.map((el) => el?.value)
            }
            cratesubadmin.mutate(data);
            return;

        }
    }

    return (
        <>
            <Helmet><title>Create Sub Admin | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Create Sub Admin</h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <form>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                        <input type="text" name="name" className="form-control form-control-lg "
                                            id="name" placeholder="Enter Name" value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <p className={
                                            name.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                        <input type="email" name="email" className="form-control form-control-lg " id="email"
                                            placeholder="Enter Email" value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <p className={
                                            email.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="password" className="form-label">Password <span className="required-mark">*</span></label>
                                        <input type={showPassword ? 'text' : 'password'} name="password" className="form-control form-control-lg " id="password"
                                            placeholder="Enter Password" value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span
                                            className="eye-icon toggle-password" onClick={togglePasswordVisibility}>
                                            <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}>
                                            </i></span>
                                        <p className={
                                            password.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="adminType" className="form-label">Admin Type <span className="required-mark">*</span></label>
                                        <SelectDropdown id="subadmin"
                                            value={adminType}
                                            onChange={(value) => setAdminType(value)}>
                                            <option value="menu">Menu Wise</option>
                                            <option value="group">Group Wise</option>
                                        </SelectDropdown>
                                        {/* <Autocomplete
                                            sx={autoCompleteCss}
                                            limitTags={2}
                                            id="tags-outlined"
                                            filterSelectedOptions
                                            onChange={(event, value) => setAdminType(value)}
                                            options={[
                                                {
                                                    name: "Menu Wise",
                                                    value: "menu"
                                                },
                                                {
                                                    name: "Group Wise",
                                                    value: "group"
                                                }
                                            ]}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Please Choose..." />
                                            )}
                                        /> */}
                                        <p className={
                                            adminType === "" && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    {
                                        adminType === 'group' && (
                                            <div className="mb-4">
                                                <label className="form-label">Select Groups <span className="required-mark">*</span></label>
                                                <MultiSelectDropdown id="multiple-group-select"
                                                    multiple
                                                    allowClear={true}
                                                    selectedValues={groupId}
                                                    // options={groupOption}
                                                    onChange={onChangeGroupIDs}>
                                                         {
                                                    getGroups?.data?.map((data)=>(
                                                        <option key={data.id} value={data.id}>{data.name}</option>
                                                    ))
                                                }
                                            </MultiSelectDropdown>
                                                {/* <Autocomplete
                                                    multiple
                                                    sx={autoCompleteCss}
                                                    limitTags={2}
                                                    id="tags-outlined"
                                                    filterSelectedOptions
                                                    onChange={(event, value) => setGroupId(value)}
                                                    options={getGroups?.data?.map((el) => {
                                                        return {
                                                            name: el?.name,
                                                            value: el?.id
                                                        }
                                                    })}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder="Please Choose..." />
                                                    )}
                                                /> */}
                                                <p className={
                                                    groupId?.length === 0 && loginError
                                                        ? "text-danger"
                                                        : "d-none"
                                                }>{loginError}</p>
                                            </div>
                                        )}

                                </div>

                                <div className="col-md-12 pt-2">
                                    <button className="btn btn-primary btn-xs font-15" type="submit"
                                        onClick={handleCreateAdmin}
                                        disabled={disable}>
                                             {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import {  useParams } from "react-router-dom";
import {  useEffect, useState } from "react";
import {  getSingleKyc } from "../../../api/kyc";
import {  Image, message } from "antd";
import {  useQueryClient } from "react-query";
import useGetdata from "../../../hooks/useGetData";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const UserDocumentList = () => {
    const {sidebarOpen} = useAppContext();
    const[searched, setSearched]= useState("")

    const {id} = useParams();

    const headers = [
        {label: "ID", key:"id", sortable: true},
        {label: "File", key:"filename", sortable: true},
        {label: "Document Type", key:"docType", sortable: true},
        {label: "Expiry Date", key:"expiry_date", sortable: true},
        {label: "Status", key:"status", sortable: true},
        {label: "Registration Date", key:"createdAt", sortable: true},

    ]

        const queryclient = useQueryClient()
        useEffect(() => {
          const fetchData = async () => {
            try {
              // Fetch user KYC data using the custom hook
              const data = await getSingleKyc({ id });
              // Handle successful data fetch
              queryclient.setQueryData(['userKyc'], data); // Update query data
            } catch (error) {
              message.error( error);
            }
          };
      
          fetchData(); // Call the fetch data function
        }, [id, queryclient]); // Run effect whenever id or queryClient changes

        const { data: singleUserKyc } = useGetdata(['userKyc']);
   
    const filteredData = singleUserKyc?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    (item.docType && item.docType === "poi" ? "Proof of Identity" :"" ||
     item.docType === "poa" ? "Proof of Address" :"").toLowerCase().includes(searched.toLowerCase()) 

  )

  const approvedocsData = filteredData?.filter(el=> el.status === 1)


    return (
        <>
         <Helmet><title>User Document List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> User Document List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow">
                                <div className="table-responsive-lg">
                                <div className="row p-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-end">
                    
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                                    <DataTable
                                                    data={approvedocsData}
                                                    headers={headers}
                                                    renderRow={(rowData) => (
                                                     <>                
                                                     <td>
                                                        <Image src={`${process.env.REACT_APP_API_URL}/images/kycUploads/${rowData?.filename}`} alt="docs" height ={50} width={80} />
                                                    </td>
                                                    <td>{rowData.docType === "poi" ? "Proof of Identity" : "" ||
                                                     rowData.docType === "poa" ? "Proof of Address" : ""}</td>
                                                    <td>{formatDate(rowData.expiry_date)}</td>
                                                    <td>{rowData.status === 0 ? <b className="text-warning">Pending</b>: "" ||
                                                    rowData.status === 1 ? <b className="text-success">Approved</b> : 
                                                    <b className="text-danger">Reject</b>}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                </>
                                                    )}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
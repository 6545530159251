import { useEffect, useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import useGetdata from "../../../../hooks/useGetData";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import { UpdateBannerData, UpdateImageBanner, getBannerData } from "../../../../api/banner";
import { Image, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Header } from "../../../../layout/dashboardLayout/Header";
import { StyleModal } from "../../../../component/StyleModal";
import DataTable from "../../../../component/DataTable";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";
import { SelectDropdown } from "../../../../component/DropDownComponent";



export const BannerList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    const [openbanner, setOpenBanner] = useState(false)
    const [openimgbanner, setOpenImageBanner] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [loginError, setLoginError] = useState("")
    const [type, setType] = useState("");
    const [status, setStatus] = useState("")
    const [disable, setDisable] = useState(false)
    const [fileDataUrl, setFileDataUrl] = useState(null);


    //get banner
    const { data: getBanner, isLoading: userDLoad } = useGetdata(
        "getBanner",
        getBannerData
    )

    //edit banner modal 
    const showBannerModal = (id) => {
        setOpenBanner(true);
        setSelectedBanner(id)
    }

    const hideModal = () => {
        setOpenBanner(false)
    }

    //edit image banner modal
    const showImageBannerModal = (id) => {
        setOpenImageBanner(true);
        setSelectedBanner(id)
    }

    const cancelModal = () => {
        setOpenImageBanner(false)
    }

    const handleUploadBanner = (e) => {
        const file = e.target.files[0];
        setImageFile(file)

        if (file) {
            setImageFile(file);
            const objectUrl = URL.createObjectURL(file);
            setFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Type", key: "type", sortable: true },
        { label: "Image", key: "image", sortable: true },
        { label: "Status", key: "status", sortable: true },
        { label: "Action", sortable: false }


    ]

    const filteredData = getBanner?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
        ( item.type && item.type === 0 ? "User" : "").toLowerCase().includes(searched.toLowerCase()) ||

        (item.status && item.status === 0 ? "Inactive" : "active").toLowerCase().includes(searched.toLowerCase())

    )

    // update banner
    const queryclient = useQueryClient();
    const updateBanner = useMutation(UpdateBannerData, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Banner Updated Successfully!");
                queryclient.invalidateQueries("getBanner");
                setOpenBanner(false);
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 409) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
        }
    })

    useEffect(() => {
        if (!userDLoad) {
            setType(selectedBanner?.type);
            setStatus(selectedBanner?.status);

        }
    }, [userDLoad, selectedBanner]);

    const handleUpdateBanner = (e) => {
        e.preventDefault();
        setDisable(true)
        let data = {
            type,
            status
        }
        updateBanner.mutate({ id: selectedBanner.id, data });
    }

    // update Image Banner 
    const updateImageBanner = useMutation(UpdateImageBanner, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Image Banner Updated Successfully!");
                queryclient.invalidateQueries("getBanner");
                setOpenImageBanner(false);
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 402) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
        }
    })
    useEffect(() => {
        if (!userDLoad) {
            setImageFile(selectedBanner?.image);
        }
    }, [userDLoad, selectedBanner]);

    const handleUpdateImageBanner = (e) => {
        e.preventDefault();
        setDisable(true)

        if (
            imageFile === "") {
            setLoginError(`Please Fill the  field.`);
            setDisable(false)
            return;
        }


        let data = {
            image: imageFile,
        }
        updateImageBanner.mutate({ id: selectedBanner.id, data });
    }



    return (
        <>
            <Helmet><title>Banner List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Banner List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                                <Link to="/admin/settings/addbanner" className="btn btn-xs btn-primary">Add Banner</Link>
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>
                                                <td>
                                                    {rowData.type === 0 ? "User" : "" || rowData.type === 1 ? "IB" : "" ||
                                                        rowData.type === 2 ? 'Login' : "" || rowData.type === 3 ? 'Register' : ""}
                                                </td>
                                                <td>
                                                    <Image src={`${process.env.REACT_APP_API_URL}/images/bannerUploads/${rowData.image}`} alt="banner" height={50} width={80} />
                                                </td>
                                                <td>{rowData.status === 0 ? "Inactive" : "Active"}</td>
                                                <td>
                                                    <button className="btn btn-xs btn-primary" onClick={() => showBannerModal(rowData)}>Edit Banner</button> &nbsp;
                                                    <button className="btn btn-xs btn-primary" onClick={() => showImageBannerModal(rowData)}>Edit Image Banner</button>
                                                </td>
                                            </>
                                        )} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* opent approve deposit modal */}
                    <StyleModal
                        title="Edit Banner"
                        open={openbanner}
                        width={500}
                        centered={false}
                        onCancel={hideModal}>

                        <div className="p-3">
                            <div className="mb-3">
                                <label for="bannertype" className="form-label">Type <span className="required-mark">*</span></label>
                                <SelectDropdown id="bannertype"
                                    value={type}
                                    onChange={(value) => setType(value)}>
                                    {/* <option selected="">Please choose...</option> */}
                                    <option value={0}>User</option>
                                    {/* <option value={1}>IB</option> */}
                                </SelectDropdown>
                            </div>
                            <div className="mb-3">
                                <label for="bannerstatus" className="form-label">Status <span className="required-mark">*</span></label>
                                <SelectDropdown id="bannerstatus"
                                    value={status}
                                    onChange={(value) => setStatus(value)}>
                                    {/* <option selected="">Please choose...</option> */}
                                    <option value={0}>Inactive</option>
                                    <option value={1}>Active</option>
                                </SelectDropdown>
                            </div>
                            <button className="btn btn-xs btn-primary mt-3" onClick={handleUpdateBanner}
                                disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                    "Submit"}</button>
                        </div>
                    </StyleModal>

                    {/*  Edit Image banner modal */}
                    <StyleModal
                        title="Edit Image Banner"
                        open={openimgbanner}
                        width={500}
                        centered={false}
                        onCancel={cancelModal}

                    >
                        <div className="p-3">
                            <label for="bannerimage" className="form-label">Upload Banner <span className="required-mark">*</span></label>
                            <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={handleUploadBanner} />
                            <div className="mt-3 mb-2">
                                {fileDataUrl ?
                                    <Image
                                        src={fileDataUrl}
                                        alt="Selected"
                                        width={100}
                                        height={70}
                                    /> :
                                    <Image src={`${process.env.REACT_APP_API_URL}/images/bannerUploads/${selectedBanner?.image}`} alt="logo" Width={100} height={70} />
                                }
                            </div>

                            <p className={
                                !imageFile && loginError
                                    ? "text-danger"
                                    : "d-none"
                            }>{loginError}</p>
                            <button className="btn btn-xs btn-primary mt-3" onClick={handleUpdateImageBanner}
                                disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                    "Submit"}</button>
                        </div>
                    </StyleModal>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
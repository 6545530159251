import instance from "../instance";

//get pending kyc
export const getPendingKyc = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/users/kycpending/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  }; 

  //get pending IB
export const getPendingIB = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/ib/pending/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  }; 

  //pending deposit
  export const getPendingDeposit = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/pending/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //pending withdraw
  export const getPendingWithdraw = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/pending/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

   //total ib user
   export const getIBUsers = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/ib/users/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //total deposit
  export const getTotalDeposit = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //total withdraw
  export const getTotalWithdraw = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/total`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

    //get Wallet to account
    export const getWalletToAccount = async () => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/transfer/wallet-to-account/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      
        return response.data;
      };

      //get account to wallet
      export const getAccountToWallet = async () => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/transfer/account-to-wallet/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      
        return response.data;
      };
      
      //total daily deposit
      export const getTotalDailyDeposit = async (data) => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/deposit/daily/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

      //total weekly deposit
      export const getTotalWeeklyDeposit = async (data) => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/deposit/weekly/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

      //get monthly deposit
      export const getTotalMonthlyDeposit = async (data) => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/deposit/monthly/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

    //get total daily withdraaw
    export const getTotalDailyWithdraw = async () => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/withdraw/daily/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
    };
    
    //get weekly total withdraw
    export const getTotalWeeklyWithdraw = async () => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/withdraw/weekly/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
    };

    //get monthly total withdraw
    export const getTotalMonthlyWithdraw = async () => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/withdraw/monthly/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
    };

    //total users
    export const getTotalClient = async () => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/users/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
    };

      //total daily ib withdraw
      export const getDailyIbWithdraw = async (data) => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/ibwithdraw/daily/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

      
      //total weekly ib withdraw
      export const getWeeklyIbWithdraw = async (data) => {
        let token = localStorage.getItem("token");
        let response = await instance({
          url: `api/admin/ibwithdraw/weekly/total`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

    //total monthly ib withdraw
    export const getMonthlyIbWithdraw = async (data) => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/ibwithdraw/monthly/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data
      });
    
      return response.data;
    };

    
    //total ib withdraw
    export const getTotalIbWithdraw = async (data) => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/ibwithdraw/total`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data
      });
    
      return response.data;
    };
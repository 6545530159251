import { Helmet } from "react-helmet-async"
import {  useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useAppContext } from "../../../context/AppContext";
import useGetdata from "../../../hooks/useGetData";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { getAllBankDetails } from "../../../api/bankDetails";
import { IbWithdrawAdd, getApprovedReqUser } from "../../../api/ib";
import { getLiveAccountData } from "../../../api/account";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddIBWithdraw = () => {
    const[userid, setUserId]= useState("");
    const [amount, setAmount] = useState("")
    const [paymentmethod, setPaymentMethod] = useState("")
    const [withdrawTo, setWithdrawTo] = useState("")
    const [comment, setComment] = useState("")
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    const handleUserSelect = (id) => {
        setUserId(id)
    }
    //get All users
    const { data: ibApprovedUser } = useGetdata(
        "ibApprovedUser",
        getApprovedReqUser
    )

    //get bank details
const { data: bankDetails } = useGetdata(
    "bankDetails",
    getAllBankDetails
)

const filterBankDetails = bankDetails?.data?.filter(el => el.user_id === userid)
 
//get live accounts
const { data: liveAccounts } = useGetdata(
    "liveAccounts",
    getLiveAccountData
)

const filterliveAccounts = liveAccounts?.data?.filter(el => el.user_id === userid)


       //add withdraw 
    const quericlient = useQueryClient();
    const addibwithdraw = useMutation(IbWithdrawAdd, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Ib Withdraw Successfully!");
                quericlient.invalidateQueries("approveWithdraw");
                setDisable(false)
                // navigate("/admin/transaction/approveWithdraw")
                setUserId("");
                setPaymentMethod("");
                setWithdrawTo("");
                setAmount("");
                setComment("");
            }

        },
        onError: (data) => {
             setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleAddIBWithdraw= (e) => {
            e.preventDefault();
            setDisable(true)

        if (
            paymentmethod === "" ||
            withdrawTo === "" ||
            amount === "" ||
            comment === "" ||
            userid === "" ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

       
       let data = {
        userid,
        amount: Number(amount),
        paymentmethod,
        withdrawTo,
        comment
       }
            addibwithdraw.mutate(data);
    }

    return (
        <>
            <Helmet><title>IB Withdraw | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>IB Withdraw</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select IB <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userid}
                                     onChange={(value) => handleUserSelect(parseInt(value))}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {ibApprovedUser?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>
                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userid.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="deposit" className="form-label">Payment Method <span className="required-mark">*</span></label>
                                    <SelectDropdown id="deposit"
                                    value={paymentmethod}
                                     onChange={(value) => setPaymentMethod(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="bank">Bank</option>
                                        <option value="mt5">MT5</option>
                                    </SelectDropdown>
                                    <p className={
                                    paymentmethod === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                               
                                { paymentmethod === "bank" &&
                                <div className="mb-4">
                                    <label for="withdraw" className="form-label">Withdraw To <span className="required-mark">*</span></label>
                                    <SelectDropdown id="withdraw"
                                    value={withdrawTo}
                                     onChange={(value) => setWithdrawTo(value)}>
                                       {
                                            paymentmethod === "bank" ?
                                            (
                                        filterBankDetails?.map((data) => (
                                            <option key={data.id} value={data.accountnumber}>{data.bankname} ({data.accountnumber})</option>
                                        ))
                                    ) :""
                                }
                                    </SelectDropdown>
                                    <p className={
                                    withdrawTo === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>}

                                { paymentmethod == "mt5" &&
                                <div className="mb-4">
                                    <label for="withdraw" className="form-label">Withdraw To <span className="required-mark">*</span></label>
                                    <input type="text" name="amount" className="form-control form-control-lg "
                                        id="withdraw" placeholder="Enter withdraw to" value={withdrawTo}
                                        onChange={(e) => setWithdrawTo(e.target.value)}
                                    />
                                    <p className={
                                    withdrawTo === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>}
                                <div className="mb-4">
                                <label for="amount" className="form-label">Amount <span className="required-mark">*</span></label>
                                    <input type="text" name="amount" className="form-control form-control-lg "
                                        id="amount" placeholder="Enter amount" value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <p className={
                                    amount.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="comment" className="form-label">Comment <span className="required-mark">*</span></label>
                                    <input type="text" name="comment" className="form-control form-control-lg "
                                        id="comment" placeholder="Enter comment" value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                    <p className={
                                    comment.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddIBWithdraw}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

export const BackToTopPage = () => {
    const [isVisible, setIsVisible] = useState(false);
	
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

    return (
        <>
        {/* back to top */}
		{
			isVisible &&
		
		<Link to="" className="btn btn-lg btn-primary btn-lg-square back-to-top" onClick={scrollToTop}><i className="bi bi-arrow-up"></i></Link>
		}
        </>
    )
}
import instance from "../instance";

//create Sub Admin
export const SubAdminCreate = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/create/subadmin",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };


  //get sub admin list
  export const GetSubAdmin = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //add permission
  export const AddPermission = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/add/permission",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get Permission
  export const GetPermission = async ({id}) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/all/permissions/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  
  //edit permissaion
  export const EditSubPermission = async (data) => {
    const {adminId} = data;
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/edit/permission/${adminId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  // get permission check
  //get sub admin list
  export const GetPermissionCheck = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/all/permissions/check",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { DeleteUser, DisableGoogle2FA, PromoteAsIB, VerifyUser, getAllUser } from "../../../api/user";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const UserList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get user 
    const { data: allUserDetails } = useGetdata(
        "allUserDetails",
        getAllUser
    )
    // data table
    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Name", key: "name", sortable: true },
        { label: "Email", key: "email", sortable: true },
        { label: "Country", key: "country", sortable: true },
        { label: "Phone", key: "phone", sortable: true },
        { label: "Wallet Balance", key: "balance", sortable: true },
        { label: "IB Name", key: "ib_name", sortable: true },
        { label: "Marketing Name", key: "marketing_name", sortable: true },
        { label: "Email Verification", key: "login_verified", sortable: true },
        { label: "KYC Verification", key: "kyc_verified", sortable: true },
        { label: "Registration Date", key: "createdAt", sortable: true },
        { label: "Action", sortable: false },
        { label: "Google 2FA", key: "otp_enabled", sortable: true },
        { label: "Create IB"},
    ]

    // const sortedUser = allUserDetails?.data?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

    const filteredData = allUserDetails?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.country && item.country.toLowerCase().includes(searched.toLowerCase()) ||
    item.phone && item.phone.toLowerCase().includes(searched.toLowerCase()) 
    )

    //Delete user
    const queryclient = useQueryClient();
    const deleteUser = useMutation(DeleteUser, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("User deleted Successfully!");
                queryclient.invalidateQueries("allUserDetails")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDelete = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to delete user?');
        if (userConfirmed) {
            deleteUser.mutate({ id });

        }
    }

    //disable google 2fa
    const disablegoogle2fa = useMutation(DisableGoogle2FA, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Google2FA Disabled Successfully!");
                queryclient.invalidateQueries("allUserDetails")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDisableGoogle2Fa = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to disable google2FA?');
        if (userConfirmed) {
            disablegoogle2fa.mutate({ id });

        }
    }

        //promote as ib
        const promoteib = useMutation(PromoteAsIB, {
            onSuccess: (data) => {
                if (data?.status === 200) {
                    message.success("Promote to IB successfully!");
                    queryclient.invalidateQueries("allUserDetails")
                }
            },
            onError: (data) => {
                if (data?.response?.data.status === 404) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 400) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 403) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 500) {
                    message.error(data?.response.data.message);
                }
            }
        })
    
        const handlePromoteToIB = async (id) => {
            const userConfirmed = window.confirm('Are you sure you want to promote as IB?');
            if (userConfirmed) {
                promoteib.mutate({ id });
    
            }
        }

        const verifyuser = useMutation(VerifyUser, {
            onSuccess: (data) => {
                if (data?.status === 200) {
                    message.success("User verified successfully!");
                    queryclient.invalidateQueries("allUserDetails")
                }
            },
            onError: (data) => {
                if (data?.response?.data.status === 404) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 400) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 403) {
                    message.error(data?.response.data.message);
                }
                if (data?.response?.data.status === 500) {
                    message.error(data?.response.data.message);
                }
            }
        })
    
        const handleVerifyUser = async (id) => {
            const userConfirmed = window.confirm('Are you sure you want to verify user?');
            if (userConfirmed) {
                verifyuser.mutate({ id });
    
            }
        }
    //export to pdf 
    const exportToPDF = (data) => {
        const doc = new jsPDF();

        // Define columns and rows based on your data structure
        const columns = [
            'ID',
            'Name',
            'Email',
            'Country',
            'Phone',
            'Wallet Balance',
            'IB Name',
            'Marketing Name',
            'Registration Date',
        ];

        const rows = data?.map((item,index) => [
            index + 1,
            item.name,
            item.email,
            item.country,
            item.phone,
            item.balance,
            item.ib_name,
            item.marketing_name,
            formatDate(item.createdAt)
        ]);
        doc.autoTable({
            // columns, body: rows
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'grid',
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },

            },
            styles: {
                fontSize: 10,
                font: 'Gilroy'
            }
        });
        doc.save('Users List.pdf');
    };

    //   export excel format
    const exportToExcel = (data) => {
        if (!data || data?.length === 0) {
            const headers = [
                'ID',
                'Name',
                'Email',
                'Country',
                'Phone',
                'Wallet Balance',
                'IB Name',
                'Marketing Name',
                'Registration Date',
            ];

            const ws = XLSX.utils.aoa_to_sheet([headers]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const filename = "Users List.xlsx";

            XLSX.writeFile(wb, filename);
            return;
        }


        const dataForExport = data?.map((item,index) => ({
            'ID': index + 1,
            'Name': item.name,
            'Email': item.email,
            'Country': item.country,
            'Phone': item.phone,
            'Wallet Balance': item.balance,
            'IB Name': item.ib_name,
            'Marketing Name': item.marketing_name,
            'Registration Date': formatDate(item.createdAt),
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const filename = 'Users List.xlsx';

        XLSX.writeFile(wb, filename);
    }

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>User List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> User List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(allUserDetails?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(allUserDetails?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            // <tr key={rowIndex}>
                                            <>
                                                <td>{rowData.name}</td>
                                                <td>{rowData.email}</td>
                                                <td>{rowData.country}</td>
                                                <td>{rowData.phone}</td>
                                                <td>{rowData.balance}</td>
                                                <td>{rowData.ib_name}</td>
                                                <td>{rowData.marketing_name}</td>
                                                <td>{rowData.login_verified === 0 ? 
                                                  <button className="btn btn-primary btn-xs mb-1" onClick={()=>handleVerifyUser(rowData.id)}>Verify</button> :"" ||
                                                  rowData.login_verified === 1 ? <span className="text-success">Verified</span>: "" ||
                                                  rowData.login_verified === 2 ? <span className="text-danger">Blocked</span>: ""
                                                }</td>
                                                 <td>{rowData.kyc_verified === null ? 
                                                  <span className="text-warning">Not Uploaded</span> :"" ||
                                                  rowData.kyc_verified === 0 ? 
                                                  <span className="text-warning">Pending</span> :"" ||
                                                  rowData.kyc_verified === 1 ? <span className="text-success">Approved</span>: "" ||
                                                  rowData.kyc_verified === 2 ? <span className="text-danger">Rejected</span>: ""
                                                }</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    <div className="d-flex">
                                                    {
                                                        checkPermission?.typeAdmin === 1 &&
                                                        <Link to={`/admin/user/edit/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                            <i className="fa fa-edit"></i></Link>}&nbsp;
                                                    {
                                                        checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data.includes("edit_user") ?
                                                            <Link to={`/admin/user/edit/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                                <i className="fa fa-edit"></i></Link> : ""}
                                                    {
                                                        checkPermission?.typeAdmin === 1 &&
                                                        <Link to={`/admin/user/view/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                            <i className="fa fa-eye"></i></Link>} &nbsp;

                                                    {
                                                        checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data.includes("view_user") ?
                                                            <Link to={`/admin/user/view/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                                <i className="fa fa-eye"></i></Link> : ""} &nbsp;

                                                    {
                                                        checkPermission?.typeAdmin === 1 &&
                                                        <button className="btn btn-primary btn-xs mb-1" onClick={() => handleDelete(rowData.id)}>
                                                            <i className="fa fa-trash"></i></button>}

                                                    {
                                                        checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data.includes("delete_user") ?
                                                            <button className="btn btn-primary btn-xs mb-1" onClick={() => handleDelete(rowData.id)}>
                                                                <i className="fa fa-trash"></i></button> : ""} &nbsp;
                                                    {
                                                        checkPermission?.typeAdmin === 1 &&
                                                        <Link to={`/admin/user/settings/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                            <i className="fa fa-user-cog"></i></Link>}&nbsp;

                                                    {
                                                        checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data.includes("edit_user") ?
                                                            <Link to={`/admin/user/settings/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                                <i className="fa fa-user-cog"></i></Link> : ""} &nbsp;
                                                                {
                                                                    rowData?.ib_status === 1 &&
                                                                <Link to={`/admin/ib/treechart/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                                Tree&nbsp;Chart</Link>}
                                                    </div>
                                                </td>
                                                <td>
                                                    { rowData.otp_enabled === 1 ?
                                                <button className="btn btn-primary btn-xs mb-1" 
                                                onClick={()=>handleDisableGoogle2Fa(rowData.id)}>Disable 2FA</button> : "" ||
                                                rowData.otp_enabled === 0 || rowData.otp_enabled === null ?
                                                <span className="text-warning">Not Enabled</span> : ""  
                                            }
                                                </td>
                                                <td>
                                                    { rowData.ib_status === 0 || rowData.ib_status ===  null ?
                                                <button className="btn btn-primary btn-xs mb-1" onClick={()=>handlePromoteToIB(rowData.id)}>Promote&nbsp;As&nbsp;IB</button> : "" ||
                                                rowData.ib_status === 1 ?
                                                <button className="btn btn-primary btn-xs mb-1">Already&nbsp;IB</button> : "" 
                                            }
                                                </td>
                                             {/* </tr> */}
                                             </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
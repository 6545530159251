import instance from "../instance";

//get management dashboard data
export const getManagementData = async (data) => {
    let token = localStorage.getItem("token");
    const {country, marketing, fromDate, toDate} = data;
    let response = await instance({
      url: `api/admin/managerdashboard?country=${country}&marketing=${marketing}&fromDate=${fromDate}&toDate=${toDate}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { UserPasswordChange, getAllUser } from "../../../api/user";
import { message } from "antd";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const ChangeUserPassword = () => {
    const[userId, setUserId]=useState("");
    const[password, setPassword]=useState("");
    const[loginError, setLoginError]= useState("")
    const [showPassword, setShowPassword] = useState(false);
    const[disable,setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };

    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

   //change user password
   const quericlient = useQueryClient();
   const chnagePassword = useMutation(UserPasswordChange, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("User Password Change Successfully!");
               quericlient.invalidateQueries("allUsers")
               setDisable(false)
               setUserId("");
               setPassword("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   const handlePasswordChange = (e) => {
    e.preventDefault();
    setDisable(true)

       if (
           userId === "" ||
           password === "" ){
               setLoginError("Please Fill the above field.");
               setDisable(false)
               return;
           }

           const data = {
               userId,
               password
           }
           chnagePassword.mutate(data)
       }

    return (
        <>
            <Helmet><title>Change User Password | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Change User Password </h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userId}
                                     onChange={(value) => setUserId(value)}>
                                        <option selected="">Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="password" className="form-label">Password <span className="required-mark">*</span></label>
                                    <input type={showPassword ? 'text' : 'password'} name="password" id="password"
                                     className="form-control form-control-lg"
                                     value={password}
                                     onChange={(e)=>setPassword(e.target.value)} />
                                      <span
                                            className="eye-icon toggle-password" onClick={togglePasswordVisibility}>
                                       <i className= {showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}>
                                        </i></span>
                                    <p className={
                                    password.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handlePasswordChange}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
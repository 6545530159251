import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../../api/user";
import { formatDate } from "../../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import DataTable from "../../../../component/DataTable";
import { message } from "antd";


export const WithdrawTabs = () => {
    const [userWithdraw, setUserWithdraw] = useState({})
    const[searched, setSearched]= useState("")

    const { id } = useParams()

    const headers = [
      { label: "ID", key:"id", sortable:true },
      { label: "Amount", key:"amount", sortable:true },
      { label: "Payment Method", key:"paymentmethod", sortable:true },
      { label: "Note", key:"note", sortable:true },
      { label: "Comment", key:"admincomment", sortable:true },
      { label: "Bank ID", key:"bank_id", sortable:true },
      { label: "Status", key:"status", sortable:true },
      { label: "Date", key:"createdAt", sortable:true },
    ]

    const filteredData = userWithdraw?.data?.withdraws?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    item.paymentmethod && item.paymentmethod.toLowerCase().includes(searched.toLowerCase()) ||
    item.amount && item.amount.toString().includes(searched)
  )

    //fetch single user details
    useEffect(() => {
        const fetchUserDepositList = async () => {
            try {
                const data = await getSingleUser({id});
                setUserWithdraw(data);
            } catch (error) {
                message.error(error.message);
           
            }
        };

        fetchUserDepositList();
    }, [id]);

//export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'MT5 ID',
      'Amount',
      'Payment Method',
      'Note',
      'Comment',
      'Bank ID',
      'Status',
      'Date'
    ];
    
    const rows = data?.map((item,index) => [
      index + 1,
      item.mt5id,
      item.amount,
      item.paymentmethod,
      item.note,
      item.admincomment,
      item.bank_id,
      item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Exo' 
      }
     });
    doc.save('Withdraw List.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
  if (!data || data?.length === 0) {
    const headers = [
      'ID',
      'MT5 ID',
      'Amount',
      'Payment Method',
      'Note',
      'Comment',
      'Bank ID',
      'Status',
      'Date'
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const filename = "Withdraw List.xlsx";

    XLSX.writeFile(wb, filename);
    return;
  }

    const dataForExport = data?.map((item,index) => ({
       'ID':  index + 1,
      'MT5 ID':item.mt5id,
      'Amount':item.amount,
      'Payment Method': item.paymentmethod,
      'Note':item.note,
      'Comment':item.admincomment,
      'Bank ID':item.bank_id,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Date':formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Withdraw List.xlsx';

    XLSX.writeFile(wb, filename);
  }


    return (
        <>
         <div className="table-responsive-lg">
            <div className="row p-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(userWithdraw?.data?.withdraws)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(userWithdraw?.data?.withdraws)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                                <DataTable 
                                                data={filteredData}
                                                headers={headers}
                                                renderRow={(data)=>(
                                                <>
                                                <td>{data.amount}</td>
                                                <td>{data.paymentmethod}</td>
                                                <td>{data.note}</td>
                                                <td>{data.admincomment}</td>
                                                <td>{data.bank_id}</td>
                                                <td>{data.status === 0 ? <b className="text-warning">Pending</b>: "" || 
                                                data.status === 1 ? <b className="text-success">Approved</b> : <b className="text-danger">Reject</b>}</td>
                                                <td>{formatDate(data.createdAt)}</td>
                                            </>
                                                )} />
                                </div>

        </>
    )
}
import logo from "../../img/logo.png"
import { useAppContext } from "../../context/AppContext";
import { Link, useLocation } from "react-router-dom";
import { getAllUnReadList } from "../../api/notifications";
import useGetdata from "../../hooks/useGetData";
import { GetPermissionCheck } from "../../api/subAdmin";
import { useEffect, useState } from "react";


export const SideBar = () => {
  const { sidebarOpen } = useAppContext();


  //get notification data
  const { data: unreadNotification } = useGetdata(
    "unreadNotification",
    getAllUnReadList
  )

  const totalUnRead = unreadNotification?.data.filter((item) => item.read_at === 0).length;
  //set permission
  const { data: checkPermission, refetch } = useGetdata(
    "checkPermission",
    GetPermissionCheck
  )

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      refetch()
    }
  }, [token, refetch])

  const { pathname } = useLocation();

  return (
    <>
      {/* <!-- Sidebar Start --> */}
      <div className={`sidebar ps-3 pb-3 ${sidebarOpen ? 'open' : ''}`}>
        <nav className="navbar bg-dark navbar-dark">
          <Link to="/admin/dashboard" className="navbar-brand m-4">
            <img src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`} width="100px" alt="" />
          </Link>
          <div className="navbar-nav w-100">
            <Link to="/admin/dashboard"
              className={`nav-item nav-link ${pathname === '/admin/dashboard' ? 'active' : ''}`}><i className="fa-light fa-grid-2 me-2"></i>Dashboard</Link>

            <Link to="/admin/managementreport"
              className={`nav-item nav-link ${pathname === '/admin/managementreport' ? 'active' : ''}`}>
              <i className="fa-light fa-grid-2-plus me-2"></i>Management Dashboard</Link>

            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/user') ? 'active show' : ''}`}
                data-bs-toggle="dropdown">
                <i className="fa-light fa-user me-2"></i>User Management</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/user') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/add"
                    className={`dropdown-item ${pathname === '/admin/user/add' ? 'active' : ''}`}>Add User</Link>
                }
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_user") ?
                    <Link to="/admin/user/add"
                      className={`dropdown-item ${pathname === '/admin/user/add' ? 'active' : ''}`}>Add User</Link> : ""
                }
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/userlist"
                    className={`dropdown-item ${pathname === '/admin/user/userlist' ? 'active' : ''}`}>User List</Link>
                }
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_user_list") ?
                    <Link to="/admin/user/userlist"
                      className={`dropdown-item ${pathname === '/admin/user/userlist' ? 'active' : ''}`}>User List</Link> : ""
                }
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/createmt5account"
                    className={`dropdown-item ${pathname === '/admin/user/createmt5account' ? 'active' : ''}`}>Create MT5 Account</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("create_live_account") ?
                    <Link to="/admin/user/createmt5account"
                      className={`dropdown-item ${pathname === '/admin/user/createmt5account' ? 'active' : ''}`}>Create MT5 Account</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/mt5userlist"
                    className={`dropdown-item ${pathname === '/admin/user/mt5userlist' ? 'active' : ''}`}>MT5 User List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("mt_user_list") ?
                    <Link to="/admin/user/mt5userlist"
                      className={`dropdown-item ${pathname === '/admin/user/mt5userlist' ? 'active' : ''}`}>MT5 User List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/followuplist"
                    className={`dropdown-item ${pathname === '/admin/user/followuplist' ? 'active' : ''}`}>Follow Up List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("followup_list") ?
                    <Link to="/admin/user/followuplist"
                      className={`dropdown-item ${pathname === '/admin/user/followuplist' ? 'active' : ''}`}>Follow Up List</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/pendingdocumentlist"
                    className={`dropdown-item ${pathname === '/admin/user/pendingdocumentlist' ? 'active' : ''}`}>Pending Document List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("pending_document_list") ?
                    <Link to="/admin/user/pendingdocumentlist"
                      className={`dropdown-item ${pathname === '/admin/user/pendingdocumentlist' ? 'active' : ''}`}>Pending Document List</Link> : ""
                }
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/approvedocumentlist"
                    className={`dropdown-item ${pathname === '/admin/user/approvedocumentlist' ? 'active' : ''}`}>Approved Document List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("approved_document_list") ?
                    <Link to="/admin/user/approvedocumentlist"
                      className={`dropdown-item ${pathname === '/admin/user/approvedocumentlist' ? 'active' : ''}`}>Approved Document List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/uploaduserdocument"
                    className={`dropdown-item ${pathname === '/admin/user/uploaduserdocument' ? 'active' : ''}`}>Upload User Documents</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("upload_document") ?
                    <Link to="/admin/user/uploaduserdocument"
                      className={`dropdown-item ${pathname === '/admin/user/uploaduserdocument' ? 'active' : ''}`}>Upload User Documents</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/addbankdetails"
                    className={`dropdown-item ${pathname === '/admin/user/addbankdetails' ? 'active' : ''}`}>Add Bank Details</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_bank_detail") ?
                    <Link to="/admin/user/addbankdetails"
                      className={`dropdown-item ${pathname === '/admin/user/addbankdetails' ? 'active' : ''}`}>Add Bank Details</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/bankdetailslist"
                    className={`dropdown-item ${pathname === '/admin/user/bankdetailslist' ? 'active' : ''}`}>Bank Details List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("bank_detail_list") ?
                    <Link to="/admin/user/bankdetailslist"
                      className={`dropdown-item ${pathname === '/admin/user/bankdetailslist' ? 'active' : ''}`}>Bank Details List</Link> : ""}


                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/userpasswordlist"
                    className={`dropdown-item ${pathname === '/admin/user/userpasswordlist' ? 'active' : ''}`}>User Password List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_user_password_list") ?
                    <Link to="/admin/user/userpasswordlist"
                      className={`dropdown-item ${pathname === '/admin/user/userpasswordlist' ? 'active' : ''}`}>User Password List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/changeuserpassword"
                    className={`dropdown-item ${pathname === '/admin/user/changeuserpassword' ? 'active' : ''}`}>Change User Password</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("change_user_password") ?
                    <Link to="/admin/user/changeuserpassword"
                      className={`dropdown-item ${pathname === '/admin/user/changeuserpassword' ? 'active' : ''}`}>Change User Password</Link> : ""
                }
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/addexistingclient"
                    className={`dropdown-item ${pathname === '/admin/user/addexistingclient' ? 'active' : ''}`}>Add Existing Client</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_existing_client") ?
                    <Link to="/admin/user/addexistingclient"
                      className={`dropdown-item ${pathname === '/admin/user/addexistingclient' ? 'active' : ''}`}>Add Existing Client</Link> : ""
                }

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/changemt5password"
                    className={`dropdown-item ${pathname === '/admin/user/changemt5password' ? 'active' : ''}`}>Change MT5 Password</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("change_account_password") ?
                    <Link to="/admin/user/changemt5password"
                      className={`dropdown-item ${pathname === '/admin/user/changemt5password' ? 'active' : ''}`}>Change MT5 Password</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/updateclientleverage"
                    className={`dropdown-item ${pathname === '/admin/user/updateclientleverage' ? 'active' : ''}`}>Update MT5 Leverage</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("update_mt_leverage") ?
                    <Link to="/admin/user/updateclientleverage"
                      className={`dropdown-item ${pathname === '/admin/user/updateclientleverage' ? 'active' : ''}`}>Update MT5 Leverage</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/resendmailverification"
                    className={`dropdown-item ${pathname === '/admin/user/resendmailverification' ? 'active' : ''}`}>Resend  Verification Mail</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("resend_verification_mail") ?
                    <Link to="/admin/user/resendmailverification"
                      className={`dropdown-item ${pathname === '/admin/user/resendmailverification' ? 'active' : ''}`}>Resend  Verification Mail</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/user/resendmt5datamail"
                    className={`dropdown-item ${pathname === '/admin/user/resendmt5datamail' ? 'active' : ''}`}>Resend  MT5 Data Mail</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("resend_account_details_mail") ?
                    <Link to="/admin/user/resendmt5datamail"
                      className={`dropdown-item ${pathname === '/admin/user/resendmt5datamail' ? 'active' : ''}`}>Resend  MT5 Data Mail</Link> : ""}
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/bonus') ? 'active show' : ''}`}
                data-bs-toggle="dropdown">
                <i className="fa-light fa-gift me-2"></i>Bonus</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/bonus') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/bonus/givebonus"
                    className={`dropdown-item ${pathname === '/admin/bonus/givebonus' ? 'active' : ''}`}>Give Bonus</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("give_bonus") ?
                    <Link to="/admin/bonus/givebonus"
                      className={`dropdown-item ${pathname === '/admin/bonus/givebonus' ? 'active' : ''}`}>Give Bonus</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/bonus/removebonus"
                    className={`dropdown-item ${pathname === '/admin/bonus/removebonus' ? 'active' : ''}`}>Remove Bonus</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("remove_bonus") ?
                    <Link to="/admin/bonus/removebonus"
                      className={`dropdown-item ${pathname === '/admin/bonus/removebonus' ? 'active' : ''}`}>Remove Bonus</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/bonus/bonuslist"
                    className={`dropdown-item ${pathname === '/admin/bonus/bonuslist' ? 'active' : ''}`}>Bonus List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("bonus_list") ?
                    <Link to="/admin/bonus/bonuslist"
                      className={`dropdown-item ${pathname === '/admin/bonus/bonuslist' ? 'active' : ''}`}>Bonus List</Link> : ""}
              </div>
            </div>

            <div className="nav-item dropdown"> <Link to="#"
              className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/ib') ? 'active show' : ''}`}
              data-bs-toggle="dropdown">
              <i className="fa-light fa-basketball me-2"></i>IB Management</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/ib') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/ibuserlist"
                    className={`dropdown-item ${pathname === '/admin/ib/ibuserlist' ? 'active' : ''}`}>IB User</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("approved_ib_list") ?
                    <Link to="/admin/ib/ibuserlist"
                      className={`dropdown-item ${pathname === '/admin/ib/ibuserlist' ? 'active' : ''}`}>IB User</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/ibrequestlist"
                    className={`dropdown-item ${pathname === '/admin/ib/ibrequestlist' ? 'active' : ''}`}>IB Requests</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("requested_ib_list") ?
                    <Link to="/admin/ib/ibrequestlist"
                      className={`dropdown-item ${pathname === '/admin/ib/ibrequestlist' ? 'active' : ''}`}>IB Requests</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/ibplanlist"
                    className={`dropdown-item ${pathname === '/admin/ib/ibplanlist' ? 'active' : ''}`}>IB Plan</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_ibplan_list") ?
                    <Link to="/admin/ib/ibplanlist"
                      className={`dropdown-item ${pathname === '/admin/ib/ibplanlist' ? 'active' : ''}`}>IB Plan</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/commissiongrouplist"
                    className={`dropdown-item ${pathname === '/admin/ib/commissiongrouplist' ? 'active' : ''}`}>Commission Group</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_commissiongroup_list") ?
                    <Link to="/admin/ib/commissiongrouplist"
                      className={`dropdown-item ${pathname === '/admin/ib/commissiongrouplist' ? 'active' : ''}`}>Commission Group</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/usercommissionlist"
                    className={`dropdown-item ${pathname === '/admin/ib/usercommissionlist' ? 'active' : ''}`}>Set IB Commission</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_ibcommission_list") ?
                    <Link to="/admin/ib/usercommissionlist"
                      className={`dropdown-item ${pathname === '/admin/ib/usercommissionlist' ? 'active' : ''}`}>Set IB Commission</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/moveclientunderib"
                    className={`dropdown-item ${pathname === '/admin/ib/moveclientunderib' ? 'active' : ''}`}>Move Client To IB</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("move_client_to_ib") ?
                    <Link to="/admin/ib/moveclientunderib"
                      className={`dropdown-item ${pathname === '/admin/ib/moveclientunderib' ? 'active' : ''}`}>Move Client To IB</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/removeclientunderib"
                    className={`dropdown-item ${pathname === '/admin/ib/removeclientunderib' ? 'active' : ''}`}>Remove Client From IB</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("remove_client_from_ib") ?
                    <Link to="/admin/ib/removeclientunderib"
                      className={`dropdown-item ${pathname === '/admin/ib/removeclientunderib' ? 'active' : ''}`}>Remove Client From IB</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/demoteib"
                    className={`dropdown-item ${pathname === '/admin/ib/demoteib' ? 'active' : ''}`}>Demote IB</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("demote_ib") ?
                    <Link to="/admin/ib/demoteib"
                      className={`dropdown-item ${pathname === '/admin/ib/demoteib' ? 'active' : ''}`}>Demote IB</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/ib/moveibtoib"
                    className={`dropdown-item ${pathname === '/admin/ib/moveibtoib' ? 'active' : ''}`}>Move IB To IB</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("move_ib_to_ib") ?
                    <Link to="/admin/ib/moveibtoib"
                      className={`dropdown-item ${pathname === '/admin/ib/moveibtoib' ? 'active' : ''}`}>Move IB To IB</Link> : ""}
              </div>
            </div>

            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/group') ? 'active show' : ''}`}
                data-bs-toggle="dropdown"><i className="fa-light fa-users me-2"></i>Group Management</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/group') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/group/add"
                    className={`dropdown-item ${pathname === '/admin/group/add' ? 'active' : ''}`}>Add Group</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_group") ?
                    <Link to="/admin/group/add"
                      className={`dropdown-item ${pathname === '/admin/group/add' ? 'active' : ''}`}>Add Group</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/group/grouplist"
                    className={`dropdown-item ${pathname === '/admin/group/grouplist' ? 'active' : ''}`}>Group List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("group_list") ?
                    <Link to="/admin/group/grouplist"
                      className={`dropdown-item ${pathname === '/admin/group/grouplist' ? 'active' : ''}`}>Group List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/group/updateclientgroup"
                    className={`dropdown-item ${pathname === '/admin/group/updateclientgroup' ? 'active' : ''}`}>Update MT5 Group</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("update_mt_group") ?
                    <Link to="/admin/group/updateclientgroup"
                      className={`dropdown-item ${pathname === '/admin/group/updateclientgroup' ? 'active' : ''}`}>Update MT5 Group</Link> : ""}

              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#"
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/transaction') ? 'active show' : ''}`}
                data-bs-toggle="dropdown"><i className="fa-light fa-filter-circle-dollar me-2"></i>Transaction</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/transaction') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/clientdeposit"
                    className={`dropdown-item ${pathname === '/admin/transaction/clientdeposit' ? 'active' : ''}`}>Client Deposit</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_deposit") ?
                    <Link to="/admin/transaction/clientdeposit"
                      className={`dropdown-item ${pathname === '/admin/transaction/clientdeposit' ? 'active' : ''}`}>Client Deposit</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/clientwithdraw"
                    className={`dropdown-item ${pathname === '/admin/transaction/clientwithdraw' ? 'active' : ''}`}>Client Withdraw</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_withdraw") ?
                    <Link to="/admin/transaction/clientwithdraw"
                      className={`dropdown-item ${pathname === '/admin/transaction/clientwithdraw' ? 'active' : ''}`}>Client Withdraw</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/ibwithdraw"
                    className={`dropdown-item ${pathname === '/admin/transaction/ibwithdraw' ? 'active' : ''}`}>IB Withdraw</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_ibwithdraw") ?
                    <Link to="/admin/transaction/ibwithdraw"
                      className={`dropdown-item ${pathname === '/admin/transaction/ibwithdraw' ? 'active' : ''}`}>IB Withdraw</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/internaltransfer"
                    className={`dropdown-item ${pathname === '/admin/transaction/internaltransfer' ? 'active' : ''}`}>Internal Transfer</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("internal_transfer") ?
                    <Link to="/admin/transaction/internaltransfer"
                      className={`dropdown-item ${pathname === '/admin/transaction/internaltransfer' ? 'active' : ''}`}>Internal Transfer</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/pendingdeposit"
                    className={`dropdown-item ${pathname === '/admin/transaction/pendingdeposit' ? 'active' : ''}`}>Pending Deposit</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("pending_deposit_list") ?
                    <Link to="/admin/transaction/pendingdeposit"
                      className={`dropdown-item ${pathname === '/admin/transaction/pendingdeposit' ? 'active' : ''}`}>Pending Deposit</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/pendingwithdraw"
                    className={`dropdown-item ${pathname === '/admin/transaction/pendingwithdraw' ? 'active' : ''}`}>Pending Withdraw</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("pending_withdraw_list") ?
                    <Link to="/admin/transaction/pendingwithdraw"
                      className={`dropdown-item ${pathname === '/admin/transaction/pendingwithdraw' ? 'active' : ''}`}>Pending Withdraw</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/transaction/pendingibwithdraw"
                    className={`dropdown-item ${pathname === '/admin/transaction/pendingibwithdraw' ? 'active' : ''}`}>Pending IB Withdraw</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("pending_ibwithdraw_list") ?
                    <Link to="/admin/transaction/pendingibwithdraw"
                      className={`dropdown-item ${pathname === '/admin/transaction/pendingibwithdraw' ? 'active' : ''}`}>Pending IB Withdraw</Link> : ""}




              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#"
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/marketing') ? 'active show' : ''}`}
                data-bs-toggle="dropdown"><i className="fa-light fa-filter-circle-dollar me-2"></i>Marketing</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/marketing') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/marketing/add"
                    className={`dropdown-item ${pathname === '/admin/marketing/add' ? 'active' : ''}`}>Add Marketing</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_marketing") ?
                    <Link to="/admin/marketing/add"
                      className={`dropdown-item ${pathname === '/admin/marketing/add' ? 'active' : ''}`}>Add Marketing</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/marketing/marketinglist"
                    className={`dropdown-item ${pathname === '/admin/marketing/marketinglist' ? 'active' : ''}`}>Marketing List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("marketing_list") ?
                    <Link to="/admin/marketing/marketinglist"
                      className={`dropdown-item ${pathname === '/admin/marketing/marketinglist' ? 'active' : ''}`}>Marketing List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/marketing/incentivereport"
                    className={`dropdown-item ${pathname === '/admin/marketing/incentivereport' ? 'active' : ''}`}>Incentive Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("incentive_report") ?
                    <Link to="/admin/marketing/incentivereport"
                      className={`dropdown-item ${pathname === '/admin/marketing/incentivereport' ? 'active' : ''}`}>Incentive Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/marketing/marketingwithdrawlist"
                    className={`dropdown-item ${pathname === '/admin/marketing/marketingwithdrawlist' ? 'active' : ''}`}>Marketing Withdraw Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("marketing_withdraw_report") ?
                    <Link to="/admin/marketing/marketingwithdrawlist"
                      className={`dropdown-item ${pathname === '/admin/marketing/marketingwithdrawlist' ? 'active' : ''}`}>Marketing Withdraw Report</Link> : ""}

              </div>
            </div>
            <div className="nav-item dropdown">
              <Link to="#"
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/sales') ? 'active show' : ''}`}
                data-bs-toggle="dropdown"><i className="fa-light fa-filter-circle-dollar me-2"></i>Sales</Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/sales') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/home"
                    className={`dropdown-item ${pathname === '/admin/sales/home' ? 'active' : ''}`}>Sales Dashboard</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("sales_dashboard") ?
                    <Link to="/admin/sales/home"
                      className={`dropdown-item ${pathname === '/admin/sales/home' ? 'active' : ''}`}>Sales Dashboard</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/add"
                    className={`dropdown-item ${pathname === '/admin/sales/add' ? 'active' : ''}`}>Add Sales</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("add_sales") ?
                    <Link to="/admin/sales/add"
                      className={`dropdown-item ${pathname === '/admin/sales/add' ? 'active' : ''}`}>Add Sales</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/saleslist"
                    className={`dropdown-item ${pathname === '/admin/sales/saleslist' ? 'active' : ''}`}>Sales List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("sales_list") ?
                    <Link to="/admin/sales/saleslist"
                      className={`dropdown-item ${pathname === '/admin/sales/saleslist' ? 'active' : ''}`}>Sales List</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/leads/leadstatuslist"
                    className={`dropdown-item ${pathname === '/admin/sales/leads/leadstatuslist' ? 'active' : ''}`}>Lead Status</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("lead_status") ?
                    <Link to="/admin/sales/leads/leadstatuslist"
                      className={`dropdown-item ${pathname === '/admin/sales/leads/leadstatuslist' ? 'active' : ''}`}>Lead Status</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/leads/leadsourcelist"
                    className={`dropdown-item ${pathname === '/admin/sales/leads/leadsourcelist' ? 'active' : ''}`}>Lead Source</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("lead_source") ?
                    <Link to="/admin/sales/leads/leadsourcelist"
                      className={`dropdown-item ${pathname === '/admin/sales/leads/leadsourcelist' ? 'active' : ''}`}>Lead Source</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/sales/leads/leadlist"
                    className={`dropdown-item ${pathname === '/admin/sales/leads/leadlist' ? 'active' : ''}`} >Lead List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("view_lead_list") ?
                    <Link to="/admin/sales/leads/leadlist"
                      className={`dropdown-item ${pathname === '/admin/sales/leads/leadlist' ? 'active' : ''}`}>Lead List</Link> : ""}

              </div>
            </div>
            {/* <div className="nav-item dropdown">
              <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-light fa-exchange me-2"></i>Exchanger Management</Link>
              <div className="dropdown-menu bg-transparent border-0">
                <Link to="/admin/exchanger/exchangerlist" className="dropdown-item">Exchanger List</Link>
                <Link to="/admin/exchanger/transfer" className="dropdown-item">Exchanger Transfer</Link>
                <Link to="/admin/exchanger/depositreport" className="dropdown-item">Exchanger Deposit</Link>
                <Link to="/admin/exchanger/withdrawreport" className="dropdown-item">Exchanger Withdraw</Link>
                <Link to="/admin/exchanger/ibwithdrawreport" className="dropdown-item">Exchanger IB Withdraw</Link>
                </div>
                </div> */}
            {
              checkPermission?.typeAdmin === 1 &&
              <Link to="/admin/sendemail"
                className={`nav-item  nav-link ${pathname === '/admin/sendemail' ? 'active' : ''}`}><i className="fa-light fa-envelope me-2"></i>Send Email</Link>}
            {
              checkPermission?.typeAdmin === 0 &&
                checkPermission?.data?.includes("send_email") ?
                <Link to="/admin/sendemail"
                  className={`nav-item  nav-link ${pathname === '/admin/sendemail' ? 'active' : ''}`}><i className="fa-light fa-envelope me-2"></i>Send Email</Link> : ""}

            <Link to="/admin/news/newslist"
              className={`nav-item  nav-link ${pathname === '/admin/news/newslist' ? 'active' : ''}`}><i className="fa-light fa-envelope me-2"></i>News</Link>


            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/notification') ? 'active show' : ''}`} data-bs-toggle="dropdown">
                <i className="fa-light fa-bell me-2"></i>Notification
                <span className="count bg-black">{totalUnRead}</span>
              </Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/notification') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/notification/readnotification"
                    className={`dropdown-item ${pathname === '/admin/notification/readnotification' ? 'active' : ''}`}>Read Notification</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("read_notification") ?
                    <Link to="/admin/notification/readnotification"
                      className={`dropdown-item ${pathname === '/admin/notification/readnotification' ? 'active' : ''}`}>Read Notification</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/notification/unreadnotification"
                    className={`dropdown-item ${pathname === '/admin/notification/unreadnotification' ? 'active' : ''}`}>Unread Notification</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("unread_notification") ?
                    <Link to="/admin/notification/unreadnotification"
                      className={`dropdown-item ${pathname === '/admin/notification/unreadnotification' ? 'active' : ''}`}>Unread Notification</Link> : ""}

              </div>
            </div>
            {/* <div className="nav-item dropdown">
              <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-light fa-trophy me-2"></i>Reward Management</Link>
              <div className="dropdown-menu bg-transparent border-0">
                <Link to="/admin/rewards/rewardlist" className="dropdown-item">Reward List</Link>
                <Link to="/admin/rewards/addreward" className="dropdown-item">Add Reward</Link>
                </div>
                </div> */}

            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/report') ? 'active show' : ''}`} data-bs-toggle="dropdown">
                <i className="fa-light fa-chart-line me-2"></i>All Reports
              </Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/report') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/depositreport"
                    className={`dropdown-item ${pathname === '/admin/report/depositreport' ? 'active' : ''}`}
                  >Depsoit Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("deposit_report") ?
                    <Link to="/admin/report/depositreport"
                      className={`dropdown-item ${pathname === '/admin/report/depositreport' ? 'active' : ''}`}>Depsoit Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/withdrawreport"
                    className={`dropdown-item ${pathname === '/admin/report/withdrawreport' ? 'active' : ''}`}>Withdraw Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("withdraw_report") ?
                    <Link to="/admin/report/withdrawreport"
                      className={`dropdown-item ${pathname === '/admin/report/withdrawreport' ? 'active' : ''}`}>Withdraw Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/transferreport"
                    className={`dropdown-item ${pathname === '/admin/report/transferreport' ? 'active' : ''}`}>Internal Transfer Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("transfer_report") ?
                    <Link to="/admin/report/transferreport"
                      className={`dropdown-item ${pathname === '/admin/report/transferreport' ? 'active' : ''}`}> Internal Transfer Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/ibwithdrawreport"
                    className={`dropdown-item ${pathname === '/admin/report/ibwithdrawreport' ? 'active' : ''}`}>IB Withdraw Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("ibwithdraw_report") ?
                    <Link to="/admin/report/ibwithdrawreport"
                      className={`dropdown-item ${pathname === '/admin/report/ibwithdrawreport' ? 'active' : ''}`}>IB Withdraw Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/wallethistoryreport"
                    className={`dropdown-item ${pathname === '/admin/report/wallethistoryreport' ? 'active' : ''}`}>Wallet History Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("wallet_history_report") ?
                    <Link to="/admin/report/wallethistoryreport"
                      className={`dropdown-item ${pathname === '/admin/report/wallethistoryreport' ? 'active' : ''}`}>Wallet History Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/positionreport"
                    className={`dropdown-item ${pathname === '/admin/report/positionreport' ? 'active' : ''}`}>Position Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("position_report") ?
                    <Link to="/admin/report/positionreport"
                      className={`dropdown-item ${pathname === '/admin/report/positionreport' ? 'active' : ''}`}>Position Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/historyreport"
                    className={`dropdown-item ${pathname === '/admin/report/historyreport' ? 'active' : ''}`}>History Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("history_report") ?
                    <Link to="/admin/report/historyreport"
                      className={`dropdown-item ${pathname === '/admin/report/historyreport' ? 'active' : ''}`}>History Report</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/report/loginactivity"
                    className={`dropdown-item ${pathname === '/admin/report/loginactivity' ? 'active' : ''}`}>Login Activity Report</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("login_activity_report") ?
                    <Link to="/admin/report/loginactivity"
                      className={`dropdown-item ${pathname === '/admin/report/loginactivity' ? 'active' : ''}`}>Login Activity Report</Link> : ""}



              </div>
            </div>
            {/* <div className="nav-item dropdown">
              <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-light fa-chart-line me-2"></i>Risk Management Reports</Link>
              <div className="dropdown-menu bg-transparent border-0">
                <Link to="/admin/netprofitreport" className="dropdown-item">Profit Risk Report</Link>
                <Link to="/admin/netscalpingreport" className="dropdown-item">Scalp Trade Report</Link>

              </div>
              </div> */}

            {
              checkPermission?.typeAdmin === 1 &&
              <Link to="/admin/ticket/ticketlist"
                className={`nav-item nav-link ${pathname === '/admin/ticket/ticketlist' ? 'active' : ''}`}>
                <i className="fa-light fa-circle-question me-2"></i>Ticekts</Link>}
            {
              checkPermission?.typeAdmin === 0 &&
                checkPermission?.data?.includes("ticket") ?
                <Link to="/admin/ticket/ticketlist" className="nav-item nav-link"><i className="fa-light fa-circle-question me-2"></i>Ticekts</Link> : ""}

            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/subadmin') ? 'active show' : ''}`} data-bs-toggle="dropdown">
                <i className="fa fa-shield-alt me-2"></i>Sub Admin
              </Link>
              <div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/subadmin') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/subadmin/createsubadmin"
                    className={`dropdown-item ${pathname === '/admin/subadmin/createsubadmin' ? 'active' : ''}`}>Create Sub Admin</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("create_subadmin") ?
                    <Link to="/admin/subadmin/createsubadmin"
                      className={`dropdown-item ${pathname === '/admin/subadmin/createsubadmin' ? 'active' : ''}`}>Create Sub Admin</Link> : ""}

                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/subadmin/subadminlist"
                    className={`dropdown-item ${pathname === '/admin/subadmin/subadminlist' ? 'active' : ''}`}>Sub Admin List</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("subadmin_list") ?
                    <Link to="/admin/subadmin/subadminlist"
                      className={`dropdown-item ${pathname === '/admin/subadmin/subadminlist' ? 'active' : ''}`}>Sub Admin List</Link> : ""}

              </div>
            </div>

            <div className="nav-item dropdown">
              <Link to=""
                className={`nav-link dropdown-toggle ${pathname.startsWith('/admin/settings') ? 'active show' : ''}`} data-bs-toggle="dropdown">
                <i className="fa-light fa-cog me-2"></i>Settings
              </Link><div className={`dropdown-menu bg-transparent border-0 ${pathname.startsWith('/admin/settings') ? 'show' : ''}`}>
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/settings/bannerlist"
                    className={`dropdown-item ${pathname === '/admin/settings/bannerlist' ? 'active' : ''}`}>Banner</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("banner") ?
                    <Link to="/admin/settings/bannerlist"
                      className={`dropdown-item ${pathname === '/admin/settings/bannerlist' ? 'active' : ''}`}>Banner</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/settings/systemsetting"
                    className={`dropdown-item ${pathname === '/admin/settings/systemsetting' ? 'active' : ''}`}>System Setting</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("system_setting") ?
                    <Link to="/admin/settings/systemsetting"
                      className={`dropdown-item ${pathname === '/admin/settings/systemsetting' ? 'active' : ''}`}>System Setting</Link> : ""}
                {
                  checkPermission?.typeAdmin === 1 &&
                  <Link to="/admin/settings/defaultsetting"
                    className={`dropdown-item ${pathname === '/admin/settings/defaultsetting' ? 'active' : ''}`}>Default Setting</Link>}
                {
                  checkPermission?.typeAdmin === 0 &&
                    checkPermission?.data?.includes("default_setting") ?
                    <Link to="/admin/settings/defaultsetting"
                      className={`dropdown-item ${pathname === '/admin/settings/defaultsetting' ? 'active' : ''}`}>Default Setting</Link> : ""}
                {/* <Link to="/admin/settings/updatedefaultsetting" className="dropdown-item">Update Default Setting</Link> */}
                
                {/* <Link to="/admin/settings/paymentsetting"
                      className={`dropdown-item ${pathname === '/admin/settings/paymentsetting' ? 'active' : ''}`}>Payment Setting</Link> */}
              </div>
            </div>

          </div>
        </nav>
        <div className="sidebar-help"> <i className="fa-solid fa-circle-question"></i>
          <p>Need Help ?</p>
          <Link to="/admin/ticket/ticketlist" className="btn btn-sm btn-dark w-100">Go to help desk</Link> </div>
      </div>
      {/* <!-- Sidebar End -->  */}
    </>
  )
}
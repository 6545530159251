import instance from "../instance";

//get bonus
export const GetBonus = async () => {
    let token = localStorage.getItem("token");  
    let response = await instance({
      url: `api/admin/bonus/list`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //add bonus
  export const AddBonus = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/bonus/give",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

   //remove bonus
   export const BonusRemoved = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/bonus/remove",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { DeleteMarketingUser, GetMarketingList } from "../../../api/marketing";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const MarketingList   = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get ib plan 
    const { data: marketinglistdata } = useGetdata(
        "marketinglistdata",
        GetMarketingList
    );

    const headers = [
        { label: "ID", key:"id", sortable:true },
        { label: "Name", key:"name", sortable:true },
         { label: "Email", key:"email", sortable:true },
         { label: "Wallet Balance", key:"wallet_balance", sortable:true },
         { label: "Incentive(%)", key:"incentive", sortable:true },
         { label: "Net Deposit(Monthly)", key:"net_deposit", sortable:true },
         { label: "Lot", key:"lots", sortable:true },
         { label: "Action", sortable:false },
    ]

    const filteredData = marketinglistdata?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()))

    //delete marketing user 
    const queryclient = useQueryClient();
    const deletemarketinguser = useMutation(DeleteMarketingUser, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Marketing user deleted Successfully!");
                queryclient.invalidateQueries("marketinglistdata")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDelete = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to delete marketing user?');
    if(userConfirmed){
        deletemarketinguser.mutate({id});

    }
       
    }


    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>Marketing List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Marketing List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                                    <DataTable
                                                    data={filteredData}
                                                    headers={headers}
                                                    renderRow={(rowData) => (
                                                        <>
                                                            <td>{rowData.name}</td>
                                                            <td>{rowData.email}</td>
                                                            <td>{rowData.wallet_balance || 0}</td>
                                                            <td>{rowData.incentive}</td>
                                                            <td>{rowData.net_deposit}</td>
                                                            <td>{rowData.lots}</td>
                                                            <td>
                                                            {
                                                                checkPermission?.typeAdmin === 1 &&
                                                                <Link to={`/admin/marketing/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link>}&nbsp;
                                                         {
                                                            checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data?.includes("edit_marketing") ?
                                                            <Link to={`/admin/marketing/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link> : ""}&nbsp;
                                                             {
                                                                checkPermission?.typeAdmin === 1 &&
                                                             <button className="btn btn-xs btn-primary" onClick={()=>handleDelete(rowData.id)}>Delete</button>} 
                                                              {
                                                            checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data?.includes("delete_marketing") ?
                                                            <button className="btn btn-xs btn-primary" onClick={()=>handleDelete(rowData.id)}>Delete</button> : ""} &nbsp;
                                                             
                                                             <Link to={`/admin/marketing/clients/${rowData.id}`} className="btn btn-xs btn-primary">Clients</Link> &nbsp;
                                                             <Link to={`/admin/marketing/teamdeposit/${rowData.id}`} className="btn btn-xs btn-primary">Team Deposit</Link> &nbsp;
                                                             <Link to={`/admin/marketing/teamwithdraw/${rowData.id}`} className="btn btn-xs btn-primary">Team Withdraw</Link>

                                                        </td>
                                                        </>
                                                    )}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
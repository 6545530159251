import { Input, message } from "antd";
import styled from "styled-components";
import user from "../../img/user.jpg"
import short_logo from "../../img/short_logo.png"
import { useAppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import useIdle from "../../hooks/useIdle";
import { useQueryClient } from "react-query";
import { NotificationPopover } from "./NotificationPopover";
import useGetdata from "../../hooks/useGetData";
import { getUserDetails } from "../../api/userDetails";
import { useEffect } from "react";

const {Search} = Input;

const StyleSearch = styled(Search)`
.ant-input-group >.ant-input:first-child, .ant-input-group .ant-input-group-addon:first-child {
    background: var(--secondary);
    font-family: 'Gilroy';
    color: #ffff;
    border: 1px solid #999;
}
.ant-input-search {
    border-color: #999;
    
}
.ant-input::placeholder{
    color:#999;
}
.ant-input-search:hover{
    border-color: #999;
}

.ant-btn-primary {
    background-color: #f37b4c;
    border-color:#f37b4c;
}

.ant-input-group-addon:last-child .ant-input-search-button:hover {

background-color:#7ca40a !important;
border-color:#7ca40a;
}
`


export const Header = () => {
    const { toggleSidebar } = useAppContext();
    const navigate = useNavigate();

    //search data
    // const handleSearch = async (value) => {
      // try {
      //   const response = await SearchProjectData();
      // } catch (error) {
      //   message.error(error.message);
      // }
    // };

   
    //logout method
    const handleSignOut = async () => {
    localStorage.removeItem('token');
    // logout();
    message.success("Logout Successfully!")
    localStorage.clear();
    sessionStorage.setItem('loggedin', 0);
    navigate("/admin/login");
  }



  // Logout when No action is been taken place in app
  const queryClient = useQueryClient();
  function handleLogOutIdle() {
    let token = localStorage.getItem("token");
    let loggedin = sessionStorage.getItem("loggedin");
    localStorage.clear(token);
    sessionStorage.clear(loggedin)
    queryClient.removeQueries("userDetails");
    queryClient.removeQueries("leadDetails");
    navigate("/admin/login");
  }
  const { isIdle } = useIdle({ onIdle: handleLogOutIdle, idleTime: 30 });

//get user details
  const { data: userDetails, refetch } = useGetdata(
    "userDetails",
    getUserDetails
  );

  let token = localStorage.getItem('token');
useEffect(()=>{
  if(isIdle) {
    handleLogOutIdle()
  }
  if(token) {
  refetch() 
  }
},[token, refetch, isIdle])

    return (
        <>
         {/* <!-- Navbar Start --> */}
    <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-3 px-xl-5"> 
    <Link to="/admin/dashboard" className="navbar-brand d-flex d-xl-none ms-5"> <img src={short_logo} alt="" /> </Link>
    <Link  to="" className="sidebar-toggler flex-shrink-0" onClick={toggleSidebar}> <i className="fa-sharp fa-solid fa-angle-left"></i> </Link>
      <div className="navbar-nav align-items-center ms-auto">
          <div className="nav-item hideonmobile">
          <form className="d-flex">
          <StyleSearch placeholder="Search..." 
          onSearch={(value) => console.log(value)}
          // onSearch={handleSearch}
           enterButton size="large"/>
			</form>
          </div>
       <NotificationPopover />
        <div className="nav-item dropdown">
             <Link to="" className="nav-link dropdown-toggle d-flex align-items-start" data-bs-toggle="dropdown"> 
             <img className="rounded-circle me-lg-2" src={user} alt="" style={{width: "40px", height: "40px"}} /> 
             <span className="d-none d-lg-inline font-13"><strong className="font-16">{userDetails?.data?.name}</strong>
              <span className="text-lighter d-block">{userDetails?.data?.email}</span>
              </span> </Link>
          <div className="dropdown-menu dropdown-menu-end bg-dark border-0 rounded-0 rounded-bottom m-0">
             <Link to="/admin/changePassword" className="dropdown-item">Change Password</Link>
             <Link to="/admin/login" className="dropdown-item" onClick={handleSignOut} >Log Out</Link> </div>
        </div>
      </div>
    </nav>
    {/* <!-- Navbar End -->  */}
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { useEffect, useState } from "react";
import { GetAllIBCommssion } from "../../../api/ib";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";



export const UserCommissionList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get  ib commission list
    const { data: allIbCommissionList } = useGetdata(
        "allIbCommissionList",
        GetAllIBCommssion
    );

    console.log(allIbCommissionList?.data)
    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Plan Name", key: "planname", sortable: true },
        { label: "Name", key: "username", sortable: true },
        { label: "Email", key: "useremail", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
        { label: "Action", sortable: false }
    ]

    const filteredData = allIbCommissionList?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
        item?.planname && item.planname.toLowerCase().includes(searched.toLowerCase()) ||
        item.username && item.username.toLowerCase().includes(searched.toLowerCase()) ||
        item.useremail && item.useremail.toLowerCase().includes(searched.toLowerCase())


    )

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])


    return (
        <>
            <Helmet><title>User Commission List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>User Commission List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                                {
                                    checkPermission?.typeAdmin === 1 &&
                                    <Link to="/admin/ib/addusercommission" className="btn btn-xs btn-primary">Add User Commission</Link>}
                                {
                                    checkPermission?.typeAdmin === 0 &&
                                        checkPermission?.data?.includes("add_ibcommission") ?
                                        <Link to="/admin/ib/addusercommission" className="btn btn-xs btn-primary">Add User Commission</Link> : ""}

                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>
                                                <td>{rowData.planname}</td>
                                                <td>{rowData.username}</td>
                                                <td>{rowData.useremail}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    {
                                                        checkPermission?.typeAdmin === 1 &&
                                                        <Link to={`/admin/ib/editusercommission/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link>
                                                    }
                                                    {
                                                        checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data?.includes("edit_ibcommission") ?
                                                            <Link to={`/admin/ib/editusercommission/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link> : ""}
                                                </td>
                                            </>
                                        )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
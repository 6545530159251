//Post default setting

import instance from "../instance";

export const AddDefaultSetting = async (data) => {
    let token = localStorage.getItem("token");
  
  let response = await instance({
    url: `api/admin/defaultsettings/user/add`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
     data
  });

  return response.data;
};


//update default setting
export const UpdateDefaultSetting = async (data) => {
    let token = localStorage.getItem("token");
  
  let response = await instance({
    url: `api/admin/defaultsettings/user/update`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
     data
  });

  return response.data;
};


//get default settings

export const GetDefaultSettings = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/defaultsettings/user`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { SingleLead } from "./leadTabs/SingleLead"
import { LeadNotes } from "./leadTabs/LeadNotes"
import { LeadReminderList } from "./leadTabs/LeadReminderList"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"

export const LeadDetails = () => {
    const { sidebarOpen } = useAppContext()
    return (
        <>
            <Helmet><title>Lead Details | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Single Lead</h1>
                    </div>
                    <div className="user-details referral-tab mt-4 mb-5">
                        <nav className="d-lg-flex table-head justify-content-lg-between align-items-lg-center">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-single-lead-tab" data-bs-toggle="tab" data-bs-target="#nav-single-lead" type="button" role="tab" aria-controls="nav-single-lead" aria-selected="true">Lead Details</button>
                                <button className="nav-link" id="nav-leadNotes-tab" data-bs-toggle="tab" data-bs-target="#nav-leadNotes" type="button" role="tab" aria-controls="nav-LeadNotes" aria-selected="false">Notes</button>
                                <button className="nav-link" id="nav-mt5account-tab" data-bs-toggle="tab" data-bs-target="#nav-mt5account" type="button" role="tab" aria-controls="nav-mt5account" aria-selected="false">Reminders</button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-single-lead" role="tabpanel" aria-labelledby="nav-single-lead-tab">
                               <SingleLead />
                            </div>
                            <div className="tab-pane fade" id="nav-leadNotes" role="tabpanel" aria-labelledby="nav-leadNotes-tab">
                               <LeadNotes />
                            </div>
                            <div className="tab-pane fade" id="nav-mt5account" role="tabpanel" aria-labelledby="nav-mt5account-tab">
                            <LeadReminderList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
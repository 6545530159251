import { Helmet } from "react-helmet-async"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { Header } from "../../../layout/dashboardLayout/Header"
import { useAppContext } from "../../../context/AppContext"
import { useState } from "react"
import { AddPermission, GetSubAdmin } from "../../../api/subAdmin"
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import useGetdata from "../../../hooks/useGetData"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"
import { SelectDropdown } from "../../../component/DropDownComponent"
import autoCompleteCss from "../../../component/autoCompleteCss"
import { Autocomplete, TextField } from "@mui/material"

export const AddSubAdminPermission = () => {
    const { sidebarOpen } = useAppContext();
    const [adminId, SetAdminId] = useState({})
    const [permissionNames, setPermissionNames] = useState([])
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    //get admin
    const { data: getallAdmin } = useGetdata(
        "getallAdmin",
        GetSubAdmin
    )

    //add sub admin permission
    const queryclient = useQueryClient();
    const addPermission = useMutation(AddPermission, {
        onSuccess: (data) => {
            if (data.status === 200) {
                message.success("Permission Added Successfully!");
                queryclient.invalidateQueries("subadminList")
                setDisable(false)
                // navigate("/admin/subAdminList")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleAddPermssion = (e) => {
        e.preventDefault();
        setDisable(true)

        if (!adminId) {
            setLoginError("please fill above field")
            setDisable(false)
        }

        let data = {
            adminId: Number(adminId?.value),
            permissionNames
        }
        addPermission.mutate(data);
    }

    return (
        <>
            <Helmet><title>Add Permission | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add Permission</h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="subadmin" className="form-label">Select Sub Admin <span className="required-mark">*</span></label>
                                    <SelectDropdown id="subadmin"
                                        value={adminId}
                                        onChange={(value) => SetAdminId(value)}>
                                        <option selected="">Please choose...</option>
                                        {
                                            getallAdmin?.data?.map((adminList) => (
                                                <option key={adminList.id} value={adminList.id}>{adminList.name} &nbsp;({adminList.email})</option>
                                            ))
                                        }
                                    </SelectDropdown>
                                    {/* <Autocomplete
                                        multiple
                                        sx={autoCompleteCss}
                                        limitTags={2}
                                        id="tags-outlined"
                                        filterSelectedOptions
                                        onChange={(event, value) => SetAdminId(value)}
                                        options={getallAdmin?.data?.map((el) => {
                                            return {
                                                name: el?.name,
                                                value: el?.id
                                            }
                                        })}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Please Choose..." />
                                        )}
                                    /> */}
                                    <p className={
                                        adminId?.value?.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">User Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_user"))} />
                                            <label className="form-check-label">Add User</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_user"))} />
                                            <label className="form-check-label">Edit User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_user"))} />
                                            <label className="form-check-label">Delete User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user"))} />
                                            <label className="form-check-label">View User </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user_list"))} />
                                            <label className="form-check-label">User List </label>

                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_user_settings") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_user_settings"))} />
                                            <label className="form-check-label">Edit User Settings </label>

                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("change_user_password") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "change_user_password"))} />
                                            <label className="form-check-label">Change User Password </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user_password_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user_password_list"))} />
                                            <label className="form-check-label">User Password List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("approved_document_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "approved_document_list"))} />
                                            <label className="form-check-label">Approved Document List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_document_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_document_list"))} />
                                            <label className="form-check-label">Pending Document List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("upload_document") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "upload_document"))} />
                                            <label className="form-check-label">Upload Document </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("followup_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "followup_list"))} />
                                            <label className="form-check-label">Follow Up List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_bank_detail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_bank_detail"))} />
                                            <label className="form-check-label">Add Bank Details </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("bank_detail_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "bank_detail_list"))} />
                                            <label className="form-check-label">Bank Details List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("mt_user_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "mt_user_list"))} />
                                            <label className="form-check-label">MT5 User List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("change_account_password") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "change_account_password"))} />
                                            <label className="form-check-label">Change MT5 Password </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_existing_client") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_existing_client"))} />
                                            <label className="form-check-label">Add Existing Client </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("update_mt_leverage") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_mt_leverage"))} />
                                            <label className="form-check-label">Update MT5 Leverage </label>

                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("resend_verification_mail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "resend_verification_mail"))} />
                                            <label className="form-check-label">Resend Verification Email </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("resend_account_details_mail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "resend_account_details_mail"))} />
                                            <label className="form-check-label">Resend MT5 Data Mail </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("create_live_account") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_live_account"))} />
                                            <label className="form-check-label">Create MT5 Account </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Bonus Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("give_bonus") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "give_bonus"))} />
                                            <label className="form-check-label">Give Bonus</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("remove_bonus") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "remove_bonus"))} />
                                            <label className="form-check-label">Remove Bonus</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("bonus_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "bonus_list"))} />
                                            <label className="form-check-label">Bonus List</label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">IB Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("approved_ib_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "approved_ib_list"))} />
                                            <label className="form-check-label">IB User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("requested_ib_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "requested_ib_list"))} />
                                            <label className="form-check-label">IB Request</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ib_levels") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ib_levels"))} />
                                            <label className="form-check-label">IB Levels</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_commission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_commission"))} />
                                            <label className="form-check-label">View Commission</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("demote_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "demote_ib"))} />
                                            <label className="form-check-label">Demote IB</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("remove_client_from_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "remove_client_from_ib"))} />
                                            <label className="form-check-label">Remove Client From IB</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_ibplan_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_ibplan_list"))} />
                                            <label className="form-check-label">IB Plan List</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibplan") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibplan"))} />
                                            <label className="form-check-label">Create IB Plan</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_commissiongroup_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_commissiongroup_list"))} />
                                            <label className="form-check-label">Commission Group List</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("create_commissiongroup") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_commissiongroup"))} />
                                            <label className="form-check-label">Create Commission Group</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("update_commissiongroup") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_commissiongroup"))} />
                                            <label className="form-check-label">Update Commission Group</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_ibcommission_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_ibcommission_list"))} />
                                            <label className="form-check-label">Set IB Commission</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibcommission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibcommission"))} />
                                            <label className="form-check-label">Add IB Commission</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_ibcommission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_ibcommission"))} />
                                            <label className="form-check-label">Edit IB Commission</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("move_client_to_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "move_client_to_ib"))} />
                                            <label className="form-check-label">Move Client to IB</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("move_ib_to_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "move_ib_to_ib"))} />
                                            <label className="form-check-label">Move IB to IB</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Group Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_group"))} />
                                            <label className="form-check-label">Add Group</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_group"))} />
                                            <label className="form-check-label">Edit Group</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("group_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "group_list"))} />
                                            <label className="form-check-label">Group List</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("update_mt_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_mt_group"))} />
                                            <label className="form-check-label">Update Group MT5</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Transaction</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_deposit") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_deposit"))} />
                                            <label className="form-check-label">Add Deposit </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_withdraw") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_withdraw"))} />
                                            <label className="form-check-label">Add Withdraw </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibwithdraw") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibwithdraw"))} />
                                            <label className="form-check-label">Add IB Withdraw </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_deposit_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_deposit_list"))} />
                                            <label className="form-check-label">Pending Deposit List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_withdraw_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_withdraw_list"))} />
                                            <label className="form-check-label">Pending Withdraw List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_ibwithdraw_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_ibwithdraw_list"))} />
                                            <label className="form-check-label">Pending IB Withdraw List </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("internal_transfer") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "transfer_list"))} />
                                            <label className="form-check-label">Internal Transfer </label>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Marketing Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("marketing_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "marketing_list"))} />
                                            <label className="form-check-label">Marketing List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_marketing"))} />
                                            <label className="form-check-label">Add Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_marketing"))} />
                                            <label className="form-check-label">Edit Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_marketing"))} />
                                            <label className="form-check-label">Delete Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("incentive_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "incentive_report"))} />
                                            <label className="form-check-label">Incentive Report </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("marketing_withdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "marketing_withdraw_report"))} />
                                            <label className="form-check-label">Marketing Withdraw Report </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Sales Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("sales_dashboard") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "sales_dashboard"))} />
                                            <label className="form-check-label">Sales Dashboard</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_sales"))} />
                                            <label className="form-check-label">Create Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_sales"))} />
                                            <label className="form-check-label">Edit Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_sales"))} />
                                            <label className="form-check-label">Delete Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("sales_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "sales_list"))} />
                                            <label className="form-check-label">Sales List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_lead"))} />
                                            <label className="form-check-label">Add Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_lead"))} />
                                            <label className="form-check-label">Edit Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_lead"))} />
                                            <label className="form-check-label">Delete Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_lead"))} />
                                            <label className="form-check-label">View Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_lead_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_lead_list"))} />
                                            <label className="form-check-label">Lead List </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("convert_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "convert_lead"))} />
                                            <label className="form-check-label">Convert Lead </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("lead_source") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "lead_source"))} />
                                            <label className="form-check-label">Lead Source </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("lead_status") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "lead_status"))} />
                                            <label className="form-check-label">Lead Status </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Email Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("send_email") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "send_email"))} />
                                            <label className="form-check-label">Send Email </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Notification Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("unread_notification") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "unread_notification"))} />
                                            <label className="form-check-label">Unread Notification</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("read_notification") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "read_notification"))} />
                                            <label className="form-check-label">Read Notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Report Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("deposit_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "deposit_report"))} />
                                            <label className="form-check-label">Deposit Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("withdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "withdraw_report"))} />
                                            <label className="form-check-label">Withdraw Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("transfer_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "transfer_report"))} />
                                            <label className="form-check-label">Transfer Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ibwithdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ibwithdraw_report"))} />
                                            <label className="form-check-label">IB Withdraw Report</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("position_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "position_report"))} />
                                            <label className="form-check-label">Position Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("wallet_history_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "wallet_history_report"))} />
                                            <label className="form-check-label">Wallet History Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("login_activity_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "login_activity_report"))} />
                                            <label className="form-check-label">Login Activity Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("history_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "history_report"))} />
                                            <label className="form-check-label">History Report</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Ticket Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ticket") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ticket"))} />
                                            <label className="form-check-label">Tickets</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Setting Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("default_setting") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "default_setting"))} />
                                            <label className="form-check-label">Default Setting</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("system_setting") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "system_setting"))} />
                                            <label className="form-check-label">System Setting</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("banner") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "banner"))} />
                                            <label className="form-check-label">Banner</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Sub Admin</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("create_subadmin") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_subadmin"))} />
                                            <label className="form-check-label">Create Sub Admin</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("subadmin_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "subadmin_list"))} />
                                            <label className="form-check-label">Sub Admin List</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_subadmin_permission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_subadmin_permission"))} />
                                            <label className="form-check-label">Add Sub Admin Permission</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_subadmin_permission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_subadmin_permission"))} />
                                            <label className="form-check-label">Edit Sub Admin Permission</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs" type="submit" onClick={handleAddPermssion}
                                disabled={disable}>
                                {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}

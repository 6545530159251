import instance from "../instance";
//get approve withdraw

export const getApproveWithdraw = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/approved/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

//get all pending withdraw

export const getAllPendingWithdraw = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/pending/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //approve withdraw
  export const ApproveWithdraw = async (deposit) => {
    let token = localStorage.getItem("token");
     const { id, data}= deposit
    let response = await instance({
      url: `api/admin/withdraw/approve/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

   //Reject withdraw
   export const RejectWithdraw = async (deposit) => {
    let token = localStorage.getItem("token");
     const { id, data}= deposit
    let response = await instance({
      url: `api/admin/withdraw/reject/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get monthly withdraw
  export const getMonthlyWithdraw = async ({month}) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/month/totalamount?month=${month}&year`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //get Yearly withdraw

  export const getYearlyWithdraw = async ({year}) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/withdraw/year/totalamount?year=${year}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

    //get Weekly Withdraw

export const getWeeklyWithdraw = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/withdraw/week/totalamount`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get Withdraw Report

export const getWithdrawReport = async (data) => {
  let token = localStorage.getItem("token");
  const {fromDate, toDate, type, paymentmethod} = data;
  let response = await instance({
    url: `api/admin/report/withdraw?fromDate=${fromDate}&toDate=${toDate}&type=${type}&paymentmethod=${paymentmethod}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//add client withdraw
export const AddClientWithdraw = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/withdraw/add`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
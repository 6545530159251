import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import {  UpdateGroupdData, getAllGroup } from "../../../api/group";
import { useParams } from "react-router-dom";
import useGetdata from "../../../hooks/useGetData";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditGroup = () => {
    const[name, setName]= useState("")
    const[mtgroup, setMtgroup]= useState("")
    const[status, setStatus]= useState("")
    const[disable, setDisable] = useState(false)

    const{id} = useParams();
    const { sidebarOpen } = useAppContext();
 //get Group list
 const { data: allGroupDetails, isLoading:userDLoad } = useGetdata(
    "allGroupDetails",
    getAllGroup
);
    const singleItem = allGroupDetails?.data?.find(item => item.id === parseInt(id));

   //Update Group 
   const queryclient = useQueryClient();
   const groupUpdate = useMutation(UpdateGroupdData, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("Group Updated Successfully!");
               queryclient.invalidateQueries("allGroupDetails");
               setDisable(false)
           }
       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
              }
       }
   })

   useEffect(() => {
       if (!userDLoad) {
           setName(singleItem?.name);
           setMtgroup(singleItem?.mtgroup);
           setStatus(singleItem?.status);

       }
   }, [userDLoad, singleItem]);


   const handleUpdateGroup = async (e) => {
       e.preventDefault();
       setDisable(true)

       let data = {
           name,
           mtgroup,
           status,
       }

       groupUpdate.mutate({ id: id, data });
       return;
   }



    return (
        <>
            <Helmet><title>Edit Group | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Group</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e)=> setName(e.target.value)}
                                        />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="mtgroup" className="form-label">MT5 Group Name <span className="required-mark">*</span></label>
                                    <input type="mtgroup" name="mtgroup" className="form-control form-control-lg " id="email"
                                        placeholder="Enter group Name" 
                                        value={mtgroup}
                                        onChange={(e)=> setMtgroup(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="status" className="form-label">Status <span className="required-mark">*</span></label>
                                    <SelectDropdown id="groupstatus"
                                    value={status}
                                     onChange={(value) => setStatus(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value={0}>Inactive</option>
                                        <option value={1}>Active</option>
                                    </SelectDropdown>
                                </div>
                            </div>
                          
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUpdateGroup} disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />

        </>
    )
}
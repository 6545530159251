import instance from "../instance";

  //add news
  export const CreateNews = async (data) => {
    let token = localStorage.getItem("token");
    
    let response = await instance({
      url: "api/admin/news/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //get news list
  export const GetNews = async (data) => {
    let token = localStorage.getItem("token");
    
    let response = await instance({
      url: "api/admin/news/list",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //edit news
  export const UpdateNews = async (data) => {
    let token = localStorage.getItem("token");
    const {id} = data;
    let response = await instance({
      url: `api/admin/news/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  
  //get single news
  export const getSingleNews = async (data) => {
    let token = localStorage.getItem("token");
    const {id} = data;
    let response = await instance({
      url: `api/admin/news/single/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //edit news
  export const DeleteNews = async (data) => {
    let token = localStorage.getItem("token");
    const {id} = data;
    let response = await instance({
      url: `api/admin/news/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };
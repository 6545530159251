import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { GetCommissionGroup } from "../../../api/ib";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const CommissionGroupList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get commissiongroup list
    const { data: allCommissiongroup } = useGetdata(
        "allCommissiongroup",
        GetCommissionGroup
    )

    useEffect(()=>{
        GetCommissionGroup()
    },[allCommissiongroup])

    const headers = [
        { label: "ID", key:"id", sortable: true },
        { label: " Plan Name", key:"planname", sortable: true },
        { label: " Group Name", key:"groupname", sortable: true },
        { label: "Level1 Commission", key:"level1", sortable: true },
        { label: "Level1(D2) Commission", key:"level1d2", sortable: true },
        { label: "Level2(D2) Commission", key:"level2d2", sortable: true },
        { label: "Level1(D3) Commission", key:"level1d3", sortable: true },
        { label: "Level2(D3) Commission", key:"level2d3", sortable: true },
        { label: "Level3(D3) Commission", key:"level3d3", sortable: true },
        { label: "Level1(D4) Commission", key:"level1d4", sortable: true },
        { label: "Level2(D4) Commission", key:"level2d4", sortable: true },
        { label: "Level3(D4) Commission", key:"level3d4", sortable: true },
        { label: "Level4(D4) Commission", key:"level4d4", sortable: true },
        { label: "Level1(D5) Commission", key:"level1d5", sortable: true },
        { label: "Level2(D5) Commission", key:"level2d5", sortable: true },
        { label: "Level3(D5) Commission", key:"level3d5", sortable: true },
        { label: "Level4(D5) Commission", key:"level4d5", sortable: true },
        { label: "Level5(D5) Commission", key:"level5d5", sortable: true },
        

       
        { label: "Action", key:"", sortable: false },
    ]

    const filteredData = allCommissiongroup?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.groupname && item.groupname.toLowerCase().includes(searched.toLowerCase()) ||
    item.planname && item.planname.toLowerCase().includes(searched.toLowerCase())
    )

    //set permission
    const{data:checkPermission, refetch} = useGetdata(
        "checkPermission",  
        GetPermissionCheck
      )
      
      let token = localStorage.getItem('token');
      useEffect(()=>{
      if(token) {
      refetch()
      }
      },[token, refetch])

    return (
        <>
            <Helmet><title>Commission Group List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Commission Group List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                            {
                              checkPermission?.typeAdmin === 1 &&
                                <Link to="/admin/ib/addcommissiongroup" className="btn btn-xs btn-primary">Add Commission Group</Link>}
                                {
                                  checkPermission?.typeAdmin === 0 &&
                                      checkPermission?.data.includes("create_commissiongroup") ?
                                      <Link to="/admin/ib/addcommissiongroup" className="btn btn-xs btn-primary">Add Commission Group</Link>: ""}
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <table className="table table-dark table-striped mb-0 text-center table-hover dataTable">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th> Plan Name</th>
                                                <th> Group Name</th>
                                                <th>Level1 Commission</th>
                                                <th>Level2 Commission</th>
                                                <th>Level3 Commission</th>
                                                <th>Level4 Commission</th>
                                                <th>Level5 Commission</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {allCommissiongroup?.data?.length > 0 ?
                                            <tbody> */}
                                                <DataTable
                                                data={filteredData}
                                                headers={headers}
                                                renderRow={(rowData) => (
                                                        <>
                                                <td>{rowData.planname}</td>
                                                <td>{rowData.groupname}</td>
                                                <td>{rowData.level1}</td>
                                                <td>{rowData.level1d2}</td>
                                                <td>{rowData.level2d2}</td>
                                                <td>{rowData.level1d3}</td>
                                                <td>{rowData.level2d3}</td>
                                                <td>{rowData.level3d3}</td>
                                                <td>{rowData.level1d4}</td>
                                                <td>{rowData.level2d4}</td>
                                                <td>{rowData.level3d4}</td>
                                                <td>{rowData.level4d4}</td>
                                                <td>{rowData.level1d5}</td>
                                                <td>{rowData.level2d5}</td>
                                                <td>{rowData.level3d5}</td>
                                                <td>{rowData.level4d5}</td>
                                                <td>{rowData.level5d5}</td>

                                                <td>
                                                {
                                                    checkPermission?.typeAdmin === 1 &&
                                                    <Link to={`/admin/ib/editcommissiongroup/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link>}
                                                     {
                                  checkPermission?.typeAdmin === 0 &&
                                      checkPermission?.data.includes("update_commissiongroup") ?
                                      <Link to={`/admin/ib/editcommissiongroup/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link> :""}
                                                    </td>
                                                    </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
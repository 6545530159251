import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../../context/AppContext";
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useState } from "react";
import { subMonths, format } from "date-fns";
import { useParams } from "react-router-dom";
import { getMyIBCommission } from "../../../api/ib";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { message } from "antd";

export const IBUserCommissionListReport = () => {
  const today = new Date();
  const defaultToDate = format(today, "yyyy-MM-dd"); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), "yyyy-MM-dd"); // One month ago

  const { sidebarOpen } = useAppContext();
  const [startDate, setStartDate] = useState(defaultFromDate);
  const [endDate, setEndDate] = useState(defaultToDate);
  const [commissionData, setCommissionData] = useState([]);
  const [searched, setSearched] = useState("");
  const[disable, setDisable]= useState(false)

  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  const {id} = useParams();
  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = {
        startDate: formattingDate(startDate),
        endDate: formattingDate(endDate),
      };
      const result = await getMyIBCommission(id,data);
      setCommissionData(result);
      if(result.status === 200 ){
        setDisable(false)
      }
      
    } catch (error) {
      setDisable(false)
      message.error( error);
    }
  };

  const headers = [
    { label: "ID", key: "id", sortable:true },
    { label: "Login", key: "login", sortable:true },
    { label: "Order", key: "order", sortable:true },
    { label: "Symbol", key: "symbol", sortable:true },
    { label: "Price", key: "price", sortable:true },
    { label: "Profit", key: "profit", sortable:true },
    { label: "Volume",  key: "volume", sortable:true },
    { label: "My Commission", key: ": commission", sortable:true } ,
    { label: "Date", key: "createdAt", sortable:true }
  ]

  const filteredData = commissionData?.data?.filter(
    (item) =>
      item.login && item.login.toString().includes(searched) ||
    item.price && item.price.toString().includes(searched) ||
    item.profit && item.profit.toString().includes(searched) ||
    item.order && item.order.toString().includes(searched) ||
    item.commission && item.commission.toString().includes(searched) ||
    item.symbol && item.symbol.toString().includes(searched)

      // item.userName.toLowerCase().includes(searched.toLowerCase()) ||
      // item.userEmail.toLowerCase().includes(searched.toLowerCase()) ||
      // item.from.toLowerCase().includes(searched.toLowerCase()) ||
      // item.to.toLowerCase().includes(searched.toLowerCase()) ||
      // item.amount.toString().includes(searched)
  );


  //export to pdf
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
        'ID',
        'Login',
        'Order',
        'Symbol',
        'Price',
        'Profit',
        'Volume',
        'MyCommission',
        'Date',
    ];

    const rows = data?.map((item, index) => [
        index + 1,
        item.login,
        item.order,
        item.symbol,
        item.price,
        item.profit,
        item.volume, 
        item.commission,
        formatDate(item.createdAt),
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
      },
      styles: {
        fontSize: 10,
        font: "Gilroy",
      },
    });
    doc.save("My IBCommission List.pdf");
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        'ID',
    'Login',
    'Order',
    'Symbol',
    'Price',
    'Profit',
    'Volume',
    'My Commission',
    'Date',
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "My IB Commission List.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item, index) => ({
        'ID':  index + 1,
        'Login':item.login,
        'Order':item.order,
        'Symbol':item.symbol,
        'Price':item.price,
        'Profit':item.profit,
        'Volume': item.volume,
        'My Commission' : item.commission ,
        'Date':formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const filename = "My IB Commission List.xlsx";

    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <Helmet>
        <title>
          {" "}
          IB User Commission List | {process.env.REACT_APP_PROJECT_NAME}
        </title>
      </Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>IB User Commission List</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label>
                      <strong>From :</strong>
                    </label>
                    <input
                      type="Date"
                      className="form-control form-control-lg bg-dark"
                      name="fromdate"
                      id="fromdate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label>
                      <strong>To :</strong>
                    </label>
                    <input
                      type="Date"
                      className="form-control form-control-lg bg-dark "
                      name="todate"
                      id="todate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3 pt-4">
                    <button
                      type="button"
                      id="dateSearch"
                      className="btn btn-sm btn-primary"
                      onClick={handleSearch}
                      disabled={disable}
                    >
                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Search" }</button>
                  </div>
                </div>
              </div>
              <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                <div className="table-responsive-lg p-3">
                  <div className="row mb-3 dataTables_filter">
                    <div className="col-sm-12 d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToPDF(commissionData?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;PDF
                        </button>
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToExcel(commissionData?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;EXCEL
                        </button>
                      </div>
                      <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input
                          type="search"
                          className="bg-dark  h-100"
                          value={searched}
                          onChange={(e) => setSearched(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                        <DataTable
                        data={filteredData}
                        headers={headers}
                        renderRow={(rowData) => (
                          <>
                            <td>{rowData.login}</td>
                            <td>{rowData.order}</td>
                            <td>{rowData.symbol}</td>
                            <td>{rowData.price}</td>
                            <td>{rowData.profit}</td>
                            <td>{rowData.volume}</td>
                            <td>{rowData.commission}</td>
                            <td>{formatDate(rowData.createdAt)}</td>
                          </>
                        )}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
            <BackToTopPage />
    </>
  );
};

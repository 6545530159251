import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import {  GetIBPlanData, GetSingleIBCommission, UpdateUserIBCommission, getApprovedReqUser } from "../../../api/ib";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditUserCommission = () => {
    const [planid, setPlanId] = useState("");
    const [userid, setUserId] = useState("");
    const[singleUserCommission, setSingleUserCommission] = useState({})
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();
    const {id } = useParams();
    const navigate = useNavigate();

    //get ib users
    const{data: ibApprovedUser, isLoading:userDLoad} = useGetdata(
        "ibApprovedUser",
        getApprovedReqUser
    )

//get ib plan 
const { data: allIBPlan } = useGetdata(
    "allIBPlan",
    GetIBPlanData
);

 //get single commission group
 useEffect(() => {
    const fetchSingleUserCommission = async () => {
      try {
        const data = await GetSingleIBCommission({id})
        setSingleUserCommission(data);
      } catch (error) {
        message.error(error)
      }

    };

    fetchSingleUserCommission();
  }, [id]);

  const queryclient = useQueryClient()  
  const ibCommissionUpdate = useMutation(UpdateUserIBCommission, {
      onSuccess: (data) => {
          if (data?.status === 200) {
              message.success("User IB Commission Updated Successfully!");
              navigate("/admin/ib/usercommissionlist")
              queryclient.invalidateQueries("allIbCommissionList")
              setDisable(false)


          }
      },
      onError: (data) => {
        setDisable(false)
          if (data?.response?.data.status === 400) {
              message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
              message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 404) {
              message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
              message.error(data?.response?.data.message);
          }
      }
  })

  useEffect(() => {
      if (!userDLoad) {
          setPlanId(singleUserCommission?.data?.planid);
          setUserId(singleUserCommission?.data?.userid);


      }
  }, [userDLoad, singleUserCommission]);


  const handleUpdateIBCommission = async (e) => {
      e.preventDefault();
      setDisable(true)

      ibCommissionUpdate.mutate({ id: id, planid, userid });
      return;
  }


    return (
        <>
            <Helmet><title>Edit User Commission | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit User Commission </h1>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                                <Link to="/admin/ib/usercommissionlist" className="btn btn-xs btn-primary">View User Commission</Link>
                            </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="ibuser" className="form-label">Select IB User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="ibuser"
                                    value={userid}
                                     onChange={(value) => setUserId(value)}>
                                        <option selected="">Please choose...</option>

                                        {ibApprovedUser?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                </div>
                                <div className="mb-4">
                                    <label for="planuser" className="form-label">Select Plan <span className="required-mark">*</span></label>
                                    <SelectDropdown id="planuser"
                                    value={planid}
                                     onChange={(value) => setPlanId(value)}>
                                        <option selected="">Please choose...</option>

                                        {allIBPlan?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name}</option>

                                        ))}
                                    </SelectDropdown>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUpdateIBCommission} disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
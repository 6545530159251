import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../../api/user";
import { formatDate } from "../../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import DataTable from "../../../../component/DataTable";
import { message } from "antd";

export const MT5Accounts = () => {
  const [userliveaccount, setUserLiveAccounts] = useState({})
  const [searched, setSearched] = useState("")

  const { id } = useParams()

  const headers = [
    { label: "ID", key:"id", sortable:true },
    { label: "MT5 ID", key:"account_no", sortable:true },
    { label: "Group Name", key:"groupname", sortable:true },
    { label: "Investor Password", key:"investor_password", sortable:true },
    { label: "Main Password", key:"main_password", sortable:true },
    { label: "Date", key:"createdAt", sortable:true },
  ]

  const filteredData = userliveaccount?.data?.liveAccounts?.filter((item) =>
    // index + 1.toString().includes(searched) ||
  item.groupname && item.groupname.toLowerCase().includes(searched.toLowerCase()) ||
  item.account_no && item.account_no.toString().includes(searched)
)

  //fetch single user details
  useEffect(() => {
    const fetchUserDepositList = async () => {
      try {
        const data = await getSingleUser({ id });
        setUserLiveAccounts(data);
      } catch (error) {
        message.error(error.message);

      }
    };

    fetchUserDepositList();
  }, [id]);

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'MT5 ID',
      'Group Name',
      'Investor Password',
      'Main Password',
      'Date'
    ];

    const rows = data?.map((item,index) => [
      index + 1,
      item.account_no,
      item.groupname,
      item.investor_password,
      item.main_password,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('MT5 Account List.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        'ID',
        'MT5 ID',
        'Group Name',
        'Investor Password',
        'Main Password',
        'Date'
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "MT5 Account List.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item,index) => ({
      'ID': index + 1,
      'MT5 ID': item.account_no,
      'Group Name': item.groupname,
      'Investor Password': item.investor_password,
      'Main Password': item.main_password,
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.aoa_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'account-list.xlsx';

    XLSX.writeFile(wb, filename);
  }


  return (
    <>
      <div className="table-responsive-lg">
        <div className="row p-3 dataTables_filter">
          <div className="col-sm-12 d-flex justify-content-between">
            <div className="d-flex gap-2">
              <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(userliveaccount?.data?.liveAccounts)}>
                <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
              <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(userliveaccount?.data?.liveAccounts)}>
                <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

            </div>
            <div className="d-flex align-items-center">
              <label> Search:&nbsp;</label>
              <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
            </div>
          </div>
        </div>
        <DataTable
          data={filteredData}
          headers={headers}
          renderRow={(data) => (
            <>
              <td>{data.account_no}</td>
              <td>{data.groupname}</td>
              <td>{data.investor_password}</td>
              <td>{data.main_password}</td>
              <td>{formatDate(data.createdAt)}</td>
            </>
          )} />
      </div>

    </>
  )
}
import instance from "../instance";

//get 5 unread notification
export const getUnreadNotifications = async () => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: "api/admin/notification/all?type",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  // read one notification 
  export const MarkReadNotifications = async (data) => {
    let token = localStorage.getItem("token");
  const {id} =data;
    let response = await instance({
      url: `api/admin/notification/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //mark all read notifications
  export const MarkAllReadNotifications = async () => {
    let token = localStorage.getItem("token");
    
    let response = await instance({
      url: `api/admin/notification/markread/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };


  //view all unread Notification
  export const getAllUnReadList = async () => {
    let token = localStorage.getItem("token");
    
    let response = await instance({
      url: "api/admin/notification/all?type=unread",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //view all read Notification
  export const getAllReadList = async () => {
    let token = localStorage.getItem("token");
    
    let response = await instance({
      url: "api/admin/notification/all?type=read",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

import instance from "../instance";

 //get main logo
 export const GetSystemSetting = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/systemsettings`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }; 

//upload main logo
export const UploadLogo = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/systemsettings/mainlogo",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //upload white logo
export const UploadWhiteLogo = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/systemsettings/whitelogo",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  
  //upload email logo
export const UploadEmailLogo = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/systemsettings/emaillogo",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

    //upload favicon logo
export const UploadFaviconLogo = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/systemsettings/favicon",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //add ib count
  export const AddIBCount = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/systemsettings/ibcount",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

    //add more system settings
    export const AddMoreSystemSettings = async (data) => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: "api/admin/systemsettings/addmore",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };
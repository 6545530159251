import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../../context/AppContext";
import { GetSales } from "../../../api/sales";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";


export const SalesDashboard = () => {
const {sidebarOpen} = useAppContext();

 //get dash lead reminder
 const { data: getsales } = useGetdata(
	"getsales",
    GetSales
	
)


	return (
		<>
		 <Helmet><title>Sales Dashboard | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
        <SideBar />
  {/* <!-- Content Start --> */}
    <div className={`content ${sidebarOpen ? "open" : ""}`}> 
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
        <div className="titel-head pb-4">
        <h1>Dashboard</h1>
	</div>
	<div className="row g-3 d-lg-flex align-items-center">
		<div className="col-sm-8 col-lg-5 col-xl-5 mb-4">
		<h5 className="ms-2 mb-4">Lead Overview</h5>
		<div className="bg-dark rounded-3 p-5 h-100">
		</div>
		</div>
		<div className="col-sm-9 col-lg-6 col-xl-6 mb-4">
		<h5 className="ms-2 mb-4">Lead Remiders</h5>
		<div className="bg-dark rounded-3 p-3 pt-1">
		<div className="table-responsive-sm">
					<table className="table ActivityLog-table mb-0 text-center">
						<thead>
							<tr>
								<th>Sr. No.</th>
								<th>Name</th>
								<th>Email</th>
								<th>Reminder Date</th>
								<th>Description</th>
							</tr>
						</thead>
						{getsales?.data?.length > 0 ? 
						<tbody>
							{
								getsales?.data?.map((data, index)=>(
								<tr key={data.id}>
									<td>{data.id}</td>
									<td>{data.name}</td>
									<td>{data.email}</td>
									<td>{formatDate(data.reminder)}</td>
									<td>{data.description}</td>

								</tr>
								))
							}
								
						</tbody> :
						<tbody>
								<td colspan="5">No Reminders Available</td>
						</tbody>}
					</table>
				</div>
		</div>
		</div>
	</div>
	</div>
	</div>
		</>
	)
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { createUser } from "../../../api/user";
import { message } from "antd";
import {getAllCountry} from "../../../api/countries"
import { Footer } from "../../../layout/dashboardLayout/Footer";
import {BackToTopPage} from "../../../component/BackToTop"
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddUser = () => {
    const { sidebarOpen } = useAppContext();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [dob, setDob] = useState("");
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [lengthError, setLengthError] = useState("")
    const [phonecode, setPhoneCode] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const[disable, setDisable]=useState(false)


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };

    const handleChange = (e) => {
        const { value } = e.target;
        setPhone(value);
        setLengthError(value.length > 10 && value.length > 15);
    };

    // get Countries
    const { data: getCountries } = useGetdata(
        "getCountries",
        getAllCountry
    )

    useEffect(() => {
        const selectedCountryData = getCountries?.countries?.find(c => c.country === country);
        if (selectedCountryData) {
            setPhoneCode(selectedCountryData?.code);
        } else {
            setPhoneCode('');
        }
    }, [country, getCountries]);

    //create user
    const queryclient = useQueryClient();
    const User = useMutation(createUser, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("User Created Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleAddUser = async (event) => {
        event.preventDefault();
        setDisable(true)


        if (name === "" ||
            email === "" ||
            phone === "" ||
            country === "" ||
            gender === "" ||
            password === "" ||
        dob === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }
        let data = {
            name,
            email,
            phone: phone,
            phonecode: "+" + phonecode,
            gender,
            country,
            dob,
            password,

        };
        User.mutate(data);


    }


    return (
        <>
            <Helmet><title>Add User | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add User</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <p className={
                                        name.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="email" name="email" className="form-control form-control-lg " id="email"
                                        placeholder="Enter Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p className={
                                        email.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="password" className="form-label">Password <span className="required-mark">*</span></label>
                                    <input type={showPassword ? 'text' : 'password'} name="password" className="form-control form-control-lg " id="password"
                                        placeholder="Enter Password" value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                     <span
                                            className="eye-icon toggle-password" onClick={togglePasswordVisibility}>
                                       <i className= {showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}>
                                        </i></span>
                                    <p className={
                                        password.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                    <SelectDropdown id="country"
                                    value={country}
                                     onChange={(value) => setCountry(value)}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {getCountries?.countries.map((data) => (
                                            <option key={data?.code} value={data?.country}>{data?.country}</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        country.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="phone" className="form-label">Phone <span className="required-mark">*</span></label>
                                    <div className="d-flex justify-content-start gap-2">
                                        <input type="text" name="phonecode" className="form-control form-control-lg"
                                            id="phonecode"
                                            placeholder="+91"
                                            value={"+" + phonecode} />
                                        <input type="text" name="phone" className="form-control form-control-lg col-sm-9" id="phone"
                                            placeholder="Enter Phone" value={phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {lengthError && (
                                        <p className="text-danger">
                                            Phone number must be between 10 and 15 characters long.
                                        </p>
                                    )}
                                    <p className={
                                        phone.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="gender" className="form-label">Gender <span className="required-mark">*</span></label>
                                    <SelectDropdown id="gender" value={gender} 
                                    onChange={(value) => setGender(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>

                                    </SelectDropdown>
                                    <p className={
                                        gender === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="date" className="form-label">Date of Birth <span className="required-mark">*</span></label>
                                    <input type="date" name="date" className="form-control form-control-lg" id="date"
                                        placeholder="Select date" value={dob}
                                        onChange={(e) => setDob(e.target.value)} />
                                        <p className={
                                        dob === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                disabled={disable}
                                 onClick={handleAddUser}>
                                    { disable ? 
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                    "Submit"}
                                  </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />

        </>
    )
}
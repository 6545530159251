import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { GetBonus } from "../../../api/bonus";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const BonusList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get user 
    const { data: bonuslist } = useGetdata(
        "bonuslist",
        GetBonus
    )

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "MT5 ID", key: "mt5id", sortable: true },
        { label: "Amount", key: "amount", sortable: true },
        { label: "Type", key: "type", sortable: true },
        { label: "Comment", key: "comment", sortable: true },
        { label: " Date", key: "createdAt", sortable: true },
    ]

    const filteredData = bonuslist?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.amount && item.amount.toString().includes(searched) ||
    item.mt5id && item.mt5id.toString().includes(searched)
    )



    //export to pdf 
    const exportToPDF = (data) => {
        const doc = new jsPDF();

        // Define columns and rows based on your data structure
        const columns = [
            'ID',
            'MT5 ID',
            'Amount',
            'Type',
            'Comment',
            'Date',
        ];

        const rows = data?.map((item,index) => [
            index + 1,
            item.mt5id,
            item.amount,
            item.type === 0 ? "Give" : "" || item.type === 1 ? "Remove" : "",
            item.comment,
            formatDate(item.createdAt)
        ]);
        doc.autoTable({
            // columns, body: rows
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'grid',
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },

            },
            styles: {
                fontSize: 10,
                font: 'Gilroy'
            }
        });
        doc.save('Bonus List.pdf');
    };

    //   export excel format
    const exportToExcel = (data) => {
        if (!data || data?.length === 0) {
            const headers = [
                'ID',
                'MT5 ID',
                'Amount',
                'Type',
                'Comment',
                'Date'
            ];

            const ws = XLSX.utils.aoa_to_sheet([headers]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const filename = "Bonus List.xlsx";

            XLSX.writeFile(wb, filename);
            return;
        }

        const dataForExport = data?.map((item,index) => ({
            'ID': index + 1,
            'MT5 ID': item.mt5id,
            'Amount': item.amount,
            'Type': item.type === 0 ? "Give" : "" || item.type === 1 ? "Remove" : "",
            'Comment': item.comment,
            ' Date': formatDate(item.createdAt),
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const filename = 'Bonus List.xlsx';

        XLSX.writeFile(wb, filename);
    }

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>Bonus List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Bonus List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(bonuslist?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(bonuslist?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(data) => (
                                            <>
                                                <td>{data.mt5id}</td>
                                                <td>{data.amount}</td>
                                                <td>{data.type === 0 ? "Give" : "" || data.type === 1 ? "Remove" : ""}</td>
                                                <td>{data.comment}</td>
                                                <td>{formatDate(data.createdAt)}</td>

                                            </>
                                        )
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { DepositTabs } from "./tabs/DepositTabs"
import { WithdrawTabs } from "./tabs/WithdrawTabs"
import { MT5Accounts } from "./tabs/Mt5Accounts"
import { BankDetails } from "./tabs/BankDetails"
import { DemoAccount } from "./tabs/DemoAccount"
import { useEffect, useState } from "react"
import { getSingleUser } from "../../../api/user"
import { useParams } from 'react-router-dom';
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"
import { message } from "antd"

export const UserDetails = () => {
    const { sidebarOpen } = useAppContext()
    const[data,setData]=useState({})

     
    const {id} = useParams()

      //fetch single user details
      useEffect(() => {
        const fetchUserDepositList = async () => {
            try {
                const data = await getSingleUser({id});
                setData(data);
            } catch (error) {
                message.error(error.message);
           
            }
        };

        fetchUserDepositList();
    }, [id]);

    return (
        <>
            <Helmet><title>User Details | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>User Details</h1>
                    </div>

                    <div className="row g-4 g-xxl-5 d-flex align-items-stretch">
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="h1 mb-0"><span className="fw-light">$</span>{data?.data?.totalDeposits ? data?.data?.totalDeposits.toFixed(2) : 0}</div>
                                    <p className="text-muted mt-1">Total Deposit</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="h1 mb-0"><span className="fw-light">$</span>{data?.data?.totalWithdraws ? data?.data?.totalWithdraws.toFixed(2) : 0}</div>
                                    <p className="text-muted mt-1">Total Withdraw</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="mb-0 display-3">{data?.data?.totalmt5Account || 0}</div>
                                    <p className="text-muted mt-1">Total MT5 Account</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-details referral-tab mt-4 mb-5">
                        <nav className="d-lg-flex table-head justify-content-lg-between align-items-lg-center">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-Deposit-tab" data-bs-toggle="tab" data-bs-target="#nav-Deposit" type="button" role="tab" aria-controls="nav-Deposit" aria-selected="true">Deposit List</button>
                                <button className="nav-link" id="nav-Withdraw-tab" data-bs-toggle="tab" data-bs-target="#nav-Withdraw" type="button" role="tab" aria-controls="nav-Withdraw" aria-selected="false">Withdraw List</button>
                                <button className="nav-link" id="nav-mt5account-tab" data-bs-toggle="tab" data-bs-target="#nav-mt5account" type="button" role="tab" aria-controls="nav-mt5account" aria-selected="false">MT5 Account</button>
                                <button className="nav-link" id="nav-bankdetails-tab" data-bs-toggle="tab" data-bs-target="#nav-bankdetails" type="button" role="tab" aria-controls="nav-bankdetails" aria-selected="false">Bank Details</button>
                                <button className="nav-link" id="nav-demoaccount-tab" data-bs-toggle="tab" data-bs-target="#nav-demoaccount" type="button" role="tab" aria-controls="nav-demoaccount" aria-selected="false">Demo Account</button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {/* <!--Start Deals--> */}
                            <div className="tab-pane fade show active" id="nav-Deposit" role="tabpanel" aria-labelledby="nav-Deposit-tab">
                               <DepositTabs />
                            </div>
                            <div className="tab-pane fade" id="nav-Withdraw" role="tabpanel" aria-labelledby="nav-Withdraw-tab">
                               <WithdrawTabs />
                            </div>
                            <div className="tab-pane fade" id="nav-mt5account" role="tabpanel" aria-labelledby="nav-mt5account-tab">
                               <MT5Accounts />
                            </div>
                            <div className="tab-pane fade" id="nav-bankdetails" role="tabpanel" aria-labelledby="nav-bankdetails-tab">
                               <BankDetails />
                            </div>
                            <div className="tab-pane fade" id="nav-demoaccount" role="tabpanel" aria-labelledby="nav-demoaccount-tab">
                               <DemoAccount />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
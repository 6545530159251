import { Helmet } from "react-helmet-async"
import { Header } from "../../../layout/dashboardLayout/Header"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { useAppContext } from "../../../context/AppContext"
import { getAllReadList } from "../../../api/notifications"
import useGetdata from "../../../hooks/useGetData"
import { fDateTime } from "../../../utility/formatTime"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"

export const ReadNotificationList = () => {
    const {sidebarOpen} = useAppContext();

      //get notification data
 const {data: readNotification} = useGetdata(
    "readNotification",
    getAllReadList
  )

    return (
        <>
            <Helmet><title>Read Notification List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}> 
            <Header />
            <div className="container-fluid px-3 px-xxl-5">
        <div className="titel-head pb-4">
        <h1>Read Notification List</h1>
	</div>
    <div className="row g-3">
		<div className="col-lg-12 mt-3 mt-lg-5">
			<div className="bg-dark pb-4 rounded-3 p-4 box-shadow">
                {
                    readNotification?.data.map((data)=>(
					<div className="alert bg-black" role="alert" key={data.id}>
					[{fDateTime(data.createdAt)}] {data.type}.
				</div>
                      
                      ))
                    }
	</div>
    </div>
	</div>
    </div>
            </div>
            <Footer />
            <BackToTopPage />
        
        </>
    )
}
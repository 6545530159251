import { Modal } from "antd";
import styled from "styled-components";

export const StyleModal = styled(Modal)`
  .ant-modal-title {
    color: #ffff;
    font-weight: 600;
    font-size: 24px;
    background-color: #f37b4c;
    padding: 10px;
    font-family: "Gilroy";
    border-radius: 8px 8px 0px 0px;
  }
  .ant-modal-footer {
    display: none !important;
  }
  .ant-modal-content {
    position: relative;
    background-color: black;
    padding: 0px 0px;
    font-family: "Gilroy";
  }
  .ant-modal-close {
    color: #ffff;
  }
  .ant-modal-close:hover {
    color: #ffff;
  }
  .text-black {
    --bs-text-opacity: 1;
    color: black;
    font-size: 16px;
  }
  .value {
    font-size: 16px;
  }
`
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Image, message } from "antd";
import { Link } from "react-router-dom";
import { CreateNews } from "../../../api/news";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddNews = () =>{
    const[title, setTitle]=useState("");
    const[description, setDescription]=useState("");
    const[short_description, setShortDescription] = useState("")
    const[published, setPublished] = useState("")
    const[newsfile, setNewsFile] = useState(null)
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable] = useState(false)
    const[fileDataurl, setFileDataUrl] = useState(null)


    const { sidebarOpen } = useAppContext()

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        setNewsFile(file);

        if (file) {
            setNewsFile(file);
            const objectUrl = URL.createObjectURL(file);
            setFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

   //add news
   const quericlient = useQueryClient();
   const addnews = useMutation(CreateNews, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("News Created Successfully!");
               quericlient.invalidateQueries("newslist")
               setDisable(false)
               setTitle("");
               setDescription("");
               setShortDescription("");
               setPublished("");
               setNewsFile("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }


       },
   });

   const handleAddNews = (e) => {
        e.preventDefault();
        setDisable(true)
       if (
           title === "" ||
           description === "" ||
           short_description === "" ||
           published === "" || 
           newsfile === ""){
               setLoginError("Please Fill the above field.");
               setDisable(false)
               return;
           }

           const formData = new FormData();
           formData.append('title', title);
           formData.append('description', description);
           formData.append('short_description', short_description);
           formData.append('published', published);
           formData.append('newsfile', newsfile);
           addnews.mutate(formData);
       }

    return (
        <>
            <Helmet><title>Add News | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add News </h1>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                        <Link to="/admin/news/newslist" className="btn btn-xs btn-primary">View News</Link>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Title <span className="required-mark">*</span></label>
                                    <input type="text" name="name" id="name"
                                     className="form-control form-control-lg"
                                     placeholder="Enter title"
                                     value={title}
                                     onChange={(e)=>setTitle(e.target.value)} />
                                    <p className={
                                    title.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>

                                <div className="mb-4">
                                    <label for="description" className="form-label">Description <span className="required-mark">*</span></label>
                                    <textarea type="text" rows={7} name="description" id="description"
                                     className="form-control form-control-lg"
                                     placeholder="Enter description"
                                     value={description}
                                     onChange={(e)=>setDescription(e.target.value)} />
                                    <p className={
                                    description.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="shortdescription" className="form-label">Short Description <span className="required-mark">*</span></label>
                                    <textarea type="text" rows={4} name="shortdescription" id="shortdescription"
                                     className="form-control form-control-lg"
                                     placeholder="Enter short description"
                                     value={short_description}
                                     onChange={(e)=>setShortDescription(e.target.value)} />
                                    <p className={
                                    short_description.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="newsfile" className="form-label">Image <span className="required-mark">*</span></label>
                                    <input type="file" 
                                     className="form-control form-control-lg bg-secondary"
                                     accept=".png, .jpg, .jpeg" name="newsfile" id="newsfile"
                                     onChange={handleImageFileChange} />
                                     {fileDataurl && 
                                     <Image 
                                     src={fileDataurl}
                                     className="mt-2"
                                     alt="news"
                                     width={100}
                                     height={70}/>}
                                    <p className={
                                    !newsfile && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="status" className="form-label">Status <span className="required-mark">*</span></label>
                                    <SelectDropdown id="newsstatus"
                                     value={published} 
                                    onChange={(value) => setPublished(value)}>
                                        <option value="1">Published</option>
                                        <option value="0">Unpublished</option>

                                    </SelectDropdown>
                                    <p className={
                                        published === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddNews}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />

        </>
    )
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress, TablePagination } from '@mui/material';
import useGetdata from '../hooks/useGetData';
import { Spin } from 'antd';

const StyleTablePagination = styled(TablePagination)`
.css-1utq5rl {
    fill:#ffff;
    top:18px;
    }
    .css-1chpzqh {
        font-family: 'Gilroy';
        padding-top: 25px;
      
    }
    .css-1cccqvr.css-1cccqvr.css-1cccqvr {
        padding-top: 20px;
        font-family: 'GILROY';
    }
    .css-1deacqj {
        padding-top: 20px !important;
    }
    .css-1deacqj.Mui-disabled {
        background-color: transparent;
        color: #8f8d8d !important;
    }
`
// .css-levciy-MuiTablePagination-displayedRows {
//     font-family: Gilroy;
//     padding-top: 10px;
//   }
//   .css-pdct74-MuiTablePagination-selectLabel {
//     padding-top:9px;
//     font-family: Gilroy;
//   }
  
//   .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
//     font-family: Gilroy;
//   }
//   .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
//     fill: #ffff;
//   }
//   .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
//     color: #ffff;
// }

const DataTable = ({ data, headers, renderRow }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'descending' });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate an async operation
        const timer = setTimeout(() => {
          setLoading(false);
        }, 3000);
    
        return () => clearTimeout(timer);
      }, []);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = () => {
        if (sortConfig.key) {
            const sorted = data?.sort((a, b) => {
                const valueA = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
                const valueB = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

                if (valueA < valueB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            return sorted;
        }
        return data;
    };

    const paginatedData = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return sortedData()?.slice(startIndex, endIndex);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <table className="table table-dark table-striped mb-0 text-center table-hover dataTable">
                <thead>
                    <tr>
                        {headers?.map((header, index) => (
                            <th key={index} onClick={() => header.sortable && requestSort(header.key)}>
                                {header.label} {header.sortable && (
                                    sortConfig.key === header.key ?
                                        (sortConfig.direction === 'ascending' ? <i className="fa-solid fa-sort-up" style={{ color: "#b5b5b1" }}></i> :
                                            <i className="fa-solid fa-sort-down" style={{ color: "#b5b5b1" }}></i>) :
                                        <i className="fa-solid fa-sort"></i>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                {
                    data?.length > 0 ? (

                        // <tbody>
                        //     {paginatedData()?.map((rowData, rowIndex, ) => renderRow(rowData,  rowIndex))}
                        // </tbody>
                    
                        <tbody>
                            {paginatedData()?.map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{rowIndex + 1 + page * rowsPerPage}</td> {/* Calculate index based on current page */}
                                    {renderRow(rowData, rowIndex)}
                                </tr>
                            ))}
                        </tbody>
                        ) :
                        loading ?
                        (
                        <tbody>
                    <tr className="text-center">
                    <td
                                    className="dataTables_empty pt-3 pb-3"
                                    colSpan={headers?.length}
                                >
                      <CircularProgress  className='text-primary'/>
                      </td>
                      </tr>
                    </tbody>
                        ) : 
                        (
                        <tbody>
                            <tr className="text-center">
                                <td
                                    className="dataTables_empty pt-3 pb-3"
                                    colSpan={headers?.length}
                                >
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                        )
                }
            </table>
            <StyleTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                className="text-white d-flex justify-content-end"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default DataTable;

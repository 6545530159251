import instance from "../instance";

//add Banner
export const AddBanners = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/banner/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  
    return response.data;
  };

  //get Banner
export const getBannerData = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url:`api/admin/banner/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //update Banner
  export const UpdateBannerData = async (banner) => {
    let token = localStorage.getItem("token");
    const {id, data} = banner

    let response = await instance({
      url: `api/admin/banner/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //update image Banner
  export const UpdateImageBanner = async (banner) => {
    let token = localStorage.getItem("token");
    const {id, data} = banner
    let response = await instance({
      url: `api/admin/banner/image/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data
    });
  
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { AddUserIBCommission, GetIBPlanData, getApprovedReqUser } from "../../../api/ib";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddUserCommission = () => {
    const [planid, setPlanId] = useState("");
    const [userid, setUserId] = useState("");
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)
    const navigate = useNavigate()

    const { sidebarOpen } = useAppContext();

    //get ib users
    const{data: ibApprovedUser} = useGetdata(
        "ibApprovedUser",
        getApprovedReqUser
    )

//get ib plan 
const { data: allIBPlan } = useGetdata(
    "allIBPlan",
    GetIBPlanData
);

    //add user commission
    const quericlient = useQueryClient();
    const ibCommission = useMutation(AddUserIBCommission, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Set IB Commission Successfully!");
                navigate("/admin/ib/usercommissionlist")
                quericlient.invalidateQueries("allIbCommissionList")
                setDisable(false)
                setPlanId("");
                setUserId("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handleAddIBCommssion = (e) => {
        e.preventDefault();
        setDisable(true)

        if (
            planid === "" ||
            userid === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        const data = {
            planid,
            userid
        }
        ibCommission.mutate(data)
    }


    return (
        <>
            <Helmet><title>Add User Commission | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add User Commission </h1>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                                <Link to="/admin/ib/usercommissionlist" className="btn btn-xs btn-primary">View User Commission</Link>
                            </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select IB User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="ibuser"
                                    value={userid}
                                     onChange={(value) => setUserId(value)}>
                                        {ibApprovedUser?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        userid.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="plan" className="form-label">Select Plan <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={planid}
                                     onChange={(value) => setPlanId(value)}>
                                        <option selected="">Please choose...</option>

                                        {allIBPlan?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name}</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        planid.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddIBCommssion}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer />
            <BackToTopPage />
        </>
    )
}
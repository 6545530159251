import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../../context/AppContext"
import { Header } from "../../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { GetPermissionCheck } from "../../../../api/subAdmin";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { CreateLeadStatus, DeleteLeadStatus, UpdateLeadStatus, getLeadStatusList } from "../../../../api/lead";
import { StyleModal } from "../../../../component/StyleModal";
import DataTable from "../../../../component/DataTable";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";


export const LeadStatusList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [name, setName] = useState("");
    const [order, setOrder] = useState("");
    const [selectedleadStatus, setSelectedLeadStatus] = useState({})
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)


    //get ib plan 
    const { data: leadstatuslist, isLoading: userDLoad } = useGetdata(
        "leadstatuslist",
        getLeadStatusList
    );

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Name", key: "name", sortable: true },
        { label: "Order", key: "order_no", sortable: true },
        { label: "Action", sortable: false },
    ]

    const filteredData = leadstatuslist?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase())
    )

    // add lead status modal
    const showAddLeadStatusModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false)
    }

    //add lead status
    const queryclient = useQueryClient();
    const addleadStatus = useMutation(CreateLeadStatus, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead status created Successfully!");
                setOpen(false);
                queryclient.invalidateQueries("leadstatuslist")
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })
    const handleAddLeadStatus = (e) => {
        e.preventDefault();
        setDisable(true)

        if (name === "" ||
            order === ""
        ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        const data = {
            name,
            order
        }
        addleadStatus.mutate(data);

    }

    // show edit lead status modal
    const showLeadStatusEditModal = (data) => {
        setOpenEdit(true)
        setSelectedLeadStatus(data)

    }

    const Cancelmodal = () => {
        setOpenEdit(false);
    }

    const editleadStatus = useMutation(UpdateLeadStatus, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead status updated Successfully!");
                setOpenEdit(false);
                queryclient.invalidateQueries("leadstatuslist")
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })
    useEffect(() => {
        if (!userDLoad) {
            setName(selectedleadStatus?.name);
            setOrder(selectedleadStatus?.order_no);
        }
    }, [selectedleadStatus, userDLoad])

    const handleEditLeadStatus = (e) => {
        e.preventDefault();
        setDisable(true)

        const data = {
            name,
            order
        }
        editleadStatus.mutate({ id: selectedleadStatus?.id, data });

    }

    //delete marketing user 
    const deleteleadstatus = useMutation(DeleteLeadStatus, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead status deleted Successfully!");
                queryclient.invalidateQueries("leadstatuslist")
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        }
    })

    const handleDelete = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to delete lead status?');
        if (userConfirmed) {
            deleteleadstatus.mutate({ id });

        }

    }


    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>Lead Status | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Lead Status</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                                <button className="btn btn-xs btn-primary" onClick={() => showAddLeadStatusModal()}>
                                    <i className="fa fa-plus"></i>&nbsp; Add Lead Status</button>
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>
                                                <td>{rowData.name}</td>
                                                <td>{rowData.order_no}</td>
                                                <td>
                                                    <button className="btn btn-xs btn-primary" onClick={() => showLeadStatusEditModal(rowData)}>Edit</button> &nbsp;
                                                    <button className="btn btn-xs btn-primary" onClick={() => handleDelete(rowData.id)}>Delete</button>
                                                </td>
                                            </>
                                        )} />
                                    {/* add lead status */}
                                    <StyleModal
                                        title="Add Lead Status"
                                        open={open}
                                        //   width={800}
                                        onCancel={hideModal}
                                        centered={false}
                                    >
                                        <div className="row p-3">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-4">
                                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                                    <input type="text" name="name" className="form-control form-control-lg "
                                                        id="name" placeholder="Enter Name" value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    <p className={
                                                        name === "" && loginError
                                                            ? "text-danger"
                                                            : "d-none"
                                                    }>{loginError}</p>
                                                </div>
                                                <div className="">
                                                    <label for="order" className="form-label">Order <span className="required-mark">*</span></label>
                                                    <input type="text" name="order" className="form-control form-control-lg "
                                                        id="order" placeholder="Enter order" value={order}
                                                        onChange={(e) => setOrder(e.target.value)}
                                                    />
                                                    <p className={
                                                        order === "" && loginError
                                                            ? "text-danger"
                                                            : "d-none"
                                                    }>{loginError}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <button className="btn btn-sm btn-primary" onClick={handleAddLeadStatus}
                                            disabled={disable}>
                                                 {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Add" }</button>
                                        </div>
                                    </StyleModal>

                                    {/* edit lead status modal */}
                                    <StyleModal
                                        title="Edit Lead Status"
                                        open={openedit}
                                        //   width={800}
                                        onCancel={Cancelmodal}
                                        centered={false}
                                    >
                                        <div className="row p-3">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="mb-4">
                                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                                    <input type="text" name="name" className="form-control form-control-lg "
                                                        id="name" placeholder="Enter Name" value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="">
                                                    <label for="order" className="form-label">Order <span className="required-mark">*</span></label>
                                                    <input type="text" name="order" className="form-control form-control-lg "
                                                        id="order" placeholder="Enter order" value={order}
                                                        onChange={(e) => setOrder(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <button className="btn btn-sm btn-primary" onClick={handleEditLeadStatus}
                                            disabled={disable}>
                                                 {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Update" }</button>
                                        </div>
                                    </StyleModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
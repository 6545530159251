import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

export const SelectDropdown = ({ children, placeholder,onChange, allowClear, value, ...props }) => {
    const selectRef = useRef(null);

    useEffect(() => {
        $(selectRef.current).select2({
            placeholder: "Please Choose",
            allowClear: true
        });

        // Set the value of the Select2 dropdown when value changes
        // if (value) {
        //     $(selectRef.current).val(value).trigger('change');
        // }

        $(selectRef.current).on('change', (e) => {
            onChange(e.target.value);
          });
          
        return () => {
            $(selectRef.current).select2('');
        };
    }, [placeholder, allowClear, onChange]); // Ensure useEffect runs when value changes


    return (
        <select ref={selectRef} onChange={onChange}  {...props}  value={value}   className='form-control form-control-lg bg-dark'>
            <option></option>
            {children}
        </select>
    );
};

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
// import { getAllGroup } from "../../../api/group";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { AddDefaultSetting, GetDefaultSettings } from "../../../api/defaultSettings";

export const DefaultSettings = () => {
    const [user_disable_deposit, setDisableDeposit] = useState(0)
    const [user_disable_withdraw, setDisableWithdraw] = useState(0)
    const [user_disable_transfer, setDisableTransfer] = useState(0)
    // const [user_disable_kyc, setDisableKyc] = useState(0)
    const [user_disable_account, setDisableAccount] = useState(0)
    const [user_deposit, setUserDeposit] = useState("");
    const [user_withdraw, setUserWithdraw] = useState("")   
    const [user_transfer, setUserTransfer] = useState("");
    // const [user_group, setGroupList] = useState({})
    const [loginError, setLoginError] = useState("");
    const[disable, setDisable] = useState(false)



    const { sidebarOpen } = useAppContext();

    const {data: getdefaultSetting, isLoading:userDLoad} = useGetdata(
        "getdefaultSetting",
        GetDefaultSettings
    )

    // //get group
    // const{data: getGroup} = useGetdata(
    //     "getGroup",
    //     getAllGroup
    // )

    const handleDepositChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableDeposit(newValue);
    };
    const handleWithdrawChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableWithdraw(newValue);
    }
    const handleAccountChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableAccount(newValue);
    }
    const handleTransferChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableTransfer(newValue);
    }
    // const handleKycChange = (e) => {
    //     const newValue = e.target.checked ? 1 : 0;
    //     setDisableKyc(newValue);
    // }

    //default settings
    const queryclient = useQueryClient();
    const defaultsettings = useMutation(AddDefaultSetting, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Default settings Added Successfully!");
                queryclient.invalidateQueries("");
                setDisable(false)
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    })

    useEffect(()=>{
        if(!userDLoad){
            setDisableAccount(getdefaultSetting?.data?.user_disable_account)
            setDisableDeposit(getdefaultSetting?.data?.user_disable_deposit)
            setDisableTransfer(getdefaultSetting?.data?.user_disable_transfer)
            setDisableWithdraw(getdefaultSetting?.data?.user_disable_withdraw)
            setUserDeposit(getdefaultSetting?.data?.user_deposit)
            setUserWithdraw(getdefaultSetting?.data?.user_withdraw)
            setUserTransfer(getdefaultSetting?.data?.user_transfer)
        }
    },[userDLoad, getdefaultSetting])
    
    const handleUserSetting = (e) => {
        e.preventDefault();
        setDisable(true)

        if (
            user_deposit === "" ||
            user_transfer === "" ||
            user_withdraw === ""
        ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }
        let data = {
            user_disable_deposit,
            user_disable_withdraw,
            user_disable_transfer,
            // user_disable_kyc,
            user_disable_account,
            user_deposit: Number(user_deposit),
            user_withdraw: Number(user_withdraw),
            user_transfer: Number(user_transfer),
            // user_group: Number(user_group)
        }
         
        defaultsettings.mutate(data)

    }


    return (
        <>
            <Helmet><title> Default Settings | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Default Settings</h1>
                    </div>
                <div className="bg-dark rounded-3 px-4 py-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                onChange={handleDepositChange} checked={user_disable_deposit} />
                                    <label for="name" className="form-label">User Disable Deposit</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                               onChange={handleWithdrawChange} checked={user_disable_withdraw} />
                                    <label for="name" className="form-label">User Disable Withdraw</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                 onChange={handleTransferChange} checked={user_disable_transfer} />
                                    <label for="name" className="form-label">User Disable Transfer</label>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                onChange={handleKycChange} checked={user_disable_kyc} />
                                    <label for="name" className="form-label">User Disable KYC</label>
                                </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                 onChange={handleAccountChange} checked={user_disable_account} />
                                    <label for="name" className="form-label">User Disable Account</label>
                                </div>
                            </div>
                            
                            </div>
                        <div className="row">
                        {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                 <div className="mb-4">
                                    <label for="groupList" className="form-label">User Group <span className="required-mark">*</span></label>
                                    <select className="form-select form-select-lg " id="groupList"
                                     value={user_group}
                                     onChange={(e) => setGroupList(e.target.value)}>
                                        <option selected="">Please choose...</option>
                                     {getGroup?.data?.map((group) => (
                                         <option key={group.id} value={group.id}>{group.name}</option>

                                     ))}
                                    </select>
                                    <p className={
                                                user_group === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyDeposit" className="form-label">User Deposit <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyDeposit" className="form-control form-control-lg " id="dailyDeposit"
                                        placeholder="0"
                                        value={user_deposit}
                                        onChange={(e) => setUserDeposit(e.target.value)}
                                    />
                                    <p className={
                                                user_deposit === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">User Withdraw <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={user_withdraw}
                                                onChange={(e) => setUserWithdraw(e.target.value)}
                                    />
                                    <p className={
                                                user_withdraw === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">User Transfer <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={user_transfer}
                                                onChange={(e) => setUserTransfer(e.target.value)}
                                    />
                                    <p className={
                                                user_transfer === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUserSetting}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
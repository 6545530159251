import { Link } from "react-router-dom"

export const Footer = () => {
    return (
        <>
        <div className="footer bg-dark rounded-top p-3 p-lg-4 text-center mt-4 mt-xl-5">
    <p className="m-0">Copyright &copy; {new Date().getFullYear()} <Link to="">Tradeinfy</Link>. All Rights Reserved.</p>
    </div>
        </>
    )
}
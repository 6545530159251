import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { Link } from "react-router-dom";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { getPendingKYcList } from "../../../api/kyc";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";



export const PendingDocumentList = () => {
    const {sidebarOpen} = useAppContext();
    const[searched, setSearched]= useState("")


     //get pending document 
     const {data: pendingKyc} = useGetdata(
        "pendingKyc",
        getPendingKYcList
    )

    const headers = [
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Name', key: 'name', sortable: true },
        { label: 'Email', key: 'email', sortable: true },
        { label: 'Phone', key: 'phone', sortable: true },
        { label: 'Date', key: 'createdAt', sortable: true },
        { label: 'Action', sortable: false },
    ]

    const filteredData = pendingKyc?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.phone && item.phone.toLowerCase().includes(searched.toLowerCase())
  )


    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Name',
      'Email',
      'Phone',
      'Date',
    ];
    
    const rows = data?.map((item,index) => [
      index + 1,
      item.name,
      item.email,
      item.phone,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('Pending Document List.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
        const headers = [
            'ID',
      'Name',
      'Email',
      'Phone',
      'Date',
        ];
  
        const ws = XLSX.utils.aoa_to_sheet([headers]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const filename = "Pending Document List.xlsx";
  
        XLSX.writeFile(wb, filename);
        return;
      }

    const dataForExport = data?.map((item,index) => ({
        'ID':index + 1,
        'Name': item.name,
        'Email':item.email,
        'Phone':item.phone,
        'Date':formatDate(item.createdAt),
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Pending Document List.xlsx';

    XLSX.writeFile(wb, filename);
  }

   //set permission
const{data:checkPermission, refetch} = useGetdata(
    "checkPermission",
    GetPermissionCheck
  )
  
  let token = localStorage.getItem('token');
  useEffect(()=>{
  if(token) {
  refetch()
  }
  },[token, refetch])

    return (
        <>
         <Helmet><title>Pending Document List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> Pending Document List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(pendingKyc?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(pendingKyc?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                                <DataTable
                                                data={filteredData}
                                                headers={headers}
                                                renderRow={(rowData)=> (
                                                <>
                                                    <td>{rowData.name}</td>
                                                    <td>{rowData.email}</td>
                                                    <td>{rowData.phone}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                    <td>
                                                    <Link to={`/admin/user/userdocumentdetaillist/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                        Details</Link>
                                                    </td>

                                                </>
                                                )}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useAppContext } from "../../../context/AppContext";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import useGetdata from "../../../hooks/useGetData";
import { getDailyIbWithdraw, getIBUsers, getMonthlyIbWithdraw, getPendingDeposit, getPendingIB, getPendingKyc, getPendingWithdraw, getTotalClient, getTotalDailyDeposit, getTotalDailyWithdraw, getTotalDeposit, getTotalIbWithdraw, getTotalMonthlyDeposit, getTotalMonthlyWithdraw, getTotalWeeklyDeposit, getTotalWeeklyWithdraw, getTotalWithdraw, getWeeklyIbWithdraw } from "../../../api/allTotal";
import { DepositWithdrawChart } from "./charts/DepositWtihdrawChart";
import{ getAllUser } from "../../../api/user"
import { fDate } from "../../../utility/formatTime";
import { Link, useNavigate } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { useEffect } from "react";



export const AdminDashboard = () => {
	const navigate = useNavigate()
	
    const { sidebarOpen } = useAppContext();

	const{data: allUser} = useGetdata(
		"allUser",
		getAllUser
	)

    const sortedUser = allUser?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

	const{data:totalPendingKyc}=useGetdata(
        "totalPendingKyc",
        getPendingKyc
    )

    const{data:totalpendingDeposit}=useGetdata(
        "totalpendingDeposit",
        getPendingDeposit
    )
    const{data:totalpendingWithdraw}=useGetdata(
        "totalpendingWithdraw",
        getPendingWithdraw
    )
    const{data:totalIbUser}=useGetdata(
        "totalIbUser",
        getIBUsers
    )

	const{data:pendingIb}=useGetdata(
        "pendingIb",
        getPendingIB
    )	
	
	  //total client
	  const{data:totalClient}= useGetdata(
		"totalClient",
		getTotalClient
	  )

	  //get daily deposit
const {data: DailyDeposit} = useGetdata(
	"DailyDeposit",
	getTotalDailyDeposit
  )
  
  //get Weekly deposit
  const {data: weekDeposit} = useGetdata(
	"weekDeposit",
	getTotalWeeklyDeposit
  )
  
  //get monthly deposit
  const {data: monthlyDeposit} = useGetdata(
	"monthlyDeposit",
	getTotalMonthlyDeposit
  )
  
	//get total deposit
	const{data:totalDeposit}= useGetdata(
	  "totalDeposit",
	  getTotalDeposit
	)
  
	//get total withdraw
	const{data:totalWithdraw}= useGetdata(
	  "totalWithdraw",
	  getTotalWithdraw
	)
  
	//get total Daily withdraw
	const{data:totalDailyWithdraw}= useGetdata(
	  "totalDailyWithdraw",
	  getTotalDailyWithdraw
	)
  
	 //get total Weekly withdraw
	 const{data:totalWeeklyWithdraw}= useGetdata(
	  "totalWeeklyWithdraw",
	  getTotalWeeklyWithdraw
	)
  
	  //get total monthly withdraw
	  const{data:totalMonthlyWithdraw}= useGetdata(
		"totalMonthlyWithdraw",
		getTotalMonthlyWithdraw
	  )
   //get daily ib withdraw
   const{data:dailyibWithdraw}= useGetdata(
	"dailyibWithdraw",
	getDailyIbWithdraw
  )
 //get weekly ib withdraw
 const{data:weeklyibWithdraw}= useGetdata(
	"weeklyibWithdraw",
	getWeeklyIbWithdraw
  )
//get monthly ib withdraw
const{data:monthlyibWithdraw}= useGetdata(
	"monthlyibWithdraw",
	getMonthlyIbWithdraw
  )
//get monthly ib withdraw
const{data:totalibWithdraw}= useGetdata(
	"totalibWithdraw",
	getTotalIbWithdraw
  )

  //set permission
const { data: checkPermission, refetch } = useGetdata(
    "checkPermission",
    GetPermissionCheck
)

let token = localStorage.getItem('token');
let session = sessionStorage.getItem('loggedin');
useEffect(() => {
	if(!session) {
		localStorage.clear();
		navigate("/admin/login");
	}
		if (token) {
			refetch()
		}
}, [token, refetch, navigate])


    return (
        <>
        <Helmet><title>Admin Dashboard | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
        <SideBar />
  {/* <!-- Content Start --> */}
    <div className={`content ${sidebarOpen ? "open" : ""}`}> 
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
        <div className="titel-head pb-4">
        <h1>Dashboard</h1>
	</div>
        <div className="row">
            <div className="col-sm-12 col-lg-6 col-xl-6">
                <div className="row">
                    <div className="col-sm-6">
					{
            		checkPermission?.typeAdmin === 1 &&
                        <div className="card bg-dark rounded-3 mb-4">
                        <div className="card-body">
							<h5 className="card-title">Total Clients</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalClient?.data.total_users || 0}</h1>
                                    <Link to="/admin/user/userlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-user"></i>
									</div>
								</div>
							</div>
						</div>
                        </div>}
						{
                            checkPermission?.data?.includes("view_user_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
                        <div className="card-body">
							<h5 className="card-title">Total Clients</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalClient?.data.total_users || 0}</h1>
                                    <Link to="/admin/user/userlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-user"></i>
									</div>
								</div>
							</div>
						</div>
                        </div>:""}
                    </div>
                    <div className="col-sm-6">
					{
            		checkPermission?.typeAdmin === 1 &&
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Total IB</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalIbUser?.data.total_users_ib || 0}</h1>
									<Link to="/admin/ib/ibuserlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-users"></i>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{
                            checkPermission?.data?.includes("approved_ib_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Total IB</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalIbUser?.data.total_users_ib || 0}</h1>
									<Link to="/admin/ib/ibuserlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-users"></i>
									</div>
								</div>
							</div>
						</div>
					</div> : ""}
				</div>
                <div className="col-sm-6">
				{
            		checkPermission?.typeAdmin === 1 &&
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Clients</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalPendingKyc?.data?.total_pending_kyc || 0}</h1>
									<Link to="/admin/user/pendingdocumentlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-file"></i>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{
                            checkPermission?.data?.includes("pending_document_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Clients</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalPendingKyc?.data?.total_pending_kyc || 0}</h1>
									<Link to="/admin/user/pendingdocumentlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-file"></i>
									</div>
								</div>
							</div>
						</div>
					</div> : ""}
				</div>
                <div className="col-sm-6">
				{
            		checkPermission?.typeAdmin === 1 &&
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Deposit</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalpendingDeposit?.data.total_pending_deposit  || 0}</h1>
									<Link to="/admin/transaction/pendingdeposit" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{
                            checkPermission?.data?.includes("pending_deposit_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Deposit</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalpendingDeposit?.data.total_pending_deposit || 0}</h1>
									<Link to="/admin/transaction/pendingdeposit" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div> : ""}
				</div>
                <div className="col-sm-6">
				{
            		checkPermission?.typeAdmin === 1 &&
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Withdraw</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalpendingWithdraw?.data.total_pending_withdraw || 0}</h1>
									<Link to="/admin/transaction/pendingwithdraw" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{
                            checkPermission?.data?.includes("pending_withdraw_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending Withdraw</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2">{totalpendingWithdraw?.data.total_pending_withdraw || 0}</h1>
									<Link to="/admin/transaction/pendingwithdraw" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div> : ""}
				</div>
                <div className="col-sm-6">
				{
            		checkPermission?.typeAdmin === 1 &&
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending IB</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2"> {pendingIb?.data.total_pending_ib || 0}</h1>
									<Link to="/admin/ib/ibrequestlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{
                            checkPermission?.data?.includes("requested_ib_list") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<h5 className="card-title">Pending IB</h5>
							<div className="row">
								<div className="col">
									<h1 className="mb-2"> {pendingIb?.data.total_pending_ib || 0}</h1>
									<Link to="/admin/ib/ibrequestlist" className="text-primary">View more</Link>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-usd"></i>
									</div>
								</div>
							</div>
						</div>
					</div> : ""}
				</div>
                </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-6 mb-3">
			{
                checkPermission?.typeAdmin === 1 &&
						<div className="bg-dark rounded-3 p-3 borderbx">
				<h5 className="mb-3">Latest Clients</h5>
				<div className="latestclient">
					<div className="row">
						{
							sortedUser?.slice(0,18).map((data)=>(
								<div className="col-sm-6 col-md-4" key={data.id}>
								<div className="singleclient text-center mb-3">
									<b>{data.name}</b>
									<p className="text-muted">{fDate(data.createdAt)}</p>
								</div>
							</div>
							))
						}
											</div>
					<div className="row">
						<div className="col-sm-12">
							<div className="allclient text-center mb-2 mt-2">
								<Link to="/admin/user/userlist" className="text-primary">View All Clients</Link>
							</div>
						</div>
					</div>
				</div>
			</div>}
			{
                checkPermission?.data?.includes("requested_ib_list") ?
                checkPermission?.typeAdmin === 0 &&
				<div className="bg-dark rounded-3 p-3 borderbx">
				<h5 className="mb-3">Latest Clients</h5>
				<div className="latestclient">
					<div className="row">
						{
							sortedUser?.slice(0,18).map((data)=>(
								<div className="col-sm-6 col-md-4" key={data.id}>
								<div className="singleclient text-center mb-3">
									<b>{data.name}</b>
									<p className="text-muted">{fDate(data.createdAt)}</p>
								</div>
							</div>
							))
						}
											</div>
					<div className="row">
						<div className="col-sm-12">
							<div className="allclient text-center mb-2 mt-2">
								<Link to="/admin/user/userlist" className="text-primary">View All Clients</Link>
							</div>
						</div>
					</div>
				</div>
			</div> : ""}
				</div>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
               <DepositWithdrawChart  />
            </div>
        </div>
        <div className="row">
        <div className="col-sm-12 col-lg-12 col-xl-12 mb-4">
		{
                checkPermission?.typeAdmin === 1 &&
			<div className="row">
					<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily Deposit</h5>
									<h1 className="mb-2">{DailyDeposit?.data.total_daily_deposit  ? DailyDeposit?.data.total_daily_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly Deposit</h5>
									<h1 className="mb-2">{weekDeposit?.data.total_weekly_deposit ?weekDeposit?.data.total_weekly_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly Deposit</h5>
									<h1 className="mb-2">{monthlyDeposit?.data.total_monthly_deposit ? monthlyDeposit?.data.total_monthly_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total Deposit</h5>
									<h1 className="mb-2">{totalDeposit?.data.total_deposit ? totalDeposit?.data.total_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily Withdraw</h5>
									<h1 className="mb-2">{totalDailyWithdraw?.data.total_daily_withdraw ? totalDailyWithdraw?.data.total_daily_withdraw.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly Withdraw</h5>
									<h1 className="mb-2">{totalWeeklyWithdraw?.data.total_weekly_withdraw ? totalWeeklyWithdraw?.data.total_weekly_withdraw.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly Withdraw</h5>
									<h1 className="mb-2">{totalMonthlyWithdraw?.data.total_monthly_withdraw ? totalMonthlyWithdraw?.data.total_monthly_withdraw.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total Withdraw</h5>
									<h1 className="mb-2">{totalWithdraw?.data.total_withdraws ? totalWithdraw?.data.total_withdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
									<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily IB Withdraw</h5>
									<h1 className="mb-2">{dailyibWithdraw?.data?.total_daily_ibwithdraws ? dailyibWithdraw?.data?.total_daily_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly IB Withdraw</h5>
									<h1 className="mb-2">{weeklyibWithdraw?.data?.total_weekly_ibwithdraws ? weeklyibWithdraw?.data?.total_weekly_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly IB Withdraw</h5>
									<h1 className="mb-2">{monthlyibWithdraw?.data?.total_monthly_ibwithdraws ? monthlyibWithdraw?.data?.total_monthly_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total IB Withdraw</h5>
									<h1 className="mb-2">{totalibWithdraw?.data?.total_ibwithdraws ? totalibWithdraw?.data?.total_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>}
				{
                            checkPermission?.data?.includes("deposit_report") ?
                            checkPermission?.typeAdmin === 0 &&
					<div className="row">
					<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily Deposit</h5>
									<h1 className="mb-2">{DailyDeposit?.data.total_daily_deposit ? DailyDeposit?.data.total_daily_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly Deposit</h5>
									<h1 className="mb-2">{weekDeposit?.data.total_weekly_deposit ? weekDeposit?.data.total_weekly_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly Deposit</h5>
									<h1 className="mb-2">{monthlyDeposit?.data.total_monthly_deposit ? monthlyDeposit?.data.total_monthly_deposit.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total Deposit</h5>
									<h1 className="mb-2">{totalDeposit?.data.total_deposit ? totalDeposit?.data.total_deposit?.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-sack-dollar"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
				:""
				} 
				{
                            checkPermission?.data?.includes("withdraw_report") ?
                            checkPermission?.typeAdmin === 0 &&
					<div className="row">
							<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily Withdraw</h5>
									<h1 className="mb-2">{totalDailyWithdraw?.data.total_daily_withdraw ? 
									totalDailyWithdraw?.data.total_daily_withdraw?.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly Withdraw</h5>
									<h1 className="mb-2">{totalWeeklyWithdraw?.data.total_weekly_withdraw ? totalWeeklyWithdraw?.data.total_weekly_withdraw.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly Withdraw</h5>
									<h1 className="mb-2">{totalMonthlyWithdraw?.data.total_monthly_withdraw ? totalMonthlyWithdraw?.data.total_monthly_withdraw.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total Withdraw</h5>
									<h1 className="mb-2">{totalWithdraw?.data.total_withdraws ? totalWithdraw?.data.total_withdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
									<i className="fa fa-money-bill"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					</div> :""
				}
				{
                            checkPermission?.data?.includes("ibwithdraw_report") ?
                            checkPermission?.typeAdmin === 0 &&
							<div className="row">
							<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Daily IB Withdraw</h5>
									<h1 className="mb-2">{dailyibWithdraw?.data?.total_daily_ibwithdraws ? dailyibWithdraw?.data?.total_daily_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Weekly IB Withdraw</h5>
									<h1 className="mb-2">{weeklyibWithdraw?.data?.total_weekly_ibwithdraws ? weeklyibWithdraw?.data?.total_weekly_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Monthly IB Withdraw</h5>
									<h1 className="mb-2">{monthlyibWithdraw?.data?.total_monthly_ibwithdraws ? monthlyibWithdraw?.data?.total_monthly_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-6 col-md-4 col-lg-3">
					<div className="card bg-dark rounded-3 mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col">
									<h5 className="card-title">Total IB Withdraw</h5>
									<h1 className="mb-2">{totalibWithdraw?.data?.total_ibwithdraws ? totalibWithdraw?.data?.total_ibwithdraws.toFixed(2) : 0}</h1>
								</div>
								<div className="col-auto">
									<div className="stat text-primary">
										<i className="fa fa-money-bill-transfer"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					</div> : ""}
		</div>
	</div>
        </div>
        <Footer />
		<BackToTopPage />
    </div>
        </>
    )
}
import { useState } from "react";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { formatDate } from "../../../../utility/formatTime";
import DataTable from "../../../../component/DataTable";


export const LevelFirst = (props) => {
    const [searched, setSearched] = useState("")

    const { level } = props;

    const headers = [
        { label: "ID", key: "id", sortable:true },
        { label: "Name", key: "name", sortable:true },
        { label: "Email", key: "email", sortable:true },
        { label: "Phone", key: "phone", sortable:true },
        { label: "IB Name", key: "ib_name", sortable:true },
        { label: "Total Lots", key: "totallot", sortable:true },
        { label: "Total Commission", key: "totalcommission", sortable:true },
        { label: "MT5 ID", key: "accounts", sortable:true },
        { label: "Date", key: "createdAt", sortable:true },
    ]
    
    
    const filteredData = level?.levels?.filter((item) =>
        item.name && item.name.toLowerCase().includes(searched.toLowerCase()) || 
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.phone && item.phone.toLowerCase().includes(searched.toLowerCase()) ||
    item.accounts && item.accounts.toLowerCase().includes(searched.toLowerCase())
    )

    //export to pdf 
    const exportToPDF = (data) => {
        const doc = new jsPDF();

        // Define columns and rows based on your data structure
        const columns = [
            'ID',
            'Name',
            'Email',
            'Phone',
            'IB Name',
            'Total Lots',
            'Total Commission',
            'Date'
        ];

        const rows = data?.map((item,index) => [
            index + 1,
            item.name,
            item.email,
            item.phone,
            item.ib_name,
            item.totallot,
            item.totalcommission,
            formatDate(item.createdAt)
        ]);
        doc.autoTable({
            // columns, body: rows
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'grid',
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },

            },
            styles: {
                fontSize: 10,
                font: 'Gilroy'
            }
        });
        doc.save('IB Level List.pdf');
    };

    //   export excel format
    const exportToExcel = (data) => {
        if (!data || data?.length === 0) {
            const headers = [
                'ID',
                'Name',
                'Email',
                'Phone',
                'IB Name',
                'Total Lots',
                'Total Commission',
                'Date'
            ];
      
            const ws = XLSX.utils.aoa_to_sheet([headers]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const filename = "IB Level List.xlsx";
      
            XLSX.writeFile(wb, filename);
            return;
          }

        const dataForExport = data?.map((item,index) => ({
            'ID': index + 1,
            'Name': item.name,
            'Email': item.email,
            'Phone': item.phone,
            'IB Name': item.ib_name,
            'Total Lots': item.totallot,
            'Total Commission': item.totalcommission,
            'Date': formatDate(item.createdAt),
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const filename = 'IB Level List.xlsx';

        XLSX.writeFile(wb, filename);
    }

    return (
        <>
            <div className="col-lg-12">
                <div className="row d-md-flex align-items-md-stretch flex-md-nowrap ps-4 mt-4">
                    <div className="col-sm-2 col-xl-2 mb-3">
                        <div className="bg-secondary rounded-3 p-2 text-center h-100">
                            <div className="mb-0 display-3">{level?.lot ? level?.lot.toFixed(2) : 0}</div>
                            <p className="text-muted">Lot</p>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xl-2 mb-3">
                        <div className="bg-secondary rounded-3 p-2 text-center h-100">
                            <div className="h1 mb-0"><span className="fw-normal">$</span>{level?.commission ?level?.commission.toFixed(2) : 0}</div>
                            <p className="text-muted">Commission</p>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xl-2 mb-3">
                        <div className="bg-secondary rounded-3 p-2 text-center h-100">
                            <div className="h1 mb-0"><span className="fw-normal">$</span>{level?.deposit ?level?.deposit.toFixed(2) : 0}</div>
                            <p className="text-muted">Deposit</p>
                        </div>
                    </div>
                    <div className="col-sm-2 col-xl-2 mb-3">
                        <div className="bg-secondary rounded-3 p-2 text-center h-100">
                            <div className="h1 mb-0"><span className="fw-normal">$</span>{level?.withdraw ?level?.withdraw.toFixed(2) : 0}</div>
                            <p className="text-muted">Withdraw</p>
                        </div>
                    </div>
                </div>
               
                        <div className="table-responsive-lg p-3">
                            <div className="row p-3 dataTables_filter">
                                <div className="col-sm-12 d-flex justify-content-between">
                                    <div className="d-flex gap-2">
                                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(level?.levels)}>
                                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(level?.levels)}>
                                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label> Search:&nbsp;</label>
                                        <input type="search" className="bg-dark" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                                        <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                                <>
                                                    <td>{rowData.name}</td>
                                                    <td>{rowData.email}</td>
                                                    <td>{rowData.phone}</td>
                                                    <td>{rowData.ib_name}</td>
                                                    <td>{rowData.totallot ? rowData.totallot.toFixed(2) : 0}</td>
                                                    <td>{rowData.totalcommission ? rowData.totalcommission.toFixed(2) : 0}</td>
                                                    <td>{rowData.accounts}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                </>
                                            )}/>
                  </div>
            </div>
           
        </>
    )
}
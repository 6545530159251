import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { EditMarketingUser, getSingleMarketingUser } from "../../../api/marketing";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const EditMarketing = () => {
    const { sidebarOpen } = useAppContext();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [incentive, setIncentive] = useState("");
    const [netDeposit, setNetDeposit] = useState("");
    const [lot, setLot] = useState("");
    const[selectedMarketingUser, setSelectedMarketingUser] = useState({})
    const[disable, setDisable] = useState(false)
    const navigate = useNavigate();

    const {id} = useParams();
    const {isLoading: userDLoad} = useGetdata();

    // fetch single marketing data
    useEffect(() => {
        const fetchSingleMarketingUser = async () => {
          try {
            const data = await getSingleMarketingUser({id});
            setSelectedMarketingUser(data);
          } catch (error) {
            message.error(error.message);
        }
        };
    
        fetchSingleMarketingUser();
      }, [id]);

    //edit marketing
    const queryclient = useQueryClient();
    const editmarketinguser = useMutation(EditMarketingUser, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                 
                message.success("Marketing User Updated Successfully!");
                queryclient.invalidateQueries("marketinglistdata");
                navigate("/admin/marketing/marketinglist");
                setDisable(false)
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    useEffect(() => {
        if (!userDLoad) {
            setName(selectedMarketingUser?.data?.name);
            setEmail(selectedMarketingUser?.data?.email);
            setIncentive(selectedMarketingUser?.data?.incentive);
            setNetDeposit(selectedMarketingUser?.data?.net_deposit);
            setLot(selectedMarketingUser?.data?.lot);

        }
    },[selectedMarketingUser, userDLoad])

    const handleEditMarketing = async (event) => {
        event.preventDefault();
        setDisable(true)

        let data = {
            name,
            email,
            incentive,
            netDeposit

        };
        editmarketinguser.mutate({id:id, data});


    }


    return (
        <>
            <Helmet><title>Edit Marketing | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Marketing</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="email" name="email" className="form-control form-control-lg " id="email"
                                        placeholder="Enter Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="incentive" className="form-label">Incentive(%) <span className="required-mark">*</span></label>
                                    <input type="text" name="incentive" className="form-control form-control-lg "
                                        id="incentive" placeholder="Incentive" value={incentive}
                                        onChange={(e) => setIncentive(e.target.value)}
                                    />
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="netdeposit" className="form-label">Net Deposit (Monthly) <span className="required-mark">*</span></label>
                                    <input type="number" name="netdeposit" className="form-control form-control-lg "
                                        id="netdeposit" placeholder="Net Deposit" value={netDeposit}
                                        onChange={(e) => setNetDeposit(e.target.value)}
                                    />
                                </div>  
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="lot" className="form-label">Lot<span className="required-mark">*</span></label>
                                    <input type="text" name="lot" className="form-control form-control-lg "
                                        id="lot" placeholder="Enter lot" value={lot}
                                        onChange={(e) => setLot(e.target.value)}
                                    />
                                </div>  
                            </div>
                             <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleEditMarketing} disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utility/formatTime";
import { ConvertLead, DeleteLead, getLeadData, LeadAssignTo } from "../../../api/lead"
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { GetSales } from "../../../api/sales";
import { StyleModal} from "../../../component/StyleModal"
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const LeadList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    const[open, setOpen]= useState(false)
    const[salesId, setSalesId]=useState('')
    const[selectedLead, setSelectedLead] = useState({})
    const[disable, setDisable] = useState(false)


  //get lead
  const { data: leadDetails } = useGetdata(
    "leadDetails",
    getLeadData
);

 //get sales list
 const { data: salesList } = useGetdata(
    "salesList",
    GetSales
);

const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Name", key: "firstname", sortable: true },
    { label: "Email", key: "email", sortable: true },
    { label: "City", key: "city", sortable: true },
    { label: "Lead Source", key: "leadsourceName", sortable: true },
    { label: "Lead Status", key: "leadstatusName", sortable: true },
    { label: "Country", key: "country", sortable: true },
    { label: "Mobile", key: "mobile", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
    { label: "Action", sortable: false },
]

    const filteredData = leadDetails?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.firstname && item.firstname.toLowerCase().includes(searched.toLowerCase()) ||
    item.lastname && item.lastname.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.city && item.city.toLowerCase().includes(searched.toLowerCase()) 
    )

    //delete lead
    const queryclient = useQueryClient();
    const deleteLead = useMutation(DeleteLead, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                message.success("Lead deleted Successfully!");
                queryclient.invalidateQueries("leadDetails");
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        }
    })

    const handleDelete = async (id) => {
        window.confirm("Are you sure you want to delete this record?")
        deleteLead.mutate({id:id});
    }
    
    //assign lead modal
    const openAssignModal = (id) => {
        setOpen(true);
        setSelectedLead(id)
    }

    const hideModal = () => {
        setOpen(false);
    }

     //assign to
     const assigntoSales = useMutation(LeadAssignTo, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Lead Assign To Sales Successfully!");
                queryclient.invalidateQueries("leadDetails");
                setDisable(false)
                setOpen(false);
            }
        },
        onError: (data) => {
            setDisable(true)
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        }
    })

    const handleLeadAssignTo = async (e) => {
        e.preventDefault();
        setDisable(true)

        let data = {
            salesId: Number(salesId)
        }

        assigntoSales.mutate({ id: selectedLead, data });
        return;
    }

    //conver lead
    const ConvertLeadToSales = async (id) => {
        const userConfirmed = window.confirm('Are you sure you want to convert this lead to a user?');
        if (userConfirmed) {
        try {
            const result = await ConvertLead(id);
            message.success('Lead converted successfully!');
          } catch (error) {
            // If there's an error during the conversion, update the state
            message.error(error.message);
          }
    }
}

//set permission
const { data: checkPermission, refetch } = useGetdata(
    "checkPermission",
    GetPermissionCheck
)

let token = localStorage.getItem('token');
useEffect(() => {
    if (token) {
        refetch()
    }
}, [token, refetch])

    return (
        <>
            <Helmet><title>Lead List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3  px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Leads</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                            {
                                   checkPermission?.typeAdmin === 1 &&
                                <Link  to="/admin/sales/leads/addleads" className="btn btn-xs btn-primary">
                                    <i className="fa-light fa-plus"></i> Add Lead</Link>}
                                    {
                                        checkPermission?.typeAdmin === 0 &&
                                        checkPermission?.data?.includes("add_lead") ?
                                        <Link  to="/admin/sales/leads/addleads" className="btn btn-xs btn-primary">
                                        <i className="fa-light fa-plus"></i> Add Lead</Link> : ""}
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                    data={filteredData}
                                    headers={headers}
                                    renderRow={(rowData) => (
                                                        <>
                                                        <td>{rowData?.firstname} &nbsp; {rowData?.lastname}</td>
                                                        <td>{rowData?.email}</td>
                                                        <td>{rowData?.city}</td>
                                                        <td>{rowData?.leadsourceName}</td>
                                                        <td>{rowData?.leadstatusName}</td>
                                                        <td>{rowData?.country}</td>
                                                        <td>{rowData?.phone}</td>
                                                        <td>{formatDate(rowData?.createdAt)}</td>
                                                            <td>
                                                            {
                                                                 checkPermission?.typeAdmin === 1 &&
                                                                <Link to={`/admin/sales/leads/viewleads/${rowData.id}`} className="btn btn-xs btn-primary mb-1">View</Link>} &nbsp;
                                                                {
                                                                    checkPermission?.data?.includes("view_lead") ?
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                <Link to={`/admin/sales/leads/viewleads/${rowData.id}`} className="btn btn-xs btn-primary mb-1">View</Link> :""}
                                                                {
                                                                 checkPermission?.typeAdmin === 1 &&
                                                                <Link to={`/admin/sales/leads/editleads/${rowData.id}`} className="btn btn-xs btn-primary mb-1">Edit</Link>}&nbsp;
                                                                {
                                                                    checkPermission?.data?.includes("edit_lead") ?
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                <Link to={`/admin/sales/leads/editleads/${rowData.id}`} className="btn btn-xs btn-primary mb-1">Edit</Link> : ""}
                                                                {
                                                                 checkPermission?.typeAdmin === 1 &&
                                                                <button className="btn btn-xs btn-primary mb-1" onClick={()=>handleDelete(rowData.id)}>Delete</button>}&nbsp;
                                                                {
                                                                    checkPermission?.data?.includes("delete_lead") ?
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                <button className="btn btn-xs btn-primary mb-1" onClick={()=>handleDelete(rowData.id)}>Delete</button> : ""}
                                                                <button  className="btn btn-xs btn-primary mb-1" onClick={()=>openAssignModal(rowData.id)}>Assign To</button>&nbsp;
                                                                {
                                                                 checkPermission?.typeAdmin === 1 &&
                                                                <button className="btn btn-xs btn-primary mb-1" onClick={() => ConvertLeadToSales(rowData)}>Convert</button>}
                                                                {
                                                                    checkPermission?.data?.includes("convert_lead") ?
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                <button className="btn btn-xs btn-primary mb-1" onClick={() => ConvertLeadToSales(rowData)}>Convert</button>: ""}

                                                            </td>
                                                        </>
                                    )} />
                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StyleModal
                            title="Assign To"
                            open={open}
                            width={500}
                            onCancel={hideModal}
                            centered={false}>
                        
                        <div className="row p-3">
                            <div className="col-sm-12">
                                <div className="mb-4">
                                <label for="salesid" className="form-label">Select Sales <span className="required-mark">*</span></label>
                                    <SelectDropdown id="leadsource"
                                    value={salesId}
                                     onChange={(value) => setSalesId(value)}>
                                        <option selected="">Please choose...</option>

                                        {
                                                            salesList?.data.map((sales)=> (
                                                                <option key={sales.id} value={sales.id}>{sales.name}</option>
                                                            ))
                                                        }
                                    </SelectDropdown>
                                </div>
                            </div>
                            
                            <div className="col-sm-2">
                                <button className="btn btn-sm btn-primary" type="submit" onClick={handleLeadAssignTo}
                                disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Assign" }</button>
                            </div>
                            </div>
                </StyleModal>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { getAllUser } from "../../../api/user";
import { Image, message } from "antd";
import { getAllCountry } from "../../../api/countries"
import { useNavigate } from "react-router-dom";
import { CreateBankDetail } from "../../../api/bankDetails";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const AddBankDetails = () => {
    const [userId, setUserId] = useState("");
    const [accountname, setAccountName] = useState("")
    const [accountnumber, setAccountNumber] = useState("")
    const [bankname, setBankName] = useState("")
    const [bankcountry, setBankCountry] = useState("")
    const [iban, setIban] = useState("")
    const [ifsc, setIfsc] = useState("");
    const [bankaddress, setBankAddress] = useState("");
    const [bankbook, setBankBook] = useState(null);
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable]=useState(false)
    const[fileDataurl, setFileDataUrl] = useState(null)
    const navigate = useNavigate()

    const { sidebarOpen } = useAppContext();

    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    // get Country
    const { data: getCountries } = useGetdata(
        "getCountries",
        getAllCountry
    )

    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setBankBook(file);

        if (file) {
            setBankBook(file);
            const objectUrl = URL.createObjectURL(file);
            setFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

    //add bank details
    const quericlient = useQueryClient();
    const addBankDetails = useMutation(CreateBankDetail, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Bank Details Added Successfully!");
                setDisable(false)
                navigate("/admin/user/bankdetailslist")
                quericlient.invalidateQueries("getBankDetails");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleBankDetails = (e) => {
        e.preventDefault();
        setDisable(true)

        if (
            userId === "" ||
            accountname === "" ||
            accountnumber === "" ||
            bankname === "" ||
            bankcountry === "" ||
            iban === "" ||
            ifsc === "" ||
             bankbook === "" ||
            bankaddress === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('accountname', accountname);
        formData.append('accountnumber', accountnumber);
        formData.append('bankname', bankname);
        formData.append('bankcountry', bankcountry);
        formData.append('iban', iban)
        formData.append('ifsc', ifsc);
        formData.append('bankaddress', bankaddress);
        formData.append('bankbook', bankbook);

        addBankDetails.mutate(formData);

    }

    return (
        <>
            <Helmet><title>Add  Bank Details | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add User Bank Details</h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <form>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                        <SelectDropdown id="user"
                                        value={userId}
                                            onChange={(value) => setUserId(value)}>

                                            {allUsers?.data?.map((data) => (
                                                <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                            ))}
                                        </SelectDropdown>
                                        <p className={
                                            userId.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="accountname" className="form-label">Account Name<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="accountname" name="accountname"
                                            type="text" placeholder="Enter account name"
                                            value={accountname} onChange={(e) => setAccountName(e.target.value)} />
                                        <p className={
                                            accountname.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="accountno" className="form-label">Account Number<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="accountno" name="accountno"
                                            type="text" placeholder="Enter account number"
                                            value={accountnumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                        <p className={
                                            accountnumber.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="bankname" className="form-label">Bank Name<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="bankname" name="bankname"
                                            type="text" placeholder="Enter bank name"
                                            value={bankname} onChange={(e) => setBankName(e.target.value)} />
                                        <p className={
                                            bankname.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                        <SelectDropdown id="country"
                                        value={bankcountry}
                                            onChange={(value) => setBankCountry(value)}>
                                            <option selected="">Please choose...</option>

                                            {getCountries?.countries?.map((data) => (
                                                <option key={data?.code} value={data?.country}>{data?.country}</option>

                                            ))}
                                        </SelectDropdown>
                                        <p className={
                                            bankcountry.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="iban" className="form-label">IBAN<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="iban" name="iban"
                                            type="text" placeholder="Enter iban"
                                            value={iban} onChange={(e) => setIban(e.target.value)} />
                                        <p className={
                                            iban.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="ifsc" className="form-label">IFSC Code<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="ifsc" name="ifsc"
                                            type="text" placeholder="Enter ifsc/swift code"
                                            value={ifsc} onChange={(e) => setIfsc(e.target.value)} />
                                        <p className={
                                            ifsc.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="bankbook" className="form-label">Bank Book <span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg bg-secondary" id="bankbook" name="bankbook"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={handleFileChange} />
                                             {
                                fileDataurl && 
                                <Image
                                        src={fileDataurl}
                                        className="mt-2"
                                        alt="Selected"
                                        width={100}
                                        height={70}
                                    />
                            }
                                        <p className={
                                            bankbook === null && loginError
                                                ? "text-danger"
                                                : "d-none"
                                        }>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 mb-4">
                                    <div className="mb-4">
                                        <label for="bankaddress" className="form-label">Bank Address<span className="required-mark">*</span></label>
                                        <input className="form-control form-control-lg" id="bankaddress" name="bankaddress"
                                            type="text" placeholder="Enter bank address"
                                            value={bankaddress}
                                            onChange={(e) => setBankAddress(e.target.value)} />
                                        <p className={
                                            bankaddress.length === 0 && loginError
                                                ? "text-danger"
                                                : "d-none"}>{loginError}</p>
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2">
                                    <button className="btn btn-primary btn-xs font-15" type="submit"
                                        onClick={handleBankDetails}
                                        disabled={disable}>
                                            {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//create sales
export const createSales = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/sales/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //update sales
  export const UpdateSales = async (sales) => {
    let token = localStorage.getItem("token");
    const {id, data} = sales

    let response = await instance({
      url: `api/admin/sales/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //delete sales 
  export const DeleteSales = async (sales) => {
    let token = localStorage.getItem("token");
    const {id} = sales;
    let response = await instance({
      url: `api/admin/sales/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
    
  };

  //get sales
  export const GetSales = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/sales/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };
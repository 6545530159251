import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: 'Bearer' +  localStorage.getItem('token'),
    timeout: 1000,
  },
});

export default instance;
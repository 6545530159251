import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useState } from "react";
import { getIncentivelist } from "../../../api/marketing";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const IncentiveReportList   = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get ib plan 
    const { data: incentivelist } = useGetdata(
        "incentivelist",
        getIncentivelist
    );

    const headers = [
        { label: "ID", key:"id", sortable:true },
        { label: "Name", key:"name", sortable:true },
         { label: "Email", key:"email", sortable:true },
         { label: "Amount", key:"amount", sortable:true },
         { label: "Incentive(%)", key:"incentive", sortable:true },
         { label: "Net Deposit(Monthly)", key:"net_deposit", sortable:true },
         { label: "Total Net Deposit", key:"total_net_deposit", sortable:true },
         { label: "Date", key:"createdAt", sortable:false },
    ]

    const filteredData = incentivelist?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase())
    )

    return (
        <>
            <Helmet><title>Incentive Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Incentive List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                    data={filteredData}
                                    headers={headers}
                                    renderRow={(data) => (
                                                        <>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.amount}</td>
                                                            <td>{data.incentive}</td>
                                                            <td>{data.net_deposit}</td>
                                                            <td>{data.total_net_deposit}</td>
                                                            <td>{data.note}</td>
                                                        </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
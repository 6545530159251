import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { Image, message } from "antd";
import { DeleteNews, GetNews } from "../../../api/news";
import { useMutation, useQueryClient } from "react-query";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const NewsList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")

    //get news 
    const { data: newslist } = useGetdata(
        "newslist",
        GetNews
    );

    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Title", key: "title", sortable: true },
        { label: "Description", key: "description", sortable: true },
        { label: "Short Description", key: "short_description", sortable: true },
        { label: "Image", key: "filename", sortable: true },
        { label: "Status", key: "status", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
        { label: "Action", sortable: false },
    ]

    const filteredData = newslist?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.title && item.title.toLowerCase().includes(searched.toLowerCase()) ||
    item.short_description && item.short_description.toLowerCase().includes(searched.toLowerCase())

    )

    //edit news
    const quericlient = useQueryClient();
    const deletenews = useMutation(DeleteNews, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("News Deleted Successfully!");
                quericlient.invalidateQueries("newslist")

            }

        },
        onError: (data) => {
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handleDelete = (id) => {
        window.confirm("Are you sure you want to delete this news?");
        deletenews.mutate({ id });
    }

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>News List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>News List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="d-flex justify-content-end mb-3">
                                <Link to="/admin/news/addnews" className="btn btn-xs btn-primary">Add News</Link>
                            </div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>
                                                <td>{rowData.title}</td>
                                                <td>{rowData.description}</td>
                                                <td>{rowData.short_description}</td>
                                                <td><Image src={`${process.env.REACT_APP_API_URL}/images/newsUpload/${rowData?.filename}`} alt="deposit" height={60} width={80} /></td>
                                                <td>{rowData.status === 0 ? <span className="text-danger">Unpublished</span> : "" ||
                                                    rowData.status === 1 ? <span className="text-success">Published</span> : ""}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    <Link to={`/admin/news/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link>&nbsp;
                                                    <button className="btn btn-xs btn-primary" onClick={() => handleDelete(rowData.id)}>Delete</button>
                                                </td>
                                            </>
                                        )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
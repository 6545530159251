import instance from "../instance";

//create user
export const createUser = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/user/create",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get All user
export const getAllUser = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/user/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  
//get single user
export const getSingleUser = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/user/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

  //delete user
  export const DeleteUser = async (user) => {
    let token = localStorage.getItem("token");
    const {id} = user;
    let response = await instance({
      url: `api/admin/user/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
    
  };

  //update user

  export const UpdateUserData = async (user) => {
    let token = localStorage.getItem("token");
    const {id, data} = user

    let response = await instance({
      url: `api/admin/user/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };
  
//Post user setting

export const AddUserSetting = async (user) => {
    let token = localStorage.getItem("token");
    const {id, data} = user
  let response = await instance({
    url: `api/admin/user/add/settings/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
     data
  });

  return response.data;
};

  //search user
  export const SearchUserData = async (queryParams) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/search/user?${queryParams}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

//get setting user
export const getSettingUser = async (user) => {
  let token = localStorage.getItem("token");
  const {id} = user
  let response = await instance({
    url: `api/admin/user/settings/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//get user Password list

export const getUserPasswordList = async () => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url: `api/admin/user/passwordlist`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//change user password
export const UserPasswordChange = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/user/changepassword",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return response.data;
};

//add existing client
export const addExistingClients = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/user/addexistingclient",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//disable google2fa
export const DisableGoogle2FA = async (data) => {
  let token = localStorage.getItem("token");
  const { id} = data;
  let response = await instance({
    url: `/api/admin/user/disabled2fa/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get user settings
export const getUserSettings = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/systemsettings`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

// promote  ib
export const PromoteAsIB = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/user/promoteib/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

// verify user
export const VerifyUser = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/user/verify/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
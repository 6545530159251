import { Helmet } from "react-helmet-async"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { Header } from "../../../layout/dashboardLayout/Header"
import { useAppContext } from "../../../context/AppContext"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { AddTicketComment, getSingleTickets, getTicketComments } from "../../../api/ticket"
import { fTime, formatDate } from "../../../utility/formatTime"
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"

export const TicketDetails = () => {
    const [ticketData, setTicketData] = useState({})
    const [comment, setComment] = useState("")
  const [ticketComment, setTicketComment] = useState({});
  const[disable, setDisable]= useState(false)

    const { sidebarOpen } = useAppContext()
    const { ticket_id } = useParams()
//fetch single ticket
    useEffect(() => {
        const fetchTicketData = async () => {
            try {
                const data = await getSingleTickets({ ticket_id });
                setTicketData(data);
            } catch (error) {
                message.error(error.message);
            }
        };

        fetchTicketData();
    }, []);

    //fetch comment
    useEffect(() => {
        const fetchTicketComment = async () => {
          try {
            const data = await getTicketComments({ticket_id});
            setTicketComment(data);
          } catch (error) {
            message.error(error.message);
          }
        };
        fetchTicketComment();
      }, []);


    //add comment
    const queryclient = useQueryClient();
    const addComment = useMutation(AddTicketComment, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Comment Added Successfully!");
                queryclient.invalidateQueries("ticketComment");
                setDisable(false)
                setComment("")
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });


    const handleAddComment = (e) => {
        e.preventDefault()
        setDisable(true)
        let allData = {
            ticket_id: ticket_id,
            data: {
                comment
            }
        }
        addComment.mutate(allData);


    }

    return (
        <>
            <Helmet><title>Ticekt | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Show Ticket</h1>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-default bg-dark">
                                    <div className="card-heading bg-black p-3">
                                        #{ticketData?.data?.ticket_id} - {ticketData?.data?.title}
                                    </div>
                                    <div className="card-body">
                                        <div className="ticket-info">
                                            <p>{ticketData?.data?.title}</p>
                                            <p>Enquiry Type: {ticketData?.data?.description}</p>
                                            <p>Username:  </p>
                                            <p>
                                                Status: {ticketData?.data?.status === 0 ? <span className="text-success">Open</span> : <span className="text-danger">Close</span>}
                                            </p>
                                            <p>Created on: {fTime(ticketData?.data?.createdAt)}</p>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                {
                                    ticketData?.data?.status === 0 ?

                                        (<div className="card card-default bg-dark">
                                            <div className="card-heading bg-black p-3">Add reply</div>
                                            <div className="card-body">
                                                <div className="comment-form">
                                                    <form>
                                                        <input type="hidden" name="_token" />
                                                        <input type="hidden" name="ticket_id" />
                                                        <div className="row gx-4 gx-xl-5">
                                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                                <div className="mb-2">
                                                                    <textarea name="comment" className="form-control form-control-lg " id="comment"
                                                                        placeholder="Enter comment"
                                                                        value={comment}
                                                                        onChange={(e) => setComment(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row gx-4 gx-xl-5">
                                                            <div className="col-md-12 pt-2">
                                                                <button className="btn btn-primary btn-xs font-15"
                                                                    onClick={handleAddComment}
                                                                    disabled={disable}>
                                                                        {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>) : (
                                            <div className="comments">
                                                {
                                                   ticketComment?.data?.map((data)=>(
                                                <div className="card bg-dark mb-3 ms-5 " key={data.id}>
                                                    <div className="card card-heading bg-black p-3">
                                                        <div className="p-0">
                                                            <b>{data.user_id ?  "User":"" || data.admin_id ? "Admin":"" }</b>
                                                            <div className="float-end">{formatDate(data.createdAt)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {data.comment}
                                                    </div>
                                                </div>
                                                )) 
                                            }
                                            </div>
                                        )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
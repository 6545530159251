import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import {  useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import {  Mt5LeverageUpdate, getLiveAccountData } from "../../../api/account";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const UpdateMT5Leverage = () => {
    const [accountId, setAccountId] = useState("")
    const [leverage, setLeverage] = useState("")
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    //get All mt5
    const { data: allLiveAccounts } = useGetdata(
        "allLiveAccounts",
        getLiveAccountData
    )


    //create live account
    const queryclient = useQueryClient();
    const editMt5Leverage = useMutation(Mt5LeverageUpdate, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("MT5 Leverage Updated Successfully!");
                queryclient.invalidateQueries("allLiveAccounts");
                setDisable(false)
                setAccountId("");
                setLeverage("")
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        },
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            leverage === "" ||
            accountId === "") {

            setLoginError("Plese fill above the field")
            setDisable(false)
        }
        // setSelectedData(data.mtgroup)

        let datas = {
            leverage,
            accountId
        }

        editMt5Leverage.mutate(datas);
    }

    return (
        <>
            <Helmet><title>Update MT5 Leverage | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Update MT5 Leverage</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select MT5 ID <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={accountId}
                                     onChange={(value) => setAccountId(value)}>
                                        <option selected="">Please choose...</option>

                                        {allLiveAccounts?.data?.map((data) => (
                                            <option key={data?.id} value={data?.account_no}>{data?.contactname} &nbsp; ({data.email})&nbsp;({data.account_no})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    accountId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="leverage" className="form-label">Select Leverage <span className="required-mark">*</span></label>
                                    <SelectDropdown id="leverage"
                                    value={leverage}
                                     onChange={(value) => setLeverage(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    </SelectDropdown>
                                    <p className={
                                    leverage.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleSubmit}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import {  useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../api/user";
import { message } from "antd";
import { getAllGroup } from "../../../api/group";
import { CreateLiveAccount } from "../../../api/account";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const CreateMT5Account = () => {
    const [user_id, setUser_Id] = useState("")
    const [groupname, setGroupname] = useState("")
    const [leverage, setLeverage] = useState("")
    const[loginError, setLoginError]= useState("")
    const [disable, setDisable]= useState(false)

    const { sidebarOpen } = useAppContext();

    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    // get All group
    const { data: allGroup } = useGetdata(
        "allGroup",
        getAllGroup
    )

    //create live account
    const queryclient = useQueryClient();
    const openLiveAccount = useMutation(CreateLiveAccount, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Live Account Created Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("allGroup");
                setUser_Id("");
                setGroupname("");
                setLeverage("")
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        },
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            groupname === "" ||
            leverage === "" ||
            user_id === "") {

            setLoginError("Plese fill above the field")
            setDisable(false)
        }
        // setSelectedData(data.mtgroup)

        let datas = {
            groupname,
            leverage,
            userId: Number(user_id)
        }

        openLiveAccount.mutate(datas);
    }

    return (
        <>
            <Helmet><title>Create MT5 Account | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Create MT5 Account</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={user_id}
                                     onChange={(value) => setUser_Id(value)}>
                                        <option selected="">Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    user_id.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="group" className="form-label">Select Group <span className="required-mark">*</span></label>
                                    <SelectDropdown id="group"
                                    value={groupname}
                                     onChange={(value) => setGroupname(value)}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {allGroup?.data?.map((data) => (
                                            <option key={data?.id} value={data?.mtgroup}>{data?.name}</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    groupname.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="leverage" className="form-label">Select Leverage <span className="required-mark">*</span></label>
                                    <SelectDropdown id="leverage"
                                    value={leverage}
                                     onChange={(value) => setLeverage(value)}>
                                        <option selected="">Please choose...</option>
                                    <option value="500">1:500</option>
                                    </SelectDropdown>
                                    <p className={
                                    leverage.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleSubmit}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }
                                 </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { subMonths, format } from 'date-fns';
import { getIBWithdrawReport } from "../../../api/ib";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { message } from "antd";


export const IBWithdrawReport = () => {
    const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

    const {sidebarOpen} = useAppContext();
    const[fromDate, setFromDate]=useState(defaultFromDate);
    const[toDate, setToDate]= useState(defaultToDate);
    const[type, setType]=useState("");
    const[paymentmethod, setPaymentMethod]=useState("")
    const [ibwithdrawData, setIBWithdrawData] = useState([]);
    const[searched, setSearched] = useState("")
    const[disable, setDisable] = useState(false)


    const formattingDate = (date) => {
        return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
      };
    
    //search data
      const handleSearch = async () => {
        setDisable(true)
        try {
            const data = { fromDate : formattingDate(fromDate), toDate: formattingDate(toDate), type, paymentmethod  };
            const result = await getIBWithdrawReport(data);
            if(result?.status === 200){
              setIBWithdrawData(result);
              setDisable(false)
            }
          } catch (error) {
            setDisable(false)
            message.error( error);
          }
        };

       useEffect(() => {
        // Fetch latest one month data automatically when component mounts or updates
        fetchLatestOneMonthData();
      }, []); // Empty dependency array to ensure it runs only once when component mounts
    
      const fetchLatestOneMonthData = async () => {
        try {
          const toDate = new Date(); // Current date
          const fromDate = new Date();
          fromDate.setMonth(fromDate.getMonth() - 1); // One month ago
    
          const data = {
            fromDate: formattingDate(fromDate),
            toDate: formattingDate(toDate),
            type:"all",
            paymentmethod:"all"
          };
    
          const result = await getIBWithdrawReport(data);
          setIBWithdrawData(result);
        } catch (error) {
          message.error(error.message);
        }
      };

      const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Name", key: "name", sortable: true },
        { label: "Email", key: "email", sortable: true },
        { label: "Withdraw To", key: "withdraw_to", sortable: true },
        { label: "Amount", key: "amount", sortable: true },
        { label: "Payment Method", key: "paymentmethod", sortable: true },
        { label: "Note", key: "note", sortable: true },
        { label: "Admin Comment", key: "comment", sortable: true },
        { label: "Status", key: "status", sortable: true },
        { label: "Marketing Name", key: "marketingname", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
    ]

    const filteredData = ibwithdrawData?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    // item.userName.toLowerCase().includes(searched.toLowerCase()) ||
    // item.userEmail.toLowerCase().includes(searched.toLowerCase()) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.paymentmethod && item.paymentmethod.toLowerCase().includes(searched.toLowerCase()) ||
    item.amount && item.amount.toString().includes(searched) 
  )

    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
        'ID',
        'Name',
        'Email',
        'Withdraw To',
        'Amount',
        'Payment Method',
        'Note',
        'Comment',
        'Status',
        'Marketing Name',
        'Date'
      ];
      
      const rows = data?.map((item,index) => [
        index + 1,
        item.userName,
        item.userEmail,
        item.withdraw_to,
        item.amount,
        item.paymentmethod,
        item.note,
        item.admincomment,
        item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
        item.marketingname,
        formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('IB Withdraw Report.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
  if (!data  || data?.length === 0) {

    const headers = [
      'ID',
      'Name',
      'Email',
      'MT5 ID',
      'Amount',
      'Payment Method',
      'Note',
      'Admin Comment',
      'Status',
      'Marketing Name',
    'Date'];
   
    const ws = XLSX.utils.aoa_to_sheet([headers]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const filename = 'IB Withdraw Report.xlsx';

  XLSX.writeFile(wb, filename);
    return;
  }


    const dataForExport = data?.map((item,index) => ({
      'ID':  index + 1,
      'Name':item.userName,
      'Email':item.userEmail,
      'Withdraw To': item.withdraw_to,
      'Amount':item.amount,
      'Payment Method': item.paymentmethod,
      'Note':item.note,
      'Admin Comment':item.admincomment,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Marketing Name': item.marketingname,
      'Date':formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'IB Withdraw Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

    return (
        <>
         <Helmet><title>IB Withdraw Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>IB Withdraw Report</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                        <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>Status :</strong></label>
						<SelectDropdown id="status" className="form-control form-control-lg bg-dark"
                         onChange={(value)=> setType(value)}>
							<option value="all" selected>All</option>
							<option value="pending">Pending</option>
							<option value="approved">Approved</option>
							<option value="rejected">Rejected</option>
						</SelectDropdown>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>Payment Method :</strong></label>
						<SelectDropdown id="paymentmethod" className="form-control form-control-lg bg-dark"
                        onChange={(value)=> setPaymentMethod(value)}>
							<option value="all" selected>All</option>
							<option value="cash">Cash</option>
							<option value="bank">Bank</option>
						</SelectDropdown>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>From :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark" name="fromdate" id="fromdate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)} />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>To :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark " name="todate" id="todate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}/>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3 pt-4">
						<button type="button" id="dateSearch"
                         className="btn btn-sm btn-primary"
                         onClick={handleSearch}
                         disabled={disable}>
                           {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
					</div>
				</div>
			</div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(ibwithdrawData?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(ibwithdrawData?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
            <DataTable
            data={filteredData}
            headers={headers}
            renderRow={(rowData)=> (
                                                <>
                                                    <td>{rowData.name}</td>
                                                    <td>{rowData.email}</td>
                                                    <td>{rowData.withdraw_to}</td>
                                                    <td>{rowData.amount}</td>
                                                    <td>{rowData.paymentmethod}</td>
                                                    <td>{rowData.note}</td>
                                                    <td>{rowData.comment}</td>
                                                    <td>
                                                        {rowData.status === 0 ? <strong className="text-warning">Pending</strong> :"" ||
                                                        rowData.status === 1 ? <strong className="text-success">Approved</strong> :
                                                        <strong className="text-danger">Reject</strong> }
                                                    </td>
                                                    <td>{rowData.marketingname}</td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                </>
                                                )} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
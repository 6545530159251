import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { ChangePasswordMT, getLiveAccountData } from "../../../api/account";
import { Link } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const AddReward = () => {
    const [accountno, setaccountNo] = useState("");
    const [amount, setAmount] = useState("");
    const [comment, setComment] = useState("")
    const [loginError, setLoginError] = useState("")


    const { sidebarOpen } = useAppContext();

    //get All liveaccount
    const { data: allLiveAccounts } = useGetdata(
        "allLiveAccounts",
        getLiveAccountData
    )

    //change password mt-5
    const quericlient = useQueryClient();
    const chnagePassword = useMutation(ChangePasswordMT, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("MT5 Password Change Successfully!");
                quericlient.invalidateQueries("allLiveAccounts")
                setaccountNo("");
                setAmount("");
                setComment("");
            }

        },
        onError: (data) => {
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    const handlePasswordChange = (e) => {
        e.preventDefault();
        if (
            accountno === "" ||
            amount === "" ||
            comment === "") {
            setLoginError("Please Fill the above field.");
            return;
        }

        const data = {
            accountno: Number(accountno),
            amount,
            comment
        }
        chnagePassword.mutate(data)
    }

    return (
        <>
            <Helmet><title>Add Reward | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Add Reward </h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <form>
                            <h4>Rewards Definition</h4>
                            <hr />
                            <div className="row gx-4 gx-xl-5 mb-4">
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="reward_name" className="form-label">Reward Name<span className="required-mark">*</span></label>
                                        <input type="text" name="reward_name" id="reward_name"
                                            className="form-control form-control-lg"
                                            placeholder="Enter reward name" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="reward_image" className="form-label">Reward Image <span className="required-mark">*</span></label>
                                        <input type="file" accept=".png, .jpg, .jpeg" name="reward_image" id="reward_image"
                                            className="form-control form-control-lg bg-secondary" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="mb-4">
                                        <label for="reward_description" className="form-label">Reward Description<span className="required-mark">*</span></label>
                                        <input type="text" name="reward_description" id="reward_description"
                                            className="form-control form-control-lg"
                                            placeholder="Enter reward Descritpion" />
                                    </div>
                                </div>
                                <h4>Rule 1 - Per Customer Basis</h4>
                                <hr />

                                <div className="row gx-4 gx-xl-5 mb-4">
                                    <div className="col-sm-4 col-md-2 col-lg-2">
                                        <div className="mb-4">
                                            <label for="total_customer" className="form-label">NTC<span className="required-mark">*</span></label>
                                            <input type="number" name="total_customer" id="total_customer"
                                                className="form-control form-control-lg"
                                                placeholder="Enter  number of total " />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2 col-lg-2">
                                        <div className="mb-4">
                                            <label for="operator" className="form-label">Operator <span className="required-mark">*</span></label>
                                            <select className="form-select form-select-lg " id="operator" value={comment} onChange={(e) => setComment(e.target.value)}>
                                                <option value="and" selected>AND</option>
                                                <option value="or">OR</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <label for="min_traded_lots" className="form-label">Min. Traded Lots Per Client<span className="required-mark">*</span></label>
                                            <input type="text" name="min_traded_lots" id="min_traded_lots"
                                                className="form-control form-control-lg"
                                                placeholder="Min. traded lots per client" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2 col-lg-2">
                                        <div className="mb-4">
                                            <label for="operator" className="form-label">Operator <span className="required-mark">*</span></label>
                                            <select className="form-select form-select-lg " id="operator" value={comment} onChange={(e) => setComment(e.target.value)}>
                                                <option value="and" selected>AND</option>
                                                <option value="or">OR</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <label for="min_deposit_client" className="form-label">Min Deposit Per Client <span className="required-mark">*</span></label>
                                            <input type="number" name="min_deposit_client" id="min_deposit_client"
                                                className="form-control form-control-lg"
                                                placeholder="Min. deposit per client" />
                                        </div>
                                    </div>
                                    <div className="row gx-4 xl-5 mb-4">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="mb-4 ruleoperatorborder">
                                                <div className="ruleoperator">
                                                    <label for="operator" className="form-label">Operator <span className="required-mark">*</span></label>
                                                    <select className="form-select form-select-lg " id="operator" value={comment} onChange={(e) => setComment(e.target.value)}>
                                                        <option value="and" selected>AND</option>
                                                        <option value="or">OR</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4>Rule 2 - Total Customer Basis</h4>
                                <hr />
                                <div className="row gx-4 gx-xl-5 mb-4">
                                    <div className="col-sm-4 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <label for="total_deposit" className="form-label">Total Deposit<span className="required-mark">*</span></label>
                                            <input type="number" name="total_deposit" id="total_deposit"
                                                className="form-control form-control-lg"
                                                placeholder="Total deposit " />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-2 col-lg-2">
                                        <div className="mb-4">
                                            <label for="operator" className="form-label">Operator <span className="required-mark">*</span></label>
                                            <select className="form-select form-select-lg " id="operator" value={comment} onChange={(e) => setComment(e.target.value)}>
                                                <option value="and" selected>AND</option>
                                                <option value="or">OR</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <label for="total_traded_lots" className="form-label">Total Trade Lots<span className="required-mark">*</span></label>
                                            <input type="text" name="total_traded_lots" id="total_traded_lots"
                                                className="form-control form-control-lg"
                                                placeholder="Total trade lots" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2">
                                    <button className="btn btn-primary btn-xs font-15" type="submit"
                                    >Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import {  useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../../api/user";
import { message } from "antd";
import {  getLiveAccountData } from "../../../../api/account";
import { useAppContext } from "../../../../context/AppContext";
import useGetdata from "../../../../hooks/useGetData";
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../../layout/dashboardLayout/Header";
import { getAllBankDetails } from "../../../../api/bankDetails";
import { AddClientWithdraw } from "../../../../api/withdraw";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";
import { SelectDropdown } from "../../../../component/DropDownComponent";

export const ClientWithdraw = () => {
    const[userId, setUserId]= useState(null);
    const [amount, setAmount] = useState("")
    const [withdrawFrom, setWithdrawFrom] = useState("")
    const [withdrawTo, setWithdrawTo] = useState("")
    const [note, setNote] = useState("")
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    const handleUserSelect = (id) => {
        setUserId(id);
      };


    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    //get live account
const {data:liveAccounts} = useGetdata(
    "liveAccounts",
    getLiveAccountData
    )

    const filterliveAccounts = liveAccounts?.data?.filter(el=> el?.user_id == userId)

    //get bank details
const { data: bankDetails } = useGetdata(
    "bankDetails",
    getAllBankDetails
)
    const filterBankDetails = bankDetails?.data?.filter(el=> el.user_id == userId)

    //add withdraw 
    const quericlient = useQueryClient();
    const addwithdraw = useMutation(AddClientWithdraw, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Client Withdraw Successfully!");
                quericlient.invalidateQueries("approveWithdraw");
                // navigate("/admin/transaction/approveWithdraw")
                setDisable(false)
                setUserId("");
                setWithdrawFrom("");
                setWithdrawTo("");
                setAmount("");
                setNote("");
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }

            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });


    const handleAddClientWithdraw= (e) => {
            e.preventDefault();
            setDisable(true)
        if (
            withdrawFrom === "" ||
            withdrawTo === "" ||
            amount === "" ||
            note === "" ||
            userId === "" ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

       
       let data = {
        userId,
        amount: amount,
        withdrawFrom,
        withdrawTo,
        note
       }
            addwithdraw.mutate(data);
    }

    return (
        <>
            <Helmet><title>Client Withdraw | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Client Withdraw</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userId}
                                    onChange={(value) => handleUserSelect(parseInt(value))}>
                                        <option selected="">Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="deposit" className="form-label">Withdraw From <span className="required-mark">*</span></label>
                                    <SelectDropdown id="deposit"
                                    value={withdrawFrom}
                                     onChange={(value) => setWithdrawFrom(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="wallet">Wallet</option>
                                            {
                                                filterliveAccounts?.map((data)=>(
                                                    <option key={data.id} value={data.account_no}>{data?.contactname} ({data?.email}) ({data?.account_no})</option>
                                                ))
                                            }
                                    </SelectDropdown>
                                    <p className={
                                    withdrawFrom === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="withdraw" className="form-label">Withdraw To <span className="required-mark">*</span></label>
                                    <SelectDropdown id="withdraw"
                                    value={withdrawTo}
                                     onChange={(value) => setWithdrawTo(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="cash">Cash</option>
                                            {
                                        filterBankDetails?.map((data) => (
                                            <option key={data.id} value={data.id}>{data.bankname} ({data.accountnumber})</option>
                                        ))
                                    }
                                    </SelectDropdown>
                                    <p className={
                                    withdrawTo === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="amount" className="form-label">Amount <span className="required-mark">*</span></label>
                                    <input type="text" name="amount" className="form-control form-control-lg "
                                        id="amount" placeholder="Enter amount" value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <p className={
                                    amount.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="name" className="form-label">Note <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter comment" value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                    <p className={
                                    note.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddClientWithdraw}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
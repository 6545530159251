import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { UpdateLeadData, getLeadSourceList, getLeadStatusList, getSingleLeadData } from "../../../api/lead";
import { getAllCountry } from "../../../api/countries";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditLead = () => {
    const { sidebarOpen } = useAppContext();
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState('');
    const [phonecode, setPhoneCode] = useState('');
    const [city, setCity] = useState('');
    const [lead_source, setLead_Source] = useState('');
    const [lead_status, setLead_Status] = useState('');
    const [remark, setRemark] = useState('');
   const[leadData, setLeadData] = useState({})
   const[disable, setDisable] = useState(false)
    const navigate = useNavigate()
    const {id} = useParams();

    //get lead source
    const { data: allLeadSourceList, isLoading:userDLoad } = useGetdata(
        "allleadSourceList",
        getLeadSourceList
    );

    //get lead status
    const { data: allLeadStatusList } = useGetdata(
        "allleadStatusList",
        getLeadStatusList
    );

    //get Country
    const { data: countryList } = useGetdata(
        "countryList",
        getAllCountry

    )

    useEffect(() => {
        const selectedCountryData = countryList?.countries?.find(c => c.country === country);
        if (selectedCountryData) {
            setPhoneCode(selectedCountryData.code);
        } else {
            setPhoneCode('');
        }
    }, [country, countryList]);
   
    //get single lead
    useEffect(() => {
        const fetchSingleLeadData = async () => {
          try {
            const data = await getSingleLeadData({id});
            setLeadData(data);
          } catch (error) {
            message.error(error.message);
          }
        };
    
        fetchSingleLeadData();
      }, [id]);

     //update lead
     const queryclient = useQueryClient()
     const leadUpdate = useMutation(UpdateLeadData, {
        onSuccess: (data) => {
            if (data?.status === 200 || data?.success === true) {
                message.success("Lead Updated Successfully!");
                navigate("/admin/sales/leads/leadlist")
                queryclient.invalidateQueries("leadDetails");
                setDisable(false)

            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        }
    })

    useEffect(() => {
        if (!userDLoad) {
            setFirstName(leadData?.data?.firstname);
            setLastName(leadData?.data?.lastname);
            setEmail(leadData?.data?.email);
            setPhone(leadData?.data?.phone);
            setCountry(leadData?.data?.country);
            setCity(leadData?.data?.city);
            setLead_Source(leadData?.data?.lead_source);
            setLead_Status(leadData?.data?.lead_status);
            setRemark(leadData?.data?.remark);
        }
    }, [userDLoad, leadData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)

        let data = {
            firstname,
            lastname,
            email,
            phone: Number(phone),
            phonecode: "+" + phonecode,
            country,
            city,
            lead_source,
            lead_status,
            remark
        }

        leadUpdate.mutate({ id:id, data });
        return;
    }

    return (
        <>
            <Helmet><title>Edit Leads | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Leads</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="firstname" className="form-label">First Name <span className="required-mark">*</span></label>
                                    <input type="text" name="firstname" className="form-control form-control-lg "
                                        id="firstname"
                                         placeholder="Enter firtstname"
                                         value={firstname}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="lastname" className="form-label">Last Name <span className="required-mark">*</span></label>
                                    <input type="text" name="lastname" className="form-control form-control-lg "
                                        id="lastname"
                                         placeholder="Enter lastname"
                                         value={lastname}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="text" name="email" className="form-control form-control-lg "
                                        id="email"
                                         placeholder="Enter email"
                                         value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                   
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                    <SelectDropdown id="country"
                                    value={country}
                                     onChange={(value) => setCountry(value)}>

                                        {countryList?.countries?.map((data) => (
                                            <option key={data?.code} value={data?.country}>{data?.country}</option>

                                        ))}
                                    </SelectDropdown>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="phone" className="form-label">Phone <span className="required-mark">*</span></label>
                                    <div className="d-flex justify-content-start gap-2">
                                        <input type="text" name="phonecode" className="form-control form-control-lg"
                                            id="phonecode"
                                            placeholder="+91"
                                            value={"+" + phonecode} />
                                        <input type="text" name="phone" className="form-control form-control-lg col-sm-9" id="phone"
                                            placeholder="Enter Phone" value={phone}
                                            onChange={(e)=> setPhone(e.target.value)}
                                        />
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="city" className="form-label">City <span className="required-mark">*</span></label>
                                    <input type="text" name="city" className="form-control form-control-lg "
                                        id="city"
                                         placeholder="Enter city"
                                         value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="leadsource" className="form-label">Lead Source <span className="required-mark">*</span></label>
                                    <SelectDropdown id="leadsource"
                                    value={lead_source}
                                     onChange={(value) => setLead_Source(value)}>

                                        {
                                                allLeadSourceList?.data?.map((leadsource) => (
                                                    <option key={leadsource.id} value={leadsource.id}>{leadsource.name}</option>
                                                ))
                                            }
                                    </SelectDropdown>
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="leadstatus" className="form-label">Lead Status <span className="required-mark">*</span></label>
                                    <SelectDropdown id="leadstatus"
                                    value={lead_status}
                                     onChange={(value) => setLead_Status(value)}>

                                        {
                                                allLeadStatusList?.data.map((leadstatus) => (
                                                    <option key={leadstatus.id} value={leadstatus.id}>{leadstatus.name}</option>
                                                ))
                                            }

                                    </SelectDropdown>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="remark" className="form-label">Remark <span className="required-mark">*</span></label>
                                    <input type="text" name="remark" className="form-control form-control-lg "
                                        id="remark"
                                         placeholder="Hello world"
                                         value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleSubmit}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
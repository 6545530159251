import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../../api/user";
import { formatDate } from "../../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import DataTable from "../../../../component/DataTable";
import { message } from "antd";


export const BankDetails = () => {
  const [userbankDetails, setUserBankDetails] = useState({})
  const [searched, setSearched] = useState("")

  const { id } = useParams()

  const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Account Name", key: "accountname", sortable: true },
    { label: "Account No.", key: "accountnumber", sortable: true },
    { label: "IFSC", key: "ifsc", sortable: true },
    { label: "IBAN", key: "iban", sortable: true },
    { label: "Bank Name", key: "bankname", sortable: true },
    { label: "Bank Country", key: "bankcountry", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ]

  const filteredData = userbankDetails?.data?.bankdetails?.filter((item) =>
    // index + 1.toString().includes(searched) ||
  item.accountname && item.accountname.toLowerCase().includes(searched.toLowerCase()) ||
  item.bankname && item.bankname.toLowerCase().includes(searched.toLowerCase()) ||
  item.ifsc && item.ifsc.toLowerCase().includes(searched.toLowerCase()) ||
  item.accountnumber && item.accountnumber.toString().includes(searched)

  )

  //fetch single user details
  useEffect(() => {
    const fetchUserDepositList = async () => {
      try {
        const data = await getSingleUser({ id });
        setUserBankDetails(data);
      } catch (error) {
        message.error(error.message);

      }
    };

    fetchUserDepositList();
  }, [id]);

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Account Name',
      'Account No',
      'IFSC Code',
      'IBAN No',
      'Bank Name',
      'Bank Country',
      'Status',
      'Date'
    ];

    const rows = data?.map((item,index) => [
      index + 1,
      item.accountname,
      item.accountnumber,
      item.ifsc,
      item.iban,
      item.bankname,
      item.bankcountry,
      item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('bankdetails.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        'ID',
        'Account Name',
        'Account No',
        'IFSC Code',
        'IBAN No',
        'Bank Name',
        'Bank Country',
        'Status',
        'Date'
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "bankdetails.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item,index) => ({
      'ID': index + 1,
      'Account Name': item.accountname,
      'Account No': item.accountnumber,
      'Ifsc Code': item.ifsc,
      'IBAN': item.iban,
      'Bank Name': item.bankname,
      'Bank Country': item.bankcountry,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'bankdetails.xlsx';

    XLSX.writeFile(wb, filename);
  }


  return (
    <>
      <div className="table-responsive-lg">
        <div className="row p-3 dataTables_filter">
          <div className="col-sm-12 d-flex justify-content-between">
            <div className="d-flex gap-2">
              <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(userbankDetails?.data?.bankdetails)}>
                <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
              <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(userbankDetails?.data?.bankdetails)}>
                <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

            </div>
            <div className="d-flex align-items-center">
              <label> Search:&nbsp;</label>
              <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
            </div>
          </div>
        </div>
        <DataTable
          data={filteredData}
          headers={headers}
          renderRow={(rowData) => (
            <>
              <td>{rowData.accountname}</td>
              <td>{rowData.accountnumber}</td>
              <td>{rowData.ifsc}</td>
              <td>{rowData.iban}</td>
              <td>{rowData.bankname}</td>
              <td>{rowData.bankcountry}</td>
              <td>{rowData.status === 0 ? <b className="text-warning">Pending</b> : "" ||
                rowData.status === 1 ? <b className="text-success">Approved</b> : <b className="text-danger">Reject</b>}</td>
              <td>{formatDate(rowData.createdAt)}</td>
            </>
          )} />
      </div>

    </>
  )
}
import  { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdle = ({ onIdle, idleTime = 1 }) => {
  const [isIdle, setIsIdle] = useState("");

  const handleOnIdle = () => {
    setIsIdle(true);
    onIdle();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return {
    isIdle,
  };
};

export default useIdle;

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { useMutation, useQueryClient } from "react-query";
import { Image, message } from "antd";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { ApprovedBankDetails, DeleteBanks, RejectBankDetails, getAllBankDetails } from "../../../api/bankDetails";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { Link } from "react-router-dom";
import { StyleModal } from "../../../component/StyleModal";


export const BankDetailsList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")
    const [openReject, setOpenReject] = useState(false)
    const [admincomment, setAdminComment] = useState("")
    const [loginError, setLoginError] = useState("")
    const [bankdetailId, setBankDetailId] = useState(0);


    //get bankdetails
    const { data: getBankDetails } = useGetdata(
        "getBankDetails",
        getAllBankDetails
    )

    //reject depsoit modal
    const showRejectModal = (id) => {
        setOpenReject(true);
        setBankDetailId(id);
    }

    const cancelModal = () => {
        setOpenReject(false)
    }


    const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Account Name", key: "accountname", sortable: true },
        { label: "Account No.", key: "accountnumber", sortable: true },
        { label: "IBAN", key: "iban", sortable: true },
        { label: "IFSC", key: "ifsc", sortable: true },
        { label: "Bank Name", key: "bankname", sortable: true },
        { label: "Bank Address", key: "bankaddress", sortable: true },
        { label: "Bank Country", key: "bankcountry", sortable: true },
        { label: "Bank Book", key: "bankbook", sortable: true },
        { label: "Status", key: "status", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
        { label: "Action", sortable: false },

    ]
    // filtered data
    const filteredData = getBankDetails?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.accountname && item.accountname.toLowerCase().includes(searched.toLowerCase()) ||
    item.bankname && item.bankname.toLowerCase().includes(searched.toLowerCase()) ||
    item.ifsc && item.ifsc.toLowerCase().includes(searched.toLowerCase()) ||
    item.accountnumber && item.accountnumber.toString().includes(searched)


    )

    //approve bank details
    const queryclient = useQueryClient();
    const approveBankDetail = useMutation(ApprovedBankDetails, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Bank Details Approved Successfully!");
                queryclient.invalidateQueries("getBankDetails")
            }

        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleApproved = (id) => {
        const userConfirmed = window.confirm('Are you sure you want to approve bank details?');
        if (userConfirmed) {

            approveBankDetail.mutate({ id });

        }

    }

    //reject bank details

    const rejectBankDetail = useMutation(RejectBankDetails, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Bank Details Reject Successfully!");
                cancelModal();
                queryclient.invalidateQueries("getBankDetails")
            }

        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleReject = () => {
        if (!admincomment) {
            loginError("Please add above field");
            return;
        }
        rejectBankDetail.mutate({ id: bankdetailId, admincomment });
    }

    //delete bank details

    const deletebank = useMutation(DeleteBanks, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Bank Details Deleted Successfully!");
                queryclient.invalidateQueries("getBankDetails")
            }

        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    });

    const handleDeleteBank = (id) => {
        const userConfirmed = window.confirm('Are you sure you want to delete bank details?');
        if (userConfirmed) {
            deletebank.mutate({ id });

        }
    }


    //export to pdf 
    const exportToPDF = (data) => {
        const doc = new jsPDF();

        // Define columns and rows based on your data structure
        const columns = [
            'ID',
            'Account Name',
            'Account No.',
            'IBAN',
            'IFSC',
            'Bank Name',
            'Bank Address',
            'Country',
            'Bank Book',
            'Status',
            'Date',

        ];

        const rows = data?.map((item, index) => [
            index + 1,
            item.accountname,
            item.accountnumber,
            item.iban,
            item.ifsc,
            item.bankname,
            item.bankaddress,
            item.bankcountry,
            item.bankbook,
            item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
            formatDate(item.createdAt)
        ]);
        doc.autoTable({
            // columns, body: rows
            head: [columns],
            body: rows,
            startY: 20,
            theme: 'grid',
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },

            },
            styles: {
                fontSize: 10,
                font: 'Gilroy'
            }
        });
        doc.save('Bank Details List.pdf');
    };

    //   export excel format
    const exportToExcel = (data) => {
        if (!data || data?.length === 0) {
            const headers = [
                'ID',
                'Account Name',
                'Account No.',
                'IBAN',
                'IFSC',
                'Bank Name',
                'Bank Address',
                'Country',
                'Bank Book',
                'Status',
                'Date'
            ];

            const ws = XLSX.utils.aoa_to_sheet([headers]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            const filename = "Bank Details List.xlsx";

            XLSX.writeFile(wb, filename);
            return;
        }
        const dataForExport = data?.map((item, index) => ({
            'ID': index + 1,
            'Account Name': item.accountname,
            'Account No.': item.accountnumber,
            'IBAN': item.iban,
            'IFSC': item.ifsc,
            'Bank Name': item.bankname,
            'Bank Address': item.bankaddress,
            'Bank Country': item.bankcountry,
            'Bank Book': item.bankbook,
            'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
            'Date': formatDate(item.createdAt),
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const filename = 'Bank Detail List.xlsx';

        XLSX.writeFile(wb, filename);
    }

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])


    return (
        <>
            <Helmet><title>Bank Details List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1> Bank Details List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(getBankDetails?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                                                <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(getBankDetails?.data)}>
                                                    <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100"      />
                                            </div>
                                        </div>
                                    </div>

                                    <DataTable
                                        data={filteredData}
                                        headers={headers}
                                        renderRow={(rowData) => (
                                            <>  
                                                <td>{rowData.accountname}</td>
                                                <td>{rowData.accountnumber}</td>
                                                <td>{rowData.iban}</td>
                                                <td>{rowData.ifsc}</td>
                                                <td>{rowData.bankname}</td>
                                                <td>{rowData.bankaddress}</td>
                                                <td>{rowData.bankcountry}</td>
                                                <td>
                                                    <Image src={`${process.env.REACT_APP_API_URL}/images/bankdetailUploads/${rowData?.bankbook}`} alt='img' height={50} width={80} />
                                                </td>
                                                <td>{rowData.status === 0 ? <b className="text-warning">Pending</b> : "" || rowData.status === 1 ? <b className="text-success">Approved</b> : <b className="text-danger">Reject</b>}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                    <div className="d-flex">
                                                    {
                                                        rowData.status === 0 ?
                                                            <>
                                                                {
                                                                    checkPermission?.typeAdmin === 1 &&
                                                                    <button className="btn btn-primary btn-xs mb-1" onClick={() => handleApproved(rowData.id)}>
                                                                        Approve</button>} &nbsp;
                                                                {
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                        checkPermission?.data.includes("bank_detail_list") ?
                                                                        <button className="btn btn-primary btn-xs mb-1" onClick={() => handleApproved(rowData.id)}>
                                                                            Approve</button> : ""} &nbsp;
                                                                {
                                                                    checkPermission?.typeAdmin === 1 &&
                                                                    <button className="btn btn-primary btn-xs mb-1" onClick={() => showRejectModal(rowData.id)}>
                                                                        Reject</button>}
                                                                {
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                        checkPermission?.data.includes("bank_detail_list") ?
                                                                        <button className="btn btn-primary btn-xs mb-1" onClick={() => showRejectModal(rowData.id)}>
                                                                            Reject</button> : ""} &nbsp;

                                                            </> : ""}
                                                    <Link to={`/admin/user/addbankdetails/edit/${rowData.id}`} className="btn btn-primary btn-xs mb-1">Edit</Link>&nbsp;
                                                    <button onClick={() => handleDeleteBank(rowData.id)} className="btn btn-primary btn-xs mb-1">Delete</button>
                                                    </div>
                                                </td>

                                            </>
                                        )
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* open approve deposit modal */}
                    <StyleModal
                        title="Approve Depsoit"
                        open={openReject}
                        width={500}
                        centered={false}
                        onCancel={cancelModal}
                    >
                        <div className="p-3">
                            <div className="pb-2">
                                <label for="comment" className="form-label">Admin Comment <span className="text-danger">*</span></label>
                                <textarea type="text" className="form-control"
                                    placeholder="Enter comment" rows={3}
                                    value={admincomment}
                                    onChange={(e) => setAdminComment(e.target.value)} />
                                <p className={
                                    admincomment.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"}>{loginError}</p>
                            </div>
                            <button className="btn btn-xs btn-primary mt-3" onClick={() => handleReject()}>Submit</button>
                        </div>
                    </StyleModal>

                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { TablePagination } from "@mui/material";

import { useEffect, useState } from "react";
import styled from "styled-components";
import { GetIBPlanData } from "../../../api/ib";
import { Link } from "react-router-dom";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


const StyleTablePagination = styled(TablePagination)`
.css-levciy-MuiTablePagination-displayedRows {
    font-family: Gilroy;
    padding-top: 10px;
  }
  .css-pdct74-MuiTablePagination-selectLabel {
    padding-top:9px;
    font-family: Gilroy;
  }
  
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    font-family: Gilroy;
  }
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    fill: #ffff;
  }
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #ffff;
}
`

export const ScalpingReports = () => {
    const { sidebarOpen } = useAppContext();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searched, setSearched] = useState("")


    //get ib plan 
    const { data: allIBPlan } = useGetdata(
        "allIBPlan",
        GetIBPlanData
    );

    // pagination

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const filteredData = allIBPlan?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
        item.name.toLowerCase().includes(searched.toLowerCase())
    )

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const PaginatedData = filteredData?.slice(startIndex, endIndex);

    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>Scalping Data | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Scalping Data</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-dark table-striped mb-0 text-center table-hover dataTable">
                                        <thead>
                                            <tr>
                                                <th>Login</th>
                                                <th>Total Lots</th>
                                                <th>Scalping Lots</th>
                                                <th>Net Lot</th>
                                                <th>Action</th>


                                            </tr>
                                        </thead>
                                        {allIBPlan?.data?.length > 0 ?
                                            <tbody>
                                                {
                                                    PaginatedData?.map((data) => (
                                                        <tr key={data.id}>
                                                            <td>{data.name}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <button className="btn btn-primary btn-xs">View Full Scalping Report</button>
                                                            </td>

                                                        </tr>
                                                    ))
                                                }
                                            </tbody> :
                                            <tbody>
                                                <tr className="text-center">
                                                    <td className="dataTables_empty pt-3 pb-3" colSpan={11}>No data available</td>
                                                </tr>
                                            </tbody>}
                                    </table>
                                    <StyleTablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        className="text-white d-flex justify-content-end"
                                        count={allIBPlan?.data?.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";


export const loginAuth = async (data) => {

    let response = await instance({
      url: "api/admin/login",
      method: "POST",
      data,
    });
  
    return response.data;
  };

  //get login activity report
export const getLoginActivityData = async (data) => {
  let token = localStorage.getItem("token");
  const {fromDate, toDate} = data;
let response = await instance({
  url: `api/admin/report/loginactivity?fromDate=${fromDate}&toDate=${toDate}`,
  method: "GET",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});
return response.data;
};

// history report
export const GetHistoryReport = async (data) => {
  let token = localStorage.getItem("token");
  const {account, fromDate, toDate} = data;
let response = await instance({
  url: `api/admin/report/closetrades?account=${account}&fromDate=${fromDate}&toDate=${toDate}`,
  method: "GET",
  headers: {
    Authorization: `Bearer ${token}`,
  },
   data,
});
return response.data;
};
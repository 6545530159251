import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../../context/AppContext";
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import { subMonths, format } from "date-fns";
import {
  getWalletHistoryReport,
} from "../../../api/transfer";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { message } from "antd";


export const WalletHistoryReport = () => {
  const today = new Date();
  const defaultToDate = format(today, "yyyy-MM-dd"); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), "yyyy-MM-dd"); // One month ago

  const { sidebarOpen } = useAppContext();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [historyData, setHistoryData] = useState([]);
  const [searched, setSearched] = useState("");
  const[disable, setDisable] = useState(false)

  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = {
        fromDate: formattingDate(fromDate),
        toDate: formattingDate(toDate),
      };
      const result = await getWalletHistoryReport(data);
      if(result.status === 200){
        setHistoryData(result);
        setDisable(false)
      }
    } catch (error) {
      setDisable(false)
      message.error(error.message);
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const toDate = new Date(); // Current date
      const fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 1); // One month ago

      const data = {
        fromDate: formattingDate(fromDate),
        toDate: formattingDate(toDate),
      };

      const result = await getWalletHistoryReport(data);
      setHistoryData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Name", key: "name", sortable: true },
    { label: "Email", key: "email", sortable: true },
    { label: "Type", key: "type", sortable: true },
    { label: "Method", key: "method", sortable: true },
    { label: "To Deposit", key: "todeposit", sortable: true },
    { label: "Amount", key: "amount", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
    { label: "Marketing Name", key: "marketingname", sortable: true },
]

  const filteredData = historyData?.data?.filter(
    (item) =>
      // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.amount && item.amount.toLowerCase().includes(searched.toLowerCase()) 
     
  );

  //export to pdf
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      "ID",
      "Name",
      "Email",
      "Type",
      "Method",
      "To Deposit",
      "Amount",
      "Date",
      "Marketing Name",
    ];

    const rows = data?.map((item, index) => [
      index + 1,
      item.name,
      item.email,
      item.type,
      item.method,
      item.todeposit,
      item.amount,
      formatDate(item.createdAt),
      item.marketingname,

    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
      },
      styles: {
        fontSize: 10,
        font: "Gilroy",
      },
    });
    doc.save("Wallet History Report.pdf");
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        "ID",
      "Name",
      "Email",
      "Type",
      "Method",
      "To Deposit",
      "Amount",
      "Date",
      "Marketing Name",
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "Wallet History Report.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item, index) => ({
        "ID": index + 1,
      "Name": item.name,
      "Email": item.email,
      "Type": item.type,
      "Method": item.method,
      "To Deposit": item.todeposit,
      "Amount": item.amount,
      "Date": formatDate(item.createdAt),
      "Marketing Name": item.marketingname,
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const filename = "Wallet Histroy Report.xlsx";

    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <Helmet>
        <title>
          Wallet History Report | {process.env.REACT_APP_PROJECT_NAME}
        </title>
      </Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>Wallet History Report</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label>
                      <strong>From :</strong>
                    </label>
                    <input
                      type="Date"
                      className="form-control form-control-lg bg-dark"
                      name="fromdate"
                      id="fromdate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label>
                      <strong>To :</strong>
                    </label>
                    <input
                      type="Date"
                      className="form-control form-control-lg bg-dark "
                      name="todate"
                      id="todate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3 pt-4">
                    <button
                      type="button"
                      id="dateSearch"
                      className="btn btn-sm btn-primary"
                      onClick={handleSearch}
                      disabled={disable}
                    >
                       {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                  </div>
                </div>
              </div>
              <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                <div className="table-responsive-lg p-3">
                  <div className="row mb-3 dataTables_filter">
                    <div className="col-sm-12 d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToPDF(historyData?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;PDF
                        </button>
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToExcel(historyData?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;EXCEL
                        </button>
                      </div>
                      <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input
                          type="search"
                          className="bg-dark  h-100"
                          value={searched}
                          onChange={(e) => setSearched(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <DataTable
                  data={filteredData}
                  headers={headers}
                  renderRow={(data) => (
                          <>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.type}</td>
                            <td>{data.method}</td>
                            <td>{data.todeposit}</td>
                            <td>{data.amount}</td>
                            <td>{formatDate(data.createdAt)}</td>
                            <td>{data.marketingname}</td>
                          </>
                        )} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
            <BackToTopPage />
    </>
  );
};

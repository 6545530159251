import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { UpdateUserData, getSingleUser } from "../../../api/user";
import { message } from "antd";
import {getAllCountry} from "../../../api/countries"
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utility/formatTime";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { GetMarketingList } from "../../../api/marketing";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditUser = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [dob, setDob] = useState("");
    const [phonecode, setPhoneCode] = useState("");
    const [status, setStatus] = useState("");
    const [marketing, setMarketing] = useState("");
    const[selectedUser, setSelectedUser]=useState({})
    const [lengthError, setLengthError] = useState("")
    const[loginverified, setLoginVerified]= useState("")
    const[loginError, setLoginError]=useState("")
    const[disable, setDisable]=useState(false)
    


    const { sidebarOpen } = useAppContext();

    const {id} = useParams();

    const handleChange = (e) => {
        const { value } = e.target;
        setPhone(value);
        setLengthError(value.length >= 10 && value.length > 15);
    };


    // // change date format
    // const handleDateChange = (event) => {
    //     // Extracting date components
    //     const year = event.target.value.substring(0, 4);
    //     const month = ('0' + event.target.value.substring(5, 7)).slice(-2);
    //     const day = ('0' + event.target.value.substring(8, 10)).slice(-2);
    
    //     // Formatted date string in yyyy-MM-DD format
    //     const formattedDate = `${year}-${month}-${day}`;
    
    //     setDob(formattedDate);
    //   };
    

    // get Countries
    const { data: getCountries, isLoading:userDLoad } = useGetdata(
        "getCountries",
        getAllCountry
    )
// get marketing
const { data: marketingList } = useGetdata(
    "marketingList",
    GetMarketingList
)
    useEffect(() => {
        const selectedCountryData = getCountries?.countries?.find(c => c.country === country);
        if (selectedCountryData) {
            setPhoneCode(selectedCountryData?.code);
        } else {
            setPhoneCode('');
        }
    }, [country, getCountries?.countries]);

    // fetch single user
    useEffect(() => {
        const fetchSingleUser = async () => {
          try {
            const data = await getSingleUser({id});
            setSelectedUser(data);
          } catch (error) {
            message.error(error.message);
        }
        };
    
        fetchSingleUser();
      }, [id]);
    //update user
    const queryclient = useQueryClient();
    const userUpdate = useMutation(UpdateUserData, {
        onSuccess: (data) => {
            if (data?.success === true || data?.status === 200) {
                message.success("User Updated Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("allUserDetails");
                
            }

        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data.response.data.message);
            }
        }
    })

    useEffect(() => {
        if (!userDLoad) {
            setName(selectedUser?.data?.user?.name);
            setEmail(selectedUser?.data?.user?.email);
            setPhone(selectedUser?.data?.user?.phone);
            setPhoneCode(selectedUser?.data?.user?.phonecode);
            setGender(selectedUser?.data?.user?.gender);
            setCountry(selectedUser?.data?.user?.country);
            setDob(formatDate(selectedUser?.data?.user?.dob));
            setLoginVerified(selectedUser?.data?.user?.login_verified);
            setMarketing(selectedUser?.data?.user?.marketingid)

            if (selectedUser?.data?.user?.login_verified === 0) {
                setStatus("pending")
             }
             
             if (selectedUser?.data?.user?.login_verified === 1) {
                setStatus("approved")
             }
             
             if (selectedUser?.data?.user?.login_verified === 2) {
                setStatus("blocked")
             }

        }
    }, [userDLoad, selectedUser?.data?.user]);

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        setDisable(true)
        
        if (name === "" ||
            email === "" ||
            phone === "" ||
            country === "" ||
            gender === "" ||
        dob === "") {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }

        let data = {
            name,
            email,
            // password,
            phone: Number(phone),
            phonecode: "+" + phonecode,
            gender,
            country,
            dob,
            status,
            marketingid: marketing 
        }

        userUpdate.mutate({ id:id, data });
        return;
    }



    return (
        <>
            <Helmet><title>Edit User | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit User</h1>
                    </div>
                <div className="bg-dark rounded-3 px-4 py-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name <span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name" placeholder="Enter Name" value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <p className={
                                        name === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="email" className="form-label">Email <span className="required-mark">*</span></label>
                                    <input type="email" name="email" className="form-control form-control-lg " id="email"
                                        placeholder="Enter Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p className={
                                        email === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="password" className="form-label">Password <span className="required-mark">*</span></label>
                                    <input type="password" name="password" className="form-control form-control-lg " id="password"
                                        placeholder="Enter Password" value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="country" className="form-label">Country <span className="required-mark">*</span></label>
                                    <SelectDropdown id="country"
                                    value={country}
                                     onChange={(value) => setCountry(value)}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {getCountries?.countries?.map((data) => (
                                            <option key={data?.code} value={data?.country}>{data?.country}</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        country === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="phone" className="form-label">Phone <span className="required-mark">*</span></label>
                                    <div className="d-flex justify-content-start gap-2">
                                        <input type="text" name="phonecode" className="form-control form-control-lg"
                                            id="phonecode"
                                            placeholder="+91"
                                            value={"+" + phonecode} />
                                        <input type="text" name="phone" className="form-control form-control-lg col-sm-9" id="phone"
                                            placeholder="Enter Phone" value={phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <p className={
                                        phone === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                    {lengthError && (
                                        <p className="text-danger">
                                            Phone number must be between 10 and 15 characters long.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="gender" className="form-label">Gender <span className="required-mark">*</span></label>
                                    <SelectDropdown id="gender"
                                     value={gender} 
                                     onChange={(value) =>setGender(value)}>
                                        {/* <option selected="">Please choose...</option> */}
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>

                                    </SelectDropdown>
                                    <p className={
                                        gender === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="date" className="form-label">Date of Birth <span className="required-mark">*</span></label>
                                    <input type="date" name="date" className="form-control form-control-lg" id="date"
                                        placeholder="Select date"
                                        value={dob}
                                        onChange={(e)=> setDob(e.target.value)} />
                                        <p className={
                                        dob === "" && loginError
                                            ? "text-danger"
                                            : "d-none"
                                    }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="status" className="form-label">Status <span className="required-mark">*</span></label>
                                    <SelectDropdown className="form-select form-select-lg " id="verifystatus" 
                                    value={status}
                                     onChange={(value) => setStatus(value)}>
                                        {/* <option >Please Choose...</option> */}
                                        <option selected={loginverified === 0 ? true : false} value="pending">Pending</option>
                                        <option selected={loginverified === 1 ? true : false} value="approved">Approved</option>
                                        <option selected={loginverified === 2 ? true : false} value="blocked">Blocked</option>

                                    </SelectDropdown>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="marketing" className="form-label">Marketing Name<span className="required-mark">*</span></label>
                                    <SelectDropdown id="marketingid"
                                     value={marketing}
                                      onChange={(value) => setMarketing(value)}>
                                        {/* <option selected="">Please choose...</option> */}
                                        
                                        {marketingList?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                
                                    </SelectDropdown>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUpdateUser}
                                 disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Navigate, Outlet } from 'react-router-dom';


// ----------------------------------------------------------------------

export const SimpleLayout = () => {
  // const {token} = UserAuth();
let token = localStorage.getItem("token");



  if (token) {

    return <Navigate to="/admin/dashboard"/>;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllGroup } from "../../../api/group";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const GroupList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


 //get Group list
 const { data: allGroupDetails } = useGetdata(
    "allGroupDetails",
    getAllGroup
);

    const headers = [ 
        {label: "ID", key:"id", sortable: true},
        {label: "Name", key:"name", sortable: true},
        {label: "MT5 Group", key:"mtgroup", sortable: true},
        {label: "Status", key:"status", sortable: true},
        {label: "Date", key:"createdAt", sortable: true},
        {label: "Action", sortable: false},
    ]

    const filteredData = allGroupDetails?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.mtgroup && item.mtgroup.toLowerCase().includes(searched.toLowerCase())
    )

    //set permission
const{data:checkPermission, refetch} = useGetdata(
    "checkPermission",
    GetPermissionCheck
  )
  
  let token = localStorage.getItem('token');
  useEffect(()=>{
  if(token) {
  refetch()
  }
  },[token, refetch])
  
    return (
        <>
            <Helmet><title>Group List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Group List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                                <DataTable
                                                data={filteredData}
                                                headers={headers}
                                                renderRow={(rowData) => (
                                                        <>
                                                <td>{rowData.name}</td>
                                                <td>{rowData.mtgroup}</td>
                                                <td>{rowData.status === 0 ? <strong className="text-warning">Inactive</strong> :
                                                <strong className="text-success">Active</strong>}</td>
                                                <td>{formatDate(rowData.createdAt)}</td>
                                                <td>
                                                {
                                                    checkPermission?.typeAdmin === 1 &&
                                                    <Link to={`/admin/group/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link>} &nbsp;
                                                         {
                                                            checkPermission?.typeAdmin === 0 &&
                                                            checkPermission?.data?.includes("edit_group") ?
                                                            <Link to={`/admin/group/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit</Link> : ""} &nbsp;
                                                    
                                                </td>
                                                </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
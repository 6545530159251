import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../../context/AppContext";
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useState } from "react";
import { GetSingleLiveAccount, getLiveAccountData } from "../../../api/account";
import { StyleModal } from "../../../component/StyleModal";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { message, Spin } from "antd";


export const MT5AccountList = () => {
  const { sidebarOpen } = useAppContext();
  const [searched, setSearched] = useState("");
  const [open, setOpen] = useState(false);
  const [singleAccount, setSingleAccount] = useState({});

  //get All live accounts
  const { data: allLiveAccounts, isLoading } = useGetdata(
    "allLiveAccounts",
    getLiveAccountData
  );

  const headers = [
    { label: "ID", key: 'id', sortable: true },
    { label: "MT5 ID", key: 'account_no', sortable: true },
    { label: "Name", key: 'contactname', sortable: true },
    { label: "Email", key: 'email', sortable: true },
    { label: "Investor Password", key:"investor_password", sortable:true },
    { label: "Main Password", key:"main_password", sortable:true },
    { label: "Account Type", key: 'meta_trade_type', sortable: true },
    { label: "Group", key: 'groupname', sortable: true },
    { label: "Leverage", key: 'leverage', sortable: true },
    { label: "Registration Date", key: 'createdAt', sortable: true },
    { label: "Action", sortable: false },
  ]


  const ShowDetailsModal = async (id) => {
    try {
    let response = await GetSingleLiveAccount({ id });
    if(response.status === 200){
      setSingleAccount(response?.data);
      setOpen(true);
    }
  }
  catch(error){
    message.error(error.message)
  }
  };

  const hideModal = () => {
    setOpen(false);
  };

  const filteredData = allLiveAccounts?.data?.filter(
    (item) =>
      // index + 1.toString().includes(searched) ||
    item.contactname && item.contactname.toLowerCase().includes(searched.toLowerCase()) ||
    item.account_no && item.account_no.toString().includes(searched) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.groupname && item.groupname.toLowerCase().includes(searched.toLowerCase())
  );


  //export to pdf
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      "ID",
      "MT5 ID",
      "Name",
      "Email",
      "Account Type",
      "Group",
      "Leverage",
      "Registration Date",
    ];

    const rows = data?.map((item, index) => [
      index + 1,
      item.account_no,
      item.contactname,
      item.email,
      item.meta_trade_type,
      item.groupname,
      item.leverage,
      formatDate(item.createdAt),
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
      },
      styles: {
        fontSize: 10,
        font: "Gilroy",
      },
    });
    doc.save("Mt5 User List.pdf");
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        "ID",
        "MT5 ID",
        "Name",
        "Email",
        "Account Type",
        "Group",
        "Leverage",
        "Registration Date",
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "Mt5 User List.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }

    const dataForExport = data?.map((item, index) => ({
      "ID": index + 1,
      "MT5 ID": item.account_no,
      "Name": item.contactname,
      "Email": item.email,
      "Account Type": item.meta_trade_type,
      "Group": item.groupname,
      "Leverage": item.leverage,
      "Registration Date": formatDate(item.createdAt),
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const filename = "MT5 User List.xlsx";

    XLSX.writeFile(wb, filename);
  };

  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Helmet>
        <title>MT5 User List | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>MT5 User List</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="bg-dark pb-2 rounded-3 box-shadow table-rounded">
                <div className="table-responsive-lg p-3">
                  <div className="row mb-3 dataTables_filter">
                    <div className="col-sm-12 d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToPDF(allLiveAccounts?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;PDF
                        </button>
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => exportToExcel(allLiveAccounts?.data)}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;EXCEL
                        </button> &nbsp;
                      </div>
                      <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input
                          type="search"
                          className="bg-dark"
                          value={searched}
                          onChange={(e) => setSearched(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <DataTable
                  data={filteredData}
                    headers={headers}
                    renderRow={(rowData) => (
                      <>
                        <td>{rowData.account_no}</td>
                        <td>{rowData.contactname}</td>
                        <td>{rowData.email}</td>
                        <td>{rowData.investor_password}</td>
                        <td>{rowData.main_password}</td>
                        <td>{rowData.meta_trade_type}</td>
                        <td>{rowData.groupname}</td>
                        <td> {rowData.leverage}</td>
                        <td>{formatDate(rowData.createdAt)}</td>
                        <td><button
                          className="btn btn-primary btn-xs mb-1"
                          onClick={() => ShowDetailsModal(rowData?.id)}
                        >
                          Details
                        </button>
                        </td>
                      </>
                    )}
                    /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <StyleModal
          title="Account Details"
          open={open}
          width={800}
          onCancel={hideModal}
          centered={false}
        >
          <div className="row p-3">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountlogin pb-3">
                <span className="text-white-50">Login : </span>
                <strong className="text-white">
                  {singleAccount?.account_no}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountcurrency pb-3">
                <span className="text-white-50">Currency Digits : </span>
                <strong className="text-white">
                  {singleAccount?.CurrencyDigits}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountdate pb-3">
                <span className="text-white-50">Created At :</span>
                <strong className="text-white">
                  {formatDate(singleAccount?.createdAt)}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountbalance pb-3">
                <span className="text-white-50">Balance : </span>
                <strong className="text-white">
                  {parseFloat(singleAccount?.Balance?.replace(/,/g,'')).toFixed(2) || 0}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountmarginlevg pb-3">
                <span className="text-white-50">Margin Leverage : </span>
                <strong className="text-white">
                  {singleAccount?.MarginLeverage || 0}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountleverage pb-3">
                <span className="text-white-50">Leverage : </span>
                <strong className="text-white">
                  {singleAccount?.leverage}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountcredit pb-3">
                <span className="text-white-50">Credit : </span>
                <strong className="text-white">
                {parseFloat(singleAccount?.Credit?.replace(/,/g,'')).toFixed(2) || 0}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountprofit pb-3">
                <span className="text-white-50">Profit : </span>
                <strong className="text-white">{singleAccount?.Profit || 0}</strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountassets pb-3">
                <span className="text-white-50">Assets :</span>
                <strong className="text-white"> {singleAccount?.Assets}</strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountmargin pb-3">
                <span className="text-white-50">Margin : </span>
                <strong className="text-white"> {singleAccount?.Margin || 0}</strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountequity pb-3">
                <span className="text-white-50">Equity :</span>
                <strong className="text-white">
                  {" "}
                  {parseFloat(singleAccount?.Equity?.replace(/,/g,'')).toFixed(2) || 0}
                </strong>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="accountequitypevday pb-3">
                <span className="text-white-50">Equity Pev Day : </span>
                <strong className="text-white">
                  {" "}
                  {parseFloat(singleAccount?.EquityPrevDay?.replace(/,/g,'')).toFixed(2) || 0}
                </strong>
              </div>
            </div>
          </div>
        </StyleModal>
      </div>
      <Footer />
            <BackToTopPage />
    </>
  );
};

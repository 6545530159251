import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import {  useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../api/user";
import { Image, message } from "antd";
import { getLiveAccountData } from "../../../api/account";
import { AddClientDeposit } from "../../../api/deposit";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const ClientDeposit = () => {
    const[userId, setUserId]= useState("");
    const [amount, setAmount] = useState("")
    const [depositTo, setDepositTo] = useState("")
    const [note, setNote] = useState("")
    const [depositproof, setDepositProof] = useState(null)
    const [loginError, setLoginError] = useState("")
    const[fileDataUrl, setFileDataUrl] = useState(null)
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    const handleDepositProof = (e) => {
        const file = e.target.files[0];
        setDepositProof(file);

        if (file) {
            setDepositProof(file);
            const objectUrl = URL.createObjectURL(file);
            setFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )

    //get live account
const {data:liveAccounts} = useGetdata(
    "liveAccounts",
    getLiveAccountData
    )
    const filterliveAccounts = liveAccounts?.data?.filter(el=> el.user_id == userId)

        //add client deposit
        const quericlient = useQueryClient();
        const addDeposit = useMutation(AddClientDeposit, {
            onSuccess: (data) => {
                if (data?.status === 200) {
                    message.success("Deposit Added Successfully!");
                    quericlient.invalidateQueries("approveDepositList");
                    setDisable(false)
                    // navigate("/admin/transaction/approvedeposit")
                    setUserId("");
                    setDepositTo("");
                    setAmount("");
                    setNote("")
                }
    
            },
            onError: (data) => {
                 setDisable(false)
                if (data?.response?.data.status === 400) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 403) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 404) {
                    message.error(data?.response?.data.message);
                }
                if (data?.response?.data.status === 500) {
                    message.error(data?.response?.data.message);
                }
    
                if (data?.response?.data.status === 502) {
                    message.error(data?.response?.data.message);
                }
    
    
            },
        });
    
    
        const handleAddClientDeposit = (e) => {
                e.preventDefault();
                setDisable(true)
            if (
                amount === "" ||
                userId === "" ||
                depositTo === "" ||
                note === "" ||
            depositproof === "") {
                setLoginError("Please Fill the above field.");
                setDisable(false)
                return;
            }
    
           const formData = new FormData()
           formData.append('userId', userId);
           formData.append('depositTo', depositTo);
           formData.append('note', note);
           formData.append('depositproof', depositproof);
           formData.append('amount', amount);

    
            addDeposit.mutate(formData);
            }

    return (
        <>
            <Helmet><title>Client Deposit | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Client Deposit</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="userId" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="userId"
                                    value={userId}
                                     onChange={(value) => setUserId(value)}>
                                        {/* <option selected="">Please choose...</option> */}

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="depositTo" className="form-label">Deposit To <span className="required-mark">*</span></label>
                                    <SelectDropdown id="depositTo"
                                    value={depositTo}
                                     onChange={(value) => setDepositTo(value)}>
                                        <option selected="">Please choose...</option>
                                        <option value="wallet">Wallet</option>
                                        {
                                                filterliveAccounts?.map((data)=>(
                                                    <option key={data.id} value={data.account_no}>{data.contactname} &nbsp;({data.email}) &nbsp;({data.account_no})</option>
                                                ))
                                            }
                                    </SelectDropdown>
                                    <p className={
                                    depositTo.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4 depositproof">
                                    <label for="depositproof" className="form-label">Deposit Proof <span className="required-mark">*</span></label>
                                    <input className="form-control form-control-lg bg-secondary" id="depositproof" name="depositproof"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleDepositProof} />
                            {
                                fileDataUrl &&
                                <Image src={fileDataUrl}
                                className="mt-2"
                                height={70}
                                width={100} />
                            }
                                    <p className={
                                    depositproof === null && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="amount" className="form-label">Amount <span className="required-mark">*</span></label>
                                    <input type="text" name="amount" className="form-control form-control-lg "
                                        id="amount" placeholder="Enter amount" value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <p className={
                                    amount.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                <label for="note" className="form-label">Admin Comment <span className="required-mark">*</span></label>
                                    <input type="text" name="note" className="form-control form-control-lg "
                                        id="note" placeholder="Enter comment" value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                    <p className={
                                    note.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleAddClientDeposit}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
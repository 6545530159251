import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { Login } from './section/auth/Login';
import { SimpleLayout } from './layout/simpleLayout/SimpleLayout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from './component/ProtectedRoute';
import { AdminDashboard } from './section/@dashboard/dashboard/AdminDashboard';
import { AppProvider } from './context/AppContext';
import { UnreadNotificationList } from './section/@dashboard/notifications/UnreadNotifications';
import { ReadNotificationList } from './section/@dashboard/notifications/ReadNotificationList';
import { ChangePassword } from './section/auth/ChangePassword';
import { AddUser } from './section/@dashboard/user/AddUser';
import { UserList } from './section/@dashboard/user/UserList';
import { EditUser } from './section/@dashboard/user/EditUser';
import { UserDetails } from './section/@dashboard/user/UserDetails';
import { UserSettings } from './section/@dashboard/user/UserSettings';
import { CreateMT5Account } from './section/@dashboard/account/CreateMt5Account';
import { UserFollowUpList } from './section/@dashboard/user/UserFollowUpList';
import { PendingDocumentList } from './section/@dashboard/documents/PendingDocumentList';
import { UserDocumentDetailList } from './section/@dashboard/documents/UserDocumentDetailList';
import { ApprovedDocumentList } from './section/@dashboard/documents/ApproveDocumentList';
import { UserDocumentList } from './section/@dashboard/documents/UserDocumentList';
import { UploadUserDocuments } from './section/@dashboard/documents/UploadUserDocument';
import { AddBankDetails } from './section/@dashboard/bankDetails/AddBankDetails';
import { BankDetailsList } from './section/@dashboard/bankDetails/BankDetailList';
import { UserPasswordList } from './section/@dashboard/user/UserPasswordList';
import { ChangeUserPassword } from './section/@dashboard/user/ChangeUserPassword';
import { ChangeMT5Password } from './section/@dashboard/account/ChangeMT5Password';
import { SendMailVerifications } from './section/@dashboard/emailVerifications/SendMailVerifications';
import { ResendMT5DataMail } from './section/@dashboard/emailVerifications/ResendMT5DataMail';
import { MT5AccountList } from './section/@dashboard/account/MT5AccountList';
import { IBUsers } from './section/@dashboard/ib/IBUsers';
import { IBClients } from './section/@dashboard/ib/IBClients';
import { IBRequestsList } from './section/@dashboard/ib/IBRequestsList';
import { IBPlan } from './section/@dashboard/ib/IBPlan';
import { AddIBPlan } from './section/@dashboard/ib/AddIBPlan';
import { CommissionGroupList } from './section/@dashboard/ib/CommissionGroupList';
import { EditCommissionGroup } from './section/@dashboard/ib/EditCommissionGroup';
import { AddCommissionGroup } from './section/@dashboard/ib/AddCommissionGroup';
import { UserCommissionList } from './section/@dashboard/ib/UserCommissionList';
import { AddUserCommission } from './section/@dashboard/ib/AddUserCommission';
import { EditUserCommission } from './section/@dashboard/ib/EditUserCommission';
import { MoveClientToIB } from './section/@dashboard/ib/MoveClientToIB';
import { AddGroup } from './section/@dashboard/group/AddGroup';
import { GroupList } from './section/@dashboard/group/GroupList';
import { EditGroup } from './section/@dashboard/group/EditGroup';
import { ClientDeposit } from './section/@dashboard/transaction/ClientDeposit';
import { ClientWithdraw } from './section/@dashboard/transaction/withdraw/ClientWithdraw';
import { InternalTransfer } from './section/@dashboard/transaction/transfer/InternalTransfer';
import { PendingDeposit } from './section/@dashboard/transaction/PendingDepositList';
import { PendingWithdraw } from './section/@dashboard/transaction/withdraw/PendingWithdraw';
import { SendCustomEmail } from './section/@dashboard/emailVerifications/SendCustomEmail';
import { DepositReport } from './section/@dashboard/reports/DepositReport';
import { WithdrawReport } from './section/@dashboard/reports/WithdrawReport';
import { TransferReport } from './section/@dashboard/reports/TransferReport';
import { TicketList } from './section/@dashboard/tickets/TicketList';
import { TicketDetails } from './section/@dashboard/tickets/TicketDetails';
import { CreateSubAdmin } from './section/@dashboard/subadmin/CreateSubAdmin';
import { SubAdminList } from './section/@dashboard/subadmin/SubAdminList';
import { AddSubAdminPermission } from './section/@dashboard/subadmin/AddPermission';
import { EditSubAdminPermission } from './section/@dashboard/subadmin/EditPermission';
import { BannerList } from './section/@dashboard/settings/banner/BannerList';
import { AddBanner } from './section/@dashboard/settings/banner/AddBanner';
import { SystemSettings } from './section/@dashboard/settings/SystemSettings';
import { DefaultSettings } from './section/@dashboard/settings/DefaultSettings';
import { ManagementDashboard } from './section/@dashboard/managementDashboard/ManagementDashboard';
import { GiveBonus } from './section/@dashboard/bonus/GiveBonus';
import { RemoveBonus } from './section/@dashboard/bonus/RemoveBonus';
import { BonusList } from './section/@dashboard/bonus/BonusList';
import { ExchangerList } from './section/@dashboard/exchanger/ExchangerList';
import { AddExchanger } from './section/@dashboard/exchanger/AddExchanger';
import { EditExchanger } from './section/@dashboard/exchanger/EditExchanger';
import { ExchangerTransfer } from './section/@dashboard/exchanger/ExchangerTransfer';
import { ExchangerDeposit } from './section/@dashboard/exchanger/ExchangerDeposit';
import { ExchangerWithdraw } from './section/@dashboard/exchanger/ExchangerWithdraw';
import { IBExchangerWithdraw } from './section/@dashboard/exchanger/IBExchangerWithdraw';
import { NewsList } from './section/@dashboard/news/NewsList';
import { AddNews } from './section/@dashboard/news/AddNews';
import { RewardList } from './section/@dashboard/rewards/RewardList';
import { AddReward } from './section/@dashboard/rewards/AddReward';
import { NetProfitReport } from './section/@dashboard/riskManagementReport/ProfitRiskReport';
import { ScalpingReports } from './section/@dashboard/riskManagementReport/ScalpingReport';
import { AddExistingClient } from './section/@dashboard/user/AddExistingUser';
import { IBUserCommissionListReport } from './section/@dashboard/ib/IBUserCommissionListReport';
import { PendingIBWithdraw } from './section/@dashboard/ib/PendingIBWithdrawList';
import { AddIBWithdraw } from './section/@dashboard/ib/AddIBWithdraw';
import { IBTreeChart } from './section/@dashboard/ib/IBTreeChart';
import { Page404 } from './section/auth/pages/Page404';
import { AddMarketing } from './section/@dashboard/marketing/AddMarketing';
import { MarketingList } from './section/@dashboard/marketing/MarketingList';
import { EditMarketing } from './section/@dashboard/marketing/EditMarketing';
import { IncentiveReportList } from './section/@dashboard/marketing/IncentiveReport';
import { MarketingWithdrawReport } from './section/@dashboard/marketing/MarketingWithdrawReport';
import { IBWithdrawReport } from './section/@dashboard/reports/IBWithdrawReport';
import { WalletHistoryReport } from './section/@dashboard/reports/WalletHistoryReport';
import { PositionReport } from './section/@dashboard/reports/PositionReport';
import { LoginActivityReport } from './section/@dashboard/reports/LoginActivityReport';
import { UpdateMT5Leverage } from './section/@dashboard/account/UpdateMT5Leverage';
import { UpdateMT5Group } from './section/@dashboard/group/UpdateMT5Group';
import { AddSales } from './section/@dashboard/sales/AddSales';
import { SalesList } from './section/@dashboard/sales/SalesList';
import { EditSales } from './section/@dashboard/sales/EditSales';
import { LeadStatusList } from './section/@dashboard/lead/leadstatus/LeadStatusList';
import { LeadSourceList } from './section/@dashboard/lead/leadsource/LeadSource';
import { LeadList } from './section/@dashboard/lead/LeadList';
import { AddLead } from './section/@dashboard/lead/AddLead';
import { EditLead } from './section/@dashboard/lead/EditLead';
import { LeadDetails } from './section/@dashboard/lead/LeadDetails';
import { SalesDashboard } from './section/@dashboard/sales/SalesDashboard';
import { HistoryReport } from './section/@dashboard/reports/HistoryReport';
import { EditNews } from './section/@dashboard/news/EditNews';
import { EditBankDetail } from './section/@dashboard/bankDetails/EditBankDetails';
import { RemoveClientToIB } from './section/@dashboard/ib/RemoveClientToIB';
import { DemoteIB } from './section/@dashboard/ib/DemoteIB';
import { MoveIBToIB } from './section/@dashboard/ib/MoveIBToIb';
import { MarketingClients } from './section/@dashboard/marketing/MarketingClients';
import { MarketingTeamDeposit } from './section/@dashboard/marketing/MarketingTeamDeposit';
import { MarketingTeamWithdraw } from './section/@dashboard/marketing/MarketingTeamWithdraw';
import { useEffect } from 'react';
import { EditIBPlan } from './section/@dashboard/ib/EditIBPlan';


function App() {
  const navigate = useNavigate();
  
  // let session = sessionStorage.getItem("loggedin");
  // function clearStorage(e) {
  //   if (session == null) {
  //     localStorage.clear();
  //     navigate("/");
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener("load", clearStorage);

  //   return () => {
  //     window.removeEventListener("load", clearStorage);
  //   };

  // }, [])

  let session = sessionStorage.getItem("loggedin");
  let token = localStorage.getItem("token");
  const clearStorage = () => {
    sessionStorage.clear(session);
    localStorage.clear(token);
  };

  useEffect(() => {
   
    // Set interval to clear storage every 2 hours (7200000 milliseconds)
    const interval = setInterval(() => {
      clearStorage();
    }, 7200000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


    return (
    <div>
      <AppProvider>
      <HelmetProvider>
      <Routes>
              <Route element={<SimpleLayout />}>
                <Route path='/' element={<Navigate to='/admin/login' />} />
                <Route path='/admin/login' element={<Login />} />
                </Route>

                <Route  element={<ProtectedRoute />}>
            <Route path='/admin/dashboard' element={<AdminDashboard />} />
            <Route path='/admin/managementreport' element={<ManagementDashboard />} />
            <Route path='/admin/bankdetails' element={<AdminDashboard />} />
            <Route path='/admin/changePassword' element={<ChangePassword />} />

            {/* user */}
            <Route path='/admin/user/add' element={<AddUser />} />
            <Route path='/admin/user/userlist' element={<UserList />} />
            <Route path='/admin/user/edit/:id' element={<EditUser />} />
            <Route path='/admin/user/view/:id' element={<UserDetails />} />
            <Route path='/admin/user/settings/:id' element={<UserSettings />} />
            <Route path='/admin/user/followuplist' element={<UserFollowUpList />} />
            <Route path='/admin/user/userpasswordlist' element={<UserPasswordList />} />
            <Route path='/admin/user/changeuserpassword' element={<ChangeUserPassword />} />
            <Route path='/admin/user/updateclientleverage' element={<UpdateMT5Leverage />} />
            <Route path='/admin/user/addexistingclient' element={<AddExistingClient />} />

            {/* bonus */}
            <Route path='/admin/bonus/givebonus' element={<GiveBonus />} />
            <Route path='/admin/bonus/removebonus' element={<RemoveBonus />} />
            <Route path='/admin/bonus/bonuslist' element={<BonusList />} />

            {/* exchanger */}
            <Route path='/admin/exchanger/exchangerlist' element={<ExchangerList />} />
            <Route path='/admin/exchanger/addexchanger' element={<AddExchanger />} />
            <Route path='/admin/exchanger/edit/:id' element={<EditExchanger />} />
            <Route path='/admin/exchanger/transfer' element={<ExchangerTransfer />} />
            <Route path='/admin/exchanger/depositreport' element={<ExchangerDeposit />} />
            <Route path='/admin/exchanger/withdrawreport' element={<ExchangerWithdraw />} />
            <Route path='/admin/exchanger/ibwithdrawreport' element={<IBExchangerWithdraw />} />

            {/* news */}
            <Route path='/admin/news/newslist' element={<NewsList />} />
            <Route path='/admin/news/addnews' element={<AddNews />} />
            <Route path='/admin/news/edit/:id' element={<EditNews />} />

            {/* reward */}
            <Route path='/admin/rewards/rewardlist' element={<RewardList />} />
            <Route path='/admin/rewards/addreward' element={<AddReward />} />

            {/* risk management  */}
            <Route path='/admin/netprofitreport' element={<NetProfitReport />} />
            <Route path='/admin/netscalpingreport' element={<ScalpingReports />} />

            {/* kyc */}
            <Route path='/admin/user/pendingdocumentlist' element={<PendingDocumentList />} />
            <Route path='/admin/user/userdocumentdetaillist/:id' element={<UserDocumentDetailList />} />
            <Route path='/admin/user/approvedocumentlist' element={<ApprovedDocumentList />} />
            <Route path='/admin/user/userdocuments/:id' element={<UserDocumentList />} />
            <Route path='/admin/user/uploaduserdocument' element={<UploadUserDocuments />} />


          {/* bank details */}
          <Route path='/admin/user/addbankdetails' element={<AddBankDetails />} />
          <Route path='/admin/user/addbankdetails/edit/:id' element={<EditBankDetail />} />
          <Route path='/admin/user/bankdetailslist' element={<BankDetailsList />} />


          {/* account */}
          <Route path='/admin/user/createmt5account' element={<CreateMT5Account />} />
          <Route path='/admin/user/mt5userlist' element={<MT5AccountList />} />
          <Route path='/admin/user/changemt5password' element={<ChangeMT5Password />} />

          {/* ib */}
          <Route path='/admin/ib/ibuserlist' element={<IBUsers />} />
          <Route path='/admin/ib/ibclients/:id' element={<IBClients />} />
          <Route path='/admin/ib/ibRequestlist' element={<IBRequestsList />} />
          <Route path='/admin/ib/ibplanlist' element={<IBPlan />} />
          <Route path='/admin/ib/addibplan' element={<AddIBPlan />} />
          <Route path='/admin/ib/editibplan/:id' element={<EditIBPlan />} />
          <Route path='/admin/ib/commissiongrouplist' element={<CommissionGroupList />} />
          <Route path='/admin/ib/editcommissiongroup/:id' element={<EditCommissionGroup />} />
          <Route path='/admin/ib/addcommissiongroup' element={<AddCommissionGroup />} />
          <Route path='/admin/ib/usercommissionlist' element={<UserCommissionList />} />
          <Route path='/admin/ib/addusercommission' element={<AddUserCommission />} />
          <Route path='/admin/ib/editusercommission/:id' element={<EditUserCommission />} />
          <Route path='/admin/ib/moveclientunderib' element={<MoveClientToIB />} />
          <Route path='/admin/ib/removeclientunderib' element={<RemoveClientToIB />} />
          <Route path='/admin/ib/demoteib' element={<DemoteIB />} />
          <Route path='/admin/ib/moveibtoib' element={<MoveIBToIB />} />
          <Route path='/admin/ib/mycommissionlist/:id' element={<IBUserCommissionListReport />} />
          <Route path='/admin/ib/treechart/:id' element={<IBTreeChart />} />


        {/* group */}
        <Route path='/admin/group/add' element={<AddGroup />} />
        <Route path='/admin/group/grouplist' element={<GroupList />} />
        <Route path='/admin/group/edit/:id' element={<EditGroup  />} />
        <Route path='/admin/group/updateclientgroup' element={<UpdateMT5Group  />} />

        {/* transaction */}
        <Route path='/admin/transaction/clientdeposit' element={<ClientDeposit />} />
        <Route path='/admin/transaction/clientwithdraw' element={<ClientWithdraw />} />
        <Route path='/admin/transaction/ibwithdraw' element={<AddIBWithdraw />} />
        <Route path='/admin/transaction/internaltransfer' element={<InternalTransfer />} />
        <Route path='/admin/transaction/pendingdeposit' element={<PendingDeposit />} />
        <Route path='/admin/transaction/pendingwithdraw' element={<PendingWithdraw />} />
        <Route path='/admin/transaction/pendingibwithdraw' element={<PendingIBWithdraw />} />


      {/* marketing */}
      <Route path='/admin/marketing/add' element={<AddMarketing />} />
      <Route path='/admin/marketing/marketinglist' element={<MarketingList />} />
      <Route path='/admin/marketing/edit/:id' element={<EditMarketing />} />
      <Route path='/admin/marketing/incentivereport' element={<IncentiveReportList />} />
      <Route path='/admin/marketing/marketingwithdrawlist' element={<MarketingWithdrawReport />} />
      <Route path='/admin/marketing/clients/:id' element={<MarketingClients />} />
      <Route path='/admin/marketing/teamdeposit/:id' element={<MarketingTeamDeposit />} />
      <Route path='/admin/marketing/teamwithdraw/:id' element={<MarketingTeamWithdraw />} />

        {/* sales */}
        <Route path='/admin/sales/home' element={<SalesDashboard />} />
        <Route path='/admin/sales/add' element={<AddSales />} />
        <Route path='/admin/sales/saleslist' element={<SalesList />} />
        <Route path='/admin/sales/edit/:id' element={<EditSales />} />
        <Route path='/admin/sales/leads/leadstatuslist' element={<LeadStatusList />} />
        <Route path='/admin/sales/leads/leadsourcelist' element={<LeadSourceList />} />
        <Route path='/admin/sales/leads/leadlist' element={<LeadList />} />
        <Route path='/admin/sales/leads/addleads' element={<AddLead />} />
        <Route path='/admin/sales/leads/editleads/:id' element={<EditLead />} />
        <Route path='/admin/sales/leads/viewleads/:id' element={<LeadDetails />} />
        

        {/* report */}
        <Route path='/admin/report/depositreport' element={<DepositReport />} />
        <Route path='/admin/report/withdrawreport' element={<WithdrawReport />} />
        <Route path='/admin/report/transferreport' element={<TransferReport />} />
        <Route path='/admin/report/ibwithdrawreport' element={<IBWithdrawReport />} />
        <Route path='/admin/report/wallethistoryreport' element={<WalletHistoryReport />} />
        <Route path='/admin/report/positionreport' element={<PositionReport />} />
        <Route path='/admin/report/historyreport' element={<HistoryReport />} />
        <Route path='/admin/report/loginactivity' element={<LoginActivityReport />} />

        {/* ticket */}
        <Route path='/admin/ticket/ticketlist' element={<TicketList />} />
        <Route path='/admin/ticket/:ticket_id' element={<TicketDetails />} />

        {/* subadmin */}
        <Route path='/admin/subadmin/createsubadmin' element={<CreateSubAdmin />} />
        <Route path='/admin/subadmin/subadminlist' element={<SubAdminList />} />
        <Route path='/admin/subadmin/permission/add/:id' element={<AddSubAdminPermission />} />
        <Route path='/admin/subadmin/permission/edit/:id' element={<EditSubAdminPermission />} />

          {/* email */}
        <Route path='/admin/sendemail' element={<SendCustomEmail />} />
          <Route path='/admin/user/resendmailverification' element={<SendMailVerifications />} />
          <Route path='/admin/user/resendmt5datamail' element={<ResendMT5DataMail />} />

          {/* notification */}
            <Route path='/admin/notification/unreadnotification' element={<UnreadNotificationList />} />
            <Route path='/admin/notification/readnotification' element={<ReadNotificationList />} />

            {/* settings */}
            <Route path='/admin/settings/bannerlist' element={<BannerList />} />
            <Route path='/admin/settings/addbanner' element={<AddBanner />} />
            <Route path='/admin/settings/systemsetting' element={<SystemSettings />} />
            <Route path='/admin/settings/defaultsetting' element={<DefaultSettings />} />
            {/* <Route path='/admin/settings/paymentsetting' element={<AllPaymentSettings />} /> */}
            {/* <Route path='/admin/settings/updatedefaultsetting' element={<UpdateDefaultSettings />} /> */}
            </Route>
            <Route path='/*' element={<Page404 />} />
          </Routes>
      </HelmetProvider>
        </AppProvider>
    </div>
  );
}

export default App;

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import {  useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Image, message } from "antd";
import { AddIBCount, AddMoreSystemSettings, GetSystemSetting, UploadEmailLogo, UploadFaviconLogo, UploadLogo, UploadWhiteLogo } from "../../../api/systemSettings";
import useGetdata from "../../../hooks/useGetData";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { getAllGroup } from "../../../api/group";

export const SystemSettings = () => {
    const [mainlogo, setMainLogo] = useState("");
    const [whitelogo, setWhiteLogo] = useState("");
    const [emaillogo, setEmailLogo] = useState("");
    const [favicon, setFavicon] = useState("")
    const [ibcount, setIbCount] = useState("")
    const[googleclientId, setGoogleClientID] = useState("");
    const[googleSecretKey, setGoogleSecretKey] = useState("");
    const[chatbotid, setChatBotID] = useState("");
    const[ibtouserwallet, setIBToUserWallet] = useState(0);
    const[crmnumber, setCrmNumber] = useState("");
    const[crmemail, setCrmEmail] = useState("");
    const[desktopurl, setDesktopUrl]=useState("");
    const[iosurl, setIosUrl]=useState("");
    const[androidurl, setAndroidUrl]=useState("");
    const[footerText, setFooterText]=useState("");
    const[loginText, setLoginText]=useState("");
    const[registerText, setRegisterText]=useState("");
    const[kycregister, setKycRegister]=useState("");
    const[ibtermsandcondition, setIbTermsAndConditions]=useState("");
    const[accountname, setAccountName]=useState("");
    const[accountno, setAccountNo]=useState("");
    const[bankname, setBankName]=useState("");
    const[ifsccode, setIfscCode]=useState("");
    const[defaultgroup, setDefaultGroup]=useState("");
    const[defaultleverage, setDefaultLeverage]=useState("");
    const[disable, setDisable] = useState(false)


    const handleIBToWalletchange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setIBToUserWallet(newValue);
    }

    const { sidebarOpen } = useAppContext();

    const { data: getSettings, refetch, isLoading:userDLoad } = useGetdata(
        "getSettings",
        GetSystemSetting
    )

    //get groups
   
 //get Group list
 const { data: allGroupDetails } = useGetdata(
    "allGroupDetails",
    getAllGroup
);


    const handleChangeMainLogo = (e) => {
        const file = e.target.files[0];
        setMainLogo(file);
    };

    const handleChangeWhiteLogo = (e) => {
        const file = e.target.files[0];
        setWhiteLogo(file);
    };

    const handleChangeEmailLogo = (e) => {
        const file = e.target.files[0];
        setEmailLogo(file);
    };

    const handleChangeFaviconLogo = (e) => {
        const file = e.target.files[0];
        setFavicon(file);
    };

    // add main logo
    const queryclient = useQueryClient();
    const addmainLogo = useMutation(UploadLogo, {
        onSuccess: (data) => {
            if (data.status === 200) {
                message.success("Main Logo Added Successfully!");
                queryclient.invalidateQueries("getSettings")
                refetch()
                setMainLogo("");
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 401) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 409) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
        },
    });

    const handleAddMainLogo = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('mainlogo', mainlogo);
        addmainLogo.mutate(formData);
    }

 // add White logo
 const addWhiteLogo = useMutation(UploadWhiteLogo, {
    onSuccess: (data) => {
        if (data.status === 200) {
            message.success("White Logo Added Successfully!");
            queryclient.invalidateQueries("getSettings")
            refetch()
            setWhiteLogo("");
        }

    },
    onError: (data) => {
        if (data?.response?.data.status === 401) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
    },
});

const handleAddWhiteLogo = (e) => {
    e.preventDefault();

    const whiteLogoData = new FormData();
    whiteLogoData.append('whitelogo', whitelogo);

    addWhiteLogo.mutate(whiteLogoData);
}

// add Email logo
const addEmailLogo = useMutation(UploadEmailLogo, {
    onSuccess: (data) => {
        if (data.status === 200) {
            message.success("Email Logo Added Successfully!");
            queryclient.invalidateQueries("getSettings")
            refetch()
            setEmailLogo("");
        }

    },
    onError: (data) => {
        if (data?.response?.data.status === 401) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
    },
});

const handleEmailLogo = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('emaillogo', emaillogo);

    addEmailLogo.mutate(formData);
}

// add favicon logo
const addfavicon = useMutation(UploadFaviconLogo, {
    onSuccess: (data) => {
        if (data.status === 200) {
            message.success("Favicon Logo Added Successfully!");
            queryclient.invalidateQueries("getSettings")
            refetch()
            setFavicon("");
        }

    },
    onError: (data) => {
        if (data?.response?.data.status === 401) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
    },
});

const handleFaviconLogo = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('favicon', favicon);

    addfavicon.mutate(formData);
}

//add ib count
const addCountIB = useMutation(AddIBCount, {
    onSuccess: (data) => {
        if (data.status === 200) {
            message.success("IB Count Added Successfully!");
            queryclient.invalidateQueries("getSettings")
            setDisable(false)
            refetch()
            setIbCount("");
        }
    },
    onError: (data) => {
        setDisable(false)
        if (data?.response?.data.status === 401) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
    },
});

useEffect(()=>{
    if(!userDLoad){
        setIbCount(getSettings?.data?.ibcount)
         setIBToUserWallet(getSettings?.data?.ibtouserwallet)
         setIbTermsAndConditions(getSettings?.data?.ibtermsandcondition)

    }
},[userDLoad, getSettings])

const handleAddIBCount = (e) => {
    e.preventDefault();
    setDisable(true)

    let data = {
        ibcount,
        ibtouserwallet,
        ibtermsandcondition
    }
    addCountIB.mutate(data);
}


//add more settings
const addmoresettings = useMutation(AddMoreSystemSettings, {
    onSuccess: (data) => {
        if (data.status === 200) {
            message.success("Settings Added Successfully!");
            queryclient.invalidateQueries("getSettings")
            setDisable(false)
            refetch()
        }
    },
    onError: (data) => {
        setDisable(false)
        if (data?.response?.data.status === 401) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
        }
        if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
        }
    },
});
useEffect(()=>{
    if(!userDLoad){
        setGoogleClientID(getSettings?.data?.googleclientid)
        setGoogleSecretKey(getSettings?.data?.googlesecretkey)
        setChatBotID(getSettings?.data?.chatbotid)
        setCrmNumber(getSettings?.data?.crmnumber)
        setCrmEmail(getSettings?.data?.crmemail)
        setDesktopUrl(getSettings?.data?.desktopurl)
        setIosUrl(getSettings?.data?.iosurl)
        setAndroidUrl(getSettings?.data?.androidurl)
        setFooterText(getSettings?.data?.footertext)
        setLoginText(getSettings?.data?.logintext)
        setRegisterText(getSettings?.data?.registertext)
        setKycRegister(getSettings?.data?.kyc_register)
        setAccountName(getSettings?.data?.accountname)
        setAccountNo(getSettings?.data?.accountno)
        setBankName(getSettings?.data?.bankname)
        setIfscCode(getSettings?.data?.ifsccode)
        setDefaultGroup(getSettings?.data?.defaultgroup)
        setDefaultLeverage(getSettings?.data?.defaultleverage)
        
    }
},[userDLoad, getSettings])

const handleAddMoreSettings = (e) => {
    e.preventDefault();
    setDisable(true)

    // if(crmemail === "" ||
    //     crmnumber === "") {
    //         setLoginError("Please fill above filed")
    //         setDisable(false)
    //         return;
    //     }

    let data = {
        googleclientId,
        googleSecretKey,
        chatbotid,
        crmnumber,
        crmemail,
        desktopurl,
        iosurl,
        androidurl,
        footerText,
        loginText,
        registerText,
        kycregister,
        accountname,
        accountno,
        ifsccode,
        bankname,
        defaultgroup,
        defaultleverage
        
    }
    addmoresettings.mutate(data);
}



    return (
        <>
            <Helmet><title>System Settings | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>System Setting</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                <label for="bannerimage" className="form-label">Upload Main Logo <span className="required-mark">*</span></label>
                                <div className="d-flex justify-content-start align-items-center gap-5">
                                <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                            type="file"
                            accept=".ico, .png"
                            onChange={handleChangeMainLogo} />
                                <button className="btn btn-primary btn-sm font-15" type="submit"
                                 onClick={handleAddMainLogo}>Submit</button> 
                                </div>
                                <div className="mt-3 mb-2">
                                {mainlogo ? (
                                <img
                                    src={URL.createObjectURL(mainlogo)}
                                    alt="Selected"
                                    style={{ height: '100px', width: '150px' }}
                                />
                            )
                                : (<Image src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/mainlogo.png`} alt="logo" Width={100} height={70}  />
                                )}
                                 </div>
                                 </div>
                                 <div className="mb-4">
                                <label for="bannerimage" className="form-label">Upload White Logo <span className="required-mark">*</span></label>
                                <div className="d-flex justify-content-start align-items-center gap-5">
                                <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                            type="file"
                            accept=".ico, .png"
                            onChange={handleChangeWhiteLogo} />
                                <button className="btn btn-primary btn-sm font-15" type="submit"
                                 onClick={handleAddWhiteLogo}>Submit</button> 
                                </div>
                                <div className="mt-3 mb-2">
                                {whitelogo ? (
                                <img
                                    src={URL.createObjectURL(whitelogo)}
                                    alt="Selected"
                                    style={{ height: '100px', width: '150px' }}
                                    className="mt-2 mb-2"
                                />
                            ) : (<Image src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/whitelogo.png`} alt="logo" Width={100} height={70} className="mt-2 mb-2" />
                            )}
                                </div>
                                 </div>
                                 <div className="mb-4">
                                <label for="bannerimage" className="form-label">Upload Email Logo <span className="required-mark">*</span></label>
                                <div className="d-flex justify-content-start align-items-center gap-5">
                                <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                            type="file"
                            accept=".ico, .png"
                            onChange={handleChangeEmailLogo} />
                                <button className="btn btn-primary btn-sm font-15" type="submit"
                                 onClick={handleEmailLogo}>Submit</button> 
                                </div>
                                <div className="mt-3 mb-2">
                                {emaillogo ? (
                                <img
                                    src={URL.createObjectURL(emaillogo)}
                                    alt="Selected"
                                    style={{ height: '100px', width: '150px' }}
                                    className="mt-2 mb-2"
                                />
                            ) : (<Image src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/emaillogo.png`} alt="logo" Width={100} height={70} className="mt-2 mb-2" />
                            )}
                                </div>
                                 </div>
                                 <div className="mb-4">
                                <label for="bannerimage" className="form-label">Upload Favicon Logo <span className="required-mark">*</span></label>
                                <div className="d-flex justify-content-start align-items-center gap-5">
                                <input className="form-control form-control-lg bg-secondary" id="bannerimage" name="bannerimage"
                            type="file"
                            accept=".ico, .png"
                            onChange={handleChangeFaviconLogo} />
                                <button className="btn btn-primary btn-sm font-15" type="submit"
                                 onClick={handleFaviconLogo}>Submit</button> 
                                </div>
                                <div className="mt-3 mb-2">
                                {favicon ? (
                                <img
                                    src={URL.createObjectURL(favicon)}
                                    alt="Selected"
                                    style={{ height: '100px', width: '150px' }}
                                    className="mt-2 mb-2"
                                />
                            ) : (<Image src={`${process.env.REACT_APP_API_URL}/images/systemsettingsUploads/favicon.ico`} alt="logo" Width={120} height={80} className="mt-2 mb-2" />
                            )}
                                </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <h3>IB Settings</h3>
                                <hr className="m-0" />
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                                type="checkbox" 
                                                onChange={handleIBToWalletchange} checked={ibtouserwallet === 1} />
                                            <label className="form-check-label">IB User To Wallet</label>
                                        </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mt-3">
                                <label for="ibcount" className="form-label">No. Of IB Levels <span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="ibcount" name="ibcount"
                            type="text"
                            value={ibcount} onChange={(e) => setIbCount(e.target.value)} disabled={getSettings?.data?.ibcount} />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mt-3">
                                <label for="ibtermsandcondition" className="form-label">IB Terms And Conditions<span className="required-mark">*</span></label>
                                <textarea className="form-control form-control-lg bg-secondary" id="ibtermsandcondition" name="ibtermsandcondition"
                                type="text"
                                placeholder="Enter terms and condition"
                                value={ibtermsandcondition} onChange={(e) => setIbTermsAndConditions(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-sm-2 mt-3">
                            <button className="btn btn-primary btn-sm font-15" type="submit" onClick={handleAddIBCount}
                            disabled={disable}>
                                 {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button> 
                            </div>
                                 <div className="row mt-4">
                                    <h3>CRM Settings</h3>
                                    <hr className="m-0" />
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="crmnumber" className="form-label">CRM Contact Number<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="crmnumber" name="crmnumber"
                                type="text"
                                value={crmnumber} onChange={(e) => setCrmNumber(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="crmemail" className="form-label">CRM Email<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="crmemail" name="crmemail"
                                type="text"
                                value={crmemail} onChange={(e) => setCrmEmail(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="desktopurl" className="form-label">Desktop URL<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="desktopurl" name="desktopurl"
                                type="text"
                                value={desktopurl} onChange={(e) => setDesktopUrl(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="iosurl" className="form-label">IOS URL<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="iosurl" name="iosurl"
                                type="text"
                                value={iosurl} onChange={(e) => setIosUrl(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="androidurl" className="form-label">Android URL<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="androidurl" name="androidurl"
                                type="text"
                                value={androidurl} onChange={(e) => setAndroidUrl(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="footertext" className="form-label">Login Text<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="footertext" name="footertext"
                                type="text"
                                placeholder="Enter login text"
                                value={loginText} onChange={(e) => setLoginText(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="footertext" className="form-label">Register Text<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="footertext" name="footertext"
                                type="text"
                                placeholder="Enter register text"
                                value={registerText} onChange={(e) => setRegisterText(e.target.value)} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="footertext" className="form-label">Footer Text<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="footertext" name="footertext"
                                type="text"
                                placeholder="Enter footer text"
                                value={footerText} onChange={(e) => setFooterText(e.target.value)} />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="kycregister" className="form-label">KYC Register<span className="required-mark">*</span></label>
                                        <SelectDropdown id="kycregister"
                                            value={kycregister}
                                            onChange={(value) => setKycRegister(value)}>
                                            {/* <option selected="">Please choose...</option> */}
                                                <option value="0">Only Access After Kyc</option>
                                                <option value="1">Access Except Withdraw On Kyc</option>
                                        </SelectDropdown>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                        <label for="defaultgroup" className="form-label">Default Group<span className="required-mark">*</span></label>
                                        <SelectDropdown id="defaultgroup"
                                            value={defaultgroup}
                                            onChange={(value) => setDefaultGroup(value)}>
                                                { allGroupDetails?.data?.map((data) => (
                                                    <option key={data.id} value={data.name}>{data.name}</option>
                                                ))
                                            }
                                        </SelectDropdown>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                        <label for="defaultleverage" className="form-label">Default Leverage<span className="required-mark">*</span></label>
                                        <SelectDropdown id="defaultleverage"
                                            value={defaultleverage}
                                            onChange={(value) => setDefaultLeverage(value)}>
                                            {/* <option selected="">Please choose...</option> */}
                                                <option value="500">500</option>
                                        </SelectDropdown>
                                        </div>
                            </div>
                            <div className="row mt-3">
                                <h3>Bank Details</h3>
                                <hr className="m-0" />
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="accountname" className="form-label">Account Name<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="accountname" name="accountname"
                            type="text"
                            placeholder="Enter account name"
                            value={accountname} onChange={(e) => setAccountName(e.target.value)} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="accountno" className="form-label">Account No<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="accountno" name="accountno"
                            type="text"
                            placeholder="Enter account no"
                            value={accountno} onChange={(e) => setAccountNo(e.target.value)} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="bankname" className="form-label">Bank Name<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="bankname" name="bankname"
                            type="text"
                            placeholder="Enter your bank"
                            value={bankname} onChange={(e) => setBankName(e.target.value)} />
                                </div>

                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="ifsc" className="form-label">IFSC Code<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="ifsc" name="ifsc"
                            type="text"
                            placeholder="Enter ifsc"
                            value={ifsccode} onChange={(e) => setIfscCode(e.target.value)} />
                                </div>

                            </div>

                            <div className="row mt-4">
                            <h3>Google OAuth Settings</h3>
                            <hr className="m-0" />
                            <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="googleclientid" className="form-label">Google Client ID<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="googleclientid" name="googleclientid"
                            type="text"
                            placeholder="Enter Google Client ID"

                            value={googleclientId} onChange={(e) => setGoogleClientID(e.target.value)} />

                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="googlesecretkey" className="form-label">Google Secret Key<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="googlesecretkey" name="googlesecretkey"
                                type="text"
                                placeholder="Enter Google Secret Key"
                                value={googleSecretKey} onChange={(e) => setGoogleSecretKey(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mt-4">
                            <h3>Tidio Chat Bot Settings</h3>
                                <hr className="m-0" />
                                <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
                                <label for="chatbotid" className="form-label">ChatBot ID<span className="required-mark">*</span></label>
                                <input className="form-control form-control-lg bg-secondary" id="chatbotid" name="chatbotid"
                                type="text"
                                placeholder="Enter ChatBot ID"
                                value={chatbotid} onChange={(e) => setChatBotID(e.target.value)} />
                                </div>
                            </div>
                        </div>
                            <button className="btn btn-primary btn-sm font-15 mt-3"
                            onClick={handleAddMoreSettings}
                            disabled={disable}>
                             {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
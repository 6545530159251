import { Helmet } from "react-helmet-async"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { Header } from "../../../layout/dashboardLayout/Header"
import { useAppContext } from "../../../context/AppContext"
import { useEffect, useState } from "react" 
import { EditSubPermission, GetPermission, GetSubAdmin } from "../../../api/subAdmin"
import { message } from "antd"
import { useMutation } from "react-query"
import useGetdata from "../../../hooks/useGetData"
import { useParams } from "react-router-dom"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"
import { SelectDropdown } from "../../../component/DropDownComponent"
// import { Autocomplete, TextField } from "@mui/material"
// import autoCompleteCss from "../../../component/autoCompleteCss"

export const EditSubAdminPermission = () => {
    const { sidebarOpen } = useAppContext();
    const { id } = useParams()
    const[disable, setDisable] =useState(false)

    const [permissionNames, setPermissionNames] = useState([])
    const [permissionData, setPermissionData] = useState(null)
    const [checkAdd_permission, setCheckAdd_Permission] = useState(false);
    const [checkdelteUser_permission, setCheckDeleteUser_Permission] = useState(false);
    const [checkeditUser_permission, setCheckEditUser_Permission] = useState(false);
    const [checkViewUser_permission, setCheckViewUser_Permission] = useState(false);
    const [checkViewUserList_permission, setCheckViewUserList_Permission] = useState(false);
    // const [editUsersetting_permission, setEditUserSetting_Permission] = useState(false);
    const [changeUserPassword_permission, setChangeUserPassword_Permission] = useState(false);
    const [viewUserPasswordList_permission, setViewUserPasswordList_Permission] = useState(false);
    const [approveDocumentList_permission, setApproveDocumentList_Permission] = useState(false);
    const [pendingDocumentList_permission, setPendingDocumentList_Permission] = useState(false);
    const [uploadDocument_permission, setUploadDocument_Permission] = useState(false);
    const [followupList_permission, setFollowupList_Permission] = useState(false);
    const [addBankDetail_permission, setAddBankDetail_Permission] = useState(false);
    const [bankDetailList_permission, setBankDetailList_Permission] = useState(false);
    const [mtUserList_permission, setMtUserList_Permission] = useState(false);
    const [changeAccountPassword_permission, setChangeAccountPassword_Permission] = useState(false);
    const [updateMtLeverage_permission, setUpdateMtLeverage_Permission] = useState(false);
    const [existingClient_permission, setExistingClient_Permission] = useState(false);
    const [resendVerificationMail_permission, setResendVerificationMail_Permission] = useState(false);
    const [resendAccountDetailsMail_permission, setResendAccountDetailsMail_Permission] = useState(false);
    const [createLiveAccount_permission, setCreateLiveAccount_Permission] = useState(false);
    const [approvedIbList_permission, setApprovedIbList_Permission] = useState(false);
    const [requestedIbList_permission, setRequestedIBList_Permission] = useState(false);
    const [ibLevels_permission, setIbLevels_Permission] = useState(false);
    const [viewIbPlanList_permission, setViewIbPlanList_Permission] = useState(false);
    const [addIbPlan_permission, setAddIbPlan_Permission] = useState(false);
    const [createCommissionGroup_permission, setCreateCommissionGroup_Permission] = useState(false);
    const [updateCommissionGroup_permission, setUpdateCommissionGroup_Permission] = useState(false);
    const [ibCommissionset_permission, setIbCommissionset_Permission] = useState(false);
    const [moveClientToIb_permission, setMoveClientToIb_Permission] = useState(false);
    const [addGroup_permission, setAddGroup_Permission] = useState(false);
    const [editGroup_permission, setEditGroup_Permission] = useState(false);
    const [groupList_permission, setGroupList_Permission] = useState(false);
    const [updateMtGroup_permission, setUpdateMtGroup_Permission] = useState(false);
    const [addDeposit_permission, setAddDeposit_Permission] = useState(false);
    const [addWithdraw_permission, setAddWithdraw_Permission] = useState(false);
    const [pendingDepositList_permission, setPendingDepositList_Permission] = useState(false);
    const [pendingWithdrawList_permission, setPendingWithdrawList_Permission] = useState(false);
    const [internalTransfer_permission, setInternalTransfer_Permission] = useState(false);
    const [addLead_permission, setAddLead_Permission] = useState(false);
    const [editLead_permission, setEditLead_Permission] = useState(false);
    const [deleteLead_permission, setDeleteLead_Permission] = useState(false);
    const [viewLeadList_permission, setViewLeadList_Permission] = useState(false);
    const [viewLead_permission, setViewLead_Permission] = useState(false);
    const [convertLeadPermission, setConvertLead_Permission] = useState(false);
    const [leadSource_permission, setLeadSource_Permission] = useState(false);
    const [leadStatus_permission, setLeadStatus_Permission] = useState(false);
    const [addSales_permission, setAddSales_Permission] = useState(false);
    const [editSales_permission, setEditSales_Permission] = useState(false);
    const [deleteSales_permission, setDeleteSales_Permission] = useState(false);
    const [salesList_permission, setSalesList_Permission] = useState(false);
    const [sendEmail_permission, setSendEmail_Permission] = useState(false);
    const [unreadNotification_permission, setUnreadNotification_Permission] = useState(false);
    const [readNotification_permission, setReadNotification_Permission] = useState(false);
    const [depositReport_permission, setDepositReport_Permission] = useState(false);
    const [withdrawReport_permission, setWithdrawReport_Permission] = useState(false);
    const [transferReport_permission, setTransferReport_Permission] = useState(false);
    const [ticket_permission, setTicket_Permission] = useState(false);
    const [systemSetting_permission, setSystemSetting_Permission] = useState(false);
    const [deafultSetting_permission, setDefaultSetting_Permission] = useState(false);
    const [banner_permission, setBanner_Permission] = useState(false);
    const [createSubadmin_permission, setCreateSubadmin_Permission] = useState(false);
    const [subadminList_permission, setSubadminList_Permission] = useState(false);
    const [addSubadminPermission_permission, setAddSubadminPermission_Permission] = useState(false);
    const [editSubadminPermission_permission, setEditSubadminPermission_Permission] = useState(false);
    const [viewCommission_permission, setViewCommission_Permission] = useState(false);
    const [viewCommissionGroupList_permission, setViewCommissionGroupList_Permission] = useState(false);
    const [addIbCommission_permission, setAddIbCommission_Permission] = useState(false);
    const [editIbCommission_permission, setEditIbCommission_Permission] = useState(false);
    const [addibWithdraw_permission, setAddIbWithdraw_Permission] = useState(false);
    const [pendingibWithdrawList_permission, setPendingIBWithdrawList_Permission] = useState(false);
    const[salesDashboard_permission, setSalesDashboard_Permission] = useState(false);
    const [ibWithdrawReport_permission, setIBWithdrawReport_Permission] = useState(false);
    const [positionReport_permission, setPositionReport_Permission] = useState(false);
    const [walletHistoryReport_permission, setWalletHistoryReport_Permission] = useState(false);
    const [loginActivityReport_permission, setLoginActivityReport_Permission] = useState(false);
    const [marketingList_permission, setMarketingList_Permission] = useState(false);
    const [addMarketing_permission, setAddMarketing_Permission] = useState(false);
    const [editMarketing_permission, setEditMarketing_Permission] = useState(false);
    const [deleteMarketing_permission, setDeleteMarketing_Permission] = useState(false);
    const [incentiveReport_permission, setIncentiveReport_Permission] = useState(false);
    const [marketingWithdrawReport_permission, setMarketingWithdrawReport_Permission] = useState(false);
    const [historyReport_permission, setHistoryReport_Permission] = useState(false);
    const [giveBonus_permission, setGiveBonus_Permission] = useState(false);
    const [removeBonus_permission, setRemoveBonus_Permission] = useState(false);
    const [bonusList_permission, setBonusList_Permission] = useState(false);
    const [demoteib_permission, setDemoteIb_Permission] = useState(false);
    const [removeclientfromib_permission, setRemoveClientFromIb_Permission] = useState(false);
    const[moveibtoib_permission, setMoveIbtoIbPermission] = useState(false)
    const [adminId, SetAdminId] = useState(id)


    //get admin
    const { data: getallAdmin, isLoading } = useGetdata(
        "getallAdmin",
        GetSubAdmin
    )

    useEffect(() => {
        if (getallAdmin && getallAdmin?.data && getallAdmin?.data?.length > 0) {
            let filteredData = getallAdmin?.data?.find((el) => el?.id === Number(id));
            if (filteredData?.length == 1) {
                SetAdminId({name: `${filteredData[0]?.name} (${filteredData[0]?.email})`, value: filteredData[0]?.id })
            }
        }
    }, [getallAdmin]);

    //get permission

    useEffect(() => {
        const fetchPermissionData = async () => {
            try {
                const data = await GetPermission({ id })
                setPermissionData(data);
            } catch (error) {
                message.error(error)
            }
        };

        fetchPermissionData();
    }, [id]);

    //edit sub admin permission  
    const editPermission = useMutation(EditSubPermission, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("Permission Edited Successfully!");
                setDisable(false)
                // navigate("/admin/subAdminList")
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }


        },
    });

    // const permits = permissionData?.data.map((p)=> p.permission)

    useEffect(() => {


        if (permissionData?.data?.length > 0) {
            permissionData?.data?.map(p => {
                if (p.permission === "add_user") {
                    setCheckAdd_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_user") {
                    setCheckEditUser_Permission(true);
                    permissionNames?.push(p.permission);
                }
                if (p.permission === "delete_user") {
                    setCheckDeleteUser_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_user") {
                    setCheckViewUser_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_user_list") {
                    setCheckViewUserList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                // if (p.permission === "edit_user_settings") {
                //     setEditUserSetting_Permission(true);
                //     permissionNames?.push(p.permission)
                // }
                if (p.permission === "change_user_password") {
                    setChangeUserPassword_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_user_password_list") {
                    setViewUserPasswordList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "approved_document_list") {
                    setApproveDocumentList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "pending_document_list") {
                    setPendingDocumentList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "upload_document") {
                    setUploadDocument_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "followup_list") {
                    setFollowupList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_bank_detail") {
                    setAddBankDetail_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "bank_detail_list") {
                    setBankDetailList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "mt_user_list") {
                    setMtUserList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "change_account_password") {
                    setChangeAccountPassword_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "update_mt_leverage") {
                    setUpdateMtLeverage_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_existing_client") {
                    setExistingClient_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "resend_verification_mail") {
                    setResendVerificationMail_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "resend_account_details_mail") {
                    setResendAccountDetailsMail_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "create_live_account") {
                    setCreateLiveAccount_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "approved_ib_list") {
                    setApprovedIbList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "requested_ib_list") {
                    setRequestedIBList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "ib_levels") {
                    setIbLevels_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_commission") {
                    setViewCommission_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_ibplan_list") {
                    setViewIbPlanList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_ibplan") {
                    setAddIbPlan_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_commissiongroup_list") {
                    setViewCommissionGroupList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "create_commissiongroup") {
                    setCreateCommissionGroup_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "update_commissiongroup") {
                    setUpdateCommissionGroup_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_ibcommission_list") {
                    setIbCommissionset_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_ibcommission") {
                    setAddIbCommission_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_ibcommission") {
                    setEditIbCommission_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "move_client_to_ib") {
                    setMoveClientToIb_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_group") {
                    setAddGroup_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_group") {
                    setEditGroup_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "group_list") {
                    setGroupList_Permission(true);
                    permissionNames?.push(p.permission)
                }   
                if (p.permission === "update_mt_group") {
                    setUpdateMtGroup_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_deposit") {
                    setAddDeposit_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_withdraw") {
                    setAddWithdraw_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_ibwithdraw") {
                    setAddIbWithdraw_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "pending_deposit_list") {
                    setPendingDepositList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "pending_withdraw_list") {
                    setPendingWithdrawList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "pending_ibwithdraw_list") {
                    setPendingIBWithdrawList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "internal_transfer") {
                    setInternalTransfer_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "marketing_list") {
                    setMarketingList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_marketing") {
                    setAddMarketing_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_marketing") {
                    setEditMarketing_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "delete_marketing") {
                    setDeleteMarketing_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "incentive_report") {
                    setIncentiveReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "marketing_withdraw_report") {
                    setMarketingWithdrawReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_lead") {
                    setAddLead_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_lead") {
                    setEditLead_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "delete_lead") {
                    setDeleteLead_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_lead_list") {
                    setViewLeadList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "view_lead") {
                    setViewLead_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "convert_lead") {
                    setConvertLead_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "lead_source") {
                    setLeadSource_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "lead_status") {
                    setLeadStatus_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "sales_dashboard") {
                    setSalesDashboard_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_sales") {
                    setAddSales_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_sales") {
                    setEditSales_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "delete_sales") {
                    setDeleteSales_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "sales_list") {
                    setSalesList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "send_email") {
                    setSendEmail_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "unread_notification") {
                    setUnreadNotification_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "read_notification") {
                    setReadNotification_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "deposit_report") {
                    setDepositReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "withdraw_report") {
                    setWithdrawReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "transfer_report") {
                    setTransferReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "ibwithdraw_report") {
                    setIBWithdrawReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "position_report") {
                    setPositionReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "wallet_history_report") {
                    setWalletHistoryReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "login_activity_report") {
                    setLoginActivityReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "history_report") {
                    setHistoryReport_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "ticket") {
                    setTicket_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "default_setting") {
                    setDefaultSetting_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "system_setting") {
                    setSystemSetting_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "banner") {
                    setBanner_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "create_subadmin") {
                    setCreateSubadmin_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "subadmin_list") {
                    setSubadminList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "add_subadmin_permission") {
                    setAddSubadminPermission_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "edit_subadmin_permission") {
                    setEditSubadminPermission_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "give_bonus") {
                    setGiveBonus_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "remove_bonus") {
                    setRemoveBonus_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "bonus_list") {
                    setBonusList_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "demote_ib") {
                    setDemoteIb_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "remove_client_from_ib") {
                    setRemoveClientFromIb_Permission(true);
                    permissionNames?.push(p.permission)
                }
                if (p.permission === "move_ib_to_ib") {
                    setMoveIbtoIbPermission(true);
                    permissionNames?.push(p.permission)
                }
            })
        }

    }, [permissionData])

    useEffect(()=>{
        if(!isLoading){
            SetAdminId(id);
        }
    },[isLoading])

    const handleEditPermssion = () => {
        setDisable(true)
        editPermission.mutate({ adminId: Number(id), permissionNames });

    }

    return (
        <>
            <Helmet><title>Edit Permission | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Permission</h1>
                    </div>
                    <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="subadmin" className="form-label">Select Sub Admin <span className="required-mark">*</span></label>
                                    <SelectDropdown id="subadmin"
                                        value={adminId}
                                        onChange={(value) => SetAdminId(value)}>
                                        {
                                            getallAdmin?.data?.map((adminList) => (
                                                <option key={adminList.id} value={adminList.id}>{adminList.name} &nbsp;({adminList.email})</option>
                                            ))
                                        }
                                    </SelectDropdown>
                                    {/* {getallAdmin && <Autocomplete
                                        multiple
                                        sx={autoCompleteCss}
                                        limitTags={2}
                                        id="tags-outlined"
                                        filterSelectedOptions
                                        onChange={(event, value) => SetAdminId(value)}
                                        options={getallAdmin?.data?.map((el) => {
                                            return {
                                                name: `${el?.name} (${el?.email})`,
                                                value: el?.id
                                            }
                                        })}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Please Choose..." />
                                        )}
                                    />} */}
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">User Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={checkAdd_permission}
                                            onChange={() => setCheckAdd_Permission(!checkAdd_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("add_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_user"))} />
                                            <label className="form-check-label">Add User</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={checkeditUser_permission}
                                             onChange={(e) => setCheckEditUser_Permission(!checkeditUser_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_user"))} />
                                            <label className="form-check-label">Edit User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={checkdelteUser_permission}
                                             onChange={(e) => setCheckDeleteUser_Permission(!checkdelteUser_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_user"))} />
                                            <label className="form-check-label">Delete User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={checkViewUser_permission}
                                            onChange={(e) => setCheckViewUser_Permission(!checkViewUser_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user"))} />
                                            <label className="form-check-label">View User </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={checkViewUserList_permission}
                                            onChange={(e) => setCheckViewUserList_Permission(!checkViewUserList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user_list"))} />
                                            <label className="form-check-label">User List </label>

                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={editUsersetting_permission}
                                             onChange={(e) => setEditUserSetting_Permission(!editUsersetting_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_user_settings") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_user_settings"))} />
                                            <label className="form-check-label">Edit User Settings </label>

                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={changeUserPassword_permission}
                                             onChange={(e) => setChangeUserPassword_Permission(!changeUserPassword_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("change_user_password") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "change_user_password"))} />
                                            <label className="form-check-label">Change User Password </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={viewUserPasswordList_permission}
                                             onChange={(e) => setViewUserPasswordList_Permission(!viewUserPasswordList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("view_user_password_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_user_password_list"))} />
                                            <label className="form-check-label">User Password List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={approveDocumentList_permission}
                                            onChange={(e) => setApproveDocumentList_Permission(!approveDocumentList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("approved_document_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "approved_document_list"))} />
                                            <label className="form-check-label">Approved Document List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={pendingDocumentList_permission}
                                            onChange={(e) => setPendingDocumentList_Permission(!pendingDocumentList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_document_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_document_list"))} />
                                            <label className="form-check-label">Pending Document List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={uploadDocument_permission}
                                            onChange={(e) => setUploadDocument_Permission(!uploadDocument_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("upload_document") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "upload_document"))} />
                                            <label className="form-check-label">Upload Document </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={followupList_permission}
                                            onChange={(e) => setFollowupList_Permission(!followupList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("followup_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "followup_list"))} />
                                            <label className="form-check-label">Follow Up List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={addBankDetail_permission}
                                            onChange={(e) => setAddBankDetail_Permission(!addBankDetail_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("add_bank_detail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_bank_detail"))} />
                                            <label className="form-check-label">Add Bank Details </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                                checked={bankDetailList_permission}
                                                onChange={(e) => setBankDetailList_Permission(!bankDetailList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("bank_detail_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "bank_detail_list"))} />
                                            <label className="form-check-label">Bank Details List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={mtUserList_permission}
                                            onChange={(e) => setMtUserList_Permission(!mtUserList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("mt_user_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "mt_user_list"))} />
                                            <label className="form-check-label">MT5 User List </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={changeAccountPassword_permission}
                                            onChange={(e) => setChangeAccountPassword_Permission(!changeAccountPassword_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("change_account_password") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "change_account_password"))} />
                                            <label className="form-check-label">Change MT5 Password </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={updateMtLeverage_permission}
                                            onChange={(e) => setUpdateMtLeverage_Permission(!updateMtLeverage_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("update_mt_leverage") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_mt_leverage"))} />
                                            <label className="form-check-label">Update MT5 Leverage </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={existingClient_permission}
                                            onChange={(e) => setExistingClient_Permission(!existingClient_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("add_existing_client") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_existing_client"))} />
                                            <label className="form-check-label">Add Existing Client </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={resendVerificationMail_permission}
                                            onChange={(e) => setResendVerificationMail_Permission(!resendVerificationMail_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("resend_verification_mail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "resend_verification_mail"))} />
                                            <label className="form-check-label">Resend Verification Email </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={resendAccountDetailsMail_permission}
                                             onChange={(e) => setResendAccountDetailsMail_Permission(!resendAccountDetailsMail_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("resend_account_details_mail") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "resend_account_details_mail"))} />
                                            <label className="form-check-label">Resend MT5 Data Mail </label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                            checked={createLiveAccount_permission}
                                            onChange={(e) => setCreateLiveAccount_Permission(!createLiveAccount_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("create_live_account") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_live_account"))} />
                                            <label className="form-check-label">Create MT5 Account </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Bonus Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={giveBonus_permission}
                                            onChange={() => setGiveBonus_Permission(!giveBonus_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("give_bonus") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "give_bonus"))} />
                                            <label className="form-check-label">Give Bonus</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={removeBonus_permission}
                                            onChange={() => setRemoveBonus_Permission(!removeBonus_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("remove_bonus") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "remove_bonus"))} />
                                            <label className="form-check-label">Remove Bonus</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                             checked={bonusList_permission}
                                            onChange={() => setBonusList_Permission(!bonusList_permission)}
                                                onClick={e => e.target.checked ? permissionNames?.push("bonus_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "bonus_list"))} />
                                            <label className="form-check-label">Bonus List</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">IB Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={approvedIbList_permission}
                                            onChange={(e) => setApprovedIbList_Permission(!approvedIbList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("approved_ib_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "approved_ib_list"))} />
                                            <label className="form-check-label">IB User</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={requestedIbList_permission}
                                            onChange={(e) => setRequestedIBList_Permission(!requestedIbList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("requested_ib_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "requested_ib_list"))} />
                                            <label className="form-check-label">IB Request</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={ibLevels_permission}
                                            onChange={(e) => setIbLevels_Permission(!ibLevels_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ib_levels") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ib_levels"))} />
                                            <label className="form-check-label">IB Levels</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={viewCommission_permission}
                                            onChange={(e) => setViewCommission_Permission(!viewCommission_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_commission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_commission"))} />
                                            <label className="form-check-label">View Commission</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={demoteib_permission}
                                            onChange={(e) => setDemoteIb_Permission(!demoteib_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("demote_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "demote_ib"))} />
                                            <label className="form-check-label">Demote IB</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={removeclientfromib_permission}
                                            onChange={(e) => setRemoveClientFromIb_Permission(!removeclientfromib_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("remove_client_from_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "remove_client_from_ib"))} />
                                            <label className="form-check-label">Remove Client From IB</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={viewIbPlanList_permission}
                                            onChange={(e) => setViewIbPlanList_Permission(!viewIbPlanList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_ibplan_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_ibplan_list"))} />
                                            <label className="form-check-label">IB Plan List</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addIbPlan_permission}
                                            onChange={(e) => setAddIbPlan_Permission(!addIbPlan_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibplan") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibplan"))} />
                                            <label className="form-check-label">Create IB Plan</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={viewCommissionGroupList_permission}
                                             onChange={(e) => setViewCommissionGroupList_Permission(!viewCommissionGroupList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_commissiongroup_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_commissiongroup_list"))} />
                                            <label className="form-check-label">Commission Group List</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={createCommissionGroup_permission}
                                             onChange={(e) => setCreateCommissionGroup_Permission(!createCommissionGroup_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("create_commissiongroup") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_commissiongroup"))} />
                                            <label className="form-check-label">Create Commission Group</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={updateCommissionGroup_permission}
                                            onChange={(e) => setUpdateCommissionGroup_Permission(!updateCommissionGroup_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("update_commissiongroup") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_commissiongroup"))} />
                                            <label className="form-check-label">Update Commission Group</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={ibCommissionset_permission}
                                            onChange={(e) => setIbCommissionset_Permission(!ibCommissionset_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_ibcommission_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_ibcommission_list"))} />
                                            <label className="form-check-label">Set IB Commission</label>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={addIbCommission_permission}
                                             onChange={(e) => setAddIbCommission_Permission(!addIbCommission_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibcommission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibcommission"))} />
                                            <label className="form-check-label">Add Commission</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={editIbCommission_permission}
                                             onChange={(e) => setEditIbCommission_Permission(!editIbCommission_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_ibcommission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_ibcommission"))} />
                                            <label className="form-check-label">Edit Commission</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={moveClientToIb_permission}
                                            onChange={(e) => setMoveClientToIb_Permission(!moveClientToIb_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("move_client_to_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "move_client_to_ib"))} />
                                            <label className="form-check-label">Move Client to IB</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={moveibtoib_permission}
                                            onChange={(e) => setMoveIbtoIbPermission(!moveibtoib_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("move_ib_to_ib") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "move_ib_to_ib"))} />
                                            <label className="form-check-label">Move IB to IB</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Group Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addGroup_permission}
                                            onChange={(e) => setAddGroup_Permission(!addGroup_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_group"))} />
                                            <label className="form-check-label">Add Group</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={editGroup_permission}
                                            onChange={(e) => setEditGroup_Permission(!editGroup_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_group"))} />
                                            <label className="form-check-label">Edit Group</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={groupList_permission}
                                            onChange={(e) => setGroupList_Permission(!groupList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("group_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "group_list"))} />
                                            <label className="form-check-label">Group List</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={updateMtGroup_permission}
                                            onChange={(e) => setUpdateMtGroup_Permission(!updateMtGroup_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("update_mt_group") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "update_mt_group"))} />
                                            <label className="form-check-label">Update Group MT5</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Transaction</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addDeposit_permission}
                                            onChange={(e) => setAddDeposit_Permission(!addDeposit_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_deposit") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_deposit"))} />
                                            <label className="form-check-label">Add Deposit </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addWithdraw_permission}
                                            onChange={(e) => setAddWithdraw_Permission(!addWithdraw_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_withdraw") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_withdraw"))} />
                                            <label className="form-check-label">Add Withdraw </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addibWithdraw_permission}
                                            onChange={(e) => setAddIbWithdraw_Permission(!addibWithdraw_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_ibwithdraw") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_ibwithdraw"))} />
                                            <label className="form-check-label">Add IB Withdraw </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={pendingDepositList_permission}
                                            onChange={(e) => setPendingDepositList_Permission(!pendingDepositList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_deposit_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_deposit_list"))} />
                                            <label className="form-check-label">Pending Deposit List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={pendingWithdrawList_permission}
                                            onChange={(e) => setPendingWithdrawList_Permission(!pendingWithdrawList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_withdraw_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "pending_withdraw_list"))} />
                                            <label className="form-check-label">Pending Withdraw List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={pendingibWithdrawList_permission}
                                            onChange={(e) => setPendingIBWithdrawList_Permission(!pendingibWithdrawList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("pending_ibwithdraw_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "approve_deposit_list"))} />
                                            <label className="form-check-label">Pending IB Withdraw List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={internalTransfer_permission}
                                             onChange={(e) => setInternalTransfer_Permission(!internalTransfer_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("internal_transfer") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "internal_transfer"))} />
                                            <label className="form-check-label">Internal Transfer </label>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Marketing Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={marketingList_permission}
                                            onChange={(e) => setMarketingList_Permission(!marketingList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("marketing_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "marketing_list"))} />
                                            <label className="form-check-label">Marketing List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addMarketing_permission}
                                            onChange={(e) => setAddMarketing_Permission(!addMarketing_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_marketing"))} />
                                            <label className="form-check-label">Add Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={editMarketing_permission}
                                            onChange={(e) => setEditMarketing_Permission(!editMarketing_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_marketing"))} />
                                            <label className="form-check-label">Edit Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={deleteMarketing_permission}
                                            onChange={(e) => setDeleteMarketing_Permission(!deleteMarketing_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_marketing") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_marketing"))} />
                                            <label className="form-check-label">Delete Marketing </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={incentiveReport_permission}
                                            onChange={(e) => setIncentiveReport_Permission(!incentiveReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("incentive_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "incentive_report"))} />
                                            <label className="form-check-label">Incentive Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={marketingWithdrawReport_permission}
                                            onChange={(e) => setMarketingWithdrawReport_Permission(!marketingWithdrawReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("marketing_withdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "marketing_withdraw_report"))} />
                                            <label className="form-check-label">Marketing Withdraw Report</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Sales Management</h4>
                                <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={salesDashboard_permission}
                                            onChange={(e) => setSalesDashboard_Permission(!salesDashboard_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("sales_dashboard") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "sales_dashboard"))} />
                                            <label className="form-check-label">Sales Dashboard</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addSales_permission}
                                            onChange={(e) => setAddSales_Permission(!addSales_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_sales"))} />
                                            <label className="form-check-label">Create Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={editSales_permission}
                                            onChange={(e) => setEditSales_Permission(!editSales_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_sales"))} />
                                            <label className="form-check-label">Edit Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={deleteSales_permission}
                                            onChange={(e) => setDeleteSales_Permission(!deleteSales_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_sales") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_sales"))} />
                                            <label className="form-check-label">Delete Sales </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={salesList_permission}
                                            onChange={(e) => setSalesList_Permission(!salesList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("sales_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "sales_list"))} />
                                            <label className="form-check-label">Sales List </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addLead_permission}
                                            onChange={(e) => setAddLead_Permission(!addLead_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_lead"))} />
                                            <label className="form-check-label">Add Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={editLead_permission}
                                            onChange={(e) => setEditLead_Permission(!editLead_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_lead"))} />
                                            <label className="form-check-label">Edit Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={deleteLead_permission}
                                             onChange={(e) => setDeleteLead_Permission(!deleteLead_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("delete_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "delete_lead"))} />
                                            <label className="form-check-label">Delete Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={viewLead_permission}
                                             onChange={(e) => setViewLead_Permission(!viewLead_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_lead"))} />
                                            <label className="form-check-label">View Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={viewLeadList_permission}
                                            onChange={(e) => setViewLeadList_Permission(!viewLeadList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("view_lead_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "view_lead_list"))} />
                                            <label className="form-check-label">Lead List </label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={convertLeadPermission}
                                            onChange={(e) => setConvertLead_Permission(!convertLeadPermission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("convert_lead") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "convert_lead"))} />
                                            <label className="form-check-label">Convert Lead </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={leadSource_permission}
                                            onChange={(e) => setLeadSource_Permission(!leadSource_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("lead_source") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "lead_source"))} />
                                            <label className="form-check-label">Lead Source </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={leadStatus_permission}
                                            onChange={(e) => setLeadStatus_Permission(!leadStatus_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("lead_status") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "lead_status"))} />
                                            <label className="form-check-label">Lead Status </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Email Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={sendEmail_permission}
                                            onChange={(e) => setSendEmail_Permission(!sendEmail_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("send_email") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "send_email"))} />
                                            <label className="form-check-label">Send Email </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Notification Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={unreadNotification_permission}
                                            onChange={(e) => setUnreadNotification_Permission(!unreadNotification_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("unread_notification") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "unread_notification"))} />
                                            <label className="form-check-label">Unread Notification</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={readNotification_permission}
                                            onChange={(e) => setReadNotification_Permission(!readNotification_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("read_notification") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "read_notification"))} />
                                            <label className="form-check-label">Read Notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Report Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={depositReport_permission}
                                            onChange={(e) => setDepositReport_Permission(!depositReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("deposit_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "deposit_report"))} />
                                            <label className="form-check-label">Deposit Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={withdrawReport_permission}
                                            onChange={(e) => setWithdrawReport_Permission(!withdrawReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("withdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "withdraw_report"))} />
                                            <label className="form-check-label">Withdraw Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={transferReport_permission}
                                            onChange={(e) => setTransferReport_Permission(!transferReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("transfer_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "transfer_report"))} />
                                            <label className="form-check-label">Transfer Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={ibWithdrawReport_permission}
                                            onChange={(e) => setIBWithdrawReport_Permission(!ibWithdrawReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ibwithdraw_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ibwithdraw_report"))} />
                                            <label className="form-check-label">IB Withdraw Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={positionReport_permission}
                                            onChange={(e) => setPositionReport_Permission(!positionReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("position_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "position_report"))} />
                                            <label className="form-check-label">Position Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={walletHistoryReport_permission}
                                            onChange={(e) => setWalletHistoryReport_Permission(!walletHistoryReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("wallet_history_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "wallet_history_report"))} />
                                            <label className="form-check-label">Wallet History Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={loginActivityReport_permission}
                                            onChange={(e) => setLoginActivityReport_Permission(!loginActivityReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("login_activity_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "login_activity_report"))} />
                                            <label className="form-check-label">Login Activity Report</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={historyReport_permission}
                                            onChange={(e) => setHistoryReport_Permission(!historyReport_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("history_report") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "history_report"))} />
                                            <label className="form-check-label">History Report</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Ticket Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={ticket_permission}
                                            onChange={(e) => setTicket_Permission(!ticket_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("ticket") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "ticket"))} />
                                            <label className="form-check-label">Tickets</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Setting Management</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={deafultSetting_permission}
                                            onChange={(e) => setDefaultSetting_Permission(!deafultSetting_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("default_setting") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "default_setting"))} />
                                            <label className="form-check-label">Default Setting</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={systemSetting_permission}
                                            onChange={(e) => setSystemSetting_Permission(!systemSetting_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("system_setting") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "system_setting"))} />
                                            <label className="form-check-label">System Setting</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={banner_permission}
                                             onChange={(e) => setBanner_Permission(!banner_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("banner") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "banner"))} />
                                            <label className="form-check-label">Banner</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className="permissionttl">Sub Admin</h4>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={createSubadmin_permission}
                                            onChange={(e) => setCreateSubadmin_Permission(!createSubadmin_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("create_subadmin") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "create_subadmin"))} />
                                            <label className="form-check-label">Create Sub Admin</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={subadminList_permission}
                                            onChange={(e) => setSubadminList_Permission(!subadminList_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("subadmin_list") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "subadmin_list"))} />
                                            <label className="form-check-label">Sub Admin List</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                            checked={addSubadminPermission_permission}
                                            onChange={(e) => setAddSubadminPermission_Permission(!addSubadminPermission_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("add_subadmin_permission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "add_subadmin_permission"))} />
                                            <label className="form-check-label">Add Sub Admin Permission</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <div className="mb-4">
                                            <input className="form-check-input mt-0 me-2 form-check-input-sm"
                                             checked={editSubadminPermission_permission}
                                             onChange={(e) => setEditSubadminPermission_Permission(!editSubadminPermission_permission)}
                                                type="checkbox"
                                                onClick={e => e.target.checked ? permissionNames?.push("edit_subadmin_permission") :
                                                    setPermissionNames(permissionNames?.filter(permission => permission !== "edit_subadmin_permission"))} />
                                            <label className="form-check-label">Edit Sub Admin Permission</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs" type="submit"
                                 onClick={handleEditPermssion}
                                 disabled={disable}>
                                  {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { subMonths, format } from 'date-fns';
import { getLoginActivityData } from "../../../api/auth";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { message } from "antd";


export const LoginActivityReport = () => {
    const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

    const {sidebarOpen} = useAppContext();
    const[fromDate, setFromDate]=useState(defaultFromDate);
    const[toDate, setToDate]= useState(defaultToDate);
    const [loginActivityData, setLoginActivityData] = useState({});
    const[searched, setSearched] = useState("")
    const[disable, setDisable] = useState(false)


    const formattingDate = (date) => {
        return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
      };
    
    //search data
      const handleSearch = async () => {
        setDisable(true)
        try {
            const data = { fromDate : formattingDate(fromDate), toDate: formattingDate(toDate)  };
            const result = await getLoginActivityData(data);
            if(result.status === 200){
              setLoginActivityData(result);
              setDisable(false)
            }
          } catch (error) {
            setDisable(false)
            message.error(error.message);
          }
        };

       useEffect(() => {
        // Fetch latest one month data automatically when component mounts or updates
        fetchLatestOneMonthData();
      }, []); // Empty dependency array to ensure it runs only once when component mounts
    
      const fetchLatestOneMonthData = async () => {
        try {
          const toDate = new Date(); // Current date
          const fromDate = new Date();
          fromDate.setMonth(fromDate.getMonth() - 1); // One month ago
    
          const data = {
            fromDate: formattingDate(fromDate),
            toDate: formattingDate(toDate),
          };
    
          const result = await getLoginActivityData(data);
          setLoginActivityData(result);
        } catch (error) {
          message.error(error.message);
        }
      };

      const headers = [
        { label: "ID", key: "id", sortable: true },
        { label: "Name", key: "name", sortable: true },
        { label: "Email", key: "email", sortable: true },
        { label: "IP Address", key: "ipAddress", sortable: true },
        // { label: "City", key: "city", sortable: true },
        // { label: "State", key: "state", sortable: true }, 
        // { label: "Country", key: "country", sortable: true },
        { label: "Date", key: "createdAt", sortable: true },
    ]

    const filteredData = loginActivityData?.data?.filter((item) =>
      item?.name && item?.name.toLowerCase().includes(searched.toLowerCase())  || 
    item?.email && item?.email.toLowerCase().includes(searched.toLowerCase()) || 
    item?.ipAddress && item?.ipAddress.toLowerCase().includes(searched.toLowerCase())
)
    
    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
        'ID',
        'Name',
        'Email',
        'IP Address',
        'Date'
      ];
      
      const rows = data?.map((item,index) => [
        index + 1,
        item.name,
        item.email,
        item.ipAddress,
        formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('Login Activity Report.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
  if (!data  || data?.length === 0) {

    const headers = [
      'ID',
      'Name',
      'Email',
      'IP Address',
      'Date'];
   
    const ws = XLSX.utils.aoa_to_sheet([headers]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const filename = 'Login Activity Report.xlsx';

  XLSX.writeFile(wb, filename);
    return;
  }


    const dataForExport = data?.map((item,index) => ({
      'ID':  index + 1,
      'Name':item.name,
      'Email':item.email,
      'IP Address': item.ipAddress,
      'Date':formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Login Activity Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

    return (
        <>
         <Helmet><title>Login Activity Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Login Activity Report</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                        <div className="row">
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>From :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark" name="fromdate" id="fromdate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)} />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3">
						<label><strong>To :</strong></label>
						<input type="Date" className="form-control form-control-lg bg-dark " name="todate" id="todate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}/>
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
					<div className="form-group mb-3 pt-4">
						<button type="button" id="dateSearch"
                         className="btn btn-sm btn-primary"
                         onClick={handleSearch} 
                         disabled={disable}>
                           {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
					</div>
				</div>
			</div>
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(loginActivityData?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(loginActivityData?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
            <DataTable
            data={filteredData}
            headers={headers}
            renderRow={(rowData)=> (
                                                <>
                                                    <td>{rowData.name}</td>
                                                    <td>{rowData.email}</td>
                                                    <td>{rowData.ipAddress}</td>
                                                    {/* <td>{rowData.city}</td>
                                                    <td>{rowData.state}</td>
                                                    <td>{rowData.country}</td> */}
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                </>
                                                )} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
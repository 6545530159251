import { Link } from "react-router-dom"
import useGetData from "../../hooks/useGetData"
import { MarkReadNotifications, getUnreadNotifications } from "../../api/notifications"
import { formatDate } from "../../utility/formatTime"
import { useQueryClient } from "react-query"
import { useState } from "react"
import { message } from "antd"

export const NotificationPopover = () => {
    const[read, setRead] = useState({})

     //get notification data
 const {data: unreadLatestNotification} = useGetData(
    "unreadLatestNotification",
    getUnreadNotifications
  )


  //read single notification
const queryclient = useQueryClient();
const fetchNotificationData = async (id) => {
  try {
    const response = await MarkReadNotifications({id});
    setRead(response.data);
    queryclient.invalidateQueries("unreadLatestNotification")
  } catch (error) {
    message.error(error.message);
  }
};

  const handleReadOneNotification = (id) => {
    fetchNotificationData(id);
  
  }

    return(
        <>
         <div className="nav-item dropdown dropdown-arrow"> 
         <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
           <i className="fa-light fa-bell me-lg-2"></i></Link>
          <div className="dropdown-menu dropdown-menu-end bg-dark border-0 rounded-0 rounded-bottom m-0" style={{maxWidth:300}}>
            {
              unreadLatestNotification?.data.map((notifyableData)=>(
                <>
             <Link to="" className="dropdown-item" key={notifyableData?.id}
              onClick={()=> handleReadOneNotification(notifyableData?.id)}>
            <h6 className="fw-normal mb-0" style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow: "ellipsis" }}>{notifyableData?.type}</h6>
            <small>{formatDate(notifyableData?.createdAt)}</small> </Link>
             <hr className="dropdown-divider" />
             </>
             ))
            }
           
           
            <Link to="/admin/notification/unreadnotification" className="dropdown-item text-center">See all notifications</Link>
             </div>
        </div>
        </>
    )
}
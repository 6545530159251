import { Image, message } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../../api/user";
import { formatDate } from "../../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import DataTable from "../../../../component/DataTable"

export const DepositTabs = () => {
  const [userDeposit, setUserDeposit] = useState({})
  const [searched, setSearched] = useState("")

  const { id } = useParams()

  const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Amount", key: "amount", sortable: true },
    { label: "Payment Method", key: "paymentmethod", sortable: true },
    { label: "Note", key: "note", sortable: true },
    { label: "Comment", key: "admincomment", sortable: true },
    { label: "Deposit Proof", key: "depositproof", sortable: true },
    { label: "Status", key: "status", sortable: true },
    { label: "Date", key: "createdAt", sortable: true },
  ]

  const filteredData = userDeposit?.data?.deposits?.filter((item) =>
    // index + 1.toString().includes(searched) ||
  item.paymentmethod && item.paymentmethod.toLowerCase().includes(searched.toLowerCase()) ||
  item.amount && item.amount.toString().includes(searched)
  )



  //fetch single user details
  useEffect(() => {
    const fetchUserDepositList = async () => {
      try {
        const data = await getSingleUser({ id });
        setUserDeposit(data);
      } catch (error) {
        message.error(error.message);

      }
    };

    fetchUserDepositList();
  }, [id]);

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'MT5 ID',
      'Amount',
      'Payment Method',
      'Note',
      'Comment',
      'Deposit Proof',
      'Status',
      'Date'
    ];

    const rows = data?.map((item,index) => [
      index + 1,
      item.account_no,
      item.amount,
      item.paymentmethod,
      item.note,
      item.admincomment,
      item.depositproof,
      item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      formatDate(item.createdAt)
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Exo'
      }
    });
    doc.save('Deposit List.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {
      const headers = [
        'ID',
        'MT5 ID',
        'Amount',
        'Payment Method',
        'Note',
        'Comment',
        'Deposit Proof',
        'Status',
        'Date'
      ];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "Deposit List.xlsx";

      XLSX.writeFile(wb, filename);
      return;
    }
    const dataForExport = data?.map((item,index) => ({
      'ID': item.user_id,
      'MT5 ID': item.account_no,
      'Amount': item.amount,
      'Payment Method': item.paymentmethod,
      'Note': item.note,
      'Comment': item.admincomment,
      'Deposit Proof': item.depositproof,
      'Status': item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Reject',
      'Date': formatDate(item.createdAt)
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Deposit List.xlsx';

    XLSX.writeFile(wb, filename);
  }


  return (
    <>
      <div className="table-responsive-lg">
        <div className="row p-3 dataTables_filter">
          <div className="col-sm-12 d-flex justify-content-between">
            <div className="d-flex gap-2">
              <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(userDeposit?.data?.deposits)}>
                <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
              <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(userDeposit?.data?.deposits)}>
                <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

            </div>
            <div className="d-flex align-items-center">
              <label> Search:&nbsp;</label>
              <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
            </div>
          </div>
        </div>
        <DataTable
          data={filteredData}
          headers={headers}
          renderRow={(rowData) => (
            <>
              <td>{rowData.amount}</td>
              <td>{rowData.paymentmethod}</td>
              <td>{rowData.note}</td>
              <td>{rowData.admincomment}</td>
              <td><Image src={`${process.env.REACT_APP_API_URL}/images/depositUploads/${rowData.depositproof}`} height="50px" width="60px" /></td>
              <td>{rowData.status === 0 ? <b className="text-warning">Pending</b> : "" ||
                rowData.status === 1 ? <b className="text-success">Approved</b> : <b className="text-danger">Reject</b>}</td>
              <td>{formatDate(rowData.createdAt)}</td>
            </>

          )} />
      </div>

    </>
  )
}
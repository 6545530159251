import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetPermissionCheck, GetSubAdmin } from "../../../api/subAdmin";
import { formatDate } from "../../../utility/formatTime";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const SubAdminList = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")

 //get subadmim list
 const { data: subadminList } = useGetdata(
    "subadminList",
    GetSubAdmin
)

const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Name", key: "name", sortable: true },
    { label: "Email", key: "email", sortable: true },
    { label: "Password", key: "password", sortable: true },
    { label: "Group", key: "Group", sortable: true },
    { label: "Date", key: "createdAt", sortable: true }, 
    { label: "Action", sortable: false }, 
 
]

    const filteredData = subadminList?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) 
    )

     //set permission
  const { data: checkPermission, refetch } = useGetdata(
    "checkPermission",
    GetPermissionCheck
  )

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      refetch()
    }
  }, [token, refetch])

    return (
        <>
            <Helmet><title>Sub Admin List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Sub Admin List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                   <DataTable
                                   data={filteredData}
                                   headers={headers}
                                   renderRow={(rowData) => (
                                                        <>
                                                            <td>{rowData.name}</td>
                                                            <td>{rowData.email}</td>
                                                            <td>{rowData.upassword}</td>
                                                            <td>
                                                            {rowData?.groupname?.split(',')?.map((name, idx) => (
                                                                <>
                                                            <button key={idx} className="btn btn-xs btn-primary">{name.trim()}</button>&nbsp;
                                                            </>
                                                        ))}
                                                        </td>
                                                            <td>{formatDate(rowData.createdAt)}</td>
                                                            <td>
                                                            {
                                                        rowData?.permission === 0 ? ( 
                                                            <>
                                                            {
                                                                checkPermission?.typeAdmin === 1 &&
                                                                <Link to={`/admin/subadmin/permission/add/${rowData.id}`} className="btn btn-xs btn-primary">Add Permission</Link>}
                                                                {
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                    checkPermission?.data?.includes("add_subadmin_permission") ?
                                                                    <Link to={`/admin/subadmin/permission/add/${rowData.id}`} className="btn btn-xs btn-primary">Add Permission</Link> : ""}

                                                                </>
                                                                 ) : (
                                                                 <>
                                                                 {
                                                                checkPermission?.typeAdmin === 1 &&
                                                                <Link to={`/admin/subadmin/permission/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit Permission</Link>}
                                                                 {
                                                                    checkPermission?.typeAdmin === 0 &&
                                                                    checkPermission?.data?.includes("edit_subadmin_permission") ?
                                                                <Link to={`/admin/subadmin/permission/edit/${rowData.id}`} className="btn btn-xs btn-primary">Edit Permission</Link>: ""}

                                                                </>
                                                                 )}
                                                            </td>
                                                        </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//create live account
export const CreateLiveAccount = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/account/liveaccount/create",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get All Live Account

export const getLiveAccountData = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/account/liveaccount/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });

  return response.data;
};

//get single live account
export const GetSingleLiveAccount = async (data) => {
  let token = localStorage.getItem("token");
  const {id }= data;
  let response = await instance({
    url: `api/admin/account/liveaccount/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
//serach Live Account data
export const SearchLiveAccountData = async (queryParams) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/search/liveaccount?${queryParams}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};


//get All Demo Account
export const getDemoAccountData = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/account/demoaccount/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//serach Demo Account data
export const SearchDemoAccountData = async (queryParams) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/search/demoaccount?${queryParams}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

//get Leverage History
export const getLeverageData = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/all/leveragehistory`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

//serach Leverage History data
export const SearchLeverageHistory = async (queryParams) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/search/leveragehistory?${queryParams}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

//change mt5- password
export const ChangePasswordMT = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/account/liveaccount/changepassword",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//resend MT-5 data mail
export const SendMT5Data = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/send/accountdetail/email",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get position report data
export const getPositionReportData = async (data) => {
  let token = localStorage.getItem("token");
  const {account} = data;
  let response = await instance({
    url: `api/admin/report/position?account=${account}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//update mt-5 leverage
export const Mt5LeverageUpdate = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/account/liveaccount/update/leverage",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
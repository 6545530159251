import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { GetSingleCommissionGroup, UpdateCommissionGroup, getAllIBPlan } from "../../../api/ib";
import { getAllGroup } from "../../../api/group";
import { Link, useParams } from "react-router-dom";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const EditCommissionGroup = () => {
    const [planid, setPlanId] = useState("");
    const [groupid, setGroupId] = useState("");
    const [level1, setLevel1] = useState("")
    const [level1d2, setLevel1D2] = useState("")
    const [level2d2, setLevel2D2] = useState("")
    const [level1d3, setLevel1D3] = useState("")
    const [level2d3, setLevel2D3] = useState("")
    const [level3d3, setLevel3D3] = useState("")
    const [level1d4, setLevel1D4] = useState("")
    const [level2d4, setLevel2D4] = useState("")
    const [level3d4, setLevel3D4] = useState("")
    const [level4d4, setLevel4D4] = useState("")
    const [level1d5, setLevel1D5] = useState("")
    const [level2d5, setLevel2D5] = useState("")
    const [level3d5, setLevel3D5] = useState("")
    const [level4d5, setLevel4D5] = useState("")
    const [level5d5, setLevel5D5] = useState("")
    const[singleGroupCommission, setSingleGroupCommission]=useState(null)
    const[loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)
    const {id} = useParams()

    const { sidebarOpen } = useAppContext();

 //get ib plan list
 const { data: allIbplanList, isLoading:userDLoad} = useGetdata(
    "allIbplanList",
    getAllIBPlan
)

//get  group
const { data: allGroups } = useGetdata(
    "allGroups",
    getAllGroup
)

  //get single commission group
useEffect(() => {
    const fetchSingleGroupCommission = async () => {
      try {
        const data = await GetSingleCommissionGroup({id})
        setSingleGroupCommission(data);
      } catch (error) {
        message.error(error)
      }

    };

    fetchSingleGroupCommission();
  }, [id]);

 //Update group commission 
 const queryclient = useQueryClient();
 const groupCommissionUpdate = useMutation(UpdateCommissionGroup, {
     onSuccess: (data) => {
         if (data?.status === 200) {
             message.success("Group Commission Updated Successfully!");
             queryclient.invalidateQueries("allCommissiongroup");
             setDisable(false)
         }
     },
     onError: (data) => {
        setDisable(false)
         if (data?.response?.data.status === 400) {
             message.error(data?.response?.data.message);
         }
         if (data?.response?.data.status === 403) {
             message.error(data?.response?.data.message);
         }
         if (data?.response?.data.status === 404) {
             message.error(data?.response?.data.message);
         }
         if (data?.response?.data.status === 500) {
             message.error(data?.response?.data.message);
         }
     }
 })

 useEffect(() => {
     if (!userDLoad) {
         setPlanId(singleGroupCommission?.data.planid);
         setGroupId(singleGroupCommission?.data.groupid);
         setLevel1(singleGroupCommission?.data.level1);
         setLevel1D2(singleGroupCommission?.data.level1d2);
         setLevel2D2(singleGroupCommission?.data.level2d2);
         setLevel1D3(singleGroupCommission?.data.level1d3);
         setLevel2D3(singleGroupCommission?.data.level2d3);
         setLevel3D3(singleGroupCommission?.data.level3d3);
         setLevel1D4(singleGroupCommission?.data.level1d4);
         setLevel2D4(singleGroupCommission?.data.level2d4);
         setLevel3D4(singleGroupCommission?.data.level3d4);
         setLevel4D4(singleGroupCommission?.data.level4d4);
         setLevel1D5(singleGroupCommission?.data.level1d5);
         setLevel2D5(singleGroupCommission?.data.level2d5);
         setLevel3D5(singleGroupCommission?.data.level3d5);
         setLevel4D5(singleGroupCommission?.data.level4d5);
         setLevel5D5(singleGroupCommission?.data.level5d5);
     }
 }, [userDLoad, singleGroupCommission]);


 const handleUpdateCommissionGroup = async (e) => {
     e.preventDefault();
     setDisable(true)
     if (
        planid === "" ||
        groupid === "" ) {
        setLoginError("Please Fill the above field.");
        setDisable(false)
        return;
    }

     groupCommissionUpdate.mutate({
         id:id,
         planid: Number(planid),
         groupid: Number(groupid),
          level1,
          level1d2,
          level2d2,
          level1d3,
          level2d3,
          level3d3,
          level1d4,
          level2d4,
          level3d4,
          level3d4,
          level4d4,
          level1d5,
          level2d5,
          level3d5,
          level4d5,
          level5d5
     });
     return;
 }


    return (
        <>
            <Helmet><title>Edit Commission Group | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Edit Commission Group</h1>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                        <Link to="/admin/ib/commissiongrouplist" className="btn btn-xs btn-primary">View Commission Group</Link>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                <form>
                        <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="plan" className="form-label">Plan ID <span className="required-mark">*</span></label>
                                    <SelectDropdown id="plan" 
                                    value={planid} onChange={(value) => setPlanId(value)}>
                                        {
                                            allIbplanList?.data?.map((data)=>(
                                        <option key={data.id} value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </SelectDropdown>
                                    <p className={
                                    planid === "" && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="group" className="form-label">Group<span className="required-mark">*</span></label>
                                    <SelectDropdown id="group" 
                                    value={groupid} onChange={(value) => setGroupId(value)}>
                                        <option selected="">Please choose...</option>
                                        {
                                                    allGroups?.data.map((group) => (
                                                        <option key={group.id} value={group.id}>{group.name}</option>
                                                    ))
                                                }
                                    </SelectDropdown>
                                    <p className={
                                        groupid === "" && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <h3>Level1 Settings</h3>
                                <hr className="m-0 mb-2"/>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level1" className="form-label">Level1 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level1" className="form-control form-control-lg "
                                        id="level1" placeholder="Enter Level1 commission" value={level1}
                                        onChange={(e) => setLevel1(e.target.value)}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <h3>Level2 Settings</h3>
                                <hr className="m-0 mb-2"/>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level1d2" className="form-label">Level1 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level1d2" className="form-control form-control-lg "
                                        id="level1d2"  placeholder="Enter Level1 commission" value={level1d2}
                                        onChange={(e) => setLevel1D2(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level2d2" className="form-label">Level2 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level2d2" className="form-control form-control-lg "
                                        id="level2d2" placeholder="Enter Level2 commission" value={level2d2}
                                        onChange={(e) => setLevel2D2(e.target.value)}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <h3>Level3 Settings</h3>
                                <hr className="m-0 mb-2"/>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level1d3" className="form-label">Level1 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level1d3" className="form-control form-control-lg "
                                        id="level1d3" placeholder="Enter level1 commission" value={level1d3}
                                        onChange={(e) => setLevel1D3(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level2d3" className="form-label">Level2 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level2d3" className="form-control form-control-lg "
                                        id="level2d3" placeholder="Enter Level2 commission" value={level2d3}
                                        onChange={(e) => setLevel2D3(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level3d3" className="form-label">Level3 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level3d3" className="form-control form-control-lg "
                                        id="level3d3" placeholder="Enter Level3 commission" value={level3d3}
                                        onChange={(e) => setLevel3D3(e.target.value)}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <h3>Level4 Settings</h3>
                                <hr className="m-0 mb-2"/>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level1d4" className="form-label">Level1 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level1d4" className="form-control form-control-lg "
                                        id="level1d4" placeholder="Enter Level1 commission" value={level1d4}
                                        onChange={(e) => setLevel1D4(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level2d4" className="form-label">Level2 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level2d4" className="form-control form-control-lg "
                                        id="level2d4" placeholder="Enter Level2 commission" value={level2d4}
                                        onChange={(e) => setLevel2D4(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level3d4" className="form-label">Level3 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level3d4" className="form-control form-control-lg "
                                        id="level3d4" placeholder="Enter Level3 commission" value={level3d4}
                                        onChange={(e) => setLevel3D4(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level4d4" className="form-label">Level4 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level4d4" className="form-control form-control-lg "
                                        id="level4d4" placeholder="Enter Level4 commission" value={level4d4}
                                        onChange={(e) => setLevel4D4(e.target.value)}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <h3>Level5 Settings</h3>
                                <hr className="m-0 mb-2"/>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level1d5" className="form-label">Level1 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level1d5" className="form-control form-control-lg "
                                        id="level1d5" placeholder="Enter level1 commission" value={level1d5}
                                        onChange={(e) => setLevel1D5(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level2d5" className="form-label">Level2 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level2d5" className="form-control form-control-lg "
                                        id="level2d5" placeholder="Enter level2 commission" value={level2d5}
                                        onChange={(e) => setLevel2D5(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level3d5" className="form-label">Level3 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level3d5" className="form-control form-control-lg "
                                        id="level3d5" placeholder="Enter level3 commission" value={level3d5}
                                        onChange={(e) => setLevel3D5(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level4d5" className="form-label">Level4 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level4d5" className="form-control form-control-lg "
                                        id="level4d5" placeholder="Enter level4 commission" value={level4d5}
                                        onChange={(e) => setLevel4D5(e.target.value)}
                                    />
                                </div>
                                </div>
                                <div className="col-sm-6 col-md-2 col-lg-2">
                                <div className="mb-4">
                                    <label for="level5d5" className="form-label">Level5 Commission<span className="required-mark">*</span></label>
                                    <input type="text" name="level5d5" className="form-control form-control-lg "
                                        id="level5d5" placeholder="Enter level5 commission" value={level5d5}
                                        onChange={(e) => setLevel5D5(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUpdateCommissionGroup} disabled={disable}>
                                    {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//get Monthly graph

export const getMonthlyGraph = async ({month}) => {
    let token = localStorage.getItem("token");
  
    let response = await instance({
      url: `api/admin/graph/total-monthly-deposit-withdraw?month=${month}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //yearly graph
  export const getYearlyGraph = async ({year}) => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: `api/admin/graph/total-yearly-deposit-withdraw?year=${year}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  //weekly graph
  export const getWeeklyGraph = async () => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: `api/admin/graph/total-weekly-deposit-withdraw`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
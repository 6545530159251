import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import { Link } from "react-router-dom";
import { message } from "antd";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { getApprovedReqUser } from "../../../api/ib";
import { GetPermissionCheck } from "../../../api/subAdmin";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const IBUsers = () => {
    const {sidebarOpen} = useAppContext();
    const[searched, setSearched]= useState("")


      //get ib users
      const{data: ibApprovedUser} = useGetdata(
        "ibApprovedUser",
        getApprovedReqUser
    )

    const headers = [
      { label: "ID", key: "id", sortable: true },  
      { label: "Name", key: "name", sortable: true },
      { label: "Email", key: "email", sortable: true },
      { label: "Phone", key: "phone", sortable: true },  
      { label: "IB Name", key: "ib_name", sortable: true },
      { label: "Total Commission", key: "totalcommission", sortable: true },
      { label: "Available Commission", key: "availablecommission", sortable: true },
      { label: "Refferal Link", key: "ib_key", sortable: true },
      { label: "Date", key:"createdAt", sortable: true },
      { label: "Action", sortable: false },
    ]

    const filteredData = ibApprovedUser?.data?.filter((item) =>
    // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase()) ||
    item.phone && item.phone.toLowerCase().includes(searched.toLowerCase()) ||
    item.ib_name && item.ib_name.toLowerCase().includes(searched.toLowerCase()) 
  )  
    
    //export to pdf 
const exportToPDF = (data) => {
    const doc = new jsPDF();
    
    // Define columns and rows based on your data structure
    const columns = [
        'ID',  
        'Name',
        'Email',
        'Phone',  
        'IB Name',
        'Total Commission',
        'Available Commission',
        'Date' 
    ];
    
    const rows = data?.map((item,index) => [
      index + 1,
      item.name,
      item.email,
      item.phone,
      item.ib_name,
      item.totalcommission,
      item.availablecommission,
      formatDate(item.createdAt)
    ]);
    doc.autoTable({ 
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
      
      },
      styles: {
        fontSize: 10,
        font: 'Gilroy' 
      }
     });
    doc.save('IB User List.pdf');
  };

//   export excel format
const exportToExcel = (data) => {
  if (!data || data?.length === 0) {
    const headers = [
      'ID',  
      'Name',
      'Email',
      'Phone',  
      'IB Name',
      'Total Commission',
      'Available Commission',
      'Date' 
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const filename = "IB User List.xlsx";

    XLSX.writeFile(wb, filename);
    return;
  }
    const dataForExport = data?.map((item,index) => ({
        'ID': index + 1,  
        'Name': item.name,
        'Email': item.email,
        'Phone': item.phone,  
        'IB Name': item.ib_name,
        'Total Commission': item.totalcommission,
        'Available Commission': item.availablecommission,
        'Date':formatDate(item.createdAt),
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'IB User List.xlsx';

    XLSX.writeFile(wb, filename);
  }

//copy ib link
const handleCopyClick = (ib_key) => {
  navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/register/${ib_key}`);
    message.success("Link copied!")
  }

  //set permission
const{data:checkPermission, refetch} = useGetdata(
  "checkPermission",
  GetPermissionCheck
)

let token = localStorage.getItem('token');
useEffect(()=>{
if(token) {
refetch()
}
},[token, refetch])

    return (
        <>
         <Helmet><title>IB User List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>IB User List</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                <div className="row mb-3 dataTables_filter">
                <div className="col-sm-12 d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(ibApprovedUser?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(ibApprovedUser?.data)}>
                            <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                    </div>
                    <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e)=> setSearched(e.target.value)} />
                        </div>
                </div>
            </div>
                                                <DataTable
                                                  data={filteredData}
                                                  headers={headers}
                                                  renderRow={((rowData)=> (
                                                <>                    
                                                    <td>{rowData.name}</td>
                                                    <td>{rowData.email}</td>
                                                    <td>{rowData.phone}</td>
                                                    <td>{rowData.ib_name}</td> 
                                                    <td>{rowData.totalcommission ? rowData.totalcommission.toFixed(2) : 0}</td>
                                                    <td>{rowData.availablecommission ? rowData.availablecommission.toFixed(2) : 0}</td>
                                                      <td>
                                                    <button className="btn btn-primary btn-xs mb-1" onClick={()=>handleCopyClick(rowData.ib_key)}>
                                                        Copy</button>
                                                    </td>
                                                    <td>{formatDate(rowData.createdAt)}</td>
                                                    <td>
                                                    {
                                                       checkPermission?.typeAdmin === 1 &&
                                                    <Link to={`/admin/ib/ibclients/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                        View Level</Link>}
                                                        {
                                                           checkPermission?.typeAdmin === 0 &&
                                                             checkPermission?.data.includes("ib_levels") ?
                                                             <Link to={`/admin/ib/ibclients/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                        View Level</Link> :""} &nbsp;
                                                        
                                                        <Link to={`/admin/ib/treechart/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                        Tree Chart</Link> &nbsp;
                                                        
                                                        {checkPermission?.typeAdmin === 1 &&
                                                        <Link to={`/admin/ib/mycommissionlist/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                        View Commission</Link>} &nbsp;

                                                        {checkPermission?.typeAdmin === 0 &&
                                                             checkPermission?.data.includes("view_commission") ?
                                                             <Link to={`/admin/ib/mycommissionlist/${rowData.id}`} className="btn btn-primary btn-xs mb-1">
                                                             View Commission</Link> : ""}
                                                    </td>
                                                </>
                                                ))}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            <BackToTopPage />
        </>
    )
}
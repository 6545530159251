import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation } from "react-query";
import { message } from "antd";
import { DemoteIbClient, getApprovedReqUser } from "../../../api/ib";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const DemoteIB = () => {
    const [ibuserId, setIbUserId] = useState("");
    const [loginError, setLoginError] = useState("")
    const[disable, setDisable] = useState(false)

    const { sidebarOpen } = useAppContext();

    //get ib users
    const{data: ibuserlist} = useGetdata(
        "ibuserlist",
        getApprovedReqUser
    )

   //move client under ib
       const demoteibs = useMutation(DemoteIbClient, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("IB Demote Successfully!");
               setDisable(false)
               setIbUserId("");
           }

       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 404) {
               message.error(data?.response?.data.message);
           }
           if (data?.response?.data.status === 500) {
               message.error(data?.response?.data.message);
           }

           if (data?.response?.data.status === 502) {
               message.error(data?.response?.data.message);
           }

       },
   });

   const handleDemoteClient = (e) => {
        e.preventDefault();
        setDisable(true)

       if (
           ibuserId === "") {
           setLoginError("Please Fill the above field.");
           setDisable(false)
           return;
       }
       const data = {
        ibuserId: Number(ibuserId)
       }
       demoteibs.mutate(data)
   }

    return (
        <>
            <Helmet><title>Demote IB | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Demote IB</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row gx-4 gx-xl-5">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select IB User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={ibuserId}
                                     onChange={(value) => setIbUserId(value)}>
                                        <option selected="">Please choose...</option>

                                        {ibuserlist?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                        ibuserId.length === 0 && loginError
                                            ? "text-danger"
                                            : "d-none"}>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleDemoteClient}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
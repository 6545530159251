import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { Tabs, message } from "antd"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getIBLevel, getTeamTotalIB } from "../../../api/ib"
import { LevelFirst } from "./level/LevelFirts"
import styled from "styled-components"
import { getSingleUser } from "../../../api/user"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"
import useGetdata from "../../../hooks/useGetData"

const StyleTabs = styled(Tabs)`
.ant-tabs-nav .ant-tabs-tab-active {
    background-color: #f37b4c;
    color:#ffff;
}
.ant-tabs-tab {
    font-size:18px;
    cursor: pointer;
    color:  #ffff;
    font-weight: 600;
    font-family: 'Gilroy';
    border:none !important;
}
.ant-tabs-nav {
    width: fit-content;
    color: #f37b4c;
    border-bottom: 2px solid #f37b4c;
}
 
.ant-tabs-tab:hover {
    color:#ffff;
    background-color:#f37b4c;
    border-color:#f37b4c;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffff;
}
.ant-tabs-content-holder {
    font-family: 'Gilroy';
}
`

export const IBClients = () => {
    const[ibData, setIbData]=useState({})
    const[singleUser, setSingleUser]= useState({})
    const[totalteamData, setTotalTeamData]= useState({})

    const { sidebarOpen } = useAppContext()
  const {id}= useParams()


   const onChange = (key) => {
  };
  
  //fetch single user
  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        const data = await getSingleUser({id})
        setSingleUser(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSingleUser();
  }, [id]);

  useEffect(() => {
    const fetchSingleIBLevel = async () => {
      try {
        const data = await getIBLevel(id)
        setIbData(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSingleIBLevel();
  }, [id]);

  useEffect(() => {
    const fetchTotalTeamData = async () => {
      try {
        const data = await getTeamTotalIB({id})
        setTotalTeamData(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchTotalTeamData();
  }, [id]);

    return (
        <>
            <Helmet><title>IB Clients | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>My Clients</h1>
                    </div>

                    <div className="row g-4 g-xxl-5 d-flex align-items-stretch">
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="h1 mb-0">{totalteamData?.data?.totalTeamMember || 0}</div>
                                    <p className="text-muted mt-1">Total Team Member</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="h1 mb-0"><span className="fw-light">$</span>{totalteamData?.data?.totalTeamDeposit ? 
                                    totalteamData?.data?.totalTeamDeposit.toFixed(2) : 0}</div>
                                    <p className="text-muted mt-1">My Team Investment</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                            <div className="bg-dark rounded-3 p-2 text-center">
                                <div className="image-part p-4">
                                    <div className="mb-0 display-3"><span className="fw-light">$</span>{totalteamData?.data?.totalTeamWithdraw ?
                                    totalteamData?.data?.totalTeamWithdraw.toFixed(2) : 0}</div>
                                    <p className="text-muted mt-1">My Team Withdraw</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-details referral-tab mt-4 mb-5">
                    <h4 className="mb-4">Refferal Clients of IB :- &nbsp; {singleUser?.data?.user?.name}</h4>
                        <StyleTabs 
                defaultActiveKey="1"
                className="text-white  bg-dark rounded-3"
                items={ibData?.data?.map((level, i) => {
                  return {
                    key:  i + 1,
                    label: `Level ${i + 1} Client`,
                    children: <LevelFirst level={level}/>,
                  };
                }) }
                onChange={onChange}
                type="card" />
                        
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { GetPermissionCheck } from "../../../api/subAdmin";
import { getMarketingWithdrawList } from "../../../api/marketing";
import { formatDate } from "../../../utility/formatTime";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";


export const MarketingWithdrawReport   = () => {
    const { sidebarOpen } = useAppContext();
    const [searched, setSearched] = useState("")


    //get ib plan 
    const { data: marketingwithdrawlist } = useGetdata(
        "marketingwithdrawlist",
        getMarketingWithdrawList
    );

    const headers = [
        { label: "ID", key:"id", sortable:true },
        { label: "Name", key:"name", sortable:true },
         { label: "Email", key:"email", sortable:true },
         { label: "Withdraw From", key:"withdraw_from", sortable:true },
         { label: "Withdraw To", key:"withdraw_to", sortable:true },
         { label: "Amount", key:"amount", sortable:true },
         { label: "Note", key:"note", sortable:true },
         { label: "Comment", key:"comment", sortable:true },
         { label: "Status", key:"status", sortable:true },
         { label: "Date", key:"createdAt", sortable:true },
         { label: "Action", sortable:false },
    ]

    const filteredData = marketingwithdrawlist?.data?.filter((item) =>
        // index + 1.toString().includes(searched) ||
    item.name && item.name.toLowerCase().includes(searched.toLowerCase()) ||
    item.email && item.email.toLowerCase().includes(searched.toLowerCase())
    )



    //set permission
    const { data: checkPermission, refetch } = useGetdata(
        "checkPermission",
        GetPermissionCheck
    )

    let token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            refetch()
        }
    }, [token, refetch])

    return (
        <>
            <Helmet><title>Marketing Withdraw Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Marketing Withdraw Report</h1>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-12 mt-3 mt-lg-5">
                            <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                                <div className="table-responsive-lg p-3">
                                    <div className="row mb-3 dataTables_filter">
                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <div className="d-flex align-items-center">
                                                <label> Search:&nbsp;</label>
                                                <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                   <DataTable
                                   data={filteredData}
                                   headers={headers}
                                   renderRow={(data) => (
                                                        <>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.withdraw_from}</td>
                                                            <td>{data.withdraw_to}</td>
                                                            <td>{data.amount}</td>
                                                            <td>{data.note}</td>
                                                            <td>{data.comment}</td>
                                                            <td>{data.status === 0 ? <span className="text-warning">Pending</span>: "" ||
                                                                data.status === 1 ? <span className="text-success">Success</span> : "" ||
                                                                data.status === 2 ? <span className="text-danger">Reject</span>: ""
                                                            }</td>
                                                            <td>{formatDate(data.createdAt)}</td>
                                                            <td>
                                                                <button className="btn btn-xs btn-primary">Submit</button>
                                                            </td>

                                                        </>
                                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//internal transfer
export const transferInternal = async (data) => {
  let token = localStorage.getItem("token");

  let response = await instance({
    url:"api/admin/internal-transfer",
    method:"POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//all transfer report
export const AllTransferData = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/transfer/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//get Transfer Report
export const getTransferReport = async (data) => {
    let token = localStorage.getItem("token");
    const {fromDate, toDate} = data;
    let response = await instance({
      url: `api/admin/report/transfer?fromDate=${fromDate}&toDate=${toDate}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get wallet history report
  export const getWalletHistoryReport = async (data) => {
    let token = localStorage.getItem("token");
    const {fromDate, toDate} = data;
    let response = await instance({
      url: `api/admin/report/wallethistory?fromDate=${fromDate}&toDate=${toDate}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };
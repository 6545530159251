import React from  'react'
import { Navigate, Outlet } from 'react-router-dom';


export const ProtectedRoute = () => {
  
  let token = localStorage.getItem("token")
      
// return !token ? (
//     <Navigate to="/admin/login"/>
// ) : (
//     <Outlet />
// );
    
// };
if (!token) {
  return <Navigate to="/admin/login" />;
}
return <Outlet />;
};
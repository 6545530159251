import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { AddUserSetting, getSettingUser, getSingleUser } from "../../../api/user";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";

export const UserSettings = () => {
    const [selectedUser, setSelectedUser]= useState({})
    const [selectuserSetting, setSelectUserSetting]= useState({})
    const [loginError, setLoginError] = useState("")
    const [disableDeposit, setDisableDeposit] = useState(0)
    const [disableWithdraw, setDisableWithdraw] = useState(0)
    const [disableTransfer, setDisableTransfer] = useState(0)
    // const [disableKYC, setDisableKyc] = useState(0)
    const [disableAccount, setDisableAccount] = useState(0)
    const [dailyDeposit, setDailyDeposit] = useState("");
    const [dailyWithdraw, setDailyWithdraw] = useState("")
    const [dailyTransfer, setDailyTransfer] = useState("");
    const [no_of_trade, setNo_of_trade] = useState("");
    const [min_deposit, setMin_deposit] = useState("")
    const[disable, setDisable]= useState(false)


    const { sidebarOpen } = useAppContext();

    const {id} = useParams();

    
    const{ isLoading:userDLoad} = useGetdata()
   
    //checkboxes
    const handleDepositChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableDeposit(newValue);
    };

    const handleWithdrawChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableWithdraw(newValue);
    }
    const handleAccounChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableAccount(newValue);
    }
    const handleTransferChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setDisableTransfer(newValue);
    }
    // const handleKycChange = (e) => {
    //     const newValue = e.target.checked ? 1 : 0;
    //     setDisableKyc(newValue);
    // }


    // fetch single user
    useEffect(() => {
        const fetchSingleUser = async () => {
          try {
            const data = await getSingleUser({id});
            setSelectedUser(data);
          } catch (error) {
            message.error(error.message);
        }
        };
    
        fetchSingleUser();
      }, [id]);


      //fetch single user setting
      useEffect(() => {
        const fetchSingleUserSettings = async () => {
          try {
            const data = await getSettingUser({id});
            setSelectUserSetting(data);
          } catch (error) {
            message.error(error.message);
        }
        };
    
        fetchSingleUserSettings();
      }, [id]);

     


      //Post user settings
      const queryclient = useQueryClient();
    const settingUser = useMutation(AddUserSetting, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("User settings Successfully!");
                setDisable(false)
                queryclient.invalidateQueries("allUserDetails");
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 404) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response.data.message);
            }
        },
    })

    useEffect(() => {
        if (!userDLoad) {
            setDisableDeposit(selectuserSetting?.data?.disable_deposit)
            setDisableWithdraw(selectuserSetting?.data?.disable_withdraw)
            setDisableTransfer(selectuserSetting?.data?.disable_transfer)
            // setDisableKyc(selectuserSetting?.data?.disable_kyc)
            setDisableAccount(selectuserSetting?.data?.disable_account)
            setDailyDeposit(selectuserSetting?.data?.daily_deposit)
            setDailyWithdraw(selectuserSetting?.data?.daily_withdraw)
            setDailyTransfer(selectuserSetting?.data?.daily_transfer)
            setNo_of_trade(selectuserSetting?.data?.no_of_trade)
            setMin_deposit(selectuserSetting?.data?.min_deposit)


        }
    }, [userDLoad,selectuserSetting]);

    const handleUserSetting = (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            // disableDeposit === "" ||
            // disableWithdraw === "" ||
            // disableTransfer === "" ||
            // disableKYC === "" ||
            // disableAccount === "" ||
            dailyDeposit === "" ||
            dailyTransfer === "" ||
            dailyWithdraw === ""
            // groupList === ""
        ) {
            setLoginError("Please Fill the above field.");
            setDisable(false)
            return;
        }
        let data = {
            disableDeposit,
            disableWithdraw,
            disableTransfer,
            // disableKYC,
            disableAccount,
            dailyDeposit: Number(dailyDeposit),
            dailyWithdraw: Number(dailyWithdraw),
            dailyTransfer: Number(dailyTransfer),
        }
         
        settingUser.mutate({ id: id, data })

    }


    return (
        <>
            <Helmet><title> User Settings | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>User Settings</h1>
                    </div>
                <div className="bg-dark rounded-3 px-4 py-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                onChange={handleDepositChange} checked={disableDeposit === 1} />
                                    <label for="name" className="form-label">Disable Deposit</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                               onChange={handleWithdrawChange} checked={disableWithdraw === 1} />
                                    <label for="name" className="form-label">Disable Withdraw</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                 onChange={handleTransferChange} checked={disableTransfer === 1} />
                                    <label for="name" className="form-label">Disable Transfer</label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                 onChange={handleAccounChange} checked={disableAccount === 1} />
                                    <label for="name" className="form-label">Disable Account</label>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                <input className="form-check-input mt-0 me-2 form-check-input-sm" type="checkbox"
                                onChange={handleKycChange} checked={disableKYC === 1} />
                                    <label for="name" className="form-label">Disable KYC</label>
                                </div>
                            </div> */}
                            </div>
                        <div className="row">
                        {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="groupList" className="form-label">Group List <span className="required-mark">*</span></label>
                                    <SelectDropdown  id="groupList"
                                    multiple
                                     value={groupList}
                                     onChange={(value)=>setGroupList(value)}>
                                     {getGroup?.data?.map((group) => (
                                         <option key={group.id} value={group.id}>{group.name}</option>

                                     ))}
                                    </SelectDropdown>
                                   
                                </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyDeposit" className="form-label">Daily Deposit <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyDeposit" className="form-control form-control-lg " id="dailyDeposit"
                                        placeholder="0"
                                        value={dailyDeposit}
                                        onChange={(e) => setDailyDeposit(e.target.value)}
                                    />
                                    <p className={
                                                dailyDeposit === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">Daily Withdraw <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={dailyWithdraw}
                                                onChange={(e) => setDailyWithdraw(e.target.value)}
                                    />
                                    <p className={
                                                dailyWithdraw === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">Daily Transfer <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={dailyTransfer}
                                                onChange={(e) => setDailyTransfer(e.target.value)}
                                    />
                                    <p className={
                                                dailyTransfer === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            {
                                selectedUser?.data?.user?.ib_status === 1 ? (
                                <>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">No of Trade <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={no_of_trade}
                                        onChange={(e) => setNo_of_trade(e.target.value)}
                                    />
                                    <p className={
                                                no_of_trade === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="mb-4">
                                    <label for="dailyWithdraw" className="form-label">Minimum Deposit <span className="required-mark">*</span></label>
                                    <input type="number" name="dailyWithdraw" className="form-control form-control-lg " id="dailyWithdraw"
                                        placeholder="0"
                                        value={min_deposit}
                                        onChange={(e) => setMin_deposit(e.target.value)}
                                    />
                                    <p className={
                                                min_deposit === "" && loginError
                                                    ? "text-danger"
                                                    : "d-none"
                                            }>{loginError}</p>
                                </div>
                            </div>
                            </>                                    
                                ) : ""
                            }
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUserSetting}
                                 disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useEffect, useState } from "react";
import { getAllCountry } from "../../../api/countries";
import useGetdata from "../../../hooks/useGetData";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { getManagementData } from "../../../api/management";
import { GetMarketingList } from "../../../api/marketing";
import { format, subMonths } from "date-fns";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { Autocomplete, TextField } from "@mui/material";
import autoCompleteCss, { autoCompleteCssDark } from "../../../component/autoCompleteCss";
import { Doughnut } from "react-chartjs-2";
import { ArcElement } from 'chart.js'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    TimeScale,
  BarElement,
    Legend,
  } from 'chart.js';
import { padding } from "@mui/system";
import { Link } from "react-router-dom";
import { message } from "antd";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement
  )

export const ManagementDashboard = () => {
	const today = new Date();
	const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
	const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

	const { sidebarOpen } = useAppContext();
	const [fromDate, setFromDate] = useState(defaultFromDate);
	const [toDate, setToDate] = useState(defaultToDate);
	const [country, setCountry] = useState("")
	const [marketing, setMarketing] = useState("")
	const [managementData, setManagementData] = useState({})
	const[disable, setDisable] = useState(false)

	const formattingDate = (date) => {
		return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
	};

	// get Country
	const { data: getCountries } = useGetdata(
		"getCountries",
		getAllCountry
	)

	// get marketing
	const { data: markeitngList } = useGetdata(
		"markeitngList",
		GetMarketingList
	)

	// search data
	const handleSearch = async (e) => {
		e.preventDefault();
		setDisable(true)

		try {
			const data = {
				country,
				marketing,
				fromDate: formattingDate(fromDate),
				toDate: formattingDate(toDate),
			};
			const result = await getManagementData(data);
			if(result.status === 200){
				setManagementData(result);
				setDisable(false)
			}
		} catch (error) {
			setDisable(false)
			message.error(error.message);
		}
	};

	useEffect(() => {
		// Fetch latest one month data automatically when component mounts or updates
		fetchLatestOneMonthData();
	}, []); // Empty dependency array to ensure it runs only once when component mounts


	const fetchLatestOneMonthData = async () => {
		try {
			const fromDate = new Date(); // Current date
			const toDate = new Date();
			fromDate.setMonth(fromDate.getMonth() - 1); // One month ago

			const data = {
				country,
				marketing,
				fromDate: formattingDate(fromDate),
				toDate: formattingDate(toDate),
			};

			const result = await getManagementData(data);
			setManagementData(result);
		} catch (error) {
			message.error(error.message);
		}
	};

	//country wise deposit chart
	const data = {
        labels: managementData?.data?.depositChart?.length > 0 ? 
					managementData?.data?.depositChart?.map(el=>el.country) :"",
        datasets: [
			{
            label: 'Deposits',
        	data: [managementData?.data?.depositChart?.length > 0 ?
				managementData?.data?.depositChart?.map(el=>el.total_deposit) :""
			],

                backgroundColor: ["#F37B4C"],
                hoverBackgroundColor: ["#F37B4C"],
            },
        ],

    };

    const options = {
        responsive: true,
        plugins: {

            legend: {
                display: true,
                position: "top",

                labels: {
                    font: {
                        size: 14, // Change the font size as needed
                        weight: "bold",
                        family: "Gilroy"

                    },
                },

            },
        }
    };


	return (
		<>
			<Helmet><title>Management Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
			<SideBar />
			<div className={`content ${sidebarOpen ? "open" : ""}`}>
				<Header />
				<div className="container-fluid px-3 px-xxl-5">
					<div className="titel-head pb-4">
						<h1>Management Report</h1>
					</div>
					<div className="row">
						<div className="col-md-12">
							<form>
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
										<div className="form-group mb-3">
											<label for="country" className="form-label">Country:</label>
											<SelectDropdown  id="countries"
												value={country}
												onChange={(value) => setCountry(value)}>
												<option selected="">Please choose...</option>

												{getCountries?.countries?.map((data) => (
													<option key={data?.code} value={data?.country}>{data?.country}</option>

												))}
											</SelectDropdown>
											{/* <Autocomplete
												sx={autoCompleteCssDark}
												limitTags={2}
												id="tags-outlined"
												filterSelectedOptions
												onChange={(event, value) => setCountry(value)}
												options={getCountries?.countries?.map((el) => {
													return {
														name: el?.country,
														value: el?.country
													}
												})}
												getOptionLabel={(option) => option.name}
												renderInput={(params) => (
													<TextField {...params} placeholder="Please Choose..." />
												)}
											/> */}
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
										<div className="form-group mb-3">
											<label for="marketing" className="form-label">Marketing Name:</label>
											<SelectDropdown id="marketing"
												value={marketing}
												onChange={(value) => setMarketing(value)}>
												{/* <option selected="">Please choose...</option> */}

												{markeitngList?.data?.map((data) => (
													<option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

												))}
											</SelectDropdown>
											{/* <Autocomplete
												sx={autoCompleteCssDark}
												limitTags={2}
												id="tags-outlined"
												filterSelectedOptions
												onChange={(event, value) => setMarketing(value)}
												options={markeitngList?.data?.map((el) => {
													return {
														name: `${el?.name} (${el.email})`,
														value: el?.id
													}
												})}
												getOptionLabel={(option) => option.name}
												renderInput={(params) => (
													<TextField {...params} placeholder="Please Choose..." />
												)}
											/> */}
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
										<div className="form-group mb-3">
											<label for="fromDate" className="form-label">From:</label>
											<input type="date" className="form-control form-control-lg  bg-dark" id="fromDate"
												value={fromDate}
												onChange={(e) => setFromDate(e.target.value)} />
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
										<div className="form-group mb-3">
											<label for="toDate" className="form-label">To:</label>
											<input type="date" className="form-control form-control-lg  bg-dark" id="toDate"
												value={toDate}
												onChange={(e) => setToDate(e.target.value)} />
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-4 col-xs-6 mt-2">
										<div className="form-group mb-3 mt-4">
											<button type="submit" className="btn btn-sm btn-primary" onClick={handleSearch}
											disabled={disable}>
												 {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6 col-md-3">
										<div className="card bg-dark rounded-3 mb-4">
											<div className="card-body">
												<div className="row align-items-center">
													<div className="col">
														<h5 className="card-title">Total Clients</h5>
														<h1 className="mb-2">{managementData?.data?.totalClients || 0}</h1>
														<Link to="/admin/user/userlist" className="text-primary">View more</Link>
													</div>
													<div className="col-auto">
														<div className="stat text-primary">
															<i className="fa fa-user"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="card bg-dark rounded-3 mb-4">
											<div className="card-body">
												<div className="row align-items-center">
													<div className="col">
														<h5 className="card-title">Total Deposit</h5>
														<h1 className="mb-2">{managementData?.data?.totalDeposit ?
														managementData?.data?.totalDeposit.toFixed(2) : 0}</h1>
														<Link to="/admin/report/depositreport" className="text-primary">View more</Link>

													</div>
													<div className="col-auto">
														<div className="stat text-primary">
															<i className="fa fa-sack-dollar"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="card bg-dark rounded-3 mb-4">
											<div className="card-body">
												<div className="row align-items-center">
													<div className="col">
														<h5 className="card-title">Total Withdraw</h5>
														<h1 className="mb-2">{managementData?.data?.totalWithdraw ? managementData?.data?.totalWithdraw.toFixed(2) : 0}</h1>
														<Link to="/admin/report/withdrawreport" className="text-primary">View more</Link>

													</div>
													<div className="col-auto">
														<div className="stat text-primary">
															<i className="fa fa-money-bill"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6 col-md-3">
										<div className="card bg-dark rounded-3 mb-4">
											<div className="card-body">
												<div className="row align-items-center">
													<div className="col">
														<h5 className="card-title">Net Deposit</h5>
														<h1 className="mb-2">{managementData?.data?.netDeposit ?
														managementData?.data?.netDeposit.toFixed(2) : 0}</h1>
														<Link to="" className="text-primary">View more</Link>
													</div>
													<div className="col-auto">
														<div className="stat text-primary">
															<i className="fa fa-money-bill-transfer"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6 mb-4">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4 h-100">
											<div className="managementtitle mb-4">
												<h4>Country Wise Deposit Chart</h4>
											</div>
											<div className="pie-chart d-flex justify-content-center align-items-center"  style={{ height:"350px", maxHeight: '400px'}}>
											<Doughnut data={data} options={options} />
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4">
											<div className="managementtitle mb-3">
												<h4>Top 10 Marketing</h4>
											</div>
											<table className="table table-dark table-striped mb-0 text-center table-hover mb-2">
												<thead>
													<tr>
														<th>ID</th>
														<th>Name</th>
														<th>Total Deposit</th>
														<th>Total Withdraw</th>
													</tr>
												</thead>
												<tbody>
													{
														managementData?.data?.topTenMarketingList?.length > 0 ?
															managementData?.data?.topTenMarketingList?.map((data, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{data.name}</td>
																	<td>{data.total_deposit || 0}</td>
																	<td>{data.total_withdraw || 0}</td>
																</tr>
															)) : ""
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4">
											<div className="managementtitle mb-3">
												<h4>Top 10 Depositor</h4>
											</div>
											<table className="table table-dark table-striped mb-0 text-center table-hover mb-2">
												<thead>
													<tr>
														<th>ID</th>
														<th>Name</th> 
														<th>Email</th>
														<th>Total Deposit</th>
													</tr>
												</thead>
												<tbody>
													{
														managementData?.data?.topTenDepositList?.length > 0 ?
															managementData?.data?.topTenDepositList?.map((data, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{data.name}</td>
																	<td>{data.email}</td>
																	<td>{data.total_deposit || 0}</td>

																</tr>

															)) : ""
													}
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-sm-12 col-md-6">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4">
											<div className="managementtitle mb-3">
												<h4>Top 10 Withdrawals</h4>
											</div>
											<table className="table table-dark table-striped mb-0 text-center table-hover mb-2">
												<thead>
													<tr>
														<th>ID</th>
														<th>Name</th>
														<th>Email</th>
														<th>Total Withdraw</th>
													</tr>
												</thead>
												<tbody>
													{
														managementData?.data?.topTenWithdrawList?.length > 0 ?
															managementData?.data?.topTenWithdrawList?.map((data, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{data.name}</td>
																	<td>{data.email}</td>
																	<td>{data.total_withdraw || 0}</td>

																</tr>

															)) : ""
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4">
											<div className="managementtitle mb-3">
												<h4>Top 10 Winner</h4>
											</div>
											<table className="table table-dark table-striped mb-0 text-center table-hover mb-2">
												<thead>
													<tr>
														<th>ID</th>
														<th>Login</th>
														<th>MT5 ID</th>
														<th>Total Profit</th>
														<th>Total Volume</th>
													</tr>
												</thead>
												<tbody>
													{
														managementData?.data?.topProfitUserList?.length > 0 ?
															managementData?.data?.topProfitUserList?.map((data, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{data.login}</td>
																	<td>{data.account_no}</td>
																	<td>{data.total_profit || 0}</td>
																	<td>{data.total_volume || 0}</td>

																</tr>

															)) : ""
													}
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-sm-12 col-md-6">
										<div className=" bg-dark rounded-3 p-3 borderbx mb-4">
											<div className="managementtitle mb-3">
												<h4>Top 10 Losers</h4>
											</div>
											<table className="table table-dark table-striped mb-0 text-center table-hover mb-2">
												<thead>
													<tr>
														<th>ID</th>
														<th>Login</th>
														<th>MT5 ID</th>
														<th>Total Loss</th>
														<th>Total Volume</th>
													</tr>
												</thead>
												<tbody>
													{
														managementData?.data?.topLossUserList?.length > 0 ?
															managementData?.data?.topLossUserList?.map((data, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{data.mt5id}</td>
																	<td>{data.total_profit || 0}</td>
																	<td>{data.total_volume || 0}</td>

																</tr>

															)) : ""
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<BackToTopPage />
		</>
	)
}
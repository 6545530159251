import { Helmet } from "react-helmet-async"

export const Page404 = () => {
    return (
        <>
        <Helmet><title>Page Not Found</title></Helmet>
        <div id="notfound">
			<div className="notfound">
				<div className="notfound-404">
					<div></div>
					<h1>404</h1>
				</div>
				<h2> Page Not Found</h2>
				<p>The page you are looking for might have been removed had its <br/>name changed or is temporarily unavailable.</p>
				 
			</div>
		</div>
        </>
    )
}
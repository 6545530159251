import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../../context/AppContext"
import { Header } from "../../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../../hooks/useGetData";
import { useEffect, useState } from "react";
import { DeleteLeadSource, UpdateLeadSource, createLeadSource, getLeadSourceList } from "../../../../api/lead";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { StyleModal } from "../../../../component/StyleModal";
import { Footer } from "../../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../../component/BackToTop";
import { CircularProgress } from "@mui/material";



export const LeadSourceList = () => {
    const { sidebarOpen } = useAppContext();
    const[open, setOpen] = useState(false);
    const[openEditModal, setOpenEditModal]= useState(false);
    const [name, setName] = useState("")
    const [selectedLeadSource, setSelectedLeadSource] = useState({})
    const[disable, setDisable] = useState(false)
    const[loading, setLoading] = useState(true)

 //get lead source list
const{data:allLeadSource, isLoading: userDLoad} = useGetdata(
    "allLeadSource",
    getLeadSourceList
)

useEffect(() => {
    // Simulate an async operation
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

    //show lead source modal
    const showAddLeadSourceModal = async () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false)
    }

   // add lead source
   const queryclient = useQueryClient();
   const addleadSource = useMutation(createLeadSource, {
       onSuccess: (data) => {
           if (data?.status === 200) {
               message.success("Lead Source Added Successfully!");
               setOpen(false);
               queryclient.invalidateQueries("allLeadSource");
               setDisable(false)
               setName("");
           }
       },
       onError: (data) => {
        setDisable(false)
           if (data?.response?.data.status === 400) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 402) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 403) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 409) {
               message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
              }
       }
   })

   const handleAddLeadSource = async (e) => {
       e.preventDefault();
       setDisable(true)

       let data = {
           name,
       }

       addleadSource.mutate(data);
       return;
   }


 //edit lead source modal
 const ShowEditLeadSourceModal = async (id) => {
    setOpenEditModal(true);
    setSelectedLeadSource(id)
}

const cancelModal = () => {
    setOpenEditModal(false)
}
const editleadSource = useMutation(UpdateLeadSource, {
    onSuccess: (data) => {
        if (data?.status === 200) {
            message.success("Lead Source Updated Successfully!");
            setOpenEditModal(false);
            queryclient.invalidateQueries("allLeadSource");
            setDisable(false)
        }
    },
    onError: (data) => {
        setDisable(false)
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
          }
    }
})

useEffect(()=>{
    if(!userDLoad) {
        setName(selectedLeadSource?.name);
    }
},[userDLoad,selectedLeadSource])

const handleUpdateLeadSource = async (e) => {
    e.preventDefault();
    setDisable(true)

    let data = {
        name,
    }

    editleadSource.mutate({id: selectedLeadSource?.id, data});
    return;
}

 //delete lead source
 const deleteleadSource = useMutation(DeleteLeadSource, {
    onSuccess: (data) => {
        if (data?.status === 200) {
            message.success("Lead Source Deleted Successfully!");
            queryclient.invalidateQueries("allLeadSource");
        }
    },
    onError: (data) => {
        if (data?.response?.data.status === 409) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 403) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 400) {
            message.error(data?.response?.data.message);
          }
          if (data?.response?.data.status === 500) {
            message.error(data?.response?.data.message);
          }
    }
})

const handleDeleteLeadSource = async (data) => {
    window.confirm("Are you want to sure delete this record?");
    deleteleadSource.mutate({id: data.id});
    
    return;
}

return (
        <>
            <Helmet><title>Lead Source | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5 pt-5">
                    <div className="titel-head pb-2 d-flex justify-content-between align-items-center">
                        <h1 className="ms-4 mb-0">Lead Source</h1>
                        <div className="me-4">
                        <button className="btn btn-xs btn-primary" onClick={showAddLeadSourceModal}><i className="fa-light fa-plus"></i>Add</button>
                        </div>
                    </div>
                    <div className="ms-2 mt-4 row">
                    {
                        loading ? 
                        <div className="d-flex justify-content-center">
                        <CircularProgress  className='text-primary'/> 
                        </div> :
                        allLeadSource?.data?.map((data)=>(
                            <div className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3">  
                            <div className="bg-dark rounded-3 p-3 text-start mb-3 box-shadow me-4" key={data.id}>
                                <div className="h1 mb-4">{data.name}</div>
                                <div className="d-flex justify-content-between align-items-center">
                    <span className="bg-success p-2 rounded-circle px-3">
                        1
                     </span>
                    <div>
                         <button className="btn bg-transparent" onClick={()=>ShowEditLeadSourceModal(data)}><i className="fa fa-edit text-warning"></i></button>
                        <button type="button" className="btn bg-transparent" onClick={()=>handleDeleteLeadSource(data)}><i className="fa fa-trash text-danger"></i></button>
                        </div>
                </div>
                            </div>
                        </div> 
                            ))
                        } 
                        </div>
                        </div>
                        {/* add lead source modal */}
                        <StyleModal
                        title="Add Lead Source"
                        open={open}
                        width={500}
                        onCancel={hideModal}
                        centered={false}>
                        
                        <div className="row p-3">
                            <div className="col-sm-12">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name<span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg"
                                        placeholder="Enter LeadSource Name"
                                        id="name"
                                         value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-sm btn-primary" type="submit" onClick={handleAddLeadSource}
                                disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Add" }</button>
                            </div>
                            </div>
                </StyleModal>
                {/* edit lead source modal */}
                <StyleModal
                            title="Edit Lead Source"
                            open={openEditModal}
                            width={500}
                            onCancel={cancelModal}
                            centered={false}>
                        
                        <div className="row p-3">
                            <div className="col-sm-12">
                                <div className="mb-4">
                                    <label for="name" className="form-label">Name<span className="required-mark">*</span></label>
                                    <input type="text" name="name" className="form-control form-control-lg "
                                        id="name"
                                         value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-sm btn-primary" type="submit" onClick={handleUpdateLeadSource}
                                disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Update" }</button>
                            </div>
                            </div>
                </StyleModal>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
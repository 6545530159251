import instance from "../instance";

//resend mail verification
export const ResendMail = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/send/verification/email",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
  };

  //send custom email
  export const SendCustomMail = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/send/email",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
  };
import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { getLiveAccountData, getPositionReportData } from "../../../api/account";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { message } from "antd";



export const PositionReport = () => {

  const { sidebarOpen } = useAppContext();
  const [positionData, setPositionData] = useState([]);
  const [account, setAccount] = useState("")
  const [searched, setSearched] = useState("")
  const[disable, setDisable] = useState(false)

  //get live account
  const { data: allLiveaccount, isLoading:userDLoad } = useGetdata(
    "allLiveaccount",
    getLiveAccountData
  )
  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {

      const result = await getPositionReportData({ account });
      if(result.status === 200){
        setPositionData(result);
        setDisable(false)
      }
    } catch (error) {
      setDisable(false)
      message.error(error.message);
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
   
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const result = await getPositionReportData({account});
      setPositionData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(()=> {
    if(!userDLoad){
      setAccount(positionData?.data?.Login)
    }
  },[positionData, userDLoad])
  
  const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Symbol", key: "Symbol", sortable: true },
    { label: "Date", key: "TimeCreate", sortable: true },
    { label: "Volume", key: "Volume", sortable: true },
    { label: "Open Price", key: "PriceOpen", sortable: true },
    { label: "S/L", key: "PriceSL", sortable: true },
    { label: "T/P", key: "PriceTP", sortable: true },
    { label: "Current Price", key: "PriceCurrent", sortable: true },
    { label: "Profit", key: "Profit", sortable: true },
  ]

  const filteredData = positionData?.data?.allPositions?.filter((item) =>
    // index + 1.toString().includes(searched) ||
  item.Symbol && item.Symbol.toString().includes(searched) ||
  item.PriceOpen && item.PriceOpen.toString().includes(searched)||
  item.Volume && item.Volume.toString().includes(searched)||
  item.PriceCurrent && item.PriceCurrent.toString().includes(searched)
    // item.amount.toString().includes(searched)
  )

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'ID',
      'Symbol',
      'Ticket',
      'Date',
      'Type',
      'Volume',
      'Open Price',
      'S/L',
      'T/P',
      'Current Price',
      'Profit'
    ];

    const rows = data?.map((item,index) => [
      index + 1,
      item.symbol,
      item.ticket,
      new Date(parseInt(data.TimeCreate) * 1000).toISOString().split("T")[0],
      item.type,
      item.volume/10000,
      item.openprice,
      item.sl,
      item.tp,
      item.current_price,
      item.profit
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Gilroy'
      }
    });
    doc.save('Position Report.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {

      const headers = [
        'ID',
        'Symbol',
        'Ticket',
        'Date',
        'Type',
        'Volume',
        'Open Price',
        'S/L',
        'T/P',
        'Current Price',
        'Profit'];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Position Report.xlsx';

      XLSX.writeFile(wb, filename);
      return;
    }


    const dataForExport = data?.map((item,index) => ({
      'ID': index + 1,
      'Symbol': item.symbol,
      'Ticket': item.ticket,
      'Date': new Date(parseInt(data.TimeCreate) * 1000).toISOString().split("T")[0],
      'Type': item.type,
      'Volume': item.volume/10000,
      'Open Price': item.openprice,
      'S/L': item.sl,
      'T/P': item.tp,
      'Current Price': item.current_price,
      'Profit': item.profit
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'Position Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

  return (
    <>
      <Helmet><title>Position Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>Position Report</h1>
          </div>
          <div className="row g-4 g-xxl-5 d-flex align-items-stretch">
            <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
              <div className="bg-dark rounded-3 p-2 text-center">
                <div className="image-part p-4">
                  <div className="h1 mb-0">{positionData?.data?.Balance ? parseFloat(positionData?.data?.Balance).toFixed(2) : 0}</div>
                  <p className="text-muted">Balance</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
              <div className="bg-dark rounded-3 p-2 text-center">
                <div className="image-part p-4">
                  <div className="h1 mb-0">{positionData?.data?.Equity ? parseFloat(positionData?.data?.Equity).toFixed(2) : 0}</div>
                  <p className="text-muted">Equity</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
              <div className="bg-dark rounded-3 p-2 text-center">
                <div className="image-part p-4">
                  <div className="mb-0 display-3">{positionData?.data?.Profit ? positionData?.data?.Profit.toFixed(2) : 0}</div>
                  <p className="text-muted">Profit</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
              <div className="bg-dark rounded-3 p-2 text-center">
                <div className="image-part p-4">
                  <div className="mb-0 display-3">{positionData?.data?.Margin ? positionData?.data?.Margin.toFixed(2) : 0}</div>
                  <p className="text-muted">Free Margin</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label><strong>MT5 ID :</strong></label>
                    <SelectDropdown id="status"
                    value={account}
                      onChange={(value) => setAccount(value)}>
                      <option selected>{positionData?.data?.Login}</option>
                      {
                        allLiveaccount?.data?.map((data) => (
                          <option key={data.id} value={data.account_no}>{data.account_no}</option>
                        ))
                      }
                    </SelectDropdown>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3 pt-4">
                    <button type="button" id="dateSearch"
                      className="btn btn-sm btn-primary"
                      onClick={handleSearch} 
                      disabled={disable}>
                         {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                  </div>
                </div>
              </div>
              <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                <div className="table-responsive-lg p-3">
                  <div className="row mb-3 dataTables_filter">
                    <div className="col-sm-12 d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(positionData?.data?.allPositions)}>
                          <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(positionData?.data?.allPositions)}>
                          <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                      </div>
                      <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    data={filteredData}
                    headers={headers}
                    renderRow={(data) => (
                      <>
                        <td>{data.Symbol}</td>
                        {/* <td>{formatDate(new Date(parseInt(data.TimeCreate) * 1000).toLocaleDateString())}</td> */}
                        <td>{new Date(parseInt(data.TimeCreate) * 1000).toISOString().split("T")[0]}</td>
                        <td>{(data.Volume/10000).toFixed(3)}</td>
                        <td>{data.PriceOpen}</td>
                        <td>{data.PriceSL}</td>
                        <td>{data.PriceTP}</td>
                        <td>{data.PriceCurrent}</td>
                        <td>{data.Profit}</td>
                      </>
                    )} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
            <BackToTopPage />
    </>
  )
}
import instance from "../instance";

// get pending deposit

export const getAllPendingDeposit = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/pending/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get all approve deposit
  export const getAllApprovedDeposit = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/approved/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //approve deposit
  export const ApproveDeposit = async (deposit) => {
    let token = localStorage.getItem("token");
     const { id, data}= deposit
    let response = await instance({
      url: `api/admin/deposit/approve/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

   //Reject deposit
   export const RejectDeposit = async (deposit) => {
    let token = localStorage.getItem("token");
     const { id, data}= deposit
    let response = await instance({
      url: `api/admin/deposit/reject/${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get monthly deposit

  export const getMonthlyDeposit = async ({month}) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/month/totalamount?month=${month}&year`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

   //get Yearly deposit

   export const getYearlyDeposit = async ({year}) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/deposit/year/totalamount?year=${year}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //get Weekly Deposit

export const getWeeklyDeposit = async () => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/deposit/week/totalamount`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};


//get Deposit Report

export const getDepositReport = async (data) => {
  let token = localStorage.getItem("token");
  const {fromDate, toDate, type, paymentmethod} = data;
  let response = await instance({
    url: `api/admin/report/deposit?fromDate=${fromDate}&toDate=${toDate}&type=${type}&paymentmethod=${paymentmethod}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};

//add client deposit
export const AddClientDeposit = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: `api/admin/deposit/add`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type':'multipart/form-data',
    },
    data,
  });

  return response.data;
};
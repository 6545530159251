import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { useState } from "react";
import useGetdata from "../../../hooks/useGetData";
import { useMutation, useQueryClient } from "react-query";
import {  getAllUser } from "../../../api/user";
import { Image, message } from "antd";
import { AddUserKyc } from "../../../api/kyc";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";

export const UploadUserDocuments = () => {
    const [userId, setUserId] = useState("");
    const [poi, setPoi] = useState(null);
    const [poa, setPoa] = useState(null);
    // const [poaexpiredate, setPoaExpireDate] = useState("");
    // const [poiexpiredate, setPoiExpireDate] = useState("");
    const[loginError, setLoginError]= useState("")
    const[disable, setDisable]= useState(false)
    const [poifileDataUrl, setPoiFileDataUrl] = useState(null);
    const [poafileDataUrl, setPoaFileDataUrl] = useState(null);


    const { sidebarOpen } = useAppContext();

    //get All users
    const { data: allUsers } = useGetdata(
        "allUsers",
        getAllUser
    )
    
    const handlePoAFileChange = (e) => {
        const file = e.target.files[0];
        setPoa(file);

        if (file) {
            setPoa(file);
            const objectUrl = URL.createObjectURL(file);
            setPoaFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
    };

    const handlePoIFileChange = (e) => {
        const file = e.target.files[0];
        setPoi(file);

        if (file) {
            setPoi(file);
            const objectUrl = URL.createObjectURL(file);
            setPoiFileDataUrl(objectUrl);
        } else {
            message.error('No file selected');
        }
        
    };

    // upload document
    const quericlient = useQueryClient();
    const addKyc = useMutation(AddUserKyc, {
        onSuccess: (data) => {
            if (data?.status === 200) {
                message.success("KYC Added Successfully!");
                setDisable(false)
               quericlient.invalidateQueries("approveKycList")
               
            }
    
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 500) {
                message.error(data?.response?.data.message);
            }
    
            if (data?.response?.data.status === 502) {
                message.error(data?.response?.data.message);
            }
    
    
        },
    });
    

    const handleUploadDocument = (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            userId === "" ||
            poi === "" ||
            poa === "") {
            setLoginError(`Please Fill the  field.`);
            setDisable(false);
            return;
        }

        const formData = new FormData();
        formData.append('userId', userId);
        // formData.append('poiexpiredate', poiexpiredate);
        // formData.append('poaexpiredate', poaexpiredate);
        formData.append('poi', poi);
        formData.append('poa', poa);
        addKyc.mutate(formData);

    }

       return (
        <>
            <Helmet><title>Upload User Documents | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
            <SideBar />
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                <Header />
                <div className="container-fluid px-3 px-xxl-5">
                    <div className="titel-head pb-4">
                        <h1>Upload User Documents</h1>
                    </div>
                <div className="bg-dark rounded-3 p-4 px-xl-6 py-xl-5 mb-4">
                    <form>
                        <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="mb-4">
                                    <label for="user" className="form-label">Select User <span className="required-mark">*</span></label>
                                    <SelectDropdown id="user"
                                    value={userId}
                                     onChange={(value) => setUserId(value)}>
                                        <option selected="">Please choose...</option>

                                        {allUsers?.data?.map((data) => (
                                            <option key={data?.id} value={data?.id}>{data?.name} ({data.email})</option>

                                        ))}
                                    </SelectDropdown>
                                    <p className={
                                    userId.length === 0 && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="poi" className="form-label">Proof of Identity <span className="required-mark">*</span></label>
                                    <input className="form-control form-control-lg bg-secondary" id="poi" name="poi"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handlePoIFileChange} />
                            {
                                poifileDataUrl && 
                                <Image
                                        src={poifileDataUrl}
                                        className="mt-2"
                                        alt="Selected"
                                        width={100}
                                        height={70}
                                    />
                            }
                                    <p className={
                                    !poi && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                <div className="mb-4">
                                    <label for="poa" className="form-label">Proof of Address <span className="required-mark">*</span></label>
                                    <input className="form-control form-control-lg bg-secondary" id="poa" name="poa"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handlePoAFileChange} />
                             {
                                poafileDataUrl && 
                                <Image
                                        src={poafileDataUrl}
                                        className="mt-2"
                                        alt="Selected"
                                        width={100}
                                        height={70}
                                    />
                            }
                                    <p className={
                                    !poa && loginError
                                        ? "text-danger"
                                        : "d-none"
                                }>{loginError}</p>
                                </div>
                                </div>
                            <div className="col-md-12 pt-2">
                                <button className="btn btn-primary btn-xs font-15" type="submit"
                                 onClick={handleUploadDocument}
                                 disabled={disable}>
                                     { disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }
                                 </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Footer />
            <BackToTopPage />
        </>
    )
}
import instance from "../instance";

//get All group
export const getAllGroup = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/group/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //add group
export const CreateGroup = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/group/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //Update Group
  export const UpdateGroupdData = async (group) => {
    let token = localStorage.getItem("token");
    const {id, data} = group

    let response = await instance({
      url: `api/admin/group/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //get Single group details
  export const getSingleGroup = async (group) => {
    const {data} = group
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/group/${group}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //delete group
  export const DeleteGroup = async (group) => {
    let token = localStorage.getItem("token");
    const {id} = group;
    let response = await instance({
      url: `api/admin/group/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
    
  };

  //update mt-5 group
export const Mt5GroupUpdate = async (data) => {
  let token = localStorage.getItem("token");
  let response = await instance({
    url: "api/admin/group/account/update",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response.data;
};
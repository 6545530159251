import { Helmet } from "react-helmet-async"
import { Header } from "../../../layout/dashboardLayout/Header"
import { SideBar } from "../../../layout/dashboardLayout/SideBar"
import { useAppContext } from "../../../context/AppContext"
import { MarkAllReadNotifications, MarkReadNotifications, getAllUnReadList } from "../../../api/notifications"
import useGetdata from "../../../hooks/useGetData"
import { Link } from "react-router-dom"
import { fDateTime } from "../../../utility/formatTime"
import { useQueryClient } from "react-query"
import {  useState } from "react"
import { Footer } from "../../../layout/dashboardLayout/Footer"
import { BackToTopPage } from "../../../component/BackToTop"
import { message } from "antd"

export const UnreadNotificationList = () => {
  const [read, setRead] = useState({})
  const { sidebarOpen } = useAppContext();

  //get notification data
  const { data: unreadNotification } = useGetdata(
    "unreadNotification",
    getAllUnReadList
  )

  //read single notification
  const queryclient = useQueryClient();
  const fetchNotificationData = async (id) => {
    try {
      const response = await MarkReadNotifications({ id });
      setRead(response.data);
      queryclient.invalidateQueries("unreadNotification")
    } catch (error) {
      message.error( error);
    }
  };

  const handleReadOneNotification = (id) => {
    fetchNotificationData(id);

  }

  // mark all read
  const handleMarkAllAsRead = async () => {
    try {
      const response = await MarkAllReadNotifications();
      queryclient.invalidateQueries("unreadNotification")
    } catch (error) {
      message.error(error.message);
    }
  };

 

  return (
    <>
      <Helmet><title>Unread Notification List | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>Unread Notification List</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="bg-dark pb-4 rounded-3 p-4 box-shadow">
                {
                  unreadNotification?.data.map((data) => (
                    <div className="alert bg-black" role="alert" key={data.id}>
                      [{fDateTime(data.createdAt)}] {data.type}. &nbsp;
                        <Link to="" className="float-right mark-as-read" style={{ fontSize: "13px" }}
                          onClick={() => handleReadOneNotification(data?.id)}>
                          Mark as read
                        </Link>
                    </div>
                  ))}
                <div>
                  
                    <button className="btn btn-xs btn-primary" to="" id="mark-all" onClick={handleMarkAllAsRead}>
                      Mark all read
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
            <BackToTopPage />

    </>
  )
}
import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../../context/AppContext";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import { Header } from "../../../layout/dashboardLayout/Header";
import { GetIBTreeData, GetSingleIBTreeDetail } from "../../../api/ib";
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { getSingleUser } from "../../../api/user";
import { StyleModal } from "../../../component/StyleModal";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { message } from "antd";


export const IBTreeChart = () => {
  const { sidebarOpen } = useAppContext();
  const[ibtreeData, setIBTreeData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState({});
  const [userData, setUserData] = useState({});
  const {id} = useParams();


//fetch single user  
  useEffect(() => {
    fetchSingleUser()
  },[])

 

  const fetchSingleUser = async () => {
    try {
          const result = await getSingleUser({id});
      setUserData(result);
    } catch (error) {
      message.error( error);
    }
  };

//   get single tree data
useEffect(() => {
    fetchTreeData()
  },[])

  const fetchTreeData = async () => {
    try {
          const result = await GetIBTreeData({id});
      setIBTreeData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

    //show modal
    const showDetailModal = async (id) => {
        let response = await GetSingleIBTreeDetail(id);
        setSelectedDetail(response?.data);
        setShowModal(true);
      };
      const handleCloseModal = () => {
        setShowModal(false);
      };
    

  //get tree data
  const TreeNode = ({ data, level }) => (
    <ul>
      {data?.map((item, index) => (
        <li key={index + 1}>
          <div className={`treeview__level`} data-level={level}>
            <span className="level-title">
              <button
                className="ibdatabtn"
                onClick={() => showDetailModal(item)}
              >
                {item.title}
              </button>
            </span>
          </div>
          {/* Recursively render children */}
          {item.children && <TreeNode data={item.children} level={level + 1} />}
        </li>
      ))}
    </ul>
  );
  return (
    <>
      <Helmet>
        <title> IB Tree Chart | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>Tree Chart</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="bg-dark pb-4 rounded-3 box-shadow table-responsive">
                <div className="treeview">
                <ul>
                    <li>
                      <div className="treeview__level" data-level="0">
                        <span className="level-title mainibs">
                          <button className="ibdatabtn">
                            {userData?.data?.user.ib_status === 1 && userData?.data?.user.name}
                          </button>
                        </span>
                      </div>
                      {/* Render tree nodes using TreeNode component */}
                      <TreeNode data={ibtreeData?.data} level={1} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/* detail modal */}
          <StyleModal
          title="Tree Chart"
          open={showModal}
          width={800}
          onCancel={handleCloseModal}
          footer={[]}
        >
          <div className="p-3">
            <table className="table table-striped mb-0 text-center table-hover">
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Total Volume</th>
                  <th>Total Commission</th>
                  <th>Parent Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="clientnm">{selectedDetail?.name}</td>
                  <td className="clientlot">{selectedDetail?.totalVolume}</td>
                  <td className="clientcomm">{selectedDetail?.totalCommission}</td>
                  <td className="clientparentnm">{selectedDetail?.ibname}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </StyleModal>
      </div>
      <Footer />
            <BackToTopPage />
    </>
  );
};

import { Helmet } from "react-helmet-async"
import { useAppContext } from "../../../context/AppContext"
import { Header } from "../../../layout/dashboardLayout/Header";
import { SideBar } from "../../../layout/dashboardLayout/SideBar";
import useGetdata from "../../../hooks/useGetData";
import { formatDate } from "../../../utility/formatTime";
import jsPDF from "jspdf"
import 'jspdf-autotable';
import * as XLSX from "xlsx"
import { useEffect, useState } from "react";
import { getLiveAccountData } from "../../../api/account";
import { format, subMonths } from "date-fns";
import { GetHistoryReport } from "../../../api/auth";
import DataTable from "../../../component/DataTable";
import { Footer } from "../../../layout/dashboardLayout/Footer";
import { BackToTopPage } from "../../../component/BackToTop";
import { SelectDropdown } from "../../../component/DropDownComponent";
import { message } from "antd";



export const HistoryReport = () => {
  const today = new Date();
  const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date in 'yyyy-MM-dd' format
  const defaultFromDate = format(subMonths(today, 1), 'yyyy-MM-dd'); // One month ago

  const { sidebarOpen } = useAppContext();
  const [account, setAccount] = useState("")
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [historyData, setHistoryData] = useState([]);
  const [searched, setSearched] = useState("")
  const[disable, setDisable] = useState(false)

  const formattingDate = (date) => {
    return new Date(date).toISOString().slice(0, 10); // Adjust the locale as needed
  };

  //get live account
  const { data: allLiveaccount, isLoading: userDLoad } = useGetdata(
    "allLiveaccount",
    getLiveAccountData
  )
  //search data
  const handleSearch = async () => {
    setDisable(true)
    try {
      const data = {
        account,
        fromDate: formattingDate(fromDate),
        toDate: formattingDate(toDate),
      }
      const result = await GetHistoryReport(data);
      if(result.status === 200){
        setHistoryData(result);
        setDisable(false)
      }
    } catch (error) {
      setDisable(false)
      message.error(error.message);
    }
  };

  useEffect(() => {
    // Fetch latest one month data automatically when component mounts or updates
    fetchLatestOneMonthData();
  }, []); // Empty dependency array to ensure it runs only once when component mounts

  const fetchLatestOneMonthData = async () => {
    try {
      const toDate = new Date(); // Current date
      const fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 1); // One month ago

      const data = {
        account: 10002,
        fromDate: formattingDate(fromDate),
        toDate: formattingDate(toDate),
      }

      const result = await GetHistoryReport(data);
      setHistoryData(result);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(()=> {
    if(!userDLoad){
      setAccount(10002)
    }
  },[userDLoad])

  const headers = [
    { label: "ID", key: "id", sortable: true },
    { label: "Login", key: "Login", sortable: true },
    { label: "Deal", key: "Deal", sortable: true },
    { label: "Date", key: "Time", sortable: true },
    { label: "Ticket", key: "TickSize", sortable: true },
    { label: "Symbol", key: "Symbol", sortable: true },
    { label: "Price", key: "Price", sortable: true },
    { label: "Profit", key: "Profit", sortable: true },
    { label: "Volume", key: "Volume", sortable: true },
    { label: "Commission", key: "Commission", sortable: true },
    { label: "ExpertPosition ID", key: "ExpertPositionID", sortable: true },
    { label: "Entry", key: "Entry", sortable: true },
    { label: "Action", key: "Action", sortable: true },
    { label: "Comment", key: "Comment", sortable: true },
  ]

  const filteredData = historyData?.data?.filter((item) =>
    item.Login && item.Login.toString().includes(searched) ||
    item.Commission && item.Commission.toString().includes(searched) ||
    item.Volume && item.Volume.toString().includes(searched) ||
    item.Symbol && item.Symbol.toLowerCase().includes(searched.toLowerCase()) ||
  item.Price && item.Price.toString().includes(searched) ||
  item.Profit && item.Profit.toString().includes(searched)  
  )

  const actionData = filteredData?.filter(el=> el.Entry === 1 || el.Entry === 3)

  //export to pdf 
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Define columns and rows based on your data structure
    const columns = [
      'Login',
      'Date',
      'Ticket',
      'Symbol',
      'Price',
      'Profit',
      'Volume',
      'Commission',
      'ExpertPosition ID',
      'Entry',
      'Action',
      'Comment',
    ];

    const rows = data?.map((item, index) => [
      item.Login,
      new Date(parseInt(item.Time) * 1000).toISOString().split("T")[0],
      item.TickSize,
      item.Symbol,
      item.Price,
      item.Profit,
      item.Volume/10000,
      item.Commission,
      item.ExpertPositionID,
      item.Entry,
      item.Action,
      item.Comment,
    ]);
    doc.autoTable({
      // columns, body: rows
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },

      },
      styles: {
        fontSize: 10,
        font: 'Gilroy'
      }
    });
    doc.save('History Report.pdf');
  };

  //   export excel format
  const exportToExcel = (data) => {
    if (!data || data?.length === 0) {

      const headers = [
        'Login',
        'Deal',
        'Date',
        'Ticket',
        'Symbol',
        'Price',
        'Profit',
        'Volume',
        'Commission',
        'ExpertPosition ID',
        'Entry',
        'Action',
        'Comment'];

      const ws = XLSX.utils.aoa_to_sheet([headers]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'History Report.xlsx';

      XLSX.writeFile(wb, filename);
      return;
    }


    const dataForExport = data?.map((item, index) => ({
      
      'Login': item.Login,
      'Deal': item.Deal,
      'Date': new Date(parseInt(item.Time) * 1000).toISOString().split("T")[0],
      'Ticket': item.TickSize,
      'Symbol': item.Symbol,
      'Price': item.Price,
      'Profit': item.Profit,
      'Volume': item.Volume/10000,
      'Commission': item.Commission,
      'ExpertPosition ID': item.ExpertPositionID,
      'Entry': item.Entry,
      'Action': item.Action,
      'Comment': item.Comment,
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'History Report.xlsx';

    XLSX.writeFile(wb, filename);
  }

  return (
    <>
      <Helmet><title>History Report | {process.env.REACT_APP_PROJECT_NAME}</title></Helmet>
      <SideBar />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Header />
        <div className="container-fluid px-3 px-xxl-5">
          <div className="titel-head pb-4">
            <h1>History Report</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 mt-3 mt-lg-5">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label><strong>MT5 ID :</strong></label>
                    <SelectDropdown id="status" className="black-select2"
                      value={account}
                      onChange={(value) => setAccount(value)}>
                      {/* <option selected>Please Choose...</option> */}
                      {
                        allLiveaccount?.data?.map((data) => (
                          <option key={data.id} value={data.account_no}>{data.account_no}</option>
                        ))
                      }
                    </SelectDropdown>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label><strong>From :</strong></label>
                    <input type="Date" className="form-control form-control-lg bg-dark" name="fromdate" id="fromdate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3">
                    <label><strong>To :</strong></label>
                    <input type="Date" className="form-control form-control-lg bg-dark " name="todate" id="todate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)} />
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                  <div className="form-group mb-3 pt-4">
                    <button type="button" id="dateSearch"
                      className="btn btn-sm btn-primary"
                      onClick={handleSearch}
                      disabled={disable}>
                         {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                  </div>
                </div>
              </div>
              <div className="bg-dark pb-4 rounded-3 box-shadow table-rounded">
                <div className="table-responsive-lg p-3">
                  <div className="row mb-3 dataTables_filter">
                    <div className="col-sm-12 d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <button className="btn btn-xs btn-primary" onClick={() => exportToPDF(actionData)}>
                          <i className="fa fa-cloud-download"></i>&nbsp;PDF</button>
                        <button className="btn btn-xs btn-primary" onClick={() => exportToExcel(actionData)}>
                          <i className="fa fa-cloud-download"></i>&nbsp;EXCEL</button>

                      </div>
                      <div className="d-flex align-items-center">
                        <label> Search:&nbsp;</label>
                        <input type="search" className="bg-dark  h-100" value={searched} onChange={(e) => setSearched(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    data={actionData}
                    headers={headers}
                    renderRow={(rowData) => (
                      <>
                        <td>{rowData.Login}</td>
                        <td>{rowData.Deal}</td>
                        <td>{new Date(parseInt(rowData.Time) * 1000).toISOString().split("T")[0]}</td>
                        {/* <td>{formatDate(new Date(parseInt(rowData.Time) * 1000).toLocaleDateString())}</td> */}
                        <td>{rowData.TickSize}</td>
                        <td>{rowData.Symbol}</td>
                        <td>{rowData.Price}</td>
                        <td>{rowData.Profit}</td>
                        <td>{(rowData.Volume/10000).toFixed(3)}</td>
                        <td>{rowData.Commission}</td>
                        <td>{rowData.ExpertPositionID}</td>
                        <td>{rowData.Entry}</td>
                        <td>{rowData.Action === 0 ? <span className="text-success">Buy</span> : 
                        rowData.Action === 1 ? <span className="text-danger">Sell</span> : ""}</td>
                        <td>{rowData.Comment}</td>
                      </>
                    )} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <BackToTopPage />
    </>
  )
}
import instance from "../instance";

// Create Lead
export const createLead = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "/api/admin/lead/add",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

//Get Lead
export const getLeadData = async () => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/lead/all`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response.data;
  };

  //Get single Lead 
export const getSingleLeadData = async (data) => {
  let token = localStorage.getItem("token");
  const {id} = data;
  let response = await instance({
    url: `api/admin/lead/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

  //update Lead
  export const UpdateLeadData = async (lead) => {
    let token = localStorage.getItem("token");
    const {id, data} = lead
    let response = await instance({
      url: `api/admin/lead/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //delete lead
  export const DeleteLead = async (lead) => {
    const {id}= lead
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/lead/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
    
  };

  //serach Lead
  export const SearchLeadData = async (queryParams) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: `api/admin/lead/search?${queryParams}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  

  //create lead source
  export const createLeadSource = async (data) => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: "api/admin/leadsource/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get lead source
  export const getLeadSourceList = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/leadsource/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //edit lead source
  export const UpdateLeadSource = async (lead) => {
    let token = localStorage.getItem("token");
    const {id, data} = lead
    let response = await instance({
      url: `api/admin/leadsource/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //delete lead source
  export const DeleteLeadSource = async (lead) => {
    let token = localStorage.getItem("token");
    const {id, data} = lead
    let response = await instance({
      url: `api/admin/leadsource/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

  //create lead status
  export const CreateLeadStatus = async (data) => {
    let token = localStorage.getItem("token");

    let response = await instance({
      url: "api/admin/leadstatus/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //get lead status
  export const getLeadStatusList = async (data) => {
    let token = localStorage.getItem("token");
    let response = await instance({
      url: "api/admin/leadstatus/all",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  
    return response.data;
  };

  //lead status update
  export const UpdateLeadStatus = async (lead) => {
    let token = localStorage.getItem("token");
    const {id, data} = lead
    let response = await instance({
      url: `api/admin/leadstatus/update/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  
    return response.data;
  };

    //delete lead status
    export const DeleteLeadStatus = async (lead) => {
      const {id}= lead
      let token = localStorage.getItem("token");
      let response = await instance({
        url: `api/admin/leadstatus/delete/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
      
    };

    //lead assign to to sales
    export const LeadAssignTo = async (lead) => {
      let token = localStorage.getItem("token");
        const {id, data}= lead
      let response = await instance({
        url: `api/admin/lead/assign/${id}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };
   
    //convert lead
    export const ConvertLead = async (lead) => {
      let token = localStorage.getItem("token");
        const {id, data}= lead
      let response = await instance({
        url: `api/admin/lead/convert/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };
   
    //create lead notes
    export const createLeadNotes = async (data) => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: "api/admin/leadnote/create",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };

    //get lead notes
    export const GetLeadNotes = async (lead) => {
      let token = localStorage.getItem("token");
        const {id, data}= lead
      let response = await instance({
        url: `api/admin/leadnote/all/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };

    //update lead note
    export const UpdateLeadNotes = async (lead) => {
      let token = localStorage.getItem("token");
    
      const {leadid, noteid, data} = lead
      let response = await instance({
        url: `api/admin/leadnote/update/${leadid}/${noteid}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data
      });
    
      return response.data;
    };

    //delete lead notes
    export const DeleteLeadNotes = async (lead) => {
      let token = localStorage.getItem("token");
    
      const {leadid, noteid, data} = lead
      let response = await instance({
        url: `api/admin/leadnote/delete/${leadid}/${noteid}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data
      });
    
      return response.data;
    };

//create lead reminders
    export const createLeadReminder = async (data) => {
      let token = localStorage.getItem("token");
      let response = await instance({
        url: "api/admin/leadreminder/create",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };

    //get lead reminder
    export const GetLeadReminder = async (data) => {
      let token = localStorage.getItem("token");
        const {id}= data
      let response = await instance({
        url: `api/admin/leadreminder/all/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
    
      return response.data;
    };

      //update lead Reminder
      export const UpdateLeadReminder = async (lead) => {
        let token = localStorage.getItem("token");
      
        const {leadid, noteid, data} = lead
        let response = await instance({
          url: `api/admin/leadreminder/update/${leadid}/${noteid}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };

      //delete lead remind
      export const DeleteLeadReminder = async (lead) => {
        let token = localStorage.getItem("token");
      
        const {leadid, noteid, data} = lead
        let response = await instance({
          url: `api/admin/leadreminder/delete/${leadid}/${noteid}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data
        });
      
        return response.data;
      };
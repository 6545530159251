import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useGetdata from "../../../../hooks/useGetData"
import { DeleteLeadNotes, GetLeadNotes, UpdateLeadNotes, createLeadNotes } from "../../../../api/lead"
import { formatDate } from "../../../../utility/formatTime"
import { useMutation, useQueryClient } from "react-query"
import {  message } from "antd"
import { StyleModal } from "../../../../component/StyleModal"


export const LeadNotes = () => {
    // const [leadNoteData, setLeadNoteData] = useState(null)
    const [note, setNote]= useState("")
    const[open, setOpen]= useState(false)
    const[selectedLeadNote, setSelectedLeadNote] = useState({})
    const[disable, setDisable] =useState(false)
    const { id } = useParams()
    const { isLoading: userDLoad } = useGetdata()

     //get note  
    const { data: singleLeadNotes} = useGetdata(
        ['leadNoteData'], // Define the query key including the user id
        async () => {
          const data = await GetLeadNotes({ id }); // Fetch  lead  notes data
          return data;
        },
        {
          onSuccess: () => {
            // Invalidate the query on successful data fetch
            queryclient.invalidateQueries(['leadNoteData']);
          },
        }
      );
   
     //add lead notes
     const queryclient = useQueryClient();
     const leadNote = useMutation(createLeadNotes, {
         onSuccess: (data) => {
             if (data?.status === 200) {
                 message.success("Lead Notes Added Successfully!");
                 queryclient.invalidateQueries("leadNoteData");
                 setDisable(false)
                 setNote("");
             }
         },
         onError: (data) => {
            setDisable(false)
             if (data?.response?.data.status === 400) {
                 message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 402) {
                 message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 403) {
                 message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 404) {
                 message.error(data?.response?.data.message);
             }
             if (data?.response?.data.status === 409) {
                 message.error(data?.response?.data.message);
             }
         }
     })
 
     const handleAddLeadNotes = async (e) => {
         e.preventDefault();
         setDisable(true)
           
         leadNote.mutate({leadId:Number(id), note});
         return;
         
     }
 
    //  edit lead notes modal
     const showEditLeadNotesModal = (id) => {
        setOpen(true)
        setSelectedLeadNote(id)
     }
     const hideModal = () => {
        setOpen(false)
     }

     //edit lead notes
    const editleadNote = useMutation(UpdateLeadNotes, {
        onSuccess: (data) => {
            if (data.status === 200) {
                message.success("Lead Notes Updated Successfully!");
                queryclient.invalidateQueries("leadNoteData");
                setDisable(false)
                setOpen(false);
                setNote("");
            }
        },
        onError: (data) => {
            setDisable(false)
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
        }
    })

    useEffect(() => {
        if (!userDLoad) {
            setNote(selectedLeadNote?.note);
        }
    },[userDLoad,selectedLeadNote])

    const handleEdit = () => {
        setDisable(true)
        // const data = {
        //     note,
        // }
        editleadNote.mutate({leadid:Number(id), noteid: selectedLeadNote?.id, note })
    }

    //delete lead notes
    const deleteleadNote = useMutation(DeleteLeadNotes, {
        onSuccess: (data) => {
            if (data.status === 200) {
                message.success("Lead Notes Deleted Successfully!");
                queryclient.invalidateQueries("leadNoteData");
            }
        },
        onError: (data) => {
            if (data?.response?.data.status === 404) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 403) {
                message.error(data?.response?.data.message);
            }
            if (data?.response?.data.status === 400) {
                message.error(data?.response?.data.message);
            }
        }
    })

    const handleDelete = async (data) => {
        window.confirm("Are you want to sure delete this record?")

        deleteleadNote.mutate({leadid: data.lead_id, noteid: data.id});
        
        return;
    }


    return (
        <>
        <div className="p-4">
            <div className="mb-4">
                <form>
                <div className="mb-4">
                                <label for="note" className="fs-4 mb-3">Add Note</label>
                                <textarea type="text" className="form-control form-control-lg" id="note" name="note"
                                 value={note}
                                 onChange={(e)=>setNote(e.target.value)}></textarea>
                </div>
                <div>
                     <button className="btn btn-primary btn-xs font-15" type="submit" onClick={handleAddLeadNotes}
                     disabled={disable}>
                         {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Submit" }</button>
                </div>
                </form>
            </div>
            <div className="">
                        <div className="comments">
                            <h4>Your Notes</h4>
                            {
                            singleLeadNotes?.data?.map((data) => (
                                <div className="card bg-dark mb-3" key={data.id}>
                                <div className="card card-heading px-3 pb-0 pt-3 rounded-top"
                                style={{
                                     borderBottomLeftRadius: "none !important",
                                     borderBottomRightRadius: "none !important",
                                background: "#0c0c0c"}}>
                                    <div className="p-0 d-flex justify-content-between align-items-center">
                                        <div className="w-50">
                                            <h4>
                                                {data?.note}                                            
                                            </h4>
                                        </div>
                                        <div>
                                            <span className="me-2">{formatDate(data.createdAt)}</span>
                                            <button className="bg-primary p-1 rounded border-0 text-white me-2" onClick={()=>showEditLeadNotesModal(data)}><i className="fa fa-edit"></i></button>
                                            <button className="bg-primary p-1 rounded border-0 text-white me-2" onClick={()=>handleDelete(data)}>
                                            <i className="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={{background:"#0c0c0c"}}>
                                    <p> {data.lead_id} </p>
                                </div>
                            </div>))}
                            </div>
                    </div>
                     {/* edit lead notes */}
                <StyleModal
                            title="Edit Lead Notes"
                            open={open}
                            width={500}
                            onCancel={hideModal}
                            centered={false}>
                        
                        <div className="row p-3">
                            <div className="col-sm-12">
                                <div className="mb-4">
                                    <label for="note" className="form-label">Note<span className="required-mark">*</span></label>
                                    <textarea type="text"  name="note" className="form-control form-control-lg "
                                        id="note"
                                         placeholder="Enter note"
                                         value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            
                            <div className="col-sm-2">
                                <button className="btn btn-sm btn-primary" type="submit" onClick={handleEdit}
                                disabled={disable}>
                                     {disable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                                    "Edit" }</button>
                            </div>
                            </div>
                </StyleModal>
        </div>
        </>
    )
}